// Vendors
import React from "react";

export const arrowUpIcon = (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.72389 0.918214C4.55497 0.924806 4.35968 0.994354 4.23657 1.11021L0.220017 4.89046C-0.0102103 5.14656 -0.105137 5.63784 0.161019 5.9241C0.423217 6.20607 0.93148 6.19767 1.19466 5.91685L4.0151 3.25892L4.0151 12.2094C4.0151 12.6008 4.33251 12.9182 4.72392 12.9182C5.11534 12.9182 5.43274 12.6008 5.43274 12.2094L5.43274 3.25892L8.25318 5.91685C8.49544 6.15878 9.02183 6.20344 9.28682 5.9241C9.55199 5.64492 9.46728 5.13617 9.22766 4.89046L5.21111 1.11021C5.07367 0.980841 4.91244 0.917885 4.72389 0.918214Z"
      fill="#44C300"
    />
  </svg>
);
