// Vendors
import React, { useState, useContext } from "react";
import { Form, Input } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { message } from "antd";

// AWS
import {
  verifyUserAWS,
  resendVerificationCodeUserAWS,
} from "../../AWS/UserPool";

// Context
import { FormContext } from "../../context/contextProviders/FormContextProvider";

// Components
import Logo from "../../components/Logo/Logo";
import MainButton from "../../components/MainButton/MainButton";
import MainModal from "../../components/MainModal/MainModal";

// Styles
import classes from "./VerifyUser.module.scss";

// Assets
import { successIcon } from "../../assets/icons/success/success";

const VerifyUser = ({ setModalToShow, modalToShow }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [verificationError, setVerificationError] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");

  const { formData, clearFormData } = useContext(FormContext);

  const handleSendVerificationCode = (values) => {
    clearFormData();
    sendVerificationCode(values);
  };

  const sendVerificationCode = (values) => {
    resendVerificationCodeUserAWS(
      values.username,
      () => {
        setVerificationError("");
        setCurrentStep(currentStep + 1);
        setCurrentUsername(values.username);
      },
      (err) => {
        setVerificationError(err.message || JSON.stringify(err));
      }
    );
  };

  const handleFinishProcess = (values) => {
    verifyUserAWS(
      currentUsername,
      values.verificationCode,
      successCodeHandler,
      wrongCodeHandler
    );
  };

  const wrongCodeHandler = (error) => {
    setVerificationError(error);
  };

  const successCodeHandler = () => {
    message.success({
      content: "Your user has been verified!",
      className: "successMessage",
    });
    setVerificationError("");
    setModalToShow("login");
  };

  const UserVerificationSteps = [
    {
      buttonText: "Continue",
      title: "Lets verify your account!",
      handleSubmitTry: handleSendVerificationCode,
      inputs: [
        {
          name: "username",
          placeholder: "Username",
          prefix: <UserOutlined style={{ fontSize: "150%" }} />,
          rules: [{ required: true, message: "Please input your username!" }],
        },
      ],
    },
    {
      buttonText: "Finish",
      title: "Verification code sent!",
      handleSubmitTry: handleFinishProcess,
      inputs: [
        {
          name: "verificationCode",
          placeholder: "Verification Code",
          prefix: <KeyOutlined style={{ fontSize: "150%" }} />,
          rules: [
            {
              required: true,
              message: "Please type the code sent to your email",
            },
          ],
        },
      ],
    },
  ];

  return (
    <MainModal
      open={modalToShow}
      onCancel={() => setModalToShow(false)}
      footer={null}
      closable={false}
    >
      <Form
        className={classes.formContainer}
        name="verify-user"
        initialValues={{ username: formData?.username || "" }}
        onFinish={UserVerificationSteps[currentStep].handleSubmitTry}
        autoComplete="off"
      >
        <div className={classes.UserVerificationContentContainer}>
          <div className={classes.userVerificationModalTitle}>
            <Logo />
          </div>
          <p
            className={`${classes.greetingText} ${
              currentStep === UserVerificationSteps.length - 1 &&
              classes.successText
            }`}
          >
            {currentStep === UserVerificationSteps.length - 1 && (
              <span className={classes.successIcon}>{successIcon}</span>
            )}
            {UserVerificationSteps[currentStep].title}
          </p>
          <div className={classes.inputsContainer}>
            {UserVerificationSteps[currentStep].inputs.map((input) => {
              return (
                <Form.Item
                  key={input.name}
                  validateTrigger={["onBlur"]}
                  className={classes.formItem}
                  name={input.name}
                  rules={input.rules}
                >
                  <Input
                    disabled={input.disabled}
                    size="large"
                    type={input.type}
                    placeholder={input.placeholder}
                    className={classes.loginInputBox}
                    prefix={input.prefix}
                    suffix={input.suffix}
                    bordered={false}
                  />
                </Form.Item>
              );
            })}
            {currentStep === 1 && (
              <p className={classes.userVerifiedEmailSentText}>
                Verification Code sent to your email!
              </p>
            )}
            {currentStep === 1 && (
              <p className={classes.successUserVerificationText}>
                If you provided a valid username, we will send you the
                verification code.
              </p>
            )}
          </div>
        </div>

        {verificationError && (
          <span className={classes.verificationError}>{verificationError}</span>
        )}

        <div className={classes.UserVerificationFooterContainer}>
          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText={UserVerificationSteps[currentStep].buttonText}
              fullWidth="fullWidth"
            />
          </div>

          {currentStep !== UserVerificationSteps.length - 1 ? (
            <div className={classes.changeLoginText}>
              <span className={classes.changeLoginEmphasisText}>
                "Have an account?"
              </span>
              <MainButton
                buttonText="Sign in"
                type="text"
                clicked={() => setModalToShow("login")}
              />
            </div>
          ) : (
            <div className={classes.changeLoginSpacer} />
          )}
        </div>
      </Form>
    </MainModal>
  );
};

export default VerifyUser;
