// Vendors
import React from "react";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./EducationLeagueOfLegendsPage.module.scss";

// Assets
import { tsmLogo, fanaticLogo } from "../../assets/icons/logos/logos";

const EducationLeagueOfLegendsPage = () => {
  return (
    <div className={classes.educationGuidesPage}>
      <div className={classes.pageBannerContainer}>
        <GameBannerRenderer game={"leagueOfLegends"} />
      </div>
      <div className={classes.pageHeader}>
        <h2>What is League of Legends</h2>
        <p>
          League of Legends (LoL), commonly referred to as League, is a 2009
          multiplayer online battle arena video game developed and published by
          Riot Games. Inspired by Defense of the Ancients, a custom map for
          Warcraft III, Riot's founders sought to develop a stand-alone game in
          the same genre. Since its release in October 2009, League has been
          free-to-play and is monetized through purchasable character
          customization.
        </p>
        <p>
          In the game, two teams of five players battle in player-versus-player
          combat, each team occupying and defending their half of the map. Each
          of the ten players controls a character, known as a "champion", with
          unique abilities and differing styles of play. During a match,
          champions become more powerful by collecting experience points,
          earning gold, and purchasing items to defeat the opposing team. In
          League's main mode, Summoner's Rift, a team wins by pushing through to
          the enemy base and destroying their "Nexus", a large structure located
          within.
        </p>
      </div>
      <div className={classes.pageContent}>
        <h2>League of Legends Betting basics</h2>
        <h3>How do MoneyLine Odds Work?</h3>
        <p>
          A popular bet in League of Legends is the moneyline, which requires
          you to pick the winner of the game. But it’s not a 50-50 proposition
          in most cases – you won’t get paid the same for taking FNC to beat TSM
          as you would for taking the TSM to beat FNC, given the ability of each
          team.
        </p>
        <p>
          So each team is assigned moneyline odds by the sportsbook based on
          their ability, and bettors have to pay a corresponding price.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"FNC"} icon={fanaticLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-300</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
            <IconLabel
              label={"TSM"}
              icon={tsmLogo}
              isSvg
              size="large"
              inverted
            />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"TSM"} icon={tsmLogo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
          </div>
        </div>

        <p>
          American odds like these are centered around $100. So if you bet $300
          on FNC at -300, you’d win $100 if they win. If you bet $100 on TSM at
          +250 and they win, you’d win $250. You don’t have to bet $100, of
          course. If you bet $3 on FNC you’d win $1. Bet $1 on TSM, win $2.50.
        </p>
        <p>
          You don’t have to bet $100, of course. If you bet $3 on FNC you’d win
          $1. Bet $1 on TSM, win $2.50.
        </p>
      </div>
    </div>
  );
};

export default EducationLeagueOfLegendsPage;
