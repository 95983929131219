// Vendors
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { Drawer } from "antd";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Components
import MainButton from "../../components/MainButton/MainButton";
import HeaderProfile from "../../components/HeaderProfile/HeaderProfile";
import MainSelect from "../../components/MainSelect/MainSelect";

// Styles
import classes from "./MobileMenuSideDrawer.module.scss";

// Assets
import { userIcon } from "../../assets/icons/user/user";
import { exitIcon } from "../../assets/icons/exit/exit";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const MobileMenuSideDrawer = ({ openDrawer, onClose, setModalToShow }) => {
  const {
    userSession,
    userData,
    logoutSession,
    preferredOddsType,
    setPreferredOddsType,
  } = useContext(UserContext);

  const handleOpenLogin = (loginOrSigUp) => {
    setModalToShow(loginOrSigUp);
    onClose();
  };

  const handleLogOut = () => {
    logoutSession();
    onClose();
  };

  return (
    <Drawer onClose={onClose} open={openDrawer} width={266} closable={false}>
      <div className={classes.closeDrawerButtonContainer}>
        <MainButton
          type="secondary"
          icon={<CloseOutlined />}
          clicked={onClose}
        />
      </div>

      <div className={classes.drawerContentContainer}>
        <nav className={classes.navBar}>
          {globalConstants.MENU_ITEMS.slice(0, 5).map((item) => {
            return (
              <NavLink
                onClick={onClose}
                className={({ isActive }) =>
                  `${classes.navBarLink} ${
                    isActive && classes.activeNavBarLink
                  }`
                }
                key={item.key}
                to={item.key}
              >
                <span className={classes.navLinkBox}>{item.label}</span>
                {item.key === "sportsbooks" ? (
                  <span className={classes.bonusesText}>BONUSES</span>
                ) : null}
              </NavLink>
            );
          })}
        </nav>
        <div className={classes.userButtonsContainer}>
          <div className={classes.preferredControls}>
            <MainSelect
              size="small"
              currentValue={globalConstants.PREFERRED_ODDS_ITEMS.find(
                (item) => {
                  return item.key === preferredOddsType;
                }
              )}
              setCurrentValue={(item) => setPreferredOddsType(item.key)}
              options={globalConstants.PREFERRED_ODDS_ITEMS}
            />
          </div>
          {userSession ? (
            <HeaderProfile
              username={userSession.accessToken.payload.username}
              image={userData?.imageUrl}
            />
          ) : null}

          {userSession ? (
            <NavLink
              className={classes.profileLinkContainer}
              onClick={onClose}
              to={`profile/${userSession.accessToken.payload.username}`}
            >
              <MainButton
                type={"secondary"}
                buttonText={"My profile"}
                clicked={() => {}}
                icon={userIcon}
                fullWidth="fullWidth"
                justification="flex-start"
              />
            </NavLink>
          ) : (
            <MainButton
              className={classes.loginButton}
              clicked={() => handleOpenLogin("login")}
              buttonText="Login"
              type="secondary"
              fullWidth="fullWidth"
            />
          )}
          <MainButton
            type={userSession ? "text" : "primary"}
            buttonText={userSession ? "Logout" : "Sign Up"}
            clicked={() =>
              userSession ? handleLogOut() : handleOpenLogin("signUp")
            }
            icon={userSession ? exitIcon : <UserOutlined />}
            fullWidth="fullWidth"
            justification={userSession ? "flex-start" : null}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default MobileMenuSideDrawer;
