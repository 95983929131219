export const resultsPageConstants = {
  TABS: {
    ALL: "ALL",
    LIVE: "LIVE",
    STARTING: "STARTING",
    FINISHED: "FINISHED",
  },

  ESPORTS_RESULTS_ITEMS: [
    {
      label: "All games",
      key: "",
    },
    {
      label: "League of Legends",
      key: "leagueOfLegends",
    },
    {
      label: "Rocket League",
      key: "rocketLeague",
    },
    {
      label: "VALORANT",
      key: "valorant",
    },
    {
      label: "Counter-Strike",
      key: "csgo",
    },
    {
      label: "Call of Duty",
      key: "callOfDuty",
    },
  ],
};
