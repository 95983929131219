// Vendors
import React from "react";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./MatchTrends.module.scss";

// Utils
import { formatNumberWithSymbol } from "../../utils/globalUtils";

const MatchTrends = ({ seriesDetails }) => {
  const teamOne = seriesDetails.teamOne;
  const teamTwo = seriesDetails.teamTwo;

  return (
    <div className={classes.trendsContainer}>
      <div className={classes.titleContainer}>
        <h3>Trends</h3>
      </div>
      <div className={classes.trendList}>
        {seriesDetails.abiosGameId === globalConstants.LEAGUE_OF_LEGENDS ? (
          <>
            {teamOne.trends || teamTwo.trends ? (
              <>
                {teamOne?.trends?.avgScore && teamOne?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne?.trends?.matchesCount ?? ""
                    } matches, ${
                      teamOne.teamName
                    } is averaging ${teamOne.trends.avgScore.toFixed(
                      2
                    )} kills a game `}
                  </div>
                ) : null}
                {teamOne?.trends?.avgOpponentScore &&
                teamOne?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne?.trends?.matchesCount ?? ""
                    } matches, opponents of ${
                      teamOne.teamName
                    } average ${teamOne.trends.avgOpponentScore.toFixed(
                      2
                    )} kills a game `}
                  </div>
                ) : null}
                {teamTwo?.trends?.avgScore && teamTwo?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo?.trends?.matchesCount ?? ""
                    } matches, ${
                      teamTwo.teamName
                    } is averaging ${teamTwo.trends.avgScore.toFixed(
                      2
                    )} kills a game `}
                  </div>
                ) : null}
                {teamTwo?.trends?.avgOpponentScore &&
                teamTwo?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo?.trends?.matchesCount ?? ""
                    } matches, opponents of ${
                      teamTwo.teamName
                    } average ${teamTwo.trends.avgOpponentScore.toFixed(
                      2
                    )} kills a game `}
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}

        {seriesDetails.abiosGameId === globalConstants.VALORANT ||
        seriesDetails.abiosGameId === globalConstants.CS_GO ? (
          <>
            {teamOne.trends || teamTwo.trends ? (
              <>
                {teamOne?.trends?.maps &&
                teamOne?.trends?.score &&
                teamOne?.trends?.differential &&
                teamOne?.trends?.matchesCount &&
                teamOne?.trends?.opponentScore ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne.trends.matchesCount ?? ""
                    } matches, ${teamOne.teamName} has played ${
                      teamOne.trends.maps
                    } maps, went ${teamOne.trends.score}-${
                      teamOne.trends.opponentScore
                    }, and has a round differential of ${formatNumberWithSymbol(
                      teamOne.trends.differential
                    )}`}
                  </div>
                ) : null}
                {teamOne?.trends?.oppAverageRounds &&
                teamOne?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne.trends.matchesCount ?? ""
                    } matches, opponents of ${
                      teamOne.teamName
                    } average ${teamOne.trends.oppAverageRounds.toFixed(
                      2
                    )} rounds a map`}
                  </div>
                ) : null}
                {teamTwo?.trends?.maps &&
                teamTwo?.trends?.score &&
                teamTwo?.trends?.differential &&
                teamTwo?.trends?.matchesCount &&
                teamTwo?.trends?.opponentScore ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo.trends.matchesCount ?? ""
                    } matches, ${teamTwo.teamName} has played ${
                      teamTwo.trends.maps
                    } maps, went ${teamTwo.trends.score}-${
                      teamTwo.trends.opponentScore
                    }, and has a round differential of ${formatNumberWithSymbol(
                      teamTwo.trends.differential
                    )}`}
                  </div>
                ) : null}
                {teamTwo?.trends?.oppAverageRounds &&
                teamTwo?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo.trends.matchesCount ?? ""
                    } matches, opponents of ${
                      teamTwo.teamName
                    } average ${teamTwo.trends.oppAverageRounds.toFixed(
                      2
                    )} rounds a map`}
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}

        {seriesDetails.abiosGameId === globalConstants.ROCKET_LEAGUE ? (
          <>
            {teamOne.trends || teamTwo.trends ? (
              <>
                {teamOne?.trends?.avgScore && teamOne?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne?.trends?.matchesCount ?? ""
                    } matches, ${
                      teamOne.teamName
                    } is averaging ${teamOne.trends.avgScore.toFixed(
                      2
                    )} goals a game `}
                  </div>
                ) : null}
                {teamOne?.trends?.avgOpponentScore &&
                teamOne?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamOne.teamAbbreviation ||
                        seriesDetails?.teamOne.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamOne.teamImgSm ||
                        seriesDetails?.teamOne.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamOne?.trends?.matchesCount ?? ""
                    } matches, opponents of ${
                      teamOne.teamName
                    } average ${teamOne.trends.avgOpponentScore.toFixed(
                      2
                    )} goals a game`}
                  </div>
                ) : null}
                {teamTwo?.trends?.avgScore && teamTwo?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo?.trends?.matchesCount ?? ""
                    } matches, ${
                      teamTwo.teamName
                    } is averaging ${teamTwo.trends.avgScore.toFixed(
                      2
                    )} goals a game `}
                  </div>
                ) : null}
                {teamTwo?.trends?.avgOpponentScore &&
                teamTwo?.trends?.matchesCount ? (
                  <div className={classes.trendsCard}>
                    <IconLabel
                      label={
                        seriesDetails?.teamTwo.teamAbbreviation ||
                        seriesDetails?.teamTwo.teamName
                      }
                      size="small"
                      icon={
                        seriesDetails?.teamTwo.teamImgSm ||
                        seriesDetails?.teamTwo.teamImgLg
                      }
                    />
                    {`In the last ${
                      teamTwo?.trends?.matchesCount ?? ""
                    } matches, opponents of ${
                      teamTwo.teamName
                    } average ${teamTwo.trends.avgOpponentScore.toFixed(
                      2
                    )} goals a game`}
                  </div>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MatchTrends;
