// Vendors
import React, { useEffect, useState, useContext, useMemo } from "react";
import { message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { Checkbox } from "antd";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Components
import Loading from "../../components/Loading/Loading";
import MainButton from "../../components/MainButton/MainButton";
import MainSelect from "../../components/MainSelect/MainSelect";
import MainModal from "../../components/MainModal/MainModal";
import BetVerificationCard from "../../components/BetVerificationCard/BetVerificationCard";
import VerifyBets from "../../components/VerifyBets/VerifyBets";
import ScoreCard from "../../components/ScoreCard/ScoreCard";

// API
import {
  finishBetVerification,
  requestUserBetVerificationListData,
  requestUserData,
} from "../../api/requests/requests";

// Constants
import { verificationPageConstants } from "./constants/constants";
import { globalConstants } from "../../constants/globalConstants";

// Utils
import {
  capitalizeFirstLetter,
  formatNumberWithSymbol,
} from "../../utils/globalUtils";

// Assets
import { arrowUpIcon } from "../../assets/icons/arrowUp/arrowUp";
import { arrowDownIcon } from "../../assets/icons/arrowDown/arrowDown";
import defaultProfilePicture from "../../assets/images/profile/defaultProfilePicture.jpg";

// Styles
import classes from "./BetVerificationPage.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const BetVerificationPage = ({ title }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { userSession, preferredOddsType, userData } = useContext(UserContext);

  const [loadingRequestedBetList, setLoadingRequestedBetList] = useState(false);

  const [requestedUserData, setRequestedUserData] = useState(null);
  const [requestedBetList, setRequestedBetList] = useState(null);

  const [betToVerify, setBetToVerify] = useState(null);
  const [verifyOption, setVerifyOption] = useState("");

  const [menuTab, setMenuTab] = useState(
    verificationPageConstants.TABS.VERIFIED
  );

  const [loadingFinishVerifyProcess, setLoadingFinishVerifyProcess] =
    useState(false);

  const handleCloseBetToVerify = () => {
    setBetToVerify(null);
    setVerifyOption("");
  };

  const handleOpenBetToVerify = (bet) => {
    setBetToVerify(bet);
  };

  const handleVerificationCheckbox = (option) => {
    setVerifyOption(option);
  };

  const handleGetUserData = () => {
    requestUserData(
      params.id,
      (data) => {
        setBetVerificationList(
          data?.betHistorySummary?.record?.bets?.bets.filter((bet) => {
            return (
              bet.verificationStatus !== globalConstants.VERIFIED &&
              bet.verificationStatus !== globalConstants.PENDING
            );
          })
        );
        setRequestedUserData(data);
      },
      () => {
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  const handleGetBetList = (sub) => {
    setLoadingRequestedBetList(true);
    requestUserBetVerificationListData(
      { status: menuTab.API_KEY, sub: sub ?? null },
      (data) => {
        setRequestedBetList(data.bets);
        setLoadingRequestedBetList(false);
      },
      () => {
        setLoadingRequestedBetList(false);
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  const handleSubmitVerification = () => {
    setLoadingFinishVerifyProcess(true);
    if (!!verifyOption) {
      finishBetVerification(
        { status: verifyOption, betId: betToVerify.verificationId },
        () => {
          message.success({
            content: "The bet has been verified correctly!",
            className: "successMessage",
          });
          setLoadingFinishVerifyProcess(false);
          setBetToVerify(null);
          navigate(`/${globalConstants.ROUTES.BET_VERIFICATION}/${params.id}/`);
        },
        (error) => {
          message.error({
            content: error,
            className: "errorMessage",
          });
          setLoadingFinishVerifyProcess(false);
        }
      );
    }
  };

  const handleShowBetsForAllUsers = () => {
    setRequestedUserData(null);
    navigate(
      `/${globalConstants.ROUTES.BET_VERIFICATION}/${globalConstants.ALL_USERS}/`
    );
  };

  const generateMenuOptions = () => {
    const options = [verificationPageConstants.TABS.VERIFIED];
    if (isProfileOwner || isAdmin) {
      options.push(verificationPageConstants.TABS.ALL);
      options.push(verificationPageConstants.TABS.PENDING);
      options.push(verificationPageConstants.TABS.DECLINED);
    }
    return options;
  };

  useEffect(() => {
    if (params.id === globalConstants.ALL_USERS) {
      handleGetBetList();
    } else {
      handleGetUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (
      (requestedUserData && requestedUserData.sub) ||
      params.id === globalConstants.ALL_USERS
    ) {
      handleGetBetList(requestedUserData?.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedUserData, menuTab]);

  const isProfileOwner = useMemo(() => {
    return params.id === userSession?.accessToken?.payload?.username;
  }, [params, userSession]);

  const isAdmin = useMemo(() => {
    return userData?.user?.staff === globalConstants.instalock;
  }, [userData]);

  /// Ticket submit process

  const [betVerificationList, setBetVerificationList] = useState([]);
  const [showBetVerification, setShowBetVerification] = useState(false);

  const handleOpenBetsToVerify = (bet) => {
    const newBetList = [...betVerificationList];
    setBetVerificationList(newBetList);
    setShowBetVerification(true);
  };

  const handleCloseBetsToVerify = () => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      currentBet.isSelected = false;
      return currentBet;
    });

    setBetVerificationList(newBetList);
    setShowBetVerification(false);
  };

  const handleRemoveBetToVerify = (bet) => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      if (bet.betId === currentBet.betId) {
        currentBet.isSelected = false;
      }
      return currentBet;
    });

    setBetVerificationList(newBetList);
  };

  const handleToggleBetToVerify = (betId) => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      if (currentBet.betId === betId) {
        currentBet.isSelected = !currentBet.isSelected;
      }

      return currentBet;
    });
    setBetVerificationList(newBetList);
  };

  useEffect(() => {
    if (params.id === globalConstants.ALL_USERS && !isAdmin) {
      navigate(`/`);
    }
  }, [params, isAdmin, navigate]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return !loadingRequestedBetList && requestedBetList ? (
    <div className={classes.BetVerificationPageContainer}>
      <div className={classes.titleAndControls}>
        <h3 className={classes.titleContainer}>
          {requestedUserData ? (
            <div
              className={classes.profileContainer}
              style={{
                backgroundImage: `url(${
                  requestedUserData.imageUrl || defaultProfilePicture
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          ) : null}
          Verified bets
          {requestedUserData?.user?.username ? (
            <>
              {" by   "}

              <span>
                {capitalizeFirstLetter(requestedUserData?.user?.username)}
              </span>
              <div className={classes.titleSpacer} />
              <div className={classes.recordScoreList}>
                <div className={classes.overallRecord}>
                  <p>Overall record</p>
                </div>
                {formatNumberWithSymbol(
                  requestedUserData?.betHistorySummary?.record?.allTime?.roi?.toFixed(
                    2
                  )
                ) ? (
                  <div className={classes.roiAmount}>
                    {formatNumberWithSymbol(
                      requestedUserData?.betHistorySummary?.record?.allTime?.roi?.toFixed(
                        2
                      )
                    )}

                    {"% ROI"}
                    <span className={classes.recordCardArrowContainer}>
                      {requestedUserData?.betHistorySummary?.record?.allTime
                        ?.roi >= 0
                        ? arrowUpIcon
                        : arrowDownIcon}
                    </span>
                  </div>
                ) : null}
                <div className={classes.recordScore}>
                  <ScoreCard
                    result="win"
                    score={
                      requestedUserData?.betHistorySummary?.record?.allTime
                        ?.wins
                    }
                  />
                </div>
                <div className={classes.recordScore}>
                  <ScoreCard
                    result="push"
                    score={
                      requestedUserData?.betHistorySummary?.record?.allTime
                        ?.pushes
                    }
                  />
                </div>
                <div className={classes.recordScore}>
                  <ScoreCard
                    result="loss"
                    score={
                      requestedUserData?.betHistorySummary?.record?.allTime
                        ?.losses
                    }
                  />
                </div>
              </div>
            </>
          ) : null}
        </h3>
      </div>

      {isProfileOwner || isAdmin ? (
        <div className={classes.switchButtonListTablet}>
          <div className={classes.buttonListTablet}>
            <div className={classes.menuButtonContainer}>
              <MainButton
                buttonText={verificationPageConstants.TABS.ALL.label}
                fullWidth="fullWidth"
                clicked={() => setMenuTab(verificationPageConstants.TABS.ALL)}
                type={
                  menuTab.key === verificationPageConstants.TABS.ALL.key
                    ? "quinary"
                    : "senary"
                }
              />
            </div>
            <div className={classes.menuButtonContainer}>
              <MainButton
                buttonText={verificationPageConstants.TABS.VERIFIED.label}
                fullWidth="fullWidth"
                clicked={() =>
                  setMenuTab(verificationPageConstants.TABS.VERIFIED)
                }
                type={
                  menuTab.key === verificationPageConstants.TABS.VERIFIED.key
                    ? "quinary"
                    : "senary"
                }
              />
            </div>
            <div className={classes.menuButtonContainer}>
              <MainButton
                buttonText={verificationPageConstants.TABS.DECLINED.label}
                fullWidth="fullWidth"
                clicked={() =>
                  setMenuTab(verificationPageConstants.TABS.DECLINED)
                }
                type={
                  menuTab.key === verificationPageConstants.TABS.DECLINED.key
                    ? "quinary"
                    : "senary"
                }
              />
            </div>
            <div className={classes.menuButtonContainer}>
              <MainButton
                buttonText={verificationPageConstants.TABS.PENDING.label}
                fullWidth="fullWidth"
                clicked={() =>
                  setMenuTab(verificationPageConstants.TABS.PENDING)
                }
                type={
                  menuTab.key === verificationPageConstants.TABS.PENDING.key
                    ? "quinary"
                    : "senary"
                }
              />
            </div>
          </div>
          <div className={classes.controls}>
            {isAdmin && params.id !== globalConstants.ALL_USERS ? (
              <MainButton
                buttonText="Show all users"
                clicked={handleShowBetsForAllUsers}
              />
            ) : null}
            {isProfileOwner ? (
              <MainButton
                buttonText="Submit a ticket"
                clicked={handleOpenBetsToVerify}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      {isProfileOwner || isAdmin ? (
        <div className={classes.switchButtonListMobile}>
          <MainSelect
            currentValue={menuTab}
            setCurrentValue={setMenuTab}
            options={generateMenuOptions()}
          />
        </div>
      ) : null}

      {requestedBetList.length > 0 ? (
        <div className={classes.betList}>
          {requestedBetList.map((bet) => {
            return (
              <BetVerificationCard
                key={bet.betId}
                betData={bet}
                preferredOddsType={preferredOddsType}
                isAdmin={isAdmin}
                handleOpenBetToVerify={handleOpenBetToVerify}
                isProfileOwner={isProfileOwner}
              />
            );
          })}
        </div>
      ) : (
        <div className={classes.noDataCard}>
          There are no current bets for this section . . .
        </div>
      )}
      <MainModal
        open={!!betToVerify}
        onCancel={handleCloseBetToVerify}
        footer={null}
        closable={false}
        customTitle="Review"
      >
        <div className={classes.verifyModalContent}>
          <div className={classes.imageContainer}>
            <img src={betToVerify?.verificationPictureUrl} alt="bulbasaur" />
          </div>
        </div>

        <div className={classes.verifyModalControls}>
          <div
            className={classes.checkBoxLabelCard}
            style={{
              borderColor:
                verifyOption ===
                verificationPageConstants.VERIFY_OPTIONS.VERIFIED
                  ? styledVariables.backgroundEmphasisB
                  : "",
            }}
            onClick={() =>
              handleVerificationCheckbox(
                verificationPageConstants.VERIFY_OPTIONS.VERIFIED
              )
            }
          >
            <div className={classes.contentCheckbox}>
              <Checkbox
                checked={
                  verifyOption ===
                  verificationPageConstants.VERIFY_OPTIONS.VERIFIED
                }
              />
            </div>
            <span className={classes.checkboxLabel}>Mark as verified</span>
          </div>

          <div
            className={classes.checkBoxLabelCard}
            style={{
              borderColor:
                verifyOption === verificationPageConstants.VERIFY_OPTIONS.DENIED
                  ? styledVariables.backgroundEmphasisB
                  : "",
            }}
            onClick={() =>
              handleVerificationCheckbox(
                verificationPageConstants.VERIFY_OPTIONS.DENIED
              )
            }
          >
            <div className={classes.contentCheckbox}>
              <Checkbox
                checked={
                  verifyOption ===
                  verificationPageConstants.VERIFY_OPTIONS.DENIED
                }
              />
            </div>
            <span className={classes.checkboxLabel}>Deny</span>
          </div>
        </div>
        <div className={classes.verifyModalButton}>
          <MainButton
            buttonText="Submit"
            fullWidth="fullWidth"
            loading={loadingFinishVerifyProcess}
            clicked={handleSubmitVerification}
          />
        </div>
      </MainModal>
      <VerifyBets
        betVerificationList={betVerificationList}
        showBetVerification={showBetVerification}
        handleCloseBetsToVerify={handleCloseBetsToVerify}
        handleToggleBetToVerify={handleToggleBetToVerify}
        preferredOddsType={preferredOddsType}
        handleRemoveBetToVerify={handleRemoveBetToVerify}
        username={userSession?.accessToken.payload.username}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default BetVerificationPage;
