// Styles
import styledVariables from "../../../styles/utils/_variables.scss";

// Assets
import { thunderPick } from "../../../assets/icons/staff/thunderPick.js";
import { esportsbet2 } from "../../../assets/icons/staff/esportsbet2.js";
import { underdogFantasy } from "../../../assets/icons/staff/underdogFantasy";
import { prizePicks } from "../../../assets/icons/staff/prizePicks";
import { bovada } from "../../../assets/icons/staff/bovada";
import { betOnline } from "../../../assets/icons/staff/betOnline";
import { cloutFantasy } from "../../../assets/icons/staff/cloutFantasy";
import { hotstreak } from "../../../assets/icons/staff/hotstreak";

import { globalConstants } from "../../../constants/globalConstants";

export const sportsbooksPageConstants = {
  SPORTSBOOK: [
    {
      name: "Thunderpick",
      icon: thunderPick,
      description:
        "Thunderpick is a crypto-oriented esports betting brand created by gamers for gamers. The brand offers a unique experience thanks to an advanced gamification system, modern design, and the best coverage of esports with competitive markets. Key features: #1 esports betting brand with branded tournaments. Quick & anonymous registration. Fast crypto deposits and withdrawals with no fees. Races, a lot of giveaways, and more.",
      perks: [
        "10+ eSports",
        "Instant Withdrawals / No Fees",
        "Quick Registration",
      ],
      url: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
      color: styledVariables.backgroundEmphasisF,
      promo: "Get a First Time Deposit Bonus up to 550€",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "Esportsbet",
      icon: esportsbet2,
      description:
        "Esportsbet.io is the world’s premier crypto sports & esports betting platform with an embedded statistical matrix to help you understand what goes into our odds matrix. ESB offers 24/7, human customer support, the highest bet limits, and the most available markets anywhere! ESB is committed to portray how responsible sports betting can truly elevate the esports viewing experience. [Sign up today with our link to take advantage of their exclusive partner bonuses!]",
      perks: ["10+ eSports", "Precise Live Odds", "Quick Registration"],
      url: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
      color: styledVariables.backgroundPrimaryD,
      promo: "Claim a 50% Deposit Bonus on Any Currency up to 100 USDT",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "Bovada",
      icon: bovada,
      description:
        "Bovada is one of the most popular sportsbooks in the United States. It is exclusive to the US only (minus Nevada, New Jersey, Maryland, and Delaware).",
      perks: ["10+ eSports", "Crypto Deposits/Withdrawals", "Deposit Bonuses"],
      url: globalConstants.SPORTS_BOOKS_LINKS.BOVADA,
      color: styledVariables.backgroundPrimaryD,
      promo: "Get a 75% Crypto Match Deposit Bonus up to $750",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "BetOnline",
      icon: betOnline,
      description:
        "BetOnline has been around for 25 years and is one of the most trusted sportsbooks on the market. They are available in the United States and Europe.",
      perks: [
        "10+ eSports",
        "Crypto and Credit Card Deposits",
        "48 hour Payouts",
      ],
      url: globalConstants.SPORTS_BOOKS_LINKS.BET_ONLINE,
      color: styledVariables.backgroundEmphasisF,
      promo: "Get a 50% Welcome Bonus up to $1000",
      promoColor: styledVariables.backgroundEmphasisK,
    },
  ],
  FANTASY: [
    {
      name: "HotStreak Fantasy",
      icon: hotstreak,
      description:
        "With the most projections to chose from, advanced research tools, and blazing fast payouts, HotStreak is the ultimate app for playing smart and winning big",
      perks: ["LoL/ more esports soon", "Daily Fantasy", "US Only"],
      url: globalConstants.SPORTS_BOOKS_LINKS.HOTSTREAK,
      color: styledVariables.hotstreak,
      promo: "100% Deposit match up to $100 with code ‘INSTALOCK’",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "Underdog Fantasy",
      icon: underdogFantasy,
      description:
        "Underdog Fantasy is a daily fantasy sports app available to US users in 30+ states and Canadian users (excluding Ontario).",
      perks: ["LoL/CS/VAL", "Daily Fantasy", "North America Only"],
      url: globalConstants.SPORTS_BOOKS_LINKS.UDNERDOG_FANTASY,
      color: styledVariables.underdogFantasy,
      promo: "Get 100% Match Deposit up to $100",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "PrizePicks",
      icon: prizePicks,
      description:
        "Prizepicks is a daily fantasy sports app available to US users in 30+ states and Canadian users (excluding Ontario).",
      perks: ["LoL/CS/VAL/CoD/RL/Dota", "Daily Fantasy", "North America Only"],
      url: globalConstants.SPORTS_BOOKS_LINKS.PRIZE_PICKS,
      color: styledVariables.black,
      promo: "Get 100% Match Deposit up to $100",
      promoColor: styledVariables.backgroundEmphasisK,
    },
    {
      name: "Clout Fantasy",
      icon: cloutFantasy,
      description:
        "Clout Fantasy gives fans the power to compete alongside every professional esports matchup through Fantasy. Acquire knowledge, join contests, stream matches & track your ranking in real-time. Clout Fantasy is a unique twist on esports betting that is in a category of its own.",
      perks: ["LoL/CS/VAL", "Fantasy", "United States Only"],
      url: globalConstants.SPORTS_BOOKS_LINKS.CLOUT_FANTASY,
      color: styledVariables.backgroundEmphasisF,
      promo: "Get 100% Match Deposit up to $100 with code ‘ILOCK’",
      promoColor: styledVariables.backgroundEmphasisK,
    },
  ],
};
