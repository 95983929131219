// Vendors
import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import { Checkbox } from "antd";

// Components
import IconLabel from "../IconLabel/IconLabel";
import ScoreCard from "../ScoreCard/ScoreCard";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import {
  transformOddToPrefered,
  formatNumberWithSymbol,
  calculateAmountToWin,
  formatDollars,
  formatUnits,
} from "../../utils/globalUtils";

// Styles
import classes from "./VerifyBetCard.module.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import { trashIcon } from "../../assets/icons/trash/trash";
import MainButton from "../MainButton/MainButton";

const VerifyBetCard = ({
  betData,
  handleToggleBetToVerify,
  preferredOddsType,
  isSubmitStep,
  handleRemoveBetToVerify,
}) => {
  return (
    <div
      className={classes.verifyBetCard}
      style={{
        borderColor:
          betData.isSelected && !isSubmitStep
            ? styledVariables.backgroundEmphasisB
            : styledVariables.backgroundPrimaryB,
        cursor: !isSubmitStep ? "pointer" : "",
      }}
      onClick={() => handleToggleBetToVerify(betData.betId)}
    >
      <div className={classes.firstRow}>
        <div className={classes.dateAndNameContainer}>
          <p className={classes.pendingText}>
            <span>
              <ClockCircleOutlined />
            </span>
            {betData.status === "PENDING" ? "Pending" : "Finished"}
          </p>
          {betData.abiosSeriesDate ? (
            <p className={classes.dateText}>
              {format(new Date(betData.abiosSeriesDate), "MMM. dd")}
            </p>
          ) : null}
          <div className={classes.esportLabelContainer}>
            <IconLabel
              icon={esportsLogos[betData.game]}
              label={globalConstants.TITLES[betData.game]}
              isSvg
            />
          </div>
        </div>

        {isSubmitStep ? (
          <MainButton
            icon={trashIcon}
            size="small"
            type="danger"
            clicked={() => handleRemoveBetToVerify(betData)}
          />
        ) : (
          <div className={classes.contentCheckbox}>
            <Checkbox checked={betData.isSelected} />
          </div>
        )}
      </div>
      <div className={classes.secondRow}>
        <div className={classes.matchData}>
          <div className={classes.teamLabelContainer}>
            <IconLabel
              icon={betData?.selectedAbiosTeam?.imageSmall}
              label={
                betData?.selectedAbiosTeam?.teamAbbreviation ||
                betData?.selectedAbiosTeam?.teamName
              }
              size="medium"
              withAbbreviation
              bold
            />
          </div>
          <div className={classes.versusContainer}>VS</div>
          <div className={classes.teamLabelContainer}>
            <IconLabel
              icon={betData?.alternativeAbiosTeam?.imageSmall}
              label={
                betData?.alternativeAbiosTeam?.teamAbbreviation ||
                betData?.alternativeAbiosTeam?.teamName
              }
              size="medium"
              withAbbreviation
              inverted
              bold
            />
          </div>
        </div>
        <div className={classes.betDataAndAmountData}>
          <div className={classes.betData}>
            <IconLabel
              icon={betData?.selectedAbiosTeam?.imageSmall}
              label={
                betData?.selectedAbiosTeam?.teamAbbreviation ||
                betData?.selectedAbiosTeam?.teamName
              }
              size="medium"
              withAbbreviation
              bold
            />
            {betData.type === globalConstants.SPREAD && betData.spread ? (
              <div className={classes.betTypeLabel}>
                <span> Spread</span>
                {formatNumberWithSymbol(parseFloat(betData.spread))}
                <span
                  className={`${classes.odds} ${
                    betData.odd >= 100 ? classes.favorite : classes.underdog
                  }`}
                >
                  {transformOddToPrefered(betData.odd, preferredOddsType)}
                </span>
              </div>
            ) : (
              <div className={classes.betTypeLabel}>
                <span>Money Line</span>
                <span
                  className={`${classes.odds} ${
                    betData.odd >= 100 ? classes.favorite : classes.underdog
                  }`}
                >
                  {transformOddToPrefered(betData.odd, preferredOddsType)}
                </span>
              </div>
            )}
            {betData.status !== globalConstants.PENDING ? (
              <ScoreCard result={betData.result} />
            ) : null}
          </div>

          {!isSubmitStep ? (
            <div className={classes.amountData}>
              <span className={classes.amountDescription}>
                {betData.amountType === globalConstants.AMOUNT_TYPES.UNIT
                  ? formatUnits(betData.amount)
                  : formatDollars(betData.amount)}
              </span>
              <span className={classes.toWinLabel}>To win</span>
              <span className={classes.toWinAmount}>
                {betData.amountType === globalConstants.AMOUNT_TYPES.UNIT
                  ? formatUnits(
                      calculateAmountToWin({
                        risk: betData.amount,
                        odds: betData.odd,
                        preferredOddsType: "american",
                      })
                    )
                  : formatDollars(
                      calculateAmountToWin({
                        risk: betData.amount,
                        odds: betData.odd,
                        preferredOddsType: "american",
                      })
                    )}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VerifyBetCard;
