// Vendors
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./StreakCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import ClockIcon from "../../assets/icons/clock/clock";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import DividerLine from "../DividerLine/DividerLine";

const StreakCard = ({ winStreak, loseStreak }) => {
  return (
    <div className={classes.streakList}>
      {winStreak ? (
        <Link
          className={`${classes.streakCardContainer} ${
            winStreak.isSeriesPresent ? classes.isSeriesPresent : ""
          }`}
          to={`/${globalConstants.ROUTES.MATCH_PAGE}/${winStreak.abiosSeriesId}/`}
          style={{ pointerEvents: !winStreak.isSeriesPresent ? "none" : null }}
        >
          <IconLabel
            icon={esportsLogos[winStreak.game]}
            label={globalConstants.TITLES[winStreak.game]}
            isSvg
          />
          <p className={classes.typeTitle}>Win streak</p>
          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          <div className={classes.streakRow}>
            <IconLabel
              icon={winStreak?.teamOne.imageSmall}
              label={
                winStreak.teamOne.teamAbbreviation || winStreak.teamOne.teamName
              }
              logoSize="logoSmall"
              size="small"
              withAbbreviation
            />
            <div className={classes.streakValue}>{winStreak.streak}</div>
          </div>
          <div className={classes.VSRow}>
            <div className={classes.VSTeam}>
              <span>Plays VS</span>
              <IconLabel
                icon={winStreak?.teamTwo.imageSmall}
                label={
                  winStreak.teamTwo.teamAbbreviation ||
                  winStreak.teamTwo.teamName
                }
                logoSize="logoSmall"
                size="small"
                withAbbreviation
              />
            </div>
            {winStreak?.startDate ? (
              <div className={classes.dateContainer}>
                <div className={classes.dateIconContainer}>
                  {<ClockIcon color={styledVariables.backgroundEmphasisK} />}
                </div>
                <span>{format(new Date(winStreak.startDate), "HH:mm")}</span>
              </div>
            ) : null}
          </div>
          {winStreak?.startDate ? (
            <div className={classes.matchPageLinkContainer}>
              <Link
                className={classes.matchPageLink}
                to={{
                  pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${winStreak.abiosSeriesId}`,
                }}
              >
                Match Page &#8594;
              </Link>
            </div>
          ) : null}
        </Link>
      ) : null}
      {loseStreak ? (
        <Link
          className={`${classes.streakCardContainer} ${
            loseStreak.isSeriesPresent ? classes.isSeriesPresent : ""
          }`}
          to={`/${globalConstants.ROUTES.MATCH_PAGE}/${loseStreak.abiosSeriesId}/`}
          style={{ pointerEvents: !loseStreak.isSeriesPresent ? "none" : null }}
        >
          <IconLabel
            icon={esportsLogos[loseStreak.game]}
            label={globalConstants.TITLES[loseStreak.game]}
            isSvg
          />
          <p className={classes.typeTitle}>Loss streak</p>
          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          <div className={classes.streakRow}>
            <IconLabel
              icon={loseStreak?.teamOne.imageSmall}
              label={
                loseStreak.teamOne.teamAbbreviation ||
                loseStreak.teamOne.teamName
              }
              logoSize="logoSmall"
              size="small"
              withAbbreviation
            />
            <div className={`${classes.streakValue} ${classes.streakLoss}`}>
              {loseStreak.streak}
            </div>
          </div>
          <div className={classes.VSRow}>
            <div className={classes.VSTeam}>
              <span>Plays VS</span>
              <IconLabel
                icon={loseStreak?.teamTwo.imageSmall}
                label={
                  loseStreak.teamTwo.teamAbbreviation ||
                  loseStreak.teamTwo.teamName
                }
                logoSize="logoSmall"
                size="small"
                withAbbreviation
              />
            </div>
            {loseStreak?.startDate ? (
              <div className={classes.dateContainer}>
                <div className={classes.dateIconContainer}>
                  {<ClockIcon color={styledVariables.backgroundEmphasisK} />}
                </div>
                <span>{format(new Date(loseStreak.startDate), "HH:mm")}</span>
              </div>
            ) : null}
          </div>
          {winStreak?.startDate ? (
            <div className={classes.matchPageLinkContainer}>
              <Link
                className={classes.matchPageLink}
                to={{
                  pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${loseStreak.abiosSeriesId}`,
                }}
              >
                Match Page &#8594;
              </Link>
            </div>
          ) : null}
        </Link>
      ) : null}
    </div>
  );
};

export default StreakCard;
