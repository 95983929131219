// Vendors
import React from "react";

export const userIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62971 7.33331C3.60498 7.33331 1.95066 5.70364 1.95066 3.67904C1.95066 1.65432 3.60498 0 5.62971 0C7.65444 0 9.30875 1.62967 9.30875 3.65427C9.30875 5.67899 7.65444 7.33331 5.62971 7.33331ZM5.62966 5.87657C6.83963 5.87657 7.82719 4.88896 7.82719 3.67904C7.82719 2.46908 6.83963 1.48152 5.62966 1.48152C4.4197 1.48152 3.43214 2.46908 3.43214 3.67904C3.43214 4.88901 4.4197 5.87657 5.62966 5.87657ZM5.62961 9.65445C3.25934 9.65445 1.48157 11.5309 1.48157 14.0001H0C0 10.5434 2.46913 8.17293 5.62961 8.17293C8.7901 8.17293 11.2592 10.5434 11.2592 14.0001H9.77766C9.77766 11.5309 8.00006 9.65445 5.62961 9.65445Z"
      fill="#58586B"
    />
  </svg>
);
