// Vendors
import { isAfter } from "date-fns";

// Styles
import styledVariables from "../styles/utils/_variables.scss";
import { globalConstants } from "../constants/globalConstants";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isFavoritePick = (odds) => {
  return odds > 0;
};

export const formatNumberWithSymbol = (number, decimalPoints) => {
  return number >= 0
    ? `+${decimalPoints ? number.toFixed(decimalPoints) : number}`
    : `${decimalPoints ? number.toFixed(decimalPoints) : number}`;
};

export const formatDollars = (dollars) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  return formatter.format(dollars);
};

export const formatUnits = (units) => {
  return `${units} u`;
};

export const formatStringElipsisWithLength = (string, length) => {
  if (string.length <= length) {
    return string;
  } else {
    return `${string.substring(0, length)}...`;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const addLeadingZeros = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};

export const generateColorFromPercent = (percent) => {
  if (percent <= 33) {
    return styledVariables.backgroundEmphasisG;
  } else if (percent <= 66) {
    return styledVariables.backgroundEmphasisD;
  } else {
    return styledVariables.backgroundEmphasisE;
  }
};

export const calculatePercentage = (value1, value2) => {
  return (value1 / (value1 + value2)) * 100 || 0;
};

export const isAfterDate = (dateOne, dateTwo) => {
  return isAfter(dateOne, dateTwo);
};

export const abbreviateString = (string, maxCharacters) => {
  string = string.replace(/\s+/g, " ").trim().toUpperCase();
  if (string.length <= maxCharacters) {
    return string;
  }

  let newString = "";
  const splitString = string.split(" ");
  if (splitString.length > 1) {
    splitString.forEach((word) => {
      newString += word[0];
    });

    const remainingToAdd = maxCharacters - newString.length;

    newString = `${newString[0]}${splitString[0].substring(
      1,
      remainingToAdd
    )}${newString.substring(1, maxCharacters)}`;
  } else {
    return string.substring(0, maxCharacters);
  }
  return newString;
};

export const getBase64Image = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const isJpgOrPng = (file) => {
  return ["image/jpeg", "image/jpg", "image/png"].includes(file.type);
};

export const isSmallerThan1MB = (file) => {
  return file.size / 1024 / 1024 < 4;
};

export const generateSocialMediaLink = (socialMedia, profile) => {
  switch (socialMedia) {
    case "twitch":
      return `https://www.twitch.tv/${profile}`;
    case "youtube":
      return `https://www.youtube.com/c/${profile}`;
    case "instagram":
      return `https://www.instagram.com/${profile}`;
    case "twitter":
    default:
      return `https://twitter.com/${profile}`;
  }
};

export const transformOddToPrefered = (odds, preferredOdd) => {
  const preferredOddsType =
    preferredOdd || sessionStorage.getItem("preferredOddsType");
  if (preferredOddsType === "decimal") {
    return americanToDecimalOdds(odds).toFixed(2);
  }

  return formatNumberWithSymbol(odds.toFixed(0));
};

export const receiveBetSlipOdd = (odds, preferredOdd) => {
  if (!odds) {
    return "";
  }
  const preferredOddsType =
    preferredOdd || sessionStorage.getItem("preferredOddsType");

  if (preferredOddsType === "decimal") {
    if (
      (parseFloat(odds) > 0 && parseFloat(odds) < 100) ||
      (parseFloat(odds) < 0 && parseFloat(odds) > -100)
    ) {
      return "";
    }

    const newOdds = americanToDecimalOdds(odds);
    if (parseFloat(newOdds) < 1) {
      return "";
    }
    return newOdds;
  }
  return odds;
};

export const sendBetslipOdd = (odds, preferredOdd) => {
  if (!odds) {
    return "";
  }

  const preferredOddsType =
    preferredOdd || sessionStorage.getItem("preferredOddsType");
  if (preferredOddsType === "decimal") {
    if (parseFloat(odds) < 1) {
      return "";
    }

    const newOdds = decimalToAmericanOdds(odds);
    if (
      (parseFloat(newOdds) > 0 && parseFloat(newOdds) < 100) ||
      (parseFloat(newOdds) < 0 && parseFloat(newOdds) > -100)
    ) {
      return "";
    }

    return newOdds;
  }
  return odds;
};

export const americanToDecimalOdds = (american) => {
  if (american >= 0) {
    return Math.abs(american) / 100 + 1;
  }
  return 100 / Math.abs(american) + 1;
};

export const decimalToAmericanOdds = (decimal) => {
  if (decimal >= 2) {
    return (decimal - 1) * 100;
  }
  return -100 / (decimal - 1);
};

export const calculateAmountToWin = ({ risk, odds, preferredOddsType }) => {
  let newOdds =
    preferredOddsType !== "decimal"
      ? americanToDecimalOdds(parseFloat(odds))
      : parseFloat(odds);

  if (!parseFloat(odds) || !risk) {
    return "";
  }

  const toWin = risk * Math.abs(1 - newOdds);

  return toWin.toFixed(2);
};

export const calculateAmountToRisk = ({ win, odds, preferredOddsType }) => {
  let newOdds =
    preferredOddsType !== "decimal"
      ? americanToDecimalOdds(parseFloat(odds))
      : parseFloat(odds);

  if (1 - newOdds === 0 || !parseFloat(odds) || !win) {
    return "";
  }
  const toRisk = win / Math.abs(1 - newOdds);
  return toRisk.toFixed(2);
};

export const generateBetErrors = (bets, preferredOddsType) => {
  return bets.map((betData) => {
    const errors = {};
    const { risk, win, odds, spread, total } = betData;

    if (!risk || isNaN(risk) || parseFloat(risk) <= 0) {
      errors.risk = true;
    }
    if (!win || isNaN(win) || parseFloat(win) <= 0) {
      errors.win = true;
    }

    if (!odds || isNaN(parseFloat(odds))) {
      errors.odds = true;
    } else {
      if (preferredOddsType === "decimal" && parseFloat(odds) < 1) {
        errors.odds = true;
      } else if (
        preferredOddsType !== "decimal" &&
        ((parseFloat(odds) > 0 && parseFloat(odds) <= 100) ||
          (parseFloat(odds) < 0 && parseFloat(odds) >= -100))
      ) {
        errors.odds = true;
      }
    }

    if (betData.type === "spread") {
      if (
        !parseFloat(spread) ||
        ![0.5, 0].includes(Math.abs(parseFloat(spread) % 1)) ||
        parseFloat(spread) >= 10 ||
        parseFloat(spread) <= -10
      ) {
        errors.spread = true;
      }
    }

    if (betData.type === "total") {
      if (
        !parseFloat(total) ||
        ![0.5, 0].includes(Math.abs(parseFloat(total) % 1))
      ) {
        errors.total = true;
      }
    }
    return { ...betData, errors };
  });
};

export const generateOpenOdds = (serieData, sportsbooks) => {
  const result = {
    teamOne: {
      MONEYLINE: {
        oddsOpen: null,
      },
      SPREAD: {
        oddsOpen: null,
        spreadOpen: null,
      },
    },
    teamTwo: {
      MONEYLINE: {
        oddsOpen: null,
      },
      SPREAD: {
        oddsOpen: null,
        spreadOpen: null,
      },
    },
  };

  ["teamOne", "teamTwo"].forEach((teamKey) => {
    const team = serieData[teamKey];

    sportsbooks.forEach((sportsbook) => {
      const bets = team[sportsbook];

      bets &&
        bets.forEach((bet) => {
          const betType = bet.betType;

          if (betType === "MONEYLINE") {
            if (
              result[teamKey][betType].oddsOpen === null ||
              bet.oddsOpen > result[teamKey][betType].oddsOpen
            ) {
              result[teamKey][betType].oddsOpen = bet.oddsOpen;
            }
          } else if (betType === "SPREAD") {
            if (
              result[teamKey][betType].oddsOpen === null ||
              bet.oddsOpen > result[teamKey][betType].oddsOpen
            ) {
              result[teamKey][betType].oddsOpen = bet.oddsOpen;
              result[teamKey][betType].spreadOpen = bet.spreadOpen;
            }
          }
        });
    });
  });

  return result;
};

export const navigateToMatchPageIfAvailable = (
  navigate,
  isSeriesPresent,
  seriesId
) => {
  if (isSeriesPresent) {
    navigate(`/${globalConstants.ROUTES.MATCH_PAGE}/${seriesId}`);
  }
};
