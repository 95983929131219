// Vendors
import React from "react";
import { Progress } from "antd";

// Styles
import classes from "./ComparedOdds.module.scss";

const ComparedOdds = ({
  firstOdds,
  firstOddsColor,
  secondOdds,
  secondOddsColor,
}) => {
  const bet1Percent = (100 * firstOdds) / (firstOdds + secondOdds);
  return (
    <div className={classes.comparedOddsContainer}>
      <span className={classes.odds}>{bet1Percent.toFixed(0)}%</span>
      <div className={classes.progressContainer}>
        <Progress
          percent={bet1Percent}
          showInfo={false}
          strokeColor={firstOddsColor}
          trailColor={secondOddsColor}
        />
      </div>
      <span className={classes.odds}>{100 - bet1Percent.toFixed(0)}%</span>
    </div>
  );
};

export default ComparedOdds;
