// Vendors
import React, { useState } from "react";
import { parseISO } from "date-fns";

// Components
import TabSelector from "../../components/TabSelector/TabSelector";

// Styles
import classes from "./MatchRecentSchedule.module.scss";
import ScheduleCard from "../ScheduleCard/ScheduleCard";

const MatchRecentSchedule = ({ preferredOddsType, seriesDetails }) => {
  const { teamOne, teamTwo, headToHeadMatches } = seriesDetails;

  const newSeriesDetails = {
    ...seriesDetails,
    headToHeadMatches: { individualSeries: headToHeadMatches },
  };

  const OPTIONS = [
    {
      value: "teamOne",
      label: teamOne.teamAbbreviation || teamOne.teamName,
    },
    {
      value: "headToHeadMatches",
      label: "Head-to-Head",
    },
    {
      value: "teamTwo",
      label: teamTwo.teamAbbreviation || teamTwo.teamName,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(
    newSeriesDetails?.headToHeadMatches?.individualSeries?.length > 0
      ? OPTIONS[1]
      : newSeriesDetails?.teamOne?.individualSeries?.length > 0
      ? OPTIONS[0]
      : newSeriesDetails?.teamTwo?.individualSeries?.length > 0
      ? OPTIONS[2]
      : null
  );

  const handleOptionSelected = (option) => {
    setSelectedOption(option);
  };

  return selectedOption ? (
    <div className={classes.matchRecentScheduleContainer}>
      <div className={classes.titleContainer}>
        <h3>Recent schedule</h3>
      </div>
      <div className={classes.recentScheduleTitle}>
        <TabSelector
          options={OPTIONS}
          handleOptionSelected={handleOptionSelected}
          selectedOption={selectedOption}
        />
        <div className={classes.titleGrid}>
          <div className={classes.dateCell}>
            <p className={classes.columnTitle}>Date</p>
          </div>
          <div className={classes.resultCell}>
            <p className={classes.columnTitle}>Result</p>
          </div>
          <div className={classes.scoreCell}>
            <p className={classes.columnTitle}>Game score</p>
          </div>
          <div className={classes.oddsCell}>
            <p className={classes.columnTitle}>Moneyline</p>
          </div>
          <div className={classes.spreadCell}>
            <p className={classes.columnTitle}>Spread</p>
          </div>
        </div>
      </div>
      {newSeriesDetails[selectedOption.value]?.individualSeries?.length > 0 ? (
        <div className={classes.scheduledCardsList}>
          {newSeriesDetails[selectedOption.value].individualSeries
            .sort((match1, match2) => {
              const dateObject1 = parseISO(match1?.startDate);
              const dateObject2 = parseISO(match2?.startDate);
              const milliseconds1 = dateObject1.getTime();
              const milliseconds2 = dateObject2.getTime();

              return milliseconds1 - milliseconds2;
            })
            .map((scheduledMatch, index) => {
              return (
                <ScheduleCard
                  key={`${scheduledMatch.abiosSeriesId}-${index}`}
                  scheduledMatch={scheduledMatch}
                  preferredOddsType={preferredOddsType}
                  teamData={newSeriesDetails[selectedOption.value]}
                  isHeadToHead={OPTIONS[1].value === selectedOption.value}
                  isRightTeam={OPTIONS[2].value === selectedOption.value}
                  newSeriesDetails={newSeriesDetails}
                />
              );
            })}
        </div>
      ) : (
        <div className={classes.noDataCard}>
          Info unavailable for this match
        </div>
      )}
    </div>
  ) : null;
};

export default MatchRecentSchedule;
