// Vendors
import React from "react";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./EducationRocketLeaguePage.module.scss";

// Assets
import { fazeLogo, nrgLogo } from "../../assets/icons/logos/logos";

const EducationRocketLeaguePage = () => {
  return (
    <div className={classes.educationGuidesPage}>
      <div className={classes.pageBannerContainer}>
        <GameBannerRenderer game={"rocketLeague"} />
      </div>
      <div className={classes.pageHeader}>
        <h2>What is Rocket League</h2>
        <p>
          Rocket League is a vehicular soccer video game developed and published
          by Psyonix. The game was first released for Windows and PlayStation 4
          in July 2015, with ports for Xbox One and Nintendo Switch being
          released later on. In June 2016, 505 Games began distributing a
          physical retail version for PlayStation 4 and Xbox One, with Warner
          Bros. Interactive Entertainment taking over those duties by the end of
          2017. Versions for macOS and Linux were also released in 2016, but
          support for their online services were dropped in 2020. The game went
          free-to-play in September 2020.
        </p>
        <p>
          Described as "soccer, but with rocket-powered cars", Rocket League has
          up to eight players assigned to each of the two teams, using
          rocket-powered vehicles to hit a ball into their opponent's goal and
          score points over the course of a match. The game includes
          single-player and multiplayer modes that can be played both locally
          and online, including cross-platform play between all versions.
        </p>
      </div>
      <div className={classes.pageContent}>
        <h2>Rocket League Betting basics</h2>
        <h3>How do MoneyLine Odds Work?</h3>
        <p>
          A popular bet in Rocket League is the moneyline, which requires you to
          pick the winner of the game. But it’s not a 50-50 proposition in most
          cases – you won’t get paid the same for taking FAZE to beat NRG as you
          would for taking the NRG to beat FAZE, given the ability of each team.
        </p>
        <p>
          So each team is assigned moneyline odds by the sportsbook based on
          their ability, and bettors have to pay a corresponding price.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"FAZE"} icon={fazeLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-300</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
            <IconLabel
              label={"NRG"}
              icon={nrgLogo}
              isSvg
              size="large"
              inverted
            />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"NRG"} icon={nrgLogo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
          </div>
        </div>

        <p>
          American odds like these are centered around $100. So if you bet $300
          on FAZE at -300, you’d win $100 if they win. If you bet $100 on NRG at
          +250 and they win, you’d win $250.
        </p>
        <p>
          You don’t have to bet $100, of course. If you bet $3 on NRG you’d win
          $1. Bet $1 on FAZE, win $2.50.
        </p>
      </div>
    </div>
  );
};

export default EducationRocketLeaguePage;
