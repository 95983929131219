// Vendors
import React from "react";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";
import DividerLine from "../../components/DividerLine/DividerLine";

// Styles
import classes from "./MatchStatsLastTen.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const MatchStatsLastTen = ({ seriesDetails }) => {
  let { teamOne, teamTwo } = seriesDetails;

  return teamOne && teamTwo ? (
    <div className={classes.matchStatsInfo}>
      <div className={classes.teamLogoRow}>
        <div className={classes.teamLogoContainer}>
          <IconLabel
            label={
              seriesDetails?.teamOne.teamAbbreviation ||
              seriesDetails?.teamOne.teamName
            }
            size="small"
            icon={
              seriesDetails?.teamOne.teamImgSm ||
              seriesDetails?.teamOne.teamImgLg
            }
            withAbbreviation
          />
        </div>
        <div className={classes.teamLogoContainer}>
          <IconLabel
            label={
              seriesDetails?.teamTwo.teamAbbreviation ||
              seriesDetails?.teamTwo.teamName
            }
            size="small"
            icon={
              seriesDetails?.teamTwo.teamImgSm ||
              seriesDetails?.teamTwo.teamImgLg
            }
            withAbbreviation
          />
        </div>
      </div>
      <DividerLine color={styledVariables.backgroundEmphasisV} />
      <p className={classes.descriptionText}>Last 10</p>
      <div className={classes.scoreCardList}>
        <LastTenScoreCard
          label="Moneyline"
          scoreOne={teamOne?.lastTen?.mlRecords}
          scoreTwo={teamTwo?.lastTen?.mlRecords}
        />
        <LastTenScoreCard
          label="As Favorite"
          scoreOne={teamOne?.lastTen?.asFavorite}
          scoreTwo={teamTwo?.lastTen?.asFavorite}
        />
        <LastTenScoreCard
          label="As Underdog"
          scoreOne={teamOne?.lastTen?.asUnderdog}
          scoreTwo={teamTwo?.lastTen?.asUnderdog}
        />
        <LastTenScoreCard
          label="Win Streak"
          scoreOne={teamOne?.lastTen?.winStreak}
          scoreTwo={teamTwo?.lastTen?.winStreak}
          isWinStreak
        />
        <LastTenScoreCard
          label="Loss Streak"
          scoreOne={teamOne?.lastTen?.lossStreak}
          scoreTwo={teamTwo?.lastTen?.lossStreak}
        />
      </div>
    </div>
  ) : null;
};

const greenStyles = {
  backgroundColor: styledVariables.backgroundEmphasisE,
  color: styledVariables.backgroundPrimaryA,
};

const darkGreenStyles = {
  backgroundColor: styledVariables.backgroundEmphasisX,
  color: styledVariables.backgroundEmphasisE,
};

const greyStyles = {
  backgroundColor: styledVariables.backgroundEmphasisT,
  color: styledVariables.white,
};

const darkRedStyles = {
  backgroundColor: styledVariables.backgroundEmphasisY,
  color: styledVariables.backgroundEmphasisC,
};

const redStyles = {
  backgroundColor: styledVariables.backgroundEmphasisC,
  color: styledVariables.black,
};

const generateScoreCardStyles = (score, isWinStreak) => {
  if (!score) {
    return greyStyles;
  }

  if (typeof score === "number") {
    if (score >= 4) {
      return isWinStreak ? greenStyles : redStyles;
    } else if (score < 4) {
      return isWinStreak ? darkGreenStyles : darkRedStyles;
    } else {
      return greyStyles;
    }
  }

  const scoreAmounts = score.split("-");
  if (!scoreAmounts) {
    return greyStyles;
  }

  const [numberOne, numberTwo] = scoreAmounts;

  if (!numberOne || !numberTwo) {
    return greyStyles;
  }
  const percent =
    (100 * parseInt(numberOne)) / (parseInt(numberOne) + parseInt(numberTwo));

  if (percent >= 80) {
    return greenStyles;
  } else if (percent > 50) {
    return darkGreenStyles;
  } else if (percent === 50) {
    return greyStyles;
  } else if (percent > 30) {
    return darkRedStyles;
  } else {
    return redStyles;
  }
};

const LastTenScoreCard = ({ scoreOne, scoreTwo, label, isWinStreak }) => {
  return (
    <div className={classes.lastTenScoreCard}>
      <span className={classes.scoreCardLabel}>{label}</span>
      <div className={classes.scoreRow}>
        <div
          className={classes.scoreBox}
          style={generateScoreCardStyles(scoreOne, isWinStreak)}
        >
          {scoreOne || "N/A"}
        </div>
        <div
          className={classes.scoreBox}
          style={generateScoreCardStyles(scoreTwo, isWinStreak)}
        >
          {scoreTwo || "N/A"}
        </div>
      </div>
    </div>
  );
};

export default MatchStatsLastTen;
