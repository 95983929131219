// Vendors
import React from "react";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./ScheduleCardScore.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";
const ScheduleCardScore = ({
  scheduledMatch,
  scoreData,
  teamData,
  isHeadToHead,
  newSeriesDetails,
}) => {
  const { teamMatchScore, oppTeamMatchScore, result } = scoreData;

  let teamToShowOnResult =
    result === globalConstants.WIN
      ? {
          teamName: teamData.teamName,
          teamImgLg: teamData.teamImgLg,
          teamImgSm: teamData.teamImgSm,
        }
      : {
          teamName: scheduledMatch.oppTeamName,
          teamImgLg: scheduledMatch.oppTeamImgLg,
          teamImgSm: scheduledMatch.oppTeamImgSm,
        };

  if (isHeadToHead) {
    teamToShowOnResult =
      result === globalConstants.WIN
        ? {
            teamName: newSeriesDetails.teamOne.teamName,
            teamImgLg: newSeriesDetails.teamOne.teamImgLg,
            teamImgSm: newSeriesDetails.teamOne.teamImgSm,
          }
        : {
            teamName: newSeriesDetails.teamTwo.teamName,
            teamImgLg: newSeriesDetails.teamTwo.teamImgLg,
            teamImgSm: newSeriesDetails.teamTwo.teamImgSm,
          };
  }

  return (
    <div className={classes.scheduleCardScoreContainer}>
      <div className={classes.scoreLabel}>
        <IconLabel
          size="small"
          icon={teamToShowOnResult.teamImgLg || teamToShowOnResult.teamImgSm}
          withAbbreviation
          tooltipText={teamToShowOnResult.teamName}
        />
        {teamMatchScore}-{oppTeamMatchScore}
      </div>
      {scoreData.map || scoreData.gameMode ? (
        <div className={classes.mapInfo}>
          {scoreData.map ? (
            <p className={classes.mapInfoLabel}>{scoreData.map}</p>
          ) : null}
          {scoreData.gameMode ? (
            <p className={classes.mapInfoLabel}>{scoreData.gameMode}</p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ScheduleCardScore;
