// Vendors
import React, { useEffect, useState } from "react";

// Styles
import classes from "./GameBanner.module.scss";

// Components
import AffiliateBanner from "../AffiliateBanner/AffiliateBanner";

const GameBanner = ({ game }) => {
  const [imageBanner, setImageBanner] = useState();
  const [isFallbackOption, setIsFallbackOption] = useState(false);

  const loadImage = (gameName) => {
    if (gameName) {
      import(`../../assets/images/esports/${gameName}/${gameName}Banner.png`)
        .then((image) => {
          setImageBanner(image.default);
        })
        .catch(() => {
          setIsFallbackOption(true);
        });
    }
  };

  useEffect(() => {
    loadImage(game);
  }, [game]);

  return imageBanner ? (
    <div
      className={classes.gameBannerContainer}
      style={{
        backgroundSize: "cover",
        backgroundImage: `url("${imageBanner}")`,
        backgroundPosition: "center",
      }}
    />
  ) : isFallbackOption ? (
    <AffiliateBanner />
  ) : null;
};

export default GameBanner;
