// Vendors
import React from "react";

export const graphIcon = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.581 18.5H1.41986C1.01955 18.5 0.695312 18.1754 0.695312 17.7752C0.695312 17.375 1.01982 17.0507 1.41986 17.0507H18.581C18.9812 17.0507 19.3055 17.3752 19.3055 17.7752C19.3055 18.1754 18.981 18.5 18.581 18.5ZM2.11716 16.4519C2.02865 16.4519 1.93863 16.4356 1.85135 16.4011C1.47909 16.254 1.29659 15.8335 1.4434 15.4612L4.24371 8.36775C4.34359 8.11479 4.57698 7.93886 4.84758 7.91246C5.11654 7.88496 5.38097 8.01356 5.52765 8.24298L7.36277 11.1077L9.87099 5.07542C9.97168 4.83314 10.1955 4.66419 10.4561 4.63396C10.7166 4.60455 10.9735 4.71632 11.1273 4.92906L13.0969 7.65221L15.2353 2.40721L14.5271 2.64211C14.147 2.76825 13.7376 2.56235 13.6114 2.18258C13.4854 1.80279 13.6912 1.39264 14.0708 1.26666L16.2563 0.542114C16.3652 0.503123 16.4802 0.491769 16.5912 0.505722C16.5919 0.506406 16.5927 0.505995 16.5934 0.506132C16.5942 0.506132 16.5952 0.506406 16.5958 0.506406C16.5965 0.506132 16.5974 0.506679 16.5983 0.506816C16.6072 0.507911 16.6157 0.509416 16.6247 0.510921C16.6258 0.511194 16.6269 0.511331 16.6276 0.511331C16.6284 0.511605 16.6291 0.511331 16.6305 0.511741C16.6313 0.512015 16.632 0.511741 16.6331 0.512425C16.6335 0.512425 16.6337 0.512425 16.6342 0.512699C16.8054 0.544712 16.9656 0.6387 17.0782 0.787547C17.0848 0.796439 17.0915 0.805468 17.0974 0.814361L18.2496 2.46645C18.4786 2.79479 18.3981 3.24641 18.0699 3.47542C17.7417 3.70375 17.29 3.62386 17.0613 3.29566L16.6683 2.73228L13.9455 9.40831C13.8463 9.6521 13.6225 9.82284 13.361 9.85415C13.0997 9.88643 12.8417 9.77288 12.6872 9.55933L10.7116 6.8277L8.16888 12.9434C8.06532 13.1925 7.83193 13.3635 7.56324 13.387C7.29373 13.408 7.03502 13.2829 6.88961 13.0558L5.0713 10.2172L2.79101 15.9928C2.67856 16.2775 2.40576 16.4515 2.1171 16.4515L2.11716 16.4519Z"
      fill="#3378FF"
    />
  </svg>
);
