// Vendors
import React, { useState } from "react";

// Components
import Logo from "../../components/Logo/Logo";
import MainModal from "../../components/MainModal/MainModal";
import EditProfilePassword from "../../components/EditProfilePassword/EditProfilePassword";
import EditProfileData from "../../components/EditProfileData/EditProfileData";
import EditProfileSocial from "../../components/EditProfileSocial/EditProfileSocial";

// Styles
import classes from "./EditProfile.module.scss";
import MainButton from "../MainButton/MainButton";

const EDIT_PROFILE_TABS = {
  PROFILE: {
    id: 0,
    label: "Security",
  },
  SOCIAL: {
    id: 1,
    label: "Social",
  },
  SECURITY: {
    id: 2,
    label: "Profile",
  },
};

const EditProfile = ({
  showEditProfileModal,
  setShowEditProfileModal,
  userSession,
  requestedUserData,
  handleSetUserData,
}) => {
  const handleModalClose = () => {
    setShowEditProfileModal(false);
  };

  const [currentTab, setCurrentTab] = useState(EDIT_PROFILE_TABS.PROFILE);

  return (
    <MainModal
      open={showEditProfileModal}
      onCancel={() => handleModalClose()}
      footer={null}
      closable={false}
    >
      <div className={classes.EditProfileModalTitle}>
        <Logo />
      </div>
      <p className={classes.greetingText}>Edit your profile!</p>
      <div className={classes.editProfileControls}>
        <div className={classes.editProfileControlItem}>
          <MainButton
            buttonText="Profile"
            fullWidth="fullWidth"
            clicked={() => setCurrentTab(EDIT_PROFILE_TABS.PROFILE)}
            type={
              currentTab.id === EDIT_PROFILE_TABS.PROFILE.id
                ? "primary"
                : "tertiary"
            }
          />
        </div>
        <div className={classes.editProfileControlItem}>
          <MainButton
            buttonText="Social"
            fullWidth="fullWidth"
            clicked={() => setCurrentTab(EDIT_PROFILE_TABS.SOCIAL)}
            type={
              currentTab.id === EDIT_PROFILE_TABS.SOCIAL.id
                ? "primary"
                : "tertiary"
            }
          />
        </div>
        <div className={classes.editProfileControlItem}>
          <MainButton
            buttonText="Security"
            fullWidth="fullWidth"
            clicked={() => setCurrentTab(EDIT_PROFILE_TABS.SECURITY)}
            type={
              currentTab.id === EDIT_PROFILE_TABS.SECURITY.id
                ? "primary"
                : "tertiary"
            }
          />
        </div>
      </div>
      <div className={classes.EditProfileModalContent}>
        {currentTab.id === EDIT_PROFILE_TABS.PROFILE.id ? (
          <EditProfileData
            requestedUserData={requestedUserData}
            handleModalClose={handleModalClose}
            handleSetUserData={handleSetUserData}
          />
        ) : null}
        {currentTab.id === EDIT_PROFILE_TABS.SOCIAL.id ? (
          <EditProfileSocial
            requestedUserData={requestedUserData}
            handleModalClose={handleModalClose}
            handleSetUserData={handleSetUserData}
          />
        ) : null}
        {currentTab.id === EDIT_PROFILE_TABS.SECURITY.id ? (
          <EditProfilePassword
            userSession={userSession}
            handleModalClose={handleModalClose}
          />
        ) : null}
      </div>
    </MainModal>
  );
};

export default EditProfile;
