// Vendors
import React from "react";
// Styles
import classes from "./OddsTag.module.scss";

import {
  isFavoritePick,
  transformOddToPrefered,
} from "../../utils/globalUtils";

const OddsTag = ({
  odds,
  additionalText,
  preferredOddsType,
  fullWidth,
  size,
}) => {
  return odds || additionalText ? (
    <div
      className={`${classes.moneyLine} ${
        odds && !isFavoritePick(odds) && classes.favoriteML
      }
      ${size === "large" ? classes.large : null}
      `}
      style={{
        width: fullWidth ? "100%" : null,
        justifyContent: "center",
      }}
    >
      {`${odds ? transformOddToPrefered(odds, preferredOddsType) : ""}${
        additionalText ? ` ${additionalText}` : ""
      }`}
    </div>
  ) : null;
};

export default OddsTag;
