// Vendors
import React, { useState } from "react";
import { Form, Input } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";

// AWS
import {
  startForgotPasswordAWS,
  finishForgotPasswordAWS,
} from "../../AWS/UserPool";

// Components
import Logo from "../../components/Logo/Logo";
import MainButton from "../../components/MainButton/MainButton";
import MainModal from "../../components/MainModal/MainModal";

// Styles
import classes from "./ForgotPassword.module.scss";

const ForgotPassword = ({ setModalToShow, modalToShow }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [username, setUsername] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  const handleSendRecoveryCode = (values) => {
    startForgotPasswordAWS(
      values.username,
      () => {
        setUsername(values.username);
        setForgotPasswordError("");
        setCurrentStep(currentStep + 1);
      },
      (err) => {
        setForgotPasswordError(err);
      }
    );
  };

  const handleSetNewPassword = (values) => {
    const formValues = {
      username,
      verificationCode: values.otp,
      newPassword: values.password,
    };
    finishForgotPasswordAWS(
      formValues,
      () => {
        setCurrentStep(currentStep + 1);
        setForgotPasswordError("");
      },
      (err) => {
        setForgotPasswordError(err);
      }
    );
  };

  const usernameInput = {
    name: "username",
    placeholder: "Username",
    prefix: <UserOutlined style={{ fontSize: "150%" }} />,
    rules: [{ required: true, message: "Please input your username!" }],
  };

  const passwordInput = {
    name: "password",
    disabled: currentStep > 1,
    placeholder: "Password",
    type: "password",
    prefix: <KeyOutlined style={{ fontSize: "150%" }} />,
    rules: [
      { required: true, message: "Please enter a desired password!" },
      {
        message:
          "The password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special characters.",
        validator: (_, value) => {
          if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
              value
            ) ||
            !value
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        },
      },
    ],
  };

  const passwordConfirmationInput = {
    name: "passwordConfirmation",
    placeholder: "Confirm Password",
    type: "password",
    prefix: <KeyOutlined style={{ fontSize: "150%" }} />,
    rules: [
      {
        required: true,
        message: "Please enter the password confirmation!",
      },
      ({ getFieldValue }) => ({
        message: "The passwords must match",
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The two passwords that you entered do not match!")
          );
        },
      }),
    ],
  };

  const OTPInput = {
    name: "otp",
    placeholder: "One time code",
    rules: [
      {
        required: true,
        message: "Please enter the code we sent to your email!",
      },
    ],
  };

  const ForgetPasswordSteps = [
    {
      buttonText: "Continue",
      title: "Forgot Password?",
      handleSubmitTry: handleSendRecoveryCode,
      inputs: [usernameInput],
    },
    {
      buttonText: "Confirm",
      title: "Set a new Password",
      handleSubmitTry: handleSetNewPassword,
      inputs: [passwordInput, passwordConfirmationInput, OTPInput],
    },
    {
      buttonText: "Login",
      title: "All set!",
      handleSubmitTry: () => setModalToShow("login"),
      inputs: [],
    },
  ];

  return (
    <MainModal
      open={modalToShow}
      onCancel={() => setModalToShow(false)}
      footer={null}
      closable={false}
    >
      <Form
        className={classes.formContainer}
        name="forgot-password"
        initialValues={{}}
        onFinish={ForgetPasswordSteps[currentStep].handleSubmitTry}
        autoComplete="off"
      >
        <div className={classes.forgetPasswordContentContainer}>
          <div className={classes.loginModalTitle}>
            <Logo />
          </div>
          <p
            className={`${classes.greetingText} ${
              currentStep === ForgetPasswordSteps.length - 1 &&
              classes.successText
            }`}
          >
            {ForgetPasswordSteps[currentStep].title}
          </p>

          {currentStep === ForgetPasswordSteps.length - 1 && (
            <p className={classes.successProcessText}>
              Your password has been restablised!
            </p>
          )}
          <div className={classes.inputsContainer}>
            {ForgetPasswordSteps[currentStep].inputs.map((input) => {
              const inputProps = {
                disabled: input.disabled,
                size: "large",
                type: input.type,
                placeholder: input.placeholder,
                className: classes.loginInputBox,
                prefix: input.prefix,
                suffix: input.suffix,
                bordered: false,
              };

              return (
                <Form.Item
                  key={input.name}
                  validateTrigger={["onBlur"]}
                  className={classes.formItem}
                  name={input.name}
                  rules={input.rules}
                >
                  {input.type === "password" ? (
                    <Input.Password {...inputProps} />
                  ) : (
                    <Input {...inputProps} />
                  )}
                </Form.Item>
              );
            })}
            {currentStep === 1 && (
              <p className={classes.recoveryLinkSentText}>
                Validation Code was sent to your email!
              </p>
            )}
            {currentStep === 1 && (
              <p className={classes.successForgetPasswordText}>
                If you provided a valid username, we will send you the password
                validation code.
              </p>
            )}
          </div>
        </div>
        {forgotPasswordError && (
          <span className={classes.forgotPasswordError}>
            {forgotPasswordError}
          </span>
        )}
        <div className={classes.ForgetPasswordFooterContainer}>
          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText={ForgetPasswordSteps[currentStep].buttonText}
              fullWidth="fullWidth"
            />
          </div>

          {currentStep !== ForgetPasswordSteps.length - 1 ? (
            <div className={classes.changeLoginText}>
              <span className={classes.changeLoginEmphasisText}>
                "Have an account?"
              </span>
              <MainButton
                buttonText="Sign in"
                type="text"
                clicked={() => setModalToShow("login")}
              />
            </div>
          ) : (
            <div className={classes.changeLoginSpacer} />
          )}
        </div>
      </Form>
    </MainModal>
  );
};

export default ForgotPassword;
