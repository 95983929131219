// Assets
import thunderpickco from "../../../assets/images/twitter/thunderpickco.jpg";
import CDLSportsbook from "../../../assets/images/twitter/CDLSportsbook.jpg";
import forestwithout from "../../../assets/images/twitter/forestwithout.jpg";
import LinkDFS from "../../../assets/images/twitter/LinkDFS.jpg";
import SrirachaDfs from "../../../assets/images/twitter/SrirachaDfs.jpg";
import ATMCrewGG from "../../../assets/images/twitter/ATMCrewGG.jpg";
import RotoRadar from "../../../assets/images/twitter/RotoRadar.jpg";
import TonyLavenderML from "../../../assets/images/twitter/TonyLavenderML.jpg";
import cloutfantasy from "../../../assets/images/twitter/cloutfantasy.jpg";
import EsportsBet from "../../../assets/images/twitter/EsportsBet.jpg";
import million_miles77 from "../../../assets/images/twitter/million_miles77.jpg";
import Cwill356 from "../../../assets/images/twitter/Cwill356.jpg";
import Crelykss from "../../../assets/images/twitter/Crelykss.jpg";
import Saint16King from "../../../assets/images/twitter/Saint16King.jpg";

export const communityPageConstants = {
  TWITTER_IMAGES: {
    cloutfantasy,
    thunderpickco,
    CDLSportsbook,
    forestwithout,
    LinkDFS,
    SrirachaDfs,
    ATMCrewGG,
    RotoRadar,
    TonyLavenderML,
    EsportsBet,
    million_miles77,
    Cwill356,
    Crelykss,
    Saint16King,
  },
};
