// Vendors
import React from "react";

export const cloutFantasy = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72291 18.0001C6.60781 17.9987 4.09078 15.4781 4.09939 12.3673C4.10728 9.27801 6.66446 6.73946 9.7602 6.74807C12.8523 6.75667 15.3593 9.30024 15.3522 12.4218C15.345 15.5218 12.843 18.0016 9.72363 17.9994L9.72291 18.0001Z"
      fill="#EB9403"
    />
    <path
      d="M8.20391 1.42999C9.12467 2.70643 10.0447 3.98287 10.9648 5.26003C11.0063 5.31812 11.0501 5.37549 11.0867 5.43644C11.1397 5.52608 11.2652 5.61572 11.2122 5.71468C11.139 5.85093 10.9956 5.7491 10.8887 5.73332C8.96762 5.44576 7.21359 5.8416 5.65245 7.0155C5.4739 7.1496 5.34338 7.2127 5.18419 6.98682C4.25052 5.66089 3.30896 4.33999 2.37027 3.01765C2.25912 2.7889 2.33227 2.59528 2.47139 2.40381C3.20355 1.397 4.24263 0.985382 5.4323 0.818297C6.04973 0.731527 6.6399 0.828336 7.23797 0.919408C7.61732 0.977493 7.93356 1.16179 8.20319 1.42999H8.20391Z"
      fill="#EB9403"
    />
    <path
      d="M17.6496 2.23813C16.7819 3.46509 15.9143 4.69277 15.0458 5.91973C14.7906 6.27972 14.5288 6.6354 14.275 6.99682C14.1667 7.151 14.0591 7.19618 13.892 7.06781C13.608 6.84982 13.3298 6.63684 13.1197 6.3335C12.4342 5.34247 11.7314 4.36434 11.0351 3.38047C10.8644 2.78384 11.1398 2.28976 11.4661 1.85448C12.1079 0.998256 12.9648 0.497718 14.057 0.376528C15.2108 0.248884 16.2341 0.492699 17.1326 1.23275C17.2897 1.36254 17.4331 1.50381 17.5586 1.66588C17.6948 1.84157 17.7429 2.02802 17.6496 2.23813Z"
      fill="#EB9403"
    />
    <path
      d="M8.20407 1.43002C6.98141 0.936654 5.73293 0.808293 4.46653 1.2235C3.53071 1.53042 2.74476 2.04099 2.37044 3.01769C2.0585 2.62973 1.75516 2.23533 1.50418 1.80435C1.43318 1.53759 1.58449 1.37265 1.77237 1.22708C2.57983 0.603919 3.43605 0.0976438 4.49665 0.0718281C4.57696 0.0696768 4.65584 0.0395585 4.73257 0.0108744C5.40808 0.00800596 6.08288 0.00872306 6.75767 0.00155203C7.05384 -0.00203348 7.28618 0.104815 7.45541 0.350064C7.70425 0.710767 7.9538 1.07004 8.20335 1.43002H8.20407Z"
      fill="#EB9403"
    />
    <path
      d="M17.6497 2.2381C17.2489 1.28364 16.4292 0.841187 15.5042 0.630359C14.6257 0.42957 13.7343 0.480484 12.8896 0.902141C11.9531 1.36969 11.3557 2.08751 11.1227 3.10867C11.1019 3.20117 11.0646 3.29009 11.0352 3.38045C10.604 2.87513 10.5767 2.35714 10.9534 1.82649C11.2919 1.35105 11.639 0.882062 11.9681 0.400886C12.1524 0.131255 12.384 -0.0021257 12.7146 2.56105e-05C14.0721 0.00934795 15.4296 0.00863084 16.7863 0.0122164C17.3249 0.137709 17.7358 0.466142 18.123 0.838319C18.2743 0.983891 18.3525 1.1395 18.2793 1.34746C18.0692 1.64434 17.8591 1.94122 17.6497 2.2381Z"
      fill="#EB9403"
    />
    <path
      d="M4.73249 0.0115502C4.32805 0.152102 3.91786 0.278313 3.51915 0.435358C2.74612 0.73941 2.02758 1.13095 1.50338 1.80431C1.30402 1.53755 1.10323 1.27293 0.906747 1.00402C0.751136 0.790324 0.67082 0.560851 0.809938 0.30628C0.940451 0.0674843 1.16347 0.00366211 1.41446 0.00366211C2.52023 0.00581342 3.62672 0.00868183 4.73249 0.0108331V0.0115502Z"
      fill="#EB9403"
    />
    <path
      d="M18.2794 1.34753C18.0176 0.889306 17.5816 0.617524 17.1736 0.318492C17.0402 0.220966 16.8896 0.147104 16.7864 0.0122889C17.2001 0.00942048 17.6139 0.00583497 18.0277 0.00440076C18.2894 0.00368366 18.5282 0.0646374 18.6559 0.319926C18.7814 0.572346 18.696 0.804688 18.5447 1.01695C18.463 1.13169 18.369 1.23782 18.2801 1.34825L18.2794 1.34753Z"
      fill="#EB9403"
    />
    <path
      d="M9.73423 16.989C7.32047 17.0844 5.21362 14.9596 5.19354 12.4705C5.17059 9.69176 7.36134 7.77925 9.71344 7.74913C12.1874 7.71758 14.2412 9.83159 14.2778 12.325C14.3165 14.9868 12.1071 17.0708 9.73423 16.989Z"
      fill="#EB9403"
    />
    <path
      d="M6.08495 5.23278C5.44171 5.20051 4.85225 5.09151 4.34957 4.69065C3.52561 4.03378 3.55 3.0743 4.41339 2.47695C5.37861 1.80861 6.94476 1.86598 7.84831 2.60316C8.54462 3.17111 8.55322 4.06319 7.86409 4.63902C7.34275 5.07502 6.71385 5.19191 6.08567 5.23278H6.08495Z"
      fill="#EB9403"
    />
    <path
      d="M16.1324 3.60408C16.1374 4.39361 15.4662 5.01104 14.5978 5.01462C13.7423 5.01821 13.0087 4.36923 13.0015 3.60336C12.9943 2.86905 13.7315 2.22796 14.5849 2.22438C15.4755 2.22151 16.1274 2.80236 16.1324 3.60336V3.60408Z"
      fill="#EB9403"
    />
    <path
      d="M11.6818 10.9344C11.9414 11.4908 12.1092 12.0609 12.0519 12.6913C12.0182 13.0692 11.7844 13.3811 11.7636 13.7547C11.765 13.7906 11.7729 13.8336 11.7457 13.8573C10.992 14.5306 9.72127 14.631 8.87007 14.0868C7.53626 13.2341 7.63163 11.2119 9.04002 10.4769C9.84461 10.0574 10.9081 10.1699 11.5994 10.7451C11.6639 10.7988 11.6589 10.8691 11.6811 10.9344H11.6818Z"
      fill="#EB9403"
    />
    <path
      d="M11.682 10.9343C11.469 10.9207 11.3077 10.7952 11.1348 10.6891C10.412 10.2438 9.44679 10.3191 8.79494 10.8648C8.15816 11.3983 7.93514 12.2294 8.22198 12.9981C8.51312 13.7784 9.29405 14.2947 10.156 14.2674C10.5196 14.2559 10.8645 14.1584 11.1664 13.9505C11.3507 13.8235 11.5407 13.7461 11.7645 13.754C12.2062 14.0222 12.2299 14.3786 11.8254 14.6769C10.9133 15.3502 9.49627 15.3918 8.51671 14.7737C7.47261 14.1147 6.96275 12.9515 7.1958 11.7597C7.4138 10.6439 8.39767 9.67509 9.55651 9.49295C10.3439 9.36889 11.1026 9.48578 11.7781 9.95405C11.9731 10.0889 12.1345 10.243 12.1058 10.5055C12.0786 10.7565 11.8964 10.8633 11.6827 10.9343H11.682Z"
      fill="#FEFEFE"
    />
  </svg>
);
