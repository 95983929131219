// Vendors
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { Link } from "react-router-dom";

// Components
import MainButton from "../MainButton/MainButton";
import StaffTag from "../StaffTag/StaffTag";
import ProfilePicture from "../ProfilePicture/ProfilePicture";

// Styles
import classes from "./ProfileCard.module.scss";

// Assets
import BackgroundSVG from "../../assets/images/profile/background";
import { twitchIcon } from "../../assets/icons/twitch/twitch";
import { youtubeIcon } from "../../assets/icons/youtube/youtube";
import { InstagramIcon } from "../../assets/icons/instagram/instagram";
import { twitterIcon } from "../../assets/icons/twitter/twitter";
import GreenFinishedIcon from "../../assets/icons/greenFinished/greenFinished";

import { generateSocialMediaLink } from "../../utils/globalUtils";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const ProfileCard = ({ userData, isProfileOwner, handleToggleModal }) => {
  const svgString = encodeURIComponent(renderToStaticMarkup(<BackgroundSVG />));

  return (
    <>
      <div
        className={classes.profileCardContainer}
        style={{
          background: `no-repeat right/250% url("data:image/svg+xml,${svgString}")`,
        }}
      >
        <div className={classes.backgroundContainer}>
          <div className={classes.editProfileButtonContainer}>
            <Link
              to={{
                pathname: `/${globalConstants.ROUTES.BET_VERIFICATION}/${userData.user.username}/`,
              }}
            >
              <MainButton
                type="secondary"
                buttonText="Verified bets"
                icon={<GreenFinishedIcon size={18} />}
              />
            </Link>

            {isProfileOwner && (
              <MainButton
                type="secondary"
                buttonText="Edit Profile"
                clicked={handleToggleModal}
              />
            )}
          </div>

          <div className={classes.profileRow}>
            <ProfilePicture
              isOnline={userData.isOnline}
              image={userData.imageUrl}
            />
            <div className={classes.profileDataRowsContainer}>
              <div className={classes.profileTagRow}>
                <span className={classes.usernameText}>
                  @{userData?.user?.username}
                </span>
                {userData?.user?.staff ? (
                  <div className={classes.tagContainer}>
                    <StaffTag staff={userData.user.staff} />
                  </div>
                ) : null}
              </div>
              <div className={classes.profileStatus}>
                {userData.description}
              </div>
              <div className={classes.profileInteractionsAndSocialRowDesktop}>
                {/* <div className={classes.interactionsRow}>
                  <div className={classes.interactionItem}>
                    <span className={classes.profileInfoText}>Followers:</span>
                    <span className={classes.profileInfoNumber}>{`${
                      userData.followers || 0
                    }`}</span>
                  </div>
                  <div className={classes.interactionItem}>
                    <span className={classes.profileInfoText}>Following:</span>
                    <span className={classes.profileInfoNumber}>{`${
                      userData.following || 0
                    }`}</span>
                  </div>
                  <div className={classes.interactionItem}>
                    <span className={classes.profileInfoText}>Instalocks:</span>
                    <span className={classes.profileInfoNumber}>{`${
                      userData.instalocks || 0
                    }`}</span>
                  </div>
                </div> */}
                <div className={classes.interactionsRow}>
                  {userData && userData.twitter && (
                    <a
                      className={classes.socialItem}
                      href={generateSocialMediaLink(
                        "twitter",
                        userData.twitter
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className={classes.profileSocialIcon}>
                        {twitterIcon}
                      </span>
                      <span
                        className={classes.profileInfoSocial}
                      >{`${userData.twitter}`}</span>
                    </a>
                  )}
                  {userData && userData.twitch && (
                    <a
                      className={classes.socialItem}
                      href={generateSocialMediaLink("twitch", userData.twitch)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className={classes.profileSocialIcon}>
                        {twitchIcon}
                      </span>
                      <span
                        className={classes.profileInfoSocial}
                      >{`${userData.twitch}`}</span>
                    </a>
                  )}
                  {userData && userData.instagram && (
                    <a
                      className={classes.socialItem}
                      href={generateSocialMediaLink(
                        "instagram",
                        userData.instagram
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className={classes.profileSocialIcon}>
                        <InstagramIcon id="InstaLogo_Profile_card_desktop" />
                      </span>
                      <span
                        className={classes.profileInfoSocial}
                      >{`${userData.instagram}`}</span>
                    </a>
                  )}
                  {userData && userData.youtube && (
                    <a
                      className={classes.socialItem}
                      href={generateSocialMediaLink(
                        "youtube",
                        userData.youtube
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className={classes.profileSocialIcon}>
                        {youtubeIcon}
                      </span>
                      <span
                        className={classes.profileInfoSocial}
                      >{`${userData.youtube}`}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.profileInteractionsAndSocialRowMobile}>
            {/* <div className={classes.interactionsRow}>
              <div className={classes.interactionItem}>
                <span className={classes.profileInfoText}>Followers:</span>
                <span className={classes.profileInfoNumber}>{`${
                  userData.followers || 0
                }`}</span>
              </div>
              <div className={classes.interactionItem}>
                <span className={classes.profileInfoText}>Following:</span>
                <span className={classes.profileInfoNumber}>{`${
                  userData.following || 0
                }`}</span>
              </div>
              <div className={classes.interactionItem}>
                <span className={classes.profileInfoText}>Instalocks:</span>
                <span className={classes.profileInfoNumber}>{`${
                  userData.instalocks || 0
                }`}</span>
              </div>
            </div> */}
            <div className={classes.interactionsRow}>
              {userData && userData.twitter && (
                <a
                  className={classes.socialItem}
                  href={generateSocialMediaLink("twitter", userData.twitter)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={classes.profileSocialIcon}>
                    {twitterIcon}
                  </span>
                  <span
                    className={classes.profileInfoSocial}
                  >{`${userData.twitter}`}</span>
                </a>
              )}
              {userData && userData.twitch && (
                <a
                  className={classes.socialItem}
                  href={generateSocialMediaLink("twitch", userData.twitch)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={classes.profileSocialIcon}>
                    {twitchIcon}
                  </span>
                  <span
                    className={classes.profileInfoSocial}
                  >{`${userData.twitch}`}</span>
                </a>
              )}
              {userData && userData.instagram && (
                <a
                  className={classes.socialItem}
                  href={generateSocialMediaLink(
                    "instagram",
                    userData.instagram
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={classes.profileSocialIcon}>
                    <InstagramIcon id="InstaLogo_Profile_card_mobile" />
                  </span>
                  <span
                    className={classes.profileInfoSocial}
                  >{`${userData.instagram}`}</span>
                </a>
              )}
              {userData && userData.youtube && (
                <a
                  className={classes.socialItem}
                  href={generateSocialMediaLink("youtube", userData.youtube)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={classes.profileSocialIcon}>
                    {youtubeIcon}
                  </span>
                  <span
                    className={classes.profileInfoSocial}
                  >{`${userData.youtube}`}</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;
