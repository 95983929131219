// Vendors
import React, { useContext, useEffect, useState } from "react";
import { Drawer, message, Switch } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { differenceInMilliseconds } from "date-fns";

// Context
import { BetSlipContext } from "../../context/contextProviders/BetSlipContextProvider";
import { ModalContext } from "../../context/contextProviders/ModalContextProvider";

// Components
import BetSlipContent from "../BetSlipContent/BetSlipContent";
import Backdrop from "../Backdrop/Backdrop";
import MainButton from "../MainButton/MainButton";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// API
import { createBets } from "../../api/requests/requests";

// Styles
import classes from "./BetSlip.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import {
  decimalToAmericanOdds,
  generateBetErrors,
  sendBetslipOdd,
  receiveBetSlipOdd,
} from "../../utils/globalUtils";

const BetSlip = ({ openDrawer, onClose }) => {
  const { betSlipData, setBetSlipData, showBetSlip, toggleShowBetSlip } =
    useContext(BetSlipContext);

  const [loadingCreatingBets, setLoadingCreatingBets] = useState(false);

  const [currentBetSlipData, setCurrentBetSlipData] = useState(betSlipData);

  const [isDollarsSelected, setIsDollarsSelected] = useState(false);

  const { setModalToShow } = useContext(ModalContext);

  const { userSession, preferredOddsType } = useContext(UserContext);

  const onSwitchCurrency = () => {
    setIsDollarsSelected((prevSelection) => !prevSelection);
  };

  const handleBetslipOnClose = () => {
    onClose();
  };

  const handleSaveBetslip = (data) => {
    setBetSlipData(data);
  };

  const handleClearBetSlip = () => {
    setBetSlipData({ action: betSlipData.action, bets: [] });
    setCurrentBetSlipData({ action: betSlipData.action, bets: [] });
    handleBetslipOnClose();
  };

  const getTotalFromBet = (bets, property) => {
    return bets.reduce((riskTotal, currentBet) => {
      return parseFloat(riskTotal) + parseFloat(currentBet[property] || 0);
    }, 0);
  };

  const handleSumbmitTry = () => {
    if (!userSession) {
      setModalToShow("login");
      return;
    }

    if (currentBetSlipData.bets?.length <= 0) {
      return;
    }

    const newBets = generateBetErrors(
      currentBetSlipData.bets,
      preferredOddsType
    );
    let isValid = true;
    newBets.forEach((bet) => {
      if (Object.values(bet.errors).length > 0) {
        isValid = false;
      }
    });

    if (isValid) {
      handleSumbmit(newBets);
    } else {
      const newData = { action: currentBetSlipData.action, bets: newBets };
      handleSaveBetslip(newData);
    }
  };

  const formatBets = (bets) => {
    return bets?.map((bet) => {
      return {
        selectedAbiosTeamId: bet.teamOne.abiosTeamId,
        alternativeAbiosTeamId: bet.teamTwo.abiosTeamId,
        abiosSeriesId: bet.abiosSeriesId,
        type: bet.type ? bet.type.toUpperCase() : "MONEYLINE",
        odd: parseFloat(
          preferredOddsType === "decimal"
            ? decimalToAmericanOdds(bet.odds)
            : bet.odds
        ),
        amount: parseFloat(bet.risk),
        amountType: isDollarsSelected ? "US_DOLLAR" : "UNIT",
        spread: bet.type === "spread" ? bet.spread : null,
      };
    });
  };

  const handleSumbmit = (bets) => {
    setLoadingCreatingBets(true);

    const formattedBets = formatBets(bets);

    createBets(
      { bets: formattedBets },
      () => {
        message.success({
          content: "Tracking successful.",
          className: "successMessage",
        });
        setLoadingCreatingBets(false);
        handleClearBetSlip();
      },
      (error) => {
        const errorMessage =
          error.response.status === 412
            ? "One bet with the same type for one of the series already exists"
            : "There was an error tracking your bets, please  review them and try again.";

        message.error({
          content: errorMessage,
          className: "errorMessage",
        });
        setLoadingCreatingBets(false);
      }
    );
  };

  const BetSlipControls = (
    <>
      <div className={classes.drawerButtons}>
        <div className={classes.betSlipClearButton}>
          <MainButton
            clicked={handleClearBetSlip}
            buttonText="Clear Slip"
            fullWidth="fullWidth"
            type="tertiary"
          />
        </div>
        <div className={classes.betSlipSubmitButton}>
          <MainButton
            clicked={handleSumbmitTry}
            buttonText="Bet Now"
            fullWidth="fullWidth"
            type="primary"
            loading={loadingCreatingBets}
          />
        </div>
      </div>
    </>
  );

  const handleDeleteUnbetableData = (data) => {
    const availableBets = data?.bets?.filter((bet) => {
      return differenceInMilliseconds(new Date(), new Date(bet.startDate)) < 0;
    });

    return { ...data, bets: availableBets };
  };

  const handleTransformOdds = (data, callback) => {
    const newBetsOdds = data?.bets?.map((bet) => {
      return { ...bet, odds: callback(bet.odds) };
    });
    return { ...data, bets: newBetsOdds };
  };

  useEffect(() => {
    if (showBetSlip) {
      const betData = handleTransformOdds(
        handleDeleteUnbetableData(betSlipData),
        receiveBetSlipOdd
      );
      setCurrentBetSlipData({
        ...betData,
        bets: generateBetErrors(betData.bets, preferredOddsType),
      });
    } else {
      const betData = handleTransformOdds(
        handleDeleteUnbetableData(currentBetSlipData),
        sendBetslipOdd
      );
      handleSaveBetslip({
        ...betData,
        bets: generateBetErrors(betData.bets, preferredOddsType),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBetSlip]);

  const currencySelector = (
    <div className={classes.currencySelectorContainer}>
      <span
        className={classes.currencySelectorLabels}
        style={{
          color: isDollarsSelected
            ? styledVariables.white40
            : styledVariables.white,
        }}
      >
        Units
      </span>
      <Switch
        defaultChecked
        onChange={onSwitchCurrency}
        checked={isDollarsSelected}
      />
      <span
        className={classes.currencySelectorLabels}
        style={{
          color: isDollarsSelected
            ? styledVariables.white
            : styledVariables.white40,
        }}
      >
        $
      </span>
    </div>
  );

  return (
    <>
      {/* <div className={classes.mobileBetslip}>
        <Backdrop show={showBetSlip} clicked={toggleShowBetSlip} />

        <div
          className={classes.mobileBetslipFooter}
          style={{ height: showBetSlip ? "160px" : "80px" }}
        >
          <div className={classes.mobileBetSlipTitleRow}>
            <div>
              <div className={classes.betSlipTitleAndSwitchContainer}>
                <div className={classes.betSlipTitleContainer}>
                  <h3 className={classes.betSlipTitle}>Bet Tracking</h3>
                  {betSlipData?.bets?.length > 0 ? (
                    <span>{betSlipData?.bets?.length}</span>
                  ) : null}
                </div>
                {currencySelector}
              </div>

              <div className={classes.footerTotals}>
                <p className={classes.footerTotalLabel}>
                  Total risk:
                  <span className={classes.footetRiskTotal}>
                    {getTotalFromBet(currentBetSlipData.bets, "risk")}
                  </span>
                </p>
                <p className={classes.footerTotalLabel}>
                  To win:
                  <span className={classes.footetWinTotal}>
                    {getTotalFromBet(currentBetSlipData.bets, "win")}
                  </span>
                </p>
              </div>
            </div>

            <MainButton
              size="medium"
              icon={showBetSlip ? <DownOutlined /> : <UpOutlined />}
              type="secondary"
              clicked={toggleShowBetSlip}
            />
          </div>
          {BetSlipControls}
        </div>
        <div
          className={classes.mobileBetSlipContent}
          style={{
            bottom: showBetSlip ? "160px" : "-500px",
            padding: betSlipData.bets?.length > 0 ? "24px" : "0px",
          }}
        >
          <BetSlipContent
            currentBetSlipData={currentBetSlipData}
            setCurrentBetSlipData={setCurrentBetSlipData}
            preferredOddsType={preferredOddsType}
            setBetSlipData={setBetSlipData}
          />
        </div>
      </div> */}
      <div className={classes.desktopBetSlip}>
        <Drawer
          closable={false}
          onClose={handleBetslipOnClose}
          open={openDrawer}
          size="default"
          className={classes.desktopBetSlip}
          destroyOnClose={true}
        >
          <div className={classes.betSlipContainer}>
            <div className={classes.betSlipTitleRow}>
              <div className={classes.betSlipTitleAndSwitchContainer}>
                <div className={classes.betSlipTitleContainer}>
                  <h3 className={classes.betSlipTitle}>Bet Tracking</h3>

                  {betSlipData?.bets?.length > 0 ? (
                    <span>{betSlipData?.bets?.length}</span>
                  ) : null}
                </div>
                {currencySelector}
              </div>

              <MainButton
                buttonText="Close"
                type="secondary"
                clicked={handleBetslipOnClose}
              />
            </div>
            <div className={classes.betSlipContentContainer}>
              <BetSlipContent
                currentBetSlipData={currentBetSlipData}
                setCurrentBetSlipData={setCurrentBetSlipData}
                preferredOddsType={preferredOddsType}
                setBetSlipData={setBetSlipData}
              />
            </div>
            <div className={classes.drawerFooter}>
              <div className={classes.footerTotals}>
                <p className={classes.footerTotalLabel}>
                  Total risk:
                  <span className={classes.footetRiskTotal}>
                    {getTotalFromBet(currentBetSlipData.bets, "risk")}
                  </span>
                </p>
                <p className={classes.footerTotalLabel}>
                  To win:
                  <span className={classes.footetWinTotal}>
                    {getTotalFromBet(currentBetSlipData.bets, "win")}
                  </span>
                </p>
              </div>
              {BetSlipControls}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};

export default BetSlip;
