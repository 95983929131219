// Vendors
import React from "react";

export const trashIcon = (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.23613 0.783676C9.49704 1.04462 9.64359 1.39844 9.64359 1.7675V2.29225L13.8732 2.29212V3.48474L0.126953 3.48488V2.29225H4.35661V1.7675C4.35661 1.39841 4.50313 1.0446 4.76406 0.783676C5.025 0.522766 5.37894 0.376221 5.74788 0.376221H8.25231C8.62126 0.376221 8.9752 0.522748 9.23613 0.783676ZM5.54916 1.7675V2.29225L8.45094 2.29225V1.76749C8.45094 1.71481 8.43001 1.6642 8.39271 1.62691C8.35556 1.58961 8.30495 1.56868 8.25226 1.56868H5.74784C5.63817 1.56868 5.54916 1.65769 5.54916 1.7675ZM11.6634 15.6195C11.1788 16.1041 10.5216 16.3762 9.83622 16.3762H4.16372C3.47843 16.3762 2.82111 16.1041 2.33656 15.6195C1.85202 15.1349 1.57986 14.4776 1.57986 13.7925V5.33918C1.57986 5.18098 1.64267 5.0293 1.75441 4.91742C1.8663 4.80567 2.01798 4.74287 2.17617 4.74287H11.8238C11.982 4.74287 12.1336 4.80567 12.2455 4.91742C12.3573 5.02931 12.4201 5.18099 12.4201 5.33918V13.7925C12.4201 14.4776 12.1479 15.135 11.6634 15.6195ZM2.77248 5.93531V13.7923H2.77263C2.77263 14.1613 2.9193 14.5152 3.18022 14.7761C3.44117 15.037 3.79499 15.1836 4.16404 15.1836H9.83647C10.2056 15.1836 10.5594 15.0371 10.8203 14.7761C11.0812 14.5152 11.2279 14.1613 11.2279 13.7923V5.93531H2.77248ZM5.01253 13.2039C5.17072 13.2039 5.3224 13.1411 5.43415 13.0292C5.54603 12.9175 5.60884 12.7658 5.60884 12.6076V8.37618C5.60884 8.16308 5.49515 7.96634 5.31062 7.85972C5.12623 7.75325 4.89884 7.75325 4.71445 7.85972C4.52992 7.96634 4.41623 8.16308 4.41623 8.37618V12.6076C4.41623 12.7658 4.47904 12.9175 4.59092 13.0292C4.70267 13.1411 4.85434 13.2039 5.01253 13.2039ZM9.40941 13.0292C9.29766 13.1411 9.14598 13.2039 8.98779 13.2039C8.8296 13.2039 8.67793 13.1411 8.56618 13.0292C8.4543 12.9175 8.39149 12.7658 8.39149 12.6076V8.37618C8.39149 8.16308 8.50518 7.96634 8.68971 7.85972C8.8741 7.75325 9.10149 7.75325 9.28588 7.85972C9.47041 7.96634 9.5841 8.16308 9.5841 8.37618V12.6076C9.5841 12.7658 9.52129 12.9175 9.40941 13.0292Z"
      fill="#FF4D26"
    />
  </svg>
);
