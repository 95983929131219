// Vendors
import React from "react";

export const twitchIcon = (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.997147 0.625977L0 3.62595V14.6259H3.98899V16.626H5.98335L7.97805 14.6259H10.9699L14.9592 10.366V0.625977H0.997147ZM13.9617 9.62585L11.1696 12.6259H7.63317L5.48493 14.1929V12.6259H1.99436V1.62617H13.9617V9.62585H13.9617Z"
      fill="#7743D4"
    />
    <path
      d="M7.97814 4.62598H6.98096V8.62604H7.97814V4.62598Z"
      fill="#7743D4"
    />
    <path
      d="M10.9698 4.62598H9.97266V8.62604H10.9698V4.62598Z"
      fill="#7743D4"
    />
  </svg>
);
