export const glossaryPageConstants = {
  GLOSSARY_BREAK_POINTS: { default: 3, 1200: 3, 768: 2, 576: 1 },
  GLOSSARY_DATA: {
    general: [
      {
        title: "Action",
        content: "A wager of any kind.",
      },
      {
        title: "Added Game",
        content:
          "A game that is not part of the regular Las Vegas rotation. Oftentimes this will be a rescheduled game or the second game of a doubleheader.",
      },
      {
        title: "Arbitrage",
        content:
          "The simultaneous purchase and sale of the same game in different markets to profit from unequal prices.",
      },
      {
        title: "Against the Spread (ATS)",
        content:
          "Refers to taking or laying points (aka the spread) as opposed to taking a game straight up.",
      },
      {
        title: "Bookmaker",
        content:
          "A person who is licensed to create betting lines and take wagers.",
      },
      {
        title: "Bankroll",
        content: "The available funds you have to bet with.",
      },
      {
        title: "Buying Points",
        content:
          "Paying an additional fee in order to get a game at a more attractive price. Often times bettors will buy points in football around key numbers such as 3 and 7.",
      },
      {
        title: "Chalk",
        content: "The favored team.",
      },
      {
        title: "Contrarian Betting",
        content:
          "Also known as betting against the public, contrarian betting finds value by betting on games with loopsided betting percentages.",
      },
    ],
    leagueOfLegends: [
      {
        title: "Nexus",
        content:
          "Primary objective that a team destroys in order to win a League match, it is guarded by two turrets and cannot be accessed without destroying both of them, if nexus takes damage it will regenerate health over time.",
      },

      { title: "Champion", content: "The name for the playable characters." },
      {
        title: "Turret",
        content:
          "Also called towers, these deal damage to enemy champions and minions, it is not possible to win a match without having gone through a lane’s tier 1 and 2 turrets, inhibitor turrets, and nexus turrets.",
      },
      {
        title: "Gold",
        content:
          "The currency used to buy items, this is gained through getting last hit on minions (known as cs), knocking down turrets, getting objectives, and slaying enemy champions.",
      },
      {
        title: "Drake",
        content:
          "Monster type that will give different types of stats based on the drake slain (cloud, mountain, hextech, infernal, ocean), first drake spawns in at 5 minutes into the game, and once first is slain the next spawns 5 minutes after and keeps spawning until one team has slain four drakes in which the elder drake spawns thereafter.",
      },
      {
        title: "Cloud Drake",
        content: "Drake that gives out of combat movement speed to all allies.",
      },
      {
        title: "Mountain Drak",
        content: "Drake that gives armor and magic resistance to all allies.",
      },
      {
        title: "Infernal Drake",
        content:
          "Drake that gives attack damage and ability power to all allies.",
      },
      {
        title: "Hextech Drake",
        content: "Drake that gives ability haste to all allies.",
      },
      {
        title: "Ocean Drake",
        content: "Drake that restores missing health every 5 seconds to allies",
      },
      {
        title: "Elder Drake",
        content:
          "Drake that spawns after a team has slain 4 drakes, gives allies significant bonus damage on their attacks, a burn effect, and when an enemy champion is damaged by a champion with elder buff and their health is below 20%, they are immediately destroyed.",
      },
      {
        title: "Soul",
        content:
          "Buff given when a team slays 4 drakes, effect varies on the 3rd/4th drake slain Wards- items bought that champions place around the map in order to gain vision, these can be destroyed by enemy champions.",
      },
      {
        title: "Baron Nashor",
        content:
          "Powerful purple neutral monster that when slain gives a buff to all livable teammates that increases attack damage, ability power, gives a significantly faster recall, and an aura to all nearby minions in a lane that allows them to siege structures easier, spawns in at the 20th minute and respawns every 6 minutes after being slain.",
      },
      {
        title: "Rift Herald",
        content:
          "Neutral monster that spawns in the 8th minute of play and is located in the same pit as baron, when slain, the champion that slayed it can summon eye of the herald in the lane which spawns a monster that has health and sieges enemy structures.",
      },
      {
        title: "Vision",
        content:
          "The ability to see spots on the map, it is obtained through using wards, items, and through teammates and your own turrets.",
      },
      {
        title: "Blue Buff",
        content:
          "Jungle monster that grants a buff when slain to give mana regeneration to the champion that killed it, it is indicated by a blue circle under the champion model",
      },
      {
        title: "Red Buff",
        content:
          "Jungle monster that grants a buff when slain to allow the champion that slayed it to have basic attacks that have a burn effect and slow, it is indicated by a red circle under the champion model",
      },
      {
        title: "Flash",
        content:
          "Most common summoner spell in the game that causes a champion to blink a short distance, it can be used once every 300 seconds.",
      },
      {
        title: "CS",
        content:
          "Known as creep score, is the number of minions that have been last hit by a player, this is important because getting last hit on a minion will give gold and extra experience to the player.",
      },
      {
        title: "Experience (XP)",
        content:
          "Champions have levels that they reach in order to unlock extra skills and upgrade them, champion experience is gained by slaying minions, objectives, and champions.",
      },
      {
        title: "Level",
        content:
          "Indicator used to mark how much experience a champion has in-game, max level in League is 18.",
      },
      {
        title: "Level",
        content:
          "Indicator used to mark how much experience a champion has in-game, max level in League is 18.",
      },
      {
        title: "Ultimate",
        content:
          "The strongest ability a champion has, it is gained at level 6 and can be ranked up again at levels 11 and 16.",
      },
      {
        title: "Mana",
        content:
          "Resource that champions use in order to cast abilities, depicted by a blue bar under a champion’s hp bar, every ability for a champion has a different mana cost.",
      },
      {
        title: "Kills",
        content: "Amount of times a champion has slain an enemy champion.",
      },
      {
        title: "Assists",
        content:
          "Amount of times a champion has assisted in the slaying of an enemy champion.",
      },
      {
        title: "Deaths",
        content:
          "Amount of times a champion has died and lost all of their health in a game, a death can come from enemy champions or from minions/objectives.",
      },
      {
        title: "Backdoor",
        content:
          "Strategy in which a player or team will sneak into the enemy base without support of a minion wave, usually done as a surprise when the enemy team is overextended and can’t be back to defend themselves.",
      },
      {
        title: "Split push",
        content:
          "Strategy in which a player will be in a weak or undefended lane and will be sieging turrets / the enemy base while the rest of the players in the game are fighting or at an objective.",
      },
      {
        title: "Lane",
        content:
          "Sections of the map that minions spawn in and turrets are placed, the three lanes are top lane, mid lane, and bot lane.",
      },
      {
        title: "Top Laner",
        content: "Position that plays primarily in the top lane.",
      },
      {
        title: "Jungler",
        content:
          "Position that plays in the jungle and instead of slaying normal minions in lane, the jungler slays jungle creeps that are scattered throughout the jungle and is also responsible for getting the other 3 lanes ahead by coming to them and assisting in taking down enemy champions and turrets.",
      },
      {
        title: "Gank",
        content:
          "A term to describe when one or more players from a particular team coming to a lane to create a numbers advantage with the goal of killing or disrupting the opposing players.",
      },
      {
        title: "Mid Laner",
        content:
          "Position that plays primarily in the middle lane, generally considered the most influential lane in the game.",
      },
      {
        title: "Bot Laner / AD Carry",
        content:
          "Position that primarily plays in bot lane, usually is considered one of the biggest, if not the biggest, damage dealers on a team.",
      },
      {
        title: "Support",
        content:
          "Position that primarily plays in bot lane with the bot laner, generally is a healer or tank of some sort.",
      },
      {
        title: "Pick ban",
        content:
          "Process by which teams choose the champions they want to play and ban champions they don’t want in a match before the game starts.",
      },
      {
        title: "Inhibitor",
        content:
          "Structure that is present in top, middle, and bottom lanes and once destroyed will spawn super minions until it respawns five minutes after being destroyed, a team must have destroyed one inhibitor in order to be able to attack the nexus and nexus turrets and win the game.",
      },
      {
        title: "Super minions",
        content:
          "Minions that spawn in a specific lane in which the inhibitor was destroyed, these are significantly stronger and have more health than normal minions.",
      },
      {
        title: "First Turret",
        content:
          "Objective goal in which the team that takes down the first turret of the match gets an increased amount of gold.",
      },
    ],
    csgo: [
      {
        title: "CT",
        content:
          "Short for counter terrorists, the defenders of the bombsites.",
      },
      {
        title: "T",
        content: "Short for terrorists, the attackers of the bombsites.",
      },
      {
        title: "Bomb Down [position]",
        content: "A call out as to where the bomb has been dropped.",
      },
      {
        title: "Clutch",
        content: "When a player wins while they are the last teammate alive.",
      },
      {
        title: "Kit",
        content:
          "A defuse kit the CT’s can buy that shortens the defuse time greatly.",
      },
      {
        title: "Eco’d",
        content:
          "This term refers to how the team won the round in terms of economy. This usually happens when the saving team beats the buying team.",
      },
      {
        title: "Bomb Site",
        content: "Places where the bomb can be planted.",
      },
      {
        title: "Flash [location]",
        content:
          "A verb used to indicate that the player wants a flashbang from a teammate at a certain location or is using one.",
      },
      {
        title: "Pushing",
        content:
          "When someone is moving into an area rapidly and aggressively.",
      },
      {
        title: "Flank",
        content: "To attack from behind.",
      },
      {
        title: "DM",
        content:
          "Short for Deathmatch, a gamemode with rapid respawns where players can warm up their gunplay",
      },
      {
        title: "Holding [position]",
        content: "To be aiming at a specific position.",
      },
      {
        title: "Trade",
        content:
          "To rapidly kill an enemy after they’ve killed you or a teammate.",
      },
      {
        title: "Clear",
        content:
          "To make sure a location has no enemies around it. Also used as a verb, “Could you clear the back of site?”",
      },
      {
        title: "Burst",
        content: "To fire only a few bullets at a time.",
      },
      {
        title: "HP",
        content:
          "Shorthand for Health/Hit Points, this term refers to the health of a player.",
      },
      {
        title: "Low",
        content: "Shorthand for low health.",
      },
      {
        title: "Flick",
        content: "To move the mouse to a target in a fast and precise manner.",
      },
      {
        title: "Spraying",
        content: "To fire many bullets in a row without pausing.",
      },
      {
        title: "Frag",
        content: "Another word for a kill.",
      },
      {
        title: "Top Frag",
        content: "The player with the highest number of kills.",
      },
      {
        title: "Bottom Frag",
        content: "The player with the lowest number of kills.",
      },
      {
        title: "KPR",
        content:
          "Short for Kills per Round, this statistic is the average of how many kills are obtained per round.",
      },
      {
        title: "Duel",
        content:
          "A situation in which only one player on each team engages in a fight. Another word for a 1v1.",
      },
      {
        title: "Molly",
        content:
          "Short for Molotov, this term refers to a grenade that leaves a long-lasting damaging area of effect circle at a target location.",
      },
      {
        title: "AWP",
        content:
          "This weapon is the most expensive in the shop at $4750. This sniper is a one hit kill in the lower torso or higher. Because of the power of this weapon, mobility is more limited with this weapon compared to an AK or P90.",
      },
      {
        title: "Retake",
        content:
          "When the defending team has to regain control of the bomb area and defuse the bomb in a timely fashion.",
      },
      {
        title: "One",
        content:
          "Short for one health, a non-literal term also meaning low health. Typically seen as “one bullet away from getting eliminated”.",
      },
      {
        title: "Smoke",
        content: "A grenade that blocks vision in a radius.",
      },
      {
        title: "Nade",
        content:
          "Usually referring to the HE Grenade, which is an explosive that deals heavy damage when going off",
      },
      {
        title: "Boosted",
        content:
          "When one player crouches and another stands on top of them, elevating their viewpoint.",
      },
      {
        title: "Rating",
        content:
          "Based on the number of kills per round, the survival rate, and the amount of multikills.",
      },
      {
        title: "Ninja / Ninja Defuse",
        content:
          "To defuse the bomb secretly, usually within a smoke, without the terrorists realizing before it’s too LayoutContext.",
      },
    ],
    valorant: [
      {
        title: "Spike Down [position]",
        content:
          "This is announced to all players when the player with the spike has been eliminated.",
      },
      {
        title: "Thrifty",
        content:
          "This announcer term refers to how the team won the round in terms of economy. Specifically, when the winning team has a loadout average of 2500 credits less per player than the losing team. A vast economic upset.",
      },
      {
        title: "Flawless",
        content: "When the winning team loses no players.",
      },
      {
        title: "Match Point",
        content: "When either team is one round away from winning the game.",
      },
      {
        title: "Dart [position]",
        content:
          "Referring to Sova’s Recon Dart. An ability that grants vision of enemy players within sight of the dart.",
      },
      {
        title: "Scan [position]",
        content:
          "Usually referring to Sova’s Owl Drone to clear out areas where enemies could possibly be, similar to the Recon Dart. Also used as a verb, “The dart scanned two players.”",
      },
      {
        title: "Dog",
        content:
          "Referring to Skye’s Trailblazer, the wolf that Skye controls is almost always called a dog. Also used as a verb, “Could you dog this corner?”",
      },
      {
        title: "Bomb Site",
        content: "Another name for the sites where the spike can be planted.",
      },
      {
        title: "Flash",
        content:
          "Referring to any character who has an ability that blinds enemies for a short period of time. Also used as a verb, “Could you flash into the bomb site?”",
      },
      {
        title: "Pushing",
        content:
          "When someone is moving into an area rapidly and aggressively.",
      },
      {
        title: "Flank",
        content: "To attack from behind.",
      },
      {
        title: "DM",
        content:
          "Short for Deathmatch, a gamemode with no abilities and rapid respawns where players can warm up their gunplay.",
      },
      {
        title: "Holding [position]",
        content: "To be aiming at a specific position.",
      },
      {
        title: "Trade",
        content:
          "To rapidly kill an enemy after they’ve killed you or a teammate.",
      },
      {
        title: "TP",
        content:
          "Short for teleport, and referring to Omen’s Shrouded Step and From the Shadows, which both allow Omen to teleport around the map. From the Shadows has no range limit as to where Omen can teleport.",
      },
      {
        title: "Clear",
        content:
          "To make sure a location has no enemies around it. Also used as a verb, “Could you clear the back of site?”",
      },
      {
        title: "Burst",
        content: "To fire only a few bullets at a time.",
      },
      {
        title: "HP",
        content:
          "Shorthand for Health/Hit Points, this term refers to the health of a player or utility.",
      },
      {
        title: "Low",
        content: "Shorthand for low health.",
      },
      {
        title: "Flick",
        content: "To move the mouse to a target in a fast and precise manner.",
      },
      {
        title: "Spraying",
        content: "To fire many bullets in a row without pausing.",
      },
      {
        title: "Frag",
        content: "Another word for an elimination.",
      },
      {
        title: "Top Frag",
        content: "The player with the highest number of eliminations.",
      },
      {
        title: "Bottom Frag",
        content: "The player with the lowest number of eliminations.",
      },
      {
        title: "ACS",
        content:
          "Short for Average Combat Score, this statistic is the average of how much damage is dealt per round",
      },
      {
        title: "Camera",
        content:
          "Referring to Cypher’s Spycam, this ability can be placed on walls and is used as a safe way to view areas without risking Cypher’s life. Enemies can notice the camera by its distinct red light, indicating Cypher is watching his camera, otherwise the camera will go invisible. It can still be destroyed while invisible.",
      },
      {
        title: "Duel",
        content:
          "A situation in which only one player on each team engages in a fight. Another word for a 1v1.",
      },
      {
        title: "Molly",
        content:
          "Short for Molotov, this term refers to any ability that leaves a damaging area of effect circle at a target location.",
      },
      {
        title: "OP",
        content:
          "Also referred to as Operator or AWP, this weapon is the most expensive in the shop at 4700 credits. This sniper is a one hit kill in the lower torso or higher. Because of the power of this weapon, mobility is more limited with this weapon compared to a Vandal or Spectre.",
      },
      {
        title: "Odin",
        content:
          "A heavy machine gun costing 3200 credits. This weapon is inaccurate at first, but after a second of shooting, this weapon essentially becomes a laser beam, melting anything in sight. This weapon is amazing for shooting through thin walls.",
      },
      {
        title: "Retake",
        content:
          "When the defending team has to regain control of the spike area and defuse the spike in a timely fashion.",
      },
      {
        title: "One",
        content:
          "Short for one health, a non-literal term also meaning low health. Typically seen as “one bullet away from getting eliminated”.",
      },
    ],
    rocketLeague: [
      {
        title: "Musty flick",
        content:
          "A musty flick is an abnormal flick where the player jumps, pushes nose down, than backwards dodges to push the ball up in the air. It was invented/popularized by the player Musty.",
      },
      {
        title: "Flip reset",
        content:
          "When you place all four of your wheels on a surface to re-gain a flip mid-flight. A car has two jumps, your standard jump, and the second is a flip.",
      },
      {
        title: "Goal",
        content: "Result that happens when the ball is sent into a teams’ net.",
      },
      {
        title: "Crossbar",
        content:
          "The top of the rocket league net that is outside of the goal.",
      },
      {
        title: "The post",
        content:
          "The sides of the rocket league net which are outside of the goal.",
      },
      {
        title: "Octane",
        content: "The most common vehicle used in the game",
      },
      {
        title: "Boost",
        content:
          "Scattered across the map, a player’s boost can go all the way up to 100 and boost can be used on ground or mid air.",
      },
      {
        title: "Ceiling shot",
        content:
          "Type of shot that is performed when a player shoots the ball off the top of the map.",
      },
      {
        title: "Demo",
        content:
          "Also known as demolition, is when a player uses their boost to go into an opposing players car and demolishes their car, a player that is demo’d will respawn after some seconds.",
      },
      {
        title: "Aerial",
        content: "When a player is performing actions in the air.",
      },
      {
        title: "Double tap",
        content:
          "When a player would hit the ball in the air towards backboard and then hit it in (ideally without bouncing) while not touching the ground or backboard.",
      },
      {
        title: "Kickoff",
        content:
          "At the start of every game and after every goal there is a kickoff between at least 2 cars.",
      },
      {
        title: "Overtime",
        content:
          "Happens after 5 minutes of gameplay and the score is tied, it lasts until a team scores.",
      },
      {
        title: "Pinch",
        content:
          "The action of touching the ball between a car and a wall in a perfect manner to which the ball gets sent in a specific direction very fast.",
      },
      {
        title: "Air dribble",
        content:
          "The ability to control the ball midair by tapping the ball multiple times in one play.",
      },
      {
        title: "50/50",
        content:
          "The occurrence of when two players are colliding with the ball at the same time, the direction of the ball is typically unknown and based on a bunch of factors such as where each player hit it.",
      },
      {
        title: "Upper 90",
        content:
          "Represents the upper left and upper right corners of the goal.",
      },
      {
        title: "Whiff",
        content:
          "When a player fails to hit the ball when they had an east chance to do so.",
      },
      {
        title: "Rotation",
        content:
          "The ability for a team or player to go back and forth between attacking the opposing team's goal and defending their own goal.",
      },
    ],
    callOfDuty: [
      {
        title: "ADS",
        content:
          "Stands for Aim Down Sights. Means that you're looking through the scope of your weapon rather than firing from the hip.",
      },
      {
        title: "AR",
        content: "Short for Assault Rifle.",
      },
      {
        title: "Attachment",
        content:
          "A piece of equipment that changes the performance and stats of a weapon. Most weapons are rarely used without attachments.",
      },
      {
        title: "Bait",
        content:
          "Using yourself or a teammate as a distraction to eliminate an enemy. This can be called “baiting” when there is a lack of coordination.",
      },
      {
        title: "Bot",
        content:
          "A computer-controlled player. Usually used as a condescending term for underperforming players.",
      },
      {
        title: "Camping",
        content:
          "Sitting in one spot without moving. Frowned upon by most players.",
      },
      {
        title: "Chalk",
        content:
          "Ending a game or round. Players refer to an unwinnable situation as “Chalked.”",
      },
      {
        title: "Clutch",
        content:
          " Winning an intense or close situation. This can range from winning when outnumbered or defusing the bomb at the last second.",
      },
      {
        title: "Comms",
        content:
          "The communication between teammates. Pro players often use this phrase to tell their teammates to be quiet in a tense situation.",
      },
      {
        title: "Cracked",
        content: "A damaged player or opponent.",
      },
      {
        title: "Deady",
        content:
          "Slang for the “Dead Silence” perk. This is an essential perk in most competitive game modes.",
      },
      {
        title: "Donut",
        content: "Finishing a map without getting a kill.",
      },
      {
        title: "Ego Chall",
        content:
          "Challenging a player 1 on 1. Ego refers to a player’s ego and confidence in their gun skill.",
      },
      {
        title: "Fake defuse",
        content:
          "Pretending to defuse a bomb. Used to draw out hiding enemies.",
      },
      {
        title: "Flank",
        content: "Attacking the enemy from behind.",
      },
      {
        title: "Flex",
        content:
          "A player that can fill either an AR or SMG role in a competitive setting.",
      },
      {
        title: "Flipping Spawns",
        content:
          "Causing each team to swap spawn points by contesting parts of the map.",
      },
      {
        title: "GA",
        content:
          "Stands for Gentlemen’s Agreement. Competitive players often agree not to use certain weapons or items, even though no official rule requires it.",
      },
      {
        title: "Head glitch (Heady)",
        content:
          "Refers to a spot where only a player’s head can be seen, making it hard to be killed.",
      },
      {
        title: "Hill",
        content:
          "The capture point in a Hardpoint match that players must hold to earn points",
      },
      {
        title: "Hitmarker",
        content: "The audible and visual queue that an enemy is hit.",
      },
      {
        title: "Holding a cross",
        content:
          "Holding a line of sight so enemies do not rotate to other parts of the map.",
      },
      {
        title: "In-game Leader",
        content: "The player in charge of making the main calls in a match.",
      },
      {
        title: "KD",
        content:
          "Short for kill death ratio. A kill death ratio is usually a reflection of a player’s skill level.",
      },
      {
        title: "Nade",
        content: "Grenade.",
      },
      {
        title: "Ninja Defuse",
        content: "Defusing the bomb without being spotted by the enemy team.",
      },
      {
        title: "OBJ",
        content:
          "Short for Objective. Refers to a player whose role is to hold or contest the objective.",
      },
      {
        title: "One-shot",
        content:
          "When a player is damaged and only needs one more shot to die.",
      },
      {
        title: "Reverse sweep",
        content:
          "When a team is one map away from losing but comes back and wins the series.",
      },
      {
        title: "Rotate",
        content: "Moving between locations and objectives on the map.",
      },
      {
        title: "Spawn",
        content: "The location where either team respawns in a match.",
      },
      {
        title: "Spawn Out",
        content:
          "When a player respawns away from their usual spawn location. Happens usually when a team is losing map control.",
      },
      {
        title: "Trade",
        content:
          "Killing an enemy that kills your teammate to keep the odds even.",
      },
      {
        title: "Wall bang",
        content: "Killing an enemy through a wall or surface.",
      },
    ],
  },
};
