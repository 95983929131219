// Vendors
import React from "react";

export const rivalry = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.210096 0.00083976C0.422269 0.00083976 0.550711 0.00083976 0.679967 0.00083976C3.87395 0.00083976 7.06794 -0.00159902 10.2619 0.00083976C13.195 0.00327854 15.7093 2.21281 16.0922 5.1304C16.3857 7.36594 15.6362 9.20072 13.9298 10.6575C13.8282 10.7445 13.7225 10.8274 13.577 10.9469C14.3769 12.6052 15.1793 14.2669 16.0085 15.987C14.6883 15.987 13.451 15.9935 12.2138 15.9748C12.1138 15.9732 11.9796 15.8179 11.9227 15.7033C11.3675 14.5823 10.8188 13.4572 10.2847 12.3264C10.1587 12.059 9.99609 11.9403 9.69612 11.959C9.32055 11.9817 8.94092 11.9817 8.56453 11.959C8.21497 11.9378 8.08897 12.0614 8.09547 12.4191C8.11417 13.4564 8.09141 14.4945 8.10929 15.5318C8.11498 15.8708 8.0345 16.0057 7.66218 15.9984C6.5184 15.9748 5.37299 15.9895 4.17961 15.9895C4.17067 15.8228 4.15685 15.6814 4.15685 15.5399C4.15522 14.4888 4.16986 13.4369 4.14384 12.3866C4.13815 12.1728 4.0341 11.9118 3.88615 11.7598C2.78707 10.6298 1.66523 9.52182 0.550711 8.40568C0.448283 8.30325 0.351545 8.19513 0.184082 8.0171C0.788899 8.0171 1.30673 8.0171 1.82457 8.0171C2.48466 8.0171 3.14557 8.00816 3.80567 8.02116C4.06336 8.02604 4.16254 7.94312 4.1601 7.67567C4.14953 6.53026 4.16579 5.38485 4.14466 4.23943C4.1414 4.06547 4.02759 3.85655 3.90078 3.72729C2.78544 2.59326 1.6571 1.47305 0.532827 0.347959C0.442592 0.256911 0.358048 0.160173 0.210096 0.00083976ZM8.1036 5.97015C8.1036 6.52132 8.11092 7.07248 8.10035 7.62364C8.09547 7.90573 8.18164 8.0358 8.49299 8.0236C9.05716 8.00165 9.62215 8.02116 10.1871 8.01628C11.2464 8.00653 12.0731 7.22693 12.1886 6.13518C12.291 5.16129 11.6049 4.17115 10.605 4.01994C9.86846 3.90857 9.10675 3.95003 8.35642 3.95816C8.27107 3.95897 8.12149 4.18741 8.1158 4.31585C8.08897 4.86539 8.10442 5.41818 8.1036 5.96934V5.97015Z"
      fill="#FE6700"
    />
  </svg>
);
