// Vendors
import React from "react";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./EducationCallOfDutyPage.module.scss";

// Assets
import { atlFazeLogo, opticLogo } from "../../assets/icons/logos/logos";

const EducationCallOfDutyPage = () => {
  return (
    <div className={classes.educationGuidesPage}>
      <div className={classes.pageBannerContainer}>
        <GameBannerRenderer game={"callOfDuty"} />
      </div>
      <div className={classes.pageHeader}>
        <h2>What is Call of Duty</h2>
        <p>
          Call of Duty: Modern Warfare II is a 2022 first-person shooter game
          developed by Infinity Ward and published by Activision. It is a sequel
          to the 2019 reboot, and serves as the nineteenth installment in the
          overall Call of Duty series.
        </p>
      </div>
      <div className={classes.pageContent}>
        <h2>Call of Duty Betting basics</h2>
        <h3>How do MoneyLine Odds Work?</h3>
        <p>
          A popular bet in Call of Duty is the moneyline, which requires you to
          pick the winner of the game. But it’s not a 50-50 proposition in most
          cases – you won’t get paid the same for taking ATL FAZE to beat OPT TX
          as you would for taking the OPT TX to beat ATL FAZE, given the ability
          of each team.
        </p>
        <p>
          So each team is assigned moneyline odds by the sportsbook based on
          their ability, and bettors have to pay a corresponding price.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel
              label={"ATL FAZE"}
              icon={atlFazeLogo}
              isSvg
              size="large"
            />
            <div className={classes.oddsLabel}>-300</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
            <IconLabel
              label={"OPT TX"}
              icon={opticLogo}
              isSvg
              size="large"
              inverted
            />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"OPT TX"} icon={opticLogo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
          </div>
        </div>

        <p>
          American odds like these are centered around $100. So if you bet $300
          on ATL FAZE at -300, you’d win $100 if they win. If you bet $100 on
          OPT TX at +250 and they win, you’d win $250.
        </p>
        <p>
          You don’t have to bet $100, of course. If you bet $3 on ATL FAZE you’d
          win $1. Bet $1 on OPT TX, win $2.50.
        </p>
      </div>
    </div>
  );
};

export default EducationCallOfDutyPage;
