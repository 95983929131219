// Vendors
import React from "react";

export const rollbit = (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.955688 0.48282C5.60678 0.316869 10.2549 0.0970972 14.9075 0.00141417C19.0966 -0.0838036 22.6473 3.12606 23.0017 7.16269C23.3724 11.3952 20.5797 15.1702 16.407 15.8713C15.0749 16.0956 13.6756 15.964 12.3076 15.9297C10.4164 15.8833 8.52661 15.7428 6.63537 15.7024C4.74563 15.662 2.8544 15.6934 0.963164 15.6934V15.4752C4.93401 15.1761 8.90486 14.8771 12.8757 14.5781L12.8652 14.3479C12.176 14.3479 11.4838 14.312 10.7976 14.3584C10.1667 14.4002 9.67331 14.1909 9.29058 13.7155C8.82263 13.1354 8.40252 12.5165 7.91065 11.9588C7.71031 11.7316 7.39486 11.5073 7.10781 11.4729C5.97307 11.3399 4.82936 11.2815 3.68714 11.141C4.86673 11.0603 6.04633 10.9795 7.34104 10.8913C6.79235 8.96273 6.72956 7.08196 7.37243 5.10551C6.07922 5.01281 4.8772 4.9276 3.67518 4.84088L3.6677 4.79454C4.81142 4.70782 5.95812 4.64802 7.09734 4.51496C7.38439 4.48058 7.65649 4.22492 7.91065 4.03804C8.02278 3.95582 8.07511 3.79286 8.15285 3.66578C8.99456 2.29183 10.0336 1.28865 11.8322 1.61457C12.1671 1.67587 12.5214 1.62354 12.8667 1.62354L12.8787 1.40825C8.90635 1.11074 4.93102 0.81173 0.955688 0.512721V0.484315V0.48282ZM18.0411 7.99244C18.0411 6.27314 16.697 4.90517 14.9912 4.88873C13.2839 4.87228 11.8247 6.30304 11.8247 7.99244C11.8247 9.68185 13.2853 11.1141 14.9912 11.0962C16.6955 11.0797 18.0411 9.71025 18.0411 7.99244Z"
      fill="#FFB019"
    />
  </svg>
);
