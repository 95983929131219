// Vendors
import React, { useState } from "react";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

// Styles
import classes from "./TabSelector.module.scss";
import MainButton from "../MainButton/MainButton";

const TabSelector = ({
  options,
  handleOptionSelected,
  selectedOption,
  withArrows,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickLeft = () => {
    const prevIndex = currentIndex;
    let newIndex = 0;

    newIndex = prevIndex - 3;

    if (newIndex < 0) {
      newIndex = 0;
    }

    setCurrentIndex(newIndex);
  };

  const handleClickRight = (options) => {
    const prevIndex = currentIndex;
    let newIndex = 0;

    newIndex = prevIndex + 3;

    if (newIndex + 1 >= options.length - 1) {
      newIndex = options.length - 1 - 2;
    }
    setCurrentIndex(newIndex);
  };

  const displayItems = withArrows
    ? options.slice(currentIndex, currentIndex + 3)
    : options;

  return (
    <div className={classes.tabSelectorContainer}>
      {withArrows && options.length > 3 ? (
        <MainButton
          icon={<ArrowLeftOutlined />}
          type="secondary"
          size="small"
          clicked={() => handleClickLeft(options)}
          disabled={currentIndex === 0}
        />
      ) : null}

      {displayItems.map((option) => {
        return (
          <div
            className={`${classes.labelText} ${
              selectedOption.value === option.value ? classes.selected : ""
            }`}
            key={option.value}
            onClick={() => handleOptionSelected(option)}
          >
            {option.label}
          </div>
        );
      })}

      {withArrows && options.length > 3 ? (
        <MainButton
          icon={<ArrowRightOutlined />}
          type="secondary"
          size="small"
          clicked={() => handleClickRight(options)}
          disabled={currentIndex + 2 >= options.length - 1}
        />
      ) : null}
    </div>
  );
};

export default TabSelector;
