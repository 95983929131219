// Vendors
import React from "react";

export const controllerIcon = (
  <svg
    width="37"
    height="16"
    viewBox="0 0 37 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.854 0.027832L29.1306 0.0369235C33.3407 0.264219 36.6875 3.74336 36.6875 7.99834C36.6875 12.2533 33.1067 15.9719 28.6899 15.9719C24.7138 15.9719 21.4157 13.0807 20.7986 9.29241H16.5764C15.9563 13.0807 12.6581 15.9719 8.68514 15.9719C4.26835 15.9719 0.6875 12.4018 0.6875 7.99834C0.6875 3.59486 4.16804 0.127842 8.49667 0.027832H28.854ZM25.9206 6.61638C25.1546 6.61638 24.5345 7.23463 24.5345 7.99834C24.5345 8.76206 25.1546 9.3803 25.9206 9.3803C26.6867 9.3803 27.3068 8.76206 27.3068 7.99834C27.3068 7.23463 26.6867 6.61638 25.9206 6.61638ZM31.453 6.61638C30.687 6.61638 30.0669 7.23463 30.0669 7.99834C30.0669 8.76206 30.687 9.3803 31.453 9.3803C32.219 9.3803 32.8391 8.76206 32.8391 7.99834C32.8391 7.23463 32.219 6.61638 31.453 6.61638ZM28.6868 3.87974C27.9208 3.87974 27.3007 4.49799 27.3007 5.2617C27.3007 6.02541 27.9208 6.64366 28.6868 6.64366C29.4528 6.64366 30.073 6.02541 30.073 5.2617C30.073 4.49799 29.4528 3.87974 28.6868 3.87974ZM28.6868 9.35303C27.9208 9.35303 27.3007 9.97127 27.3007 10.735C27.3007 11.4987 27.9208 12.1169 28.6868 12.1169C29.4528 12.1169 30.073 11.4987 30.073 10.735C30.073 9.97127 29.4528 9.35303 28.6868 9.35303ZM7.93127 3.86459C7.58474 3.86459 7.30204 4.14643 7.30204 4.49192V6.61941H5.16812C4.82159 6.61941 4.53889 6.90126 4.53889 7.24978V8.7469C4.53889 9.09239 4.82159 9.37424 5.16812 9.37424H7.30204V11.5017C7.30204 11.8472 7.58474 12.1321 7.93127 12.1321H9.43292C9.77945 12.1321 10.0652 11.8502 10.0652 11.5017V9.37424H12.1991C12.5456 9.37424 12.8283 9.09239 12.8283 8.7469V7.24978C12.8283 6.90429 12.5456 6.61941 12.1991 6.61941H10.0652V4.49192C10.0652 4.14643 9.78249 3.86459 9.43292 3.86459H7.93127Z"
      fill="white"
    />
  </svg>
);
