// Vendors
import React from "react";

export const defenseIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99242 9.7465e-05C6.53719 0.51458 7.20306 0.862712 7.89911 1.15015C9.01895 1.61924 10.1841 1.83109 11.3947 1.90678C11.5915 1.92188 11.7731 1.92188 12 1.93708C11.9697 2.20941 11.9395 2.46678 11.9092 2.724C11.652 4.60048 11.4249 6.49203 11.1375 8.35335C10.8349 10.3206 9.69991 11.7279 8.02016 12.7115C7.42998 13.0596 6.79444 13.3017 6.17397 13.5742C6.09829 13.6045 5.97722 13.6347 5.90164 13.6045C4.53976 13.1354 3.29882 12.4544 2.3152 11.395C1.43751 10.4417 0.953318 9.2916 0.786832 8.02046C0.529591 6.08348 0.272349 4.1465 0.0150942 2.20952C-3.1086e-07 2.13384 0 2.05815 0 1.95228C0.408608 1.92199 0.80203 1.90689 1.21064 1.8615C2.73901 1.69504 4.2069 1.28643 5.49318 0.408714C5.67474 0.287639 5.8261 0.13638 5.97748 0.000105557C5.96217 6.75727e-09 5.97727 0 5.99236 0L5.99242 9.7465e-05Z"
      fill="#3378FF"
    />
  </svg>
);
