// Vendors
import React from "react";
// Styles
import classes from "./SpreadTag.module.scss";
import { formatNumberWithSymbol } from "../../utils/globalUtils";

const SpreadTag = ({ spread, fullWidth, size }) => {
  return spread ? (
    <div
      className={`${classes.moneyLine}
      ${size === "large" ? classes.large : null}
      ${spread && spread < 0 && classes.favoriteML}
      `}
      style={{
        width: fullWidth ? "100%" : null,
        justifyContent: "center",
      }}
    >
      {formatNumberWithSymbol(spread)}
    </div>
  ) : null;
};

export default SpreadTag;
