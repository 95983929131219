// Vendors
import React from "react";

export const bookitSports = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3177_4139)">
      <path
        d="M0.421003 3.13503C0.992254 2.92413 1.56941 2.7091 2.14834 2.49762C4.38963 1.67825 6.6321 0.862435 8.87161 0.0389365C9.0447 -0.0248639 9.17998 -0.00832308 9.34125 0.0743812C11.9429 1.41655 14.5304 2.78826 17.1615 4.069C18.0234 4.48843 18.3737 5.15833 18.5823 5.97651C18.8895 7.18163 18.6656 8.30996 17.9791 9.34494C17.9047 9.45659 17.8752 9.53989 17.972 9.65449C19.3692 11.3056 18.8599 14.1548 16.3144 15.1372C13.9969 16.0316 11.7066 16.998 9.40801 17.9409C9.19652 18.0277 9.04056 18.0224 8.84207 17.893C6.11047 16.1119 3.37355 14.3379 0.633081 12.571C0.435181 12.4434 0.341843 12.3087 0.364883 12.0736C0.384377 11.8792 0.376697 11.6807 0.366655 11.4852C0.355431 11.269 0.41805 11.2513 0.59291 11.3635C1.52688 11.9637 2.46498 12.5568 3.4019 13.1511C5.23498 14.3143 7.06925 15.4757 8.89938 16.6436C9.04293 16.7352 9.15812 16.7293 9.30758 16.6684C11.6723 15.7055 14.043 14.7562 16.4036 13.7826C17.0333 13.5227 17.3819 12.9987 17.4628 12.3294C17.5485 11.6229 17.2933 11.0427 16.7232 10.6026C16.5956 10.504 16.491 10.5512 16.3699 10.5991C14.0584 11.5094 11.7438 12.4145 9.43518 13.3319C9.21129 13.4211 9.04352 13.414 8.83735 13.2882C6.08211 11.5998 3.32156 9.91915 0.559828 8.24084C0.415687 8.15341 0.356612 8.05416 0.366064 7.8858C0.379651 7.64123 0.368427 7.39548 0.369608 7.14973C0.371381 6.83191 0.374925 6.82778 0.64076 6.98846C1.80866 7.69558 2.97538 8.40507 4.1427 9.11396C5.75484 10.0928 7.36757 11.0711 8.97795 12.0529C9.09728 12.1256 9.19711 12.1386 9.33357 12.0854C11.602 11.1993 13.8711 10.3132 16.1472 9.44655C16.4928 9.31481 16.7344 9.11278 16.914 8.805C17.4599 7.86926 17.4587 6.92761 16.963 5.97356C16.8496 5.75558 16.7244 5.70182 16.4733 5.79102C14.1145 6.63283 11.7485 7.45574 9.3891 8.29519C9.16935 8.37317 9.01162 8.34658 8.81726 8.23021C6.0703 6.58262 3.3192 4.9433 0.569871 3.30103C0.51375 3.26736 0.438135 3.24964 0.420413 3.13444L0.421003 3.13503Z"
        fill="#FF9833"
      />
    </g>
    <defs>
      <clipPath id="clip0_3177_4139">
        <rect
          width="18.3722"
          height="18"
          fill="white"
          transform="translate(0.361938)"
        />
      </clipPath>
    </defs>
  </svg>
);
