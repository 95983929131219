// Vendors
import React, { useState } from "react";
import { TwitchPlayer } from "react-twitch-embed";

// Components
import IconLabel from "../IconLabel/IconLabel";
import LiveCard from "../LiveCard/LiveCard";
import DividerLine from "../DividerLine/DividerLine";

// Styles
import classes from "./StreamBox.module.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const StreamBox = ({ esport, twitchStreamData }) => {
  const [currentStream, setCurrentStream] = useState(0);
  const [showStream, setShowStream] = useState(true);

  // const twitchStreamData = {
  //   streams: ["lcka", "jupeson", "lcka", "jupeson"],
  // };

  const handleNextStream = () => {
    if (
      currentStream === twitchStreamData?.streams.length - 1 ||
      currentStream === 2
    ) {
      setShowStream(false);
    } else {
      setCurrentStream(currentStream + 1);
    }
  };

  return showStream ? (
    <div className={classes.streamBoxContainer}>
      <div className={classes.streamHeaderContainer}>
        <div className={classes.liveRow}>
          <div className={classes.liveRowLeftColumn}>
            {esport ? (
              <div className={classes.gameTagContainer}>
                <IconLabel
                  label={globalConstants.TITLES[esport]}
                  isSvg
                  icon={esportsLogos[esport]}
                />
              </div>
            ) : null}
            <LiveCard />
          </div>
        </div>
        <p className={classes.matchTitle}>
          {twitchStreamData?.streams[currentStream]}
        </p>
      </div>
      <DividerLine />
      <div className={classes.matchDetails}>
        <div className={classes.liveStreamBox}>
          <TwitchPlayer
            width="100%"
            channel={twitchStreamData?.streams[currentStream]}
            autoplay
            muted
            onOffline={handleNextStream}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className={classes.columnTitle}>Live Stream</div>
      <div className={classes.noDataCard}>Stream not available . . .</div>
    </>
  );
};

export default StreamBox;
