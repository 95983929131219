// Vendors
import React from "react";

const RedFinishedIcon = ({ size }) => {
  return (
    <svg
      width={size || "48"}
      height={size || "48"}
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.13102 2.99922L5.76724 1.363C6.07681 1.05343 6.07681 0.54807 5.76724 0.232175C5.45767 -0.0773916 4.95231 -0.0773916 4.63642 0.232175L3.0002 1.8684L1.36398 0.232175C1.05441 -0.0773916 0.549047 -0.0773916 0.233151 0.232175C-0.0764151 0.541741 -0.0764151 1.0471 0.233151 1.363L1.86937 2.99922L0.233151 4.63544C-0.0764151 4.94501 -0.0764151 5.45037 0.233151 5.76626C0.391088 5.9242 0.593269 6 0.795405 6C0.997586 6 1.20602 5.9242 1.35766 5.76626L2.99388 4.13004L4.6301 5.76626C4.78804 5.9242 4.99022 6 5.19235 6C5.39453 6 5.60296 5.9242 5.75461 5.76626C6.06417 5.4567 6.06417 4.95134 5.75461 4.63544L4.13102 2.99922Z"
        fill="#0F0F14"
      />
    </svg>
  );
};

export default RedFinishedIcon;
