import React from "react";
import { UserContextProvider } from "./contextProviders/UserContextProvider";
import { CarouselContextProvider } from "./contextProviders/CarouselContextProvider";
import { BetSlipContextProvider } from "./contextProviders/BetSlipContextProvider";
import { FormContextProvider } from "./contextProviders/FormContextProvider";
import { ModalContextProvider } from "./contextProviders/ModalContextProvider";

export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};

const providers = [
  UserContextProvider,
  CarouselContextProvider,
  BetSlipContextProvider,
  FormContextProvider,
  ModalContextProvider,
];

export const AppContextProvider = combineComponents(...providers);
