// Vendors
import React from "react";
import { Link } from "react-router-dom";

import { format } from "date-fns";
// import { ClockCircleOutlined } from "@ant-design/icons";

// Components
import IconLabel from "../IconLabel/IconLabel";
import ProfileTag from "../ProfileTag/ProfileTag";
import VerifiedTag from "../VerifiedTag/VerifiedTag";
import ScoreCard from "../ScoreCard/ScoreCard";

// Utils
import {
  transformOddToPrefered,
  formatNumberWithSymbol,
  formatUnits,
  formatDollars,
  calculateAmountToWin,
} from "../../utils/globalUtils";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import ClockIcon from "../../assets/icons/clock/clock";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./BetVerificationCard.module.scss";
import MainButton from "../MainButton/MainButton";

const BetVerificationCard = ({
  betData,
  preferredOddsType,
  isAdmin,
  handleOpenBetToVerify,
  isProfileOwner,
}) => {
  return betData ? (
    <div className={classes.betVerificationCard}>
      <div className={classes.firstRow}>
        <div className={classes.profileAndEsportData}>
          <div className={classes.profileContainer}>
            <Link
              to={{
                pathname: `/${globalConstants.ROUTES.PROFILE}/${betData.username}/`,
              }}
            >
              <ProfileTag
                image={betData.imageUrl}
                profileName={betData.username}
                size={"large"}
              />
            </Link>
          </div>
        </div>
        <div className={`${classes.verifiedContainer} ${classes.mobileOnly}`}>
          {betData.verificationStatus === globalConstants.VERIFIED ? (
            <VerifiedTag />
          ) : betData.verificationStatus === globalConstants.DECLINED &&
            (isAdmin || isProfileOwner) ? (
            <VerifiedTag denied={true} />
          ) : isAdmin &&
            betData.verificationStatus === globalConstants.PENDING ? (
            <MainButton
              buttonText="Verify bet"
              fullWidth="fullWidth"
              clicked={() => handleOpenBetToVerify(betData)}
            />
          ) : null}
        </div>
      </div>
      <div className={classes.secondRow}>
        <div className={classes.dateAndEsportAndPending}>
          <div className={classes.dateAndEsport}>
            {betData.abiosSeriesDate ? (
              <span className={classes.betDate}>
                {format(new Date(betData.abiosSeriesDate), "MMM. dd")}
              </span>
            ) : null}
            <IconLabel
              icon={esportsLogos[betData.game]}
              label={globalConstants.TITLES[betData.game]}
              isSvg
            />
          </div>

          <div className={`${classes.pendingContainer} ${classes.mobileOnly}`}>
            {betData.status === globalConstants.PENDING ? (
              <ScoreCard
                result="PENDING"
                icon={
                  <div className={classes.clockIconContainer}>
                    <ClockIcon />
                  </div>
                }
              />
            ) : null}
          </div>
        </div>
        <div className={classes.betData}>
          <IconLabel
            icon={betData?.selectedAbiosTeam?.imageSmall}
            label={
              betData?.selectedAbiosTeam?.teamAbbreviation ||
              betData?.selectedAbiosTeam?.teamName
            }
            size="medium"
            withAbbreviation
            bold
          />
          {betData.type === globalConstants.SPREAD && betData.spread ? (
            <div className={classes.betTypeLabel}>
              <span> Spread</span>
              {formatNumberWithSymbol(parseFloat(betData.spread))}
              <span
                className={`${classes.odds} ${
                  betData.odd >= 100 ? classes.favorite : classes.underdog
                }`}
              >
                {transformOddToPrefered(betData.odd, preferredOddsType)}
              </span>
            </div>
          ) : (
            <div className={classes.betTypeLabel}>
              <span>Money Line</span>
              <span
                className={`${classes.odds} ${
                  betData.odd >= 100 ? classes.favorite : classes.underdog
                }`}
              >
                {transformOddToPrefered(betData.odd, preferredOddsType)}
              </span>
            </div>
          )}
        </div>
        <div className={classes.matchData}>
          <div className={classes.teamLabelContainer}>
            <IconLabel
              icon={betData?.selectedAbiosTeam?.imageSmall}
              label={
                betData?.selectedAbiosTeam?.teamAbbreviation ||
                betData?.selectedAbiosTeam?.teamName
              }
              size="medium"
              withAbbreviation
              bold
            />
          </div>
          <div className={classes.versusContainer}>VS</div>
          <div className={classes.teamLabelContainer}>
            <IconLabel
              icon={betData?.alternativeAbiosTeam?.imageSmall}
              label={
                betData?.alternativeAbiosTeam?.teamAbbreviation ||
                betData?.alternativeAbiosTeam?.teamName
              }
              size="medium"
              withAbbreviation
              inverted
              bold
            />
          </div>
        </div>
        <div className={classes.amountData}>
          <span className={classes.amountDescription}>
            {betData.amountType === globalConstants.AMOUNT_TYPES.UNIT
              ? formatUnits(betData.amount)
              : formatDollars(betData.amount)}
          </span>
          <span className={classes.toWinLabel}>To win</span>
          <span className={classes.toWinAmount}>
            {betData.amountType === globalConstants.AMOUNT_TYPES.UNIT
              ? formatUnits(
                  calculateAmountToWin({
                    risk: betData.amount,
                    odds: betData.odd,
                    preferredOddsType: "american",
                  })
                )
              : formatDollars(
                  calculateAmountToWin({
                    risk: betData.amount,
                    odds: betData.odd,
                    preferredOddsType: "american",
                  })
                )}
          </span>
        </div>
        <div
          className={`${classes.verifiedContainer} ${classes.tabletDesktopOnly}`}
        >
          {betData.verificationStatus === globalConstants.VERIFIED ? (
            <VerifiedTag />
          ) : betData.verificationStatus === globalConstants.DECLINED &&
            (isAdmin || isProfileOwner) ? (
            <VerifiedTag denied={true} />
          ) : isAdmin &&
            betData.verificationStatus === globalConstants.PENDING ? (
            <MainButton
              buttonText="Verify bet"
              fullWidth="fullWidth"
              clicked={() => handleOpenBetToVerify(betData)}
            />
          ) : null}
        </div>
        <div
          className={`${classes.pendingContainer} ${classes.tabletDesktopOnly}`}
        >
          {betData.status === globalConstants.PENDING ? (
            <ScoreCard
              result="PENDING"
              icon={
                <div className={classes.clockIconContainer}>
                  <ClockIcon />
                </div>
              }
            />
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

export default BetVerificationCard;
