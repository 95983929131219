// Vendors
import React, { useState } from "react";

// Components
import TabSelector from "../TabSelector/TabSelector";
import DividerLine from "../DividerLine/DividerLine";
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./MatchStats.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { summonersRiftIcon } from "../../assets/icons/summonersRift/summonersRift";
import { pentaKillIcon } from "../../assets/icons/pentaKill/pentaKill";
import { doubleKillIcon } from "../../assets/icons/doubleKill/doubleKill";
import { tripleKillIcon } from "../../assets/icons/tripleKill/tripleKill";
import { quadraKillIcon } from "../../assets/icons/quadraKill/quadraKill";
import { Tooltip } from "antd";
import InfernoMobile from "../../assets/maps/cs/InfernoMobile.png";
import Inferno from "../../assets/maps/cs/Inferno.png";
import MirageMobile from "../../assets/maps/cs/MirageMobile.png";
import Mirage from "../../assets/maps/cs/Mirage.png";
import NukeMobile from "../../assets/maps/cs/NukeMobile.png";
import Nuke from "../../assets/maps/cs/Nuke.png";
import OverpassMobile from "../../assets/maps/cs/OverpassMobile.png";
import Overpass from "../../assets/maps/cs/Overpass.png";
import TrainMobile from "../../assets/maps/cs/TrainMobile.png";
import Train from "../../assets/maps/cs/Train.png";
import CacheMobile from "../../assets/maps/cs/CacheMobile.png";
import Cache from "../../assets/maps/cs/Cache.png";
import DustII from "../../assets/maps/cs/DustII.png";
import DustIIMobile from "../../assets/maps/cs/DustIIMobile.png";
import VertigoMobile from "../../assets/maps/cs/VertigoMobile.png";
import Vertigo from "../../assets/maps/cs/Vertigo.png";
import AncientMobile from "../../assets/maps/cs/AncientMobile.png";
import Ancient from "../../assets/maps/cs/Ancient.png";
import AnubisMobile from "../../assets/maps/cs/AnubisMobile.png";
import Anubis from "../../assets/maps/cs/Anubis.png";
// Valorant
import BindMobile from "../../assets/maps/valorant/BindMobile.png";
import Bind from "../../assets/maps/valorant/Bind.png";
import HavenMobile from "../../assets/maps/valorant/HavenMobile.png";
import Haven from "../../assets/maps/valorant/Haven.png";
import SplitMobile from "../../assets/maps/valorant/SplitMobile.png";
import Split from "../../assets/maps/valorant/Split.png";
import AscentMobile from "../../assets/maps/valorant/AscentMobile.png";
import Ascent from "../../assets/maps/valorant/Ascent.png";
import IceboxMobile from "../../assets/maps/valorant/IceboxMobile.png";
import Icebox from "../../assets/maps/valorant/Icebox.png";
import BreezeMobile from "../../assets/maps/valorant/BreezeMobile.png";
import Breeze from "../../assets/maps/valorant/Breeze.png";
import FractureMobile from "../../assets/maps/valorant/FractureMobile.png";
import Fracture from "../../assets/maps/valorant/Fracture.png";
import PearlMobile from "../../assets/maps/valorant/PearlMobile.png";
import Pearl from "../../assets/maps/valorant/Pearl.png";
import LotusMobile from "../../assets/maps/valorant/LotusMobile.png";
import Lotus from "../../assets/maps/valorant/Lotus.png";
import SunsetMobile from "../../assets/maps/valorant/SunsetMobile.png";
import Sunset from "../../assets/maps/valorant/Sunset.png";
import { defaultPlayerIcon } from "../../assets/icons/defaultPlayer/defaultPlayer";

const MAP_IMAGES = {
  Inferno: {
    mobile: InfernoMobile,
    desktop: Inferno,
  },
  Mirage: {
    mobile: MirageMobile,
    desktop: Mirage,
  },
  Nuke: {
    mobile: NukeMobile,
    desktop: Nuke,
  },
  Overpass: {
    mobile: OverpassMobile,
    desktop: Overpass,
  },
  Train: {
    mobile: TrainMobile,
    desktop: Train,
  },
  Cache: {
    mobile: CacheMobile,
    desktop: Cache,
  },
  DustII: {
    mobile: DustIIMobile,
    desktop: DustII,
  },
  Vertigo: {
    mobile: VertigoMobile,
    desktop: Vertigo,
  },
  Ancient: {
    mobile: AncientMobile,
    desktop: Ancient,
  },
  Anubis: {
    mobile: AnubisMobile,
    desktop: Anubis,
  },
  /// Valorant
  Bind: {
    mobile: BindMobile,
    desktop: Bind,
  },
  Haven: {
    mobile: HavenMobile,
    desktop: Haven,
  },
  Split: {
    mobile: SplitMobile,
    desktop: Split,
  },
  Ascent: {
    mobile: AscentMobile,
    desktop: Ascent,
  },
  Icebox: {
    mobile: IceboxMobile,
    desktop: Icebox,
  },
  Breeze: {
    mobile: BreezeMobile,
    desktop: Breeze,
  },
  Fracture: {
    mobile: FractureMobile,
    desktop: Fracture,
  },
  Pearl: {
    mobile: PearlMobile,
    desktop: Pearl,
  },
  Lotus: {
    mobile: LotusMobile,
    desktop: Lotus,
  },
  Sunset: {
    mobile: SunsetMobile,
    desktop: Sunset,
  },
};
const MatchStats = ({ matchInfo }) => {
  const teamOneMatches = matchInfo.teamOne.matches || [];
  const teamTwoMatches = matchInfo.teamTwo.matches || [];
  const options = [];

  teamOneMatches?.forEach((_match, index) => {
    options.push({ label: `Map ${index + 1}`, value: index });
  });
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionSelected = (option) => {
    setSelectedOption(option);
  };

  if (!matchInfo) {
    return null;
  }

  const sortPlayersByRole = (players) => {
    if (matchInfo.abiosGameId !== globalConstants.LEAGUE_OF_LEGENDS) {
      return players;
    }
    if (!players) return [];

    const sortedPlayers = players.sort((a, b) => {
      return a.lane < b.lane ? 1 : a.lane > b.lane ? -1 : 0;
    });

    return [
      sortedPlayers[0],
      sortedPlayers[3],
      sortedPlayers[2],
      sortedPlayers[4],
      sortedPlayers[1],
    ];
  };

  let teamOnePlayerStats = [];

  if (selectedOption && (selectedOption.value || selectedOption.value === 0)) {
    teamOnePlayerStats = sortPlayersByRole(
      teamOneMatches[selectedOption?.value]?.playerStats
    );
  }

  let teamTwoPlayerStats = [];

  if (selectedOption && (selectedOption.value || selectedOption.value === 0)) {
    teamTwoPlayerStats = sortPlayersByRole(
      teamTwoMatches[selectedOption?.value]?.playerStats
    );
  }

  const shouldShowMap = (teamOnePlayers, teamTwoPlayers) => {
    const filteredTeamOne = teamOnePlayers.filter((player) => {
      return player?.characterImg && player?.statsPlayerName && player?.lane;
    });
    const filteredTeamTwo = teamTwoPlayers.filter((player) => {
      return player?.characterImg && player?.statsPlayerName && player?.lane;
    });
    return filteredTeamOne.length === 5 && filteredTeamTwo.length === 5;
  };

  return teamOneMatches?.length > 0 && teamTwoMatches?.length > 0 ? (
    <div className={classes.MatchStatsContainer}>
      <div className={classes.titleContainer}>
        <h3>Match Stats</h3>
      </div>
      <div className={classes.mapStatsContainer}>
        <TabSelector
          options={options}
          handleOptionSelected={handleOptionSelected}
          selectedOption={selectedOption}
          withArrows
        />
        <div className={classes.mapStatRow}>
          <div className={classes.mapName}>
            {teamOneMatches[selectedOption?.value]?.mapName}
          </div>
          <div className={classes.mapScores}>
            <div
              className={`${classes.scoreBox} ${
                teamOneMatches[selectedOption?.value]?.matchWinner > 0
                  ? classes.matchWinner
                  : ""
              }`}
            >
              {teamOneMatches[selectedOption?.value]?.matchScore}
              {teamOneMatches[selectedOption?.value]?.totalGold ? (
                <p className={classes.goldCount}>
                  <span className={classes.goldCoin} />
                  {(
                    teamOneMatches[selectedOption?.value]?.totalGold / 1000
                  ).toFixed(1)}
                  K
                </p>
              ) : null}
            </div>
            <div className={classes.separator}>:</div>
            <div
              className={`${classes.scoreBox} ${
                teamTwoMatches[selectedOption?.value]?.matchWinner > 0
                  ? classes.matchWinner
                  : ""
              }`}
            >
              {teamTwoMatches[selectedOption?.value]?.matchScore}
              {teamTwoMatches[selectedOption?.value]?.totalGold ? (
                <p className={classes.goldCount}>
                  <span className={classes.goldCoin} />
                  {(
                    teamTwoMatches[selectedOption?.value]?.totalGold / 1000
                  ).toFixed(1)}
                  K
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {MAP_IMAGES[teamOneMatches[selectedOption?.value]?.mapName] ? (
          <div className={classes.mapImageContainer}>
            {
              <img
                className={classes.mobileMapImage}
                src={
                  MAP_IMAGES[teamOneMatches[selectedOption?.value]?.mapName]
                    ?.mobile
                }
                alt="Map"
              />
            }
            <img
              className={classes.mapImage}
              src={
                MAP_IMAGES[teamOneMatches[selectedOption?.value]?.mapName]
                  ?.desktop
              }
              alt="Map"
            />
          </div>
        ) : null}
        {matchInfo.abiosGameId === globalConstants.LEAGUE_OF_LEGENDS &&
        shouldShowMap(teamOnePlayerStats, teamTwoPlayerStats) ? (
          <div className={classes.mapContainer}>
            <div className={classes.map}>
              {summonersRiftIcon}
              <div className={classes.top1}>
                <SummonerTag playerData={teamTwoPlayerStats[0]} />
              </div>
              <div className={classes.top2}>
                <SummonerTag playerData={teamOnePlayerStats[0]} />
              </div>
              <div className={classes.jg1}>
                <SummonerTag playerData={teamOnePlayerStats[1]} />
              </div>
              <div className={classes.jg2}>
                <SummonerTag playerData={teamTwoPlayerStats[1]} />
              </div>
              <div className={classes.mid1}>
                <SummonerTag playerData={teamTwoPlayerStats[2]} />
              </div>
              <div className={classes.mid2}>
                <SummonerTag playerData={teamOnePlayerStats[2]} />
              </div>
              <div className={classes.bottom1}>
                <SummonerTag playerData={teamOnePlayerStats[4]} />
                <SummonerTag playerData={teamOnePlayerStats[3]} />
              </div>
              <div className={classes.bottom2}>
                <SummonerTag playerData={teamTwoPlayerStats[4]} />
                <SummonerTag playerData={teamTwoPlayerStats[3]} />
              </div>
            </div>
          </div>
        ) : null}

        {teamOnePlayerStats?.length > 0 && teamTwoPlayerStats?.length > 0 ? (
          <div className={classes.playerStats}>
            <DividerLine color={styledVariables.black} />
            <div className={classes.playersLabel}>Players</div>

            <div className={classes.playerBoxesContainer}>
              {[matchInfo.teamOne, matchInfo.teamTwo]?.map((team, index) => {
                return (
                  <div className={classes.teamPlayerStatsBox} key={index}>
                    <DividerLine color={styledVariables.black} />

                    <div className={classes.teamPlayerStatsHeader}>
                      <IconLabel
                        icon={team?.teamImgSm || team?.teamImgLg}
                        label={
                          team?.teamAbbreviation ||
                          team?.selectedAbiosTeam?.teamName
                        }
                        size={"large"}
                        withAbbreviation
                      />
                      <div className={classes.teamPlayerStatsHeaderCells}>
                        <div className={classes.teamPlayerStatsHeaderCell}>
                          K
                        </div>
                        <div className={classes.teamPlayerStatsHeaderCell}>
                          D
                        </div>
                        <div className={classes.teamPlayerStatsHeaderCell}>
                          A
                        </div>
                        {matchInfo.abiosGameId !==
                        globalConstants.LEAGUE_OF_LEGENDS ? (
                          <div className={classes.teamPlayerStatsHeaderCell}>
                            {"+ / -"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      {sortPlayersByRole(
                        team?.matches?.[selectedOption?.value]?.playerStats
                      ).map((player, index) => {
                        return (
                          <div key={`${player?.statsPlayerName}-${index}`}>
                            <DividerLine color={styledVariables.black} />
                            <div className={classes.teamPlayerStatsData}>
                              <MultikillBadgeRow playerData={player} />
                              <div className={classes.teamPlayerNameContainer}>
                                {player?.characterImg ? (
                                  <div className={classes.teamPlayerImage}>
                                    <img
                                      src={player?.characterImg}
                                      alt="player-character"
                                    />
                                  </div>
                                ) : null}
                                <div className={classes.teamPlayerImage}>
                                  {player?.statsPlayerImg ? (
                                    <img
                                      src={player?.statsPlayerImg}
                                      alt={player?.statsPlayerName}
                                    />
                                  ) : (
                                    <div
                                      className={
                                        classes.defaultPlayerImageContainer
                                      }
                                    >
                                      {defaultPlayerIcon}
                                    </div>
                                  )}
                                </div>
                                <div className={classes.teamPlayerName}>
                                  {player?.statsPlayerName}
                                </div>
                              </div>
                              <div
                                className={classes.teamPlayerStatsDataScores}
                              >
                                <div
                                  className={classes.teamPlayerStatsDataCells}
                                >
                                  <div
                                    className={classes.teamPlayerStatsDataCell}
                                  >
                                    {player?.matchKills}
                                  </div>
                                  <div
                                    className={classes.teamPlayerStatsDataCell}
                                  >
                                    {player?.matchDeaths}
                                  </div>
                                  <div
                                    className={classes.teamPlayerStatsDataCell}
                                  >
                                    {player?.matchAssists}
                                  </div>
                                  {matchInfo.abiosGameId !==
                                  globalConstants.LEAGUE_OF_LEGENDS ? (
                                    <div
                                      className={
                                        classes.teamPlayerStatsDataCell
                                      }
                                      style={{
                                        color:
                                          player?.matchKills -
                                            player?.matchDeaths >
                                          0
                                            ? styledVariables.backgroundEmphasisE
                                            : styledVariables.backgroundEmphasisC,
                                      }}
                                    >
                                      {player?.matchKills - player?.matchDeaths}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default MatchStats;

const SummonerTag = ({ playerData }) => {
  if (!playerData) {
    return null;
  }
  return (
    <div className={classes.summonerTag}>
      {playerData?.characterImg ? (
        <img
          className={classes.summonerTagImage}
          src={playerData.characterImg}
          alt={playerData.characterImg}
        />
      ) : null}
      {playerData.statsPlayerName ? (
        <p className={classes.summonerTagName}>{playerData.statsPlayerName}</p>
      ) : null}
    </div>
  );
};

const MultikillBadgeRow = ({ playerData }) => {
  if (!playerData) {
    return null;
  }

  return (
    <div className={classes.multikillRow}>
      {playerData.doubleKills ? (
        <div className={classes.killBadge}>
          {playerData.doubleKills}x
          <Tooltip placement="top" title={"Double Kill"}>
            {doubleKillIcon}
          </Tooltip>
        </div>
      ) : null}
      {playerData.tripleKills ? (
        <div className={classes.killBadge}>
          {playerData.tripleKills}x
          <Tooltip placement="top" title={"Triple Kill"}>
            {tripleKillIcon}
          </Tooltip>
        </div>
      ) : null}
      {playerData.quadraKills ? (
        <div className={classes.killBadge}>
          {playerData.quadraKills}x
          <Tooltip placement="top" title={"Quadra Kill"}>
            {quadraKillIcon}
          </Tooltip>
        </div>
      ) : null}
      {playerData.pentaKills ? (
        <div className={classes.killBadge}>
          {playerData.pentaKills}x
          <Tooltip placement="top" title={"Penta Kill"}>
            {pentaKillIcon}
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};
