// Vendors
import React from "react";

// Components
// import MainButton from "../MainButton/MainButton";

// Utils
import { formatNumberWithSymbol } from "../../utils/globalUtils";

// Assets
import { arrowUpIcon } from "../../assets/icons/arrowUp/arrowUp";
import { arrowDownIcon } from "../../assets/icons/arrowDown/arrowDown";
// import { shareIcon } from "../../assets/icons/share/share";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./RecordCardTitleRow.module.scss";
// import styledVariables from "../../styles/utils/_variables.scss";

const generateTitle = (type) => {
  switch (type) {
    case globalConstants.RECORD_TYPE.lastSevenDays.value:
    case globalConstants.RECORD_TYPE.lastThirtyDays.value:
      return `${globalConstants.RECORD_TYPE[type].label} days`;
    case globalConstants.RECORD_TYPE.allTime.value:
      return `${globalConstants.RECORD_TYPE[type].label}`;
    default:
      return `${type}`;
  }
};

const RecordCardTitleRow = ({
  recordData,
  handleToggleModal,
  showButton,
  isProfileOwner,
}) => {
  return (
    <div className={classes.recordCardContainer}>
      {recordData.type && (
        <div className={classes.recordCardFirstRow}>
          <p
            className={classes.recordCardTitle}
            // style={{
            //   borderRightColor:
            //     showButton && isProfileOwner ? styledVariables.white40 : "",
            // }}
          >
            {generateTitle(recordData.type)}
          </p>
          {/* {showButton && isProfileOwner ? (
            <div className={classes.shareButtonContainer}>
              <MainButton
                buttonText={
                  <span className={classes.shareButtonText}>Share</span>
                }
                type="text"
                icon={shareIcon}
                clicked={handleToggleModal}
              />
            </div>
          ) : null} */}
        </div>
      )}
      <p className={classes.recordCardROI}>
        {formatNumberWithSymbol(parseFloat(recordData.roi).toFixed(2))}% ROI
        <span className={classes.recordCardArrowContainer}>
          {recordData.roi >= 0 ? arrowUpIcon : arrowDownIcon}
        </span>
      </p>
    </div>
  );
};

export default RecordCardTitleRow;
