// Vendors
import React from "react";

export const instabreakIcon2 = (
  <svg
    width="58"
    height="61"
    viewBox="0 0 58 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3520_729012)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.3843 21.5412L45.8299 21.5559C52.6128 21.9233 58.0049 27.5461 58.0049 34.4229C58.0049 41.2996 52.2357 47.3094 45.1198 47.3094C38.714 47.3094 33.4003 42.6367 32.4061 36.5143H25.6036C24.6046 42.6367 19.2909 47.3094 12.89 47.3094C5.77403 47.3094 0.00488281 41.5396 0.00488281 34.4229C0.00488281 27.3061 5.61241 21.7029 12.5863 21.5412H45.3843V21.5412ZM40.6583 32.1894C39.4241 32.1894 38.425 33.1886 38.425 34.4229C38.425 35.6571 39.4241 36.6563 40.6583 36.6563C41.8924 36.6563 42.8915 35.6571 42.8915 34.4229C42.8915 33.1886 41.8924 32.1894 40.6583 32.1894ZM49.5715 32.1894C48.3374 32.1894 47.3383 33.1886 47.3383 34.4229C47.3383 35.6571 48.3374 36.6563 49.5715 36.6563C50.8057 36.6563 51.8048 35.6571 51.8048 34.4229C51.8048 33.1886 50.8057 32.1894 49.5715 32.1894ZM45.1149 27.7665C43.8808 27.7665 42.8817 28.7657 42.8817 30C42.8817 31.2343 43.8808 32.2335 45.1149 32.2335C46.3491 32.2335 47.3481 31.2343 47.3481 30C47.3481 28.7657 46.3491 27.7665 45.1149 27.7665ZM45.1149 36.6123C43.8808 36.6123 42.8817 37.6114 42.8817 38.8457C42.8817 40.08 43.8808 41.0792 45.1149 41.0792C46.3491 41.0792 47.3481 40.08 47.3481 38.8457C47.3481 37.6114 46.3491 36.6123 45.1149 36.6123ZM11.6754 27.742C11.1171 27.742 10.6616 28.1976 10.6616 28.7559V32.1943H7.22366C6.66536 32.1943 6.2099 32.6498 6.2099 33.2131V35.6327C6.2099 36.191 6.66536 36.6465 7.22366 36.6465H10.6616V40.0849C10.6616 40.6433 11.1171 41.1037 11.6754 41.1037H14.0947C14.653 41.1037 15.1134 40.6482 15.1134 40.0849V36.6465H18.5514C19.1097 36.6465 19.5651 36.191 19.5651 35.6327V33.2131C19.5651 32.6547 19.1097 32.1943 18.5514 32.1943H15.1134V28.7559C15.1134 28.1976 14.6579 27.742 14.0947 27.742H11.6754V27.742Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4169 49.9543C43.4941 55.9886 36.6916 60 29.0026 60C21.3088 60 14.5112 55.9886 10.5884 49.9543C11.3377 50.0669 12.1066 50.1208 12.8902 50.1208C19.7563 50.1208 25.7263 45.6735 27.8077 39.3257H30.2025C32.2839 45.6735 38.2587 50.1208 45.12 50.1208C45.8987 50.1208 46.6676 50.062 47.4169 49.9543V49.9543Z"
        fill="url(#paint0_linear_3520_7290)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0002 0C32.7565 0 36.1749 1.53306 38.6481 4.00653C41.1213 6.48 42.6541 9.89388 42.6541 13.6555L42.6541 18.7249L35.4647 18.7249L35.4647 13.6555C35.4647 11.8824 34.735 10.2661 33.5645 9.09061C32.3941 7.9151 30.773 7.1902 29.0002 7.1902C27.2273 7.1902 25.6112 7.92 24.4358 9.09061C23.2604 10.2661 22.5356 11.8824 22.5356 13.6555L22.5356 18.7249L15.3462 18.7249L15.3462 13.6555C15.3462 9.89878 16.884 6.48 19.3523 4.00653C21.8255 1.53306 25.239 0 29.0002 0Z"
        fill="url(#paint1_linear_3520_7290)"
      />
    </g>
    <path
      d="M21.2207 1.91309L28.8694 9.56167L25.9926 25.041L33.5268 31.0683L22.9191 59.5817"
      stroke="#0F0F14"
      strokeWidth="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3520_7290"
        x1="53.7952"
        y1="44.4912"
        x2="13.693"
        y2="65.8336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A02BE2" />
        <stop offset="1" stopColor="#3019BC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3520_7290"
        x1="47.3836"
        y1="4.67846"
        x2="15.3141"
        y2="18.6512"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A02BE2" />
        <stop offset="1" stopColor="#3019BC" />
      </linearGradient>
      <clipPath id="clip0_3520_729012">
        <rect width="58" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
