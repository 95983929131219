// Vendors
import React from "react";

export const attackIcon = (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.36732 11.7243L4.93877 11.1529L1.34699 7.52038L0.775547 8.09183L2.28573 9.64286L0 11.9286L0.571444 12.5L2.85718 10.2143L4.36736 11.7245L4.36732 11.7243ZM7.59182 11.7243L7.02037 11.1529L10.6529 7.52038L11.2243 8.09183L9.71412 9.64286L11.9999 11.9286L11.4284 12.5L9.14268 10.2143L7.59165 11.7245L7.59182 11.7243ZM9.38768 8.41818L7.91836 9.8875L6.00006 8.17325L4.08175 9.8875L2.57156 8.41818L4.20419 6.58147L0.163496 2.98969L0.000274325 0.499967L2.49 0.663189L6.00014 4.62224L9.51027 0.663189L12 0.499967L11.7959 2.98969L7.75524 6.58147L9.38786 8.41818H9.38768Z"
      fill="#FF4D26"
    />
  </svg>
);
