// Vendors
import React, { useContext } from "react";
import { format } from "date-fns";
import { differenceInMilliseconds } from "date-fns";

// Components
import LiveCard from "../LiveCard/LiveCard";
import IconLabel from "../IconLabel/IconLabel";
import OddsTag from "../OddsTag/OddsTag";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Styles
import classes from "./LiveGameCard.module.scss";

// Assets
import { cupIcon } from "../../assets/icons/cup/cup";
import ClockIcon from "../../assets/icons/clock/clock";
import { finishedIcon } from "../../assets/icons/finished/finished";

import { attackIcon } from "../../assets/icons/attack/attack";
import { defenseIcon } from "../../assets/icons/defense/defense";
import { terroristIcon } from "../../assets/icons/terrorist/terrorist";
import { counterTerroristIcon } from "../../assets/icons/counterTerrorist/counterTerrorist";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import styledVariables from "../../styles/utils/_variables.scss";

import { Link } from "react-router-dom";

const LiveGameCard = ({ game, serie }) => {
  const hasStarted =
    differenceInMilliseconds(new Date(), new Date(serie.startDate)) >= 0;

  const { preferredOddsType } = useContext(UserContext);

  const matchHasWinner = (serie) => {
    return serie.teamOne.winner || serie.teamTwo.winner;
  };

  const hasWinner = matchHasWinner(serie);

  const generateAttackingIcons = (faction) => {
    let label = "";
    let icon = "";

    switch (faction) {
      case "Counter-Terrorists":
        label = "CT";
        icon = counterTerroristIcon;

        break;
      case "Terrorists":
        label = "T";
        icon = terroristIcon;

        break;
      case "Attackers":
        label = "ATT";
        icon = attackIcon;

        break;
      case "Defenders":
        label = "DEF";
        icon = defenseIcon;

        break;
      default:
        break;
    }
    return (
      <div className={classes.factionContainer}>
        {icon}
        <span>{label}</span>
      </div>
    );
  };

  return (
    <Link
      to={`/${globalConstants.ROUTES.MATCH_PAGE}/${serie.abiosSeriesId}/`}
      className={`${classes.liveGameCardContainer} ${
        serie.isSeriesPresent ? classes.betableGame : ""
      }`}
      style={{ pointerEvents: !serie.isSeriesPresent ? "none" : null }}
    >
      {serie.live ? (
        <div className={classes.liveMap}>
          <LiveCard />
          {serie.map && <span className={classes.mapText}>{serie.map}</span>}
        </div>
      ) : !hasStarted ? (
        <div className={classes.liveMap}>
          <div className={classes.doneIconContainer}>
            {<ClockIcon color={styledVariables.backgroundEmphasisK} />}
          </div>

          {serie.startDate && (
            <span>{`Starting: ${format(
              new Date(serie.startDate),
              "HH:mm, MMM dd"
            )}`}</span>
          )}
        </div>
      ) : (
        <div className={classes.liveMap}>
          <div className={classes.doneIconContainer}>
            {/* <CheckSquareOutlined style={{ fontSize: "16px", opacity: 0.6 }} /> */}
            {finishedIcon}
          </div>
          <span>
            {`Finished ${
              serie.endDate
                ? `, ${format(new Date(serie.endDate), "HH:mm, MMM dd")}`
                : ""
            }`}
          </span>
        </div>
      )}
      <p className={classes.seriesScore}>
        {serie.bestOf > 1
          ? `Series ${serie.teamOne.seriesScore}-${serie.teamTwo.seriesScore}`
          : null}
      </p>
      <div className={classes.teamRowsContainer}>
        {[serie.teamOne, serie.teamTwo].map((team, i) => {
          return (
            <div className={classes.teamRow} key={`${i}-${team.teamName}`}>
              <div className={classes.teamNameContainer}>
                <IconLabel
                  icon={team.imageSmall}
                  label={team.teamAbbreviation || team.teamName}
                  logoSize="logoSmaller"
                  size="small"
                  withAbbreviation
                />
              </div>
              <div className={classes.scoreRowContainer}>
                {serie.teamOne?.faction && serie.teamTwo?.faction && serie.live
                  ? generateAttackingIcons(team.faction)
                  : null}

                {serie.live && game.name === globalConstants.VALORANT && (
                  <div className={classes.teamStatus}>
                    {team.statusLogo}
                    <div className={classes.statusLabel}>{team.status}</div>
                  </div>
                )}

                {hasStarted ? (
                  <span
                    className={classes.teamScore}
                    style={{ opacity: !serie.live && !team.winner ? 0.4 : 1 }}
                  >
                    {team.matchScore}
                  </span>
                ) : team.odds ? (
                  <div className={classes.oddsContainer}>
                    <OddsTag
                      odds={team.odds}
                      fullWidth
                      preferredOddsType={preferredOddsType}
                    />
                  </div>
                ) : null}

                {hasWinner && (
                  <>
                    {!serie.live && team.winner ? (
                      <div className={classes.cupIconConatiner}>{cupIcon}</div>
                    ) : (
                      <div className={classes.cupspacer}></div>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Link>
  );
};

export default LiveGameCard;
