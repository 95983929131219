// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Styles
import classes from "./ProfilePictureVerificable.module.scss";

// Assets
import { verifiedIconWithSize } from "../../assets/icons/verified/verified";
import defaultProfilePicture from "../../assets/images/profile/defaultProfilePicture.jpg";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const ProfilePictureVerificable = ({
  image,
  isVerified,
  size,
  iconSizePX,
  iconPadding,
  username,
}) => {
  const addPixelSuffix = (text) => `${text}px`;
  return (
    <Link
      className={classes.profileImageContainer}
      to={{
        pathname: `/${globalConstants.ROUTES.PROFILE}/${username}/`,
      }}
    >
      <img
        src={image || defaultProfilePicture}
        alt="profileplaceholder"
        style={{
          height: addPixelSuffix(size + 4),
          width: addPixelSuffix(size),
        }}
      />

      {isVerified && (
        <div
          className={classes.verifiedIconContainer}
          style={{
            padding: addPixelSuffix(iconPadding),
            left: addPixelSuffix(size - (iconSizePX + iconPadding)),
            bottom: addPixelSuffix(size - (iconSizePX + iconPadding)),
          }}
        >
          {verifiedIconWithSize(iconSizePX || 14)}
        </div>
      )}
    </Link>
  );
};

export default ProfilePictureVerificable;
