// Vendors
import React, { useState, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// API
import {
  getUpcomingGamesData,
  getEsportRSS,
  getTwitchStreamLink,
  getFeaturedInstalocks,
  getStreakData,
  getUpsetData,
} from "../../api/requests/requests";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import InstalockAction from "../../components/InstalockAction/InstalockAction";
import StreamBox from "../../components/StreamBox/StreamBox";
// import LatestPicksWrapper from "../../components/LatestPicksWrapper/LatestPicksWrapper";
import LatestNews from "../../components/LatestNews/LatestNews";
import AffiliateBanner from "../../components/AffiliateBanner/AffiliateBanner";
import Loading from "../../components/Loading/Loading";
import FeaturedInstalocks from "../../components/FeaturedInstalocks/FeaturedInstalocks";
import StreakCard from "../../components/StreakCard/StreakCard";
import ProbabilityCard from "../../components/ProbabilityCard/ProbabilityCard";
import InstalockUpset from "../../components/InstalockUpset/InstalockUpset";

// Assets
import dexertoNews from "../../assets/images/news/dexertoNews.png";
import shiftrleGGNews from "../../assets/images/news/shiftrleGGNews.png";
import esportsNetNews from "../../assets/images/news/esportsNetNews.png";
import hltvNews from "../../assets/images/news/hltvNews.png";
import invenGlobalNews from "../../assets/images/news/invenGlobalNews.png";

// Styles
import classes from "./EsportsDetailPage.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import LatestResultsWrapper from "../../components/LatestResultsWrapper/LatestResultsWrapper";

const EsportsDetailPage = ({ title }) => {
  let { game } = useParams();
  const { preferredOddsType, userRegion } = useContext(UserContext);

  const [loadingRSSData, setLoadingRSSData] = useState(false);
  const [RSSData, setRSSData] = useState();

  const [twitchStreamData, setTwitchStreamData] = useState("");
  const [loadingTwitchData, setLoadingTwitchData] = useState(false);

  const [streakData, setStreakData] = useState(null);
  const [loadingStreakData, setLoadingStreakData] = useState(false);

  const [upsetData, setUpsetData] = useState(null);
  const [loadingUpsetData, setLoadingUpsetData] = useState(false);

  const [loadingUpcomingMatchesData, setLoadingUpcomingMatchesData] =
    useState(false);
  const [upcomingMatchesData, setUpcomingMatchesData] = useState(null);

  // const [loadingInstalockActionData, setLoadingInstalockActionData] =
  //   useState(false);
  // const [instalockActionData, setInstalockActionData] = useState(null);

  const [loadingFeaturedInstalocks, setLoadingFeaturedInstalocks] =
    useState(false);
  const [featuredInstalocks, setFeaturedInstalocks] = useState(null);

  const handleUpcomingMatchesData = () => {
    setLoadingUpcomingMatchesData(true);
    getUpcomingGamesData(
      { esportId: globalConstants.TITLES_IDS[game] || null, type: "UPCOMING" },
      (data) => {
        setLoadingUpcomingMatchesData(false);
        setUpcomingMatchesData(data);
      },
      (error) => {
        setLoadingUpcomingMatchesData(false);
        setUpcomingMatchesData({});
      }
    );
  };

  // const handleActionMatchesData = () => {
  //   setLoadingInstalockActionData(true);
  //   getUpcomingGamesData(
  //     { esportId: globalConstants.TITLES_IDS[game] || null, type: "ACTION" },
  //     (data) => {
  //       setLoadingInstalockActionData(false);
  //       setInstalockActionData(data);
  //     },
  //     (error) => {
  //       setLoadingInstalockActionData(false);
  //       setInstalockActionData({});
  //     }
  //   );
  // };

  const handleGetTwitchStreamData = () => {
    setLoadingTwitchData(true);
    getTwitchStreamLink(
      globalConstants.TITLES_IDS[game],
      (data) => {
        setLoadingTwitchData(false);
        setTwitchStreamData(data);
      },
      (error) => {
        setLoadingTwitchData(false);
        setTwitchStreamData("");
      }
    );
  };

  const handleGetStreakData = () => {
    setLoadingStreakData(true);
    getStreakData(
      globalConstants.TITLES_IDS[game],
      (data) => {
        setLoadingStreakData(false);
        setStreakData(data);
      },
      (error) => {
        setLoadingStreakData(false);
        setStreakData(null);
      }
    );
  };

  const handleGetUpsetData = () => {
    setLoadingUpsetData(true);
    getUpsetData(
      globalConstants.TITLES_IDS[game],
      (data) => {
        setLoadingUpsetData(false);
        setUpsetData(data);
      },
      (error) => {
        setLoadingUpsetData(false);
        setUpsetData(null);
      }
    );
  };

  const replaceCDData = (string) => {
    if (!string) {
      return "";
    }
    return string.replace("<![CDATA[", "").replace("]]>", "");
  };

  const extractUrlFromString = (str) => {
    const regex = /url="(.*?)"/;
    const match = str.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };

  const generateNewsData = (data, game) => {
    const feed = new window.DOMParser().parseFromString(data, "text/xml");
    const items = feed.querySelectorAll("item");
    const feedItems = [...items].slice(0, 4).map((element) => {
      const title = replaceCDData(element.querySelector("title").innerHTML);
      const date = !!element.querySelector("pubDate")
        ? replaceCDData(element.querySelector("pubDate").innerHTML)
        : "";
      const content = !!element.querySelector("description")
        ? replaceCDData(element.querySelector("description").innerHTML)
        : "";

      const link = !!element.querySelector("link")
        ? replaceCDData(element.querySelector("link").innerHTML)
        : "";

      const media = element.children?.[element.children.length - 1];
      const mediaImage = extractUrlFromString(media.outerHTML);
      let newGame = { esport: game, image: game };

      if (!game && media && media.outerHTML) {
        if (media.outerHTML.includes("invenglobal.com")) {
          newGame = {
            esport: globalConstants.LEAGUE_OF_LEGENDS,
            image: globalConstants.LEAGUE_OF_LEGENDS,
          };
        } else if (media.outerHTML.includes("esports.net")) {
          newGame = {
            esport: globalConstants.CS_GO,
            image: `${globalConstants.CS_GO}-2`,
          };
        } else if (
          media.outerHTML.includes("shiftrle.gg") ||
          link?.includes("shiftrle.gg")
        ) {
          newGame = {
            esport: globalConstants.ROCKET_LEAGUE,
            image: globalConstants.ROCKET_LEAGUE,
          };
        } else if (media.outerHTML.includes("dexerto")) {
          if (link.includes(globalConstants.VALORANT.toLocaleLowerCase())) {
            newGame = {
              esport: globalConstants.VALORANT,
              image: globalConstants.VALORANT,
            };
          } else {
            newGame = {
              esport: globalConstants.CALL_OF_DUTY,
              image: globalConstants.CALL_OF_DUTY,
            };
          }
        }
      }

      return {
        image: getRssImage(newGame.image),
        game: newGame.esport,
        title: title,
        date: date,
        content: content,
        link: link,
        mediaImage,
      };
    });

    return { news: feedItems.slice(0, 2) };
  };

  const getRssImage = (esport) => {
    switch (esport) {
      case globalConstants.CS_GO:
        return hltvNews;
      case `${globalConstants.CS_GO}-2`:
        return esportsNetNews;
      case globalConstants.VALORANT:
        return dexertoNews;
      case globalConstants.LEAGUE_OF_LEGENDS:
        return invenGlobalNews;
      case globalConstants.ROCKET_LEAGUE:
        return shiftrleGGNews;
      case globalConstants.CALL_OF_DUTY:
        return dexertoNews;
      default:
        return "";
    }
  };

  const handleGetRSSNews = () => {
    setLoadingRSSData(true);
    getEsportRSS(
      game,
      (data) => {
        setRSSData(generateNewsData(data, game));
        setLoadingRSSData(false);
      },
      (error) => {
        setRSSData(null);
        setLoadingRSSData(false);
      }
    );
  };

  const generateMatchesData = (gamesData) => {
    if (!gamesData) {
      return [];
    }
    const upcomingMatches = [];

    Object.entries(gamesData).forEach((game) => {
      const esportName = game[0];
      const esportData = game[1];

      return esportData.series.forEach((serie) => {
        upcomingMatches.push({
          ...serie,
          game: esportName,
        });
      });
    });
    return upcomingMatches;
  };

  const handleGetFeaturedInstalocks = () => {
    setLoadingFeaturedInstalocks(true);
    getFeaturedInstalocks(
      (data) => {
        setFeaturedInstalocks(data);
        setLoadingFeaturedInstalocks(false);
      },
      () => {
        setFeaturedInstalocks(null);
        setLoadingFeaturedInstalocks(false);
      }
    );
  };

  const upcomingMatches = useMemo(
    () => generateMatchesData(upcomingMatchesData),
    [upcomingMatchesData]
  );

  // const instalockActionMatches = useMemo(
  //   () => generateMatchesData(instalockActionData),
  //   [instalockActionData]
  // );

  useEffect(() => {
    handleUpcomingMatchesData();
    handleGetTwitchStreamData();
    // handleActionMatchesData();
    handleGetRSSNews();
    handleGetFeaturedInstalocks();
    handleGetStreakData();
    handleGetUpsetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  useEffect(() => {
    document.title = !game
      ? `${title} Network - Esports Betting Odds, Lines, Stats, & News`
      : `${title} - ${globalConstants.TITLES[game]}`;
  }, [title, game]);

  const loading =
    loadingRSSData &&
    loadingTwitchData &&
    loadingUpcomingMatchesData &&
    // loadingInstalockActionData &&
    loadingFeaturedInstalocks &&
    loadingStreakData &&
    loadingUpsetData &&
    !userRegion;

  return loading ? (
    <Loading />
  ) : (
    <>
      {!game ? (
        <div className={classes.featuredInstalocksContainer}>
          <FeaturedInstalocks
            preferredOddsType={preferredOddsType}
            featuredInstalocks={featuredInstalocks?.bets || []}
          />
        </div>
      ) : null}
      <div className={classes.esportsDetailPageContainer}>
        <div className={classes.esportsDetailPageWrapper}>
          <div className={classes.pageBannerContainer}>
            {game ? (
              <GameBannerRenderer game={game} />
            ) : userRegion ? (
              <AffiliateBanner userRegion={userRegion} />
            ) : null}
          </div>
          <div className={classes.pageUpsetsContainer}>
            <InstalockUpset
              upsetData={upsetData}
              preferredOddsType={preferredOddsType}
            />
          </div>

          <div className={classes.detailColumnsContainer}>
            <div className={classes.leftColumnContainer}>
              {/* <div className={classes.columnTitle}>Heavy Instalock Action</div>
              {instalockActionMatches.length > 0 ? (
                instalockActionMatches.slice(0, 3).map((gameData, index) => {
                  return (
                    <InstalockAction
                      page={game}
                      gameData={gameData}
                      key={`${gameData.id}-${index}`}
                      showMoneyLineText
                      showBetOdds
                      preferredOddsType={preferredOddsType}
                    />
                  );
                })
              ) : loadingInstalockActionData ? null : (
                <div className={classes.noDataCard}>No action . . .</div>
              )} */}
              <div className={classes.columnTitle}>
                Implied Win Probability Radar
              </div>
              {streakData?.closest || streakData?.loopsided ? (
                <ProbabilityCard
                  closest={streakData.closest}
                  loopsided={streakData.loopsided}
                  preferredOddsType={preferredOddsType}
                />
              ) : loadingStreakData ? null : (
                <div className={classes.noDataCard}>No Data . . .</div>
              )}
              <div className={classes.lastArticlesTitle}>
                Streak Teams Playing Today
              </div>
              {streakData?.winStreak || streakData?.loseStreak ? (
                <StreakCard
                  winStreak={streakData.winStreak}
                  loseStreak={streakData.loseStreak}
                />
              ) : loadingStreakData ? null : (
                <div className={classes.noDataCard}>No Data . . .</div>
              )}
            </div>
            <div className={classes.middleColumnContainer}>
              {twitchStreamData && !loadingTwitchData ? (
                <div className={classes.liveStreamContainer}>
                  <StreamBox
                    handleGetNewStream={handleGetTwitchStreamData}
                    twitchStreamData={twitchStreamData}
                    esport={game}
                  />
                </div>
              ) : null}

              {/* <LatestPicksWrapper
                preferredOddsType={preferredOddsType}
                game={game}
              /> */}
              <LatestResultsWrapper
                preferredOddsType={preferredOddsType}
                game={game}
              />
            </div>

            <div className={classes.rightColumnContainer}>
              <>
                <div className={classes.columnTitle}>Upcoming Matches</div>

                {upcomingMatches.length > 0 ? (
                  <>
                    {upcomingMatches.map((gameData, index) => {
                      return (
                        <InstalockAction
                          page={game}
                          gameData={gameData}
                          key={`${gameData.id}-${index}`}
                          preferredOddsType={preferredOddsType}
                        />
                      );
                    })}
                  </>
                ) : loadingUpcomingMatchesData ? null : (
                  <div className={classes.noDataCard}>
                    No upcoming matches . . .
                  </div>
                )}

                <div className={classes.lastArticlesTitle}>Latest News</div>

                {RSSData?.news.length > 0 ? (
                  <LatestNews newsData={RSSData?.news} />
                ) : loadingRSSData ? null : (
                  <div className={classes.noDataCard}>No news . . .</div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EsportsDetailPage;
