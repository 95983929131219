// Vendors
import React from "react";
// Styles
import classes from "./Loading.module.scss";

const Loading = () => {
  return (
    <>
      <div className={classes.loadingContentSpace} />
      <div className={classes.loading}>
        <h3 className={classes.loadingTitle}>Loading . . .</h3>
        <div className={classes["lds-ellipsis"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default Loading;
