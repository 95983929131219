// Vendors
import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

// Components
import LikesRate from "../../components/LikesRate/LikesRate";
import MainButton from "../../components/MainButton/MainButton";
import ProfilePictureVerificable from "../../components/ProfilePictureVerificable/ProfilePictureVerificable";
import StaffTag from "../../components/StaffTag/StaffTag";

// Styles
import classes from "./GuideCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const GuideCard = ({ guideData, isMainGuide }) => {
  return (
    <div className={classes.guideCardContainer}>
      <div
        className={`${classes.guideImageContainer} ${
          isMainGuide ? classes.mainGuideImageContainer : ""
        }`}
      >
        <img
          src={isMainGuide ? guideData.secondaryImage : guideData.mainImage}
          alt="Top  Guide"
        />
      </div>
      <div className={classes.guideContent}>
        <p className={classes.guideTitle}>{guideData.title}</p>
        <p className={classes.guideDescription}>{guideData.description}</p>
        {guideData?.user ? (
          <div className={classes.guideUserContainer}>
            {guideData.user.image && guideData?.user.username ? (
              <ProfilePictureVerificable
                username={guideData.user.username}
                image={guideData.user.image}
                size={36}
              />
            ) : null}
            {guideData.user.username ? (
              <Link
                className={classes.profileLinkContainer}
                to={{
                  pathname: `/${globalConstants.ROUTES.PROFILE}/${guideData.user.username}/`,
                }}
              >
                <span>{guideData.user.username}</span>
              </Link>
            ) : null}
            {guideData.user.staff ? (
              <StaffTag staff={guideData.user.staff} />
            ) : null}
          </div>
        ) : null}
        <div className={classes.ctaRow}>
          <div className={classes.likesAndDateContainer}>
            {guideData.likePercentage ? (
              <LikesRate likeRate={guideData.likePercentage} />
            ) : null}
            <span className={classes.guideDate}>
              {format(new Date(guideData.date), "MMM dd, yyyy")}
            </span>
          </div>

          <Link
            to={{
              pathname: `/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GUIDES}/${guideData.id}`,
            }}
          >
            <MainButton buttonText="Read" type="primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GuideCard;
