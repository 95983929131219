// Vendors
import React, { useState, useContext } from "react";
import { Form, Input } from "antd";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";

// AWS
import { logInAWS } from "../../AWS/UserPool";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";
import { FormContext } from "../../context/contextProviders/FormContextProvider";

// API
import { getUserAvailability } from "../../api/requests/requests";

// Components
import Logo from "../../components/Logo/Logo";
import MainButton from "../../components/MainButton/MainButton";
import MainModal from "../../components/MainModal/MainModal";

// Styles
import classes from "./Login.module.scss";

// Utils
import { validateEmail } from "../../utils/globalUtils";

const Login = ({ modalToShow, setModalToShow }) => {
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);

  const { setFormData, clearFormData } = useContext(FormContext);
  const { getSessionData } = useContext(UserContext);

  const generateAvailabilityPayload = (inputValue) => {
    const availabilityPayload = {};
    if (validateEmail(inputValue)) {
      availabilityPayload.email = inputValue;
    } else {
      availabilityPayload.username = inputValue;
    }
    return availabilityPayload;
  };

  const launchLoginProcess = (username, password) => {
    logInAWS(
      { username, password },
      (data) => {
        sessionStorage.setItem(
          "accessToken",
          data.getAccessToken().getJwtToken()
        );
        setLoading(false);
        setModalToShow("");
        getSessionData();
      },
      (error) => {
        if (error.code === "User need to be authenticated") {
          if (!validateEmail(username)) {
            setFormData({ username });
          }
          redirectToVerifyUser();
        } else {
          setLoginError(error.message);
          setLoading(false);
        }
      },
      () => {
        setModalToShow("newPassword");
        setLoginError("Password must be changed");
        setLoading(false);
      }
    );
  };

  const handleSubmit = (values) => {
    clearFormData();
    setLoading(true);
    setLoginError("");

    const { username, password } = values;

    getUserAvailability(
      generateAvailabilityPayload(username),
      (data) => {
        if (
          (data.email && data.email.used && !data.email.emailVerified) ||
          (data.username && data.username.used && !data.username.emailVerified)
        ) {
          if (!validateEmail(username)) {
            setFormData({ username });
          }
          redirectToVerifyUser();
        } else {
          launchLoginProcess(username, password);
        }
      },
      () => {
        setLoading(false);
        setLoginError("Something went wrong, please try again.");
      }
    );
  };

  const redirectToVerifyUser = () => {
    setModalToShow("verifyUser");
  };

  return (
    <MainModal
      open={modalToShow}
      onCancel={() => setModalToShow(false)}
      footer={null}
      closable={false}
    >
      <Form
        name="login"
        initialValues={{}}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <div className={classes.loginModalTitle}>
          <Logo />
        </div>
        <p className={classes.greetingText}>Good evening!</p>
        <div className={classes.inputsContainer}>
          <Form.Item
            className={classes.formItem}
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your email or username!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Email address or Username"
              className={classes.loginInputBox}
              prefix={<UserOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            name="password"
            className={classes.formItem}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              size="large"
              placeholder="Password"
              className={classes.loginInputBox}
              prefix={<KeyOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
            />
          </Form.Item>

          {loginError && (
            <span className={classes.loginError}>{loginError}</span>
          )}

          <div className={classes.forgotPasswordContainer}>
            <MainButton
              buttonText="Forgot password?"
              type="text"
              clicked={() => setModalToShow("forgotPassword")}
            />
          </div>
          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText="Continue"
              fullWidth="fullWidth"
              loading={loading}
            />
          </div>

          <div className={classes.changeLoginText}>
            <span className={classes.changeLoginEmphasisText}>
              "No account?"
            </span>
            <MainButton
              buttonText={"Create one"}
              type="text"
              clicked={() => setModalToShow("signUp")}
            />
          </div>
        </div>
      </Form>
    </MainModal>
  );
};

export default Login;
