// Vendors
import React from "react";

// Components
import IconLabel from "../IconLabel/IconLabel";
import DividerLine from "../DividerLine/DividerLine";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./LatestNews.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

const LatestNews = ({ newsData }) => {
  return (
    <div className={classes.latestNewsContainer}>
      {newsData.map((article, index) => {
        return (
          <div key={`${article.title}-${article.game}-${article.index}`}>
            <div className={classes.articleContainer}>
              <a
                className={classes.itemLink}
                href={article.link}
                target="_blank"
                rel="noreferrer"
              >
                {article.game ? (
                  <div className={classes.lastRow}>
                    <IconLabel
                      label={globalConstants.TITLES[article.game]}
                      size="medium"
                      icon={esportsLogos[article.game]}
                      isSvg
                    />
                  </div>
                ) : null}

                {article.mediaImage ? (
                  <div className={classes.mediaImageContainer}>
                    <img src={article.mediaImage} alt="" />
                  </div>
                ) : null}

                <div className={classes.titleRow}>
                  {article.title ? (
                    <p className={classes.titleText}>{article.title}</p>
                  ) : null}
                </div>

                {article.content ? (
                  <p className={classes.contentText}>{article.content}</p>
                ) : null}
                {article.image ? (
                  <div className={classes.sourceText}>
                    Source
                    <div className={classes.imageContainer}>
                      <img src={article.image} alt={article.game} />
                    </div>
                  </div>
                ) : null}
              </a>
            </div>
            {index !== newsData.length - 1 ? (
              <div className={classes.dividerLineWrapper}>
                <DividerLine color={styledVariables.backgroundPrimaryE} />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default LatestNews;
