// Vendors
import React from "react";

export const bovada = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.05519 15.9999V6.21196L1.17104 6.08753C1.21223 6.18708 1.22682 6.31751 1.29976 6.38187C1.97254 6.97313 2.66163 7.54637 3.32926 8.14363C3.43224 8.23631 3.49488 8.4251 3.49832 8.57098C3.53693 10.029 3.57555 11.4869 3.57469 12.9449C3.57469 13.3611 3.72486 13.4409 4.09644 13.4358C5.44028 13.4178 6.78413 13.4289 8.12797 13.4289C9.13028 13.4289 9.71382 12.8806 9.78676 11.8731C9.83825 11.1669 9.60397 10.5413 9.06764 10.1148C8.48153 9.64795 7.83277 9.24462 7.16858 8.8945C6.56445 8.57613 5.90454 8.36589 5.2678 8.11102C4.21829 7.69225 3.30609 7.06838 2.48485 6.29434C1.70051 5.55548 1.22339 4.66474 1.14787 3.59464C1.06635 2.42757 1.0449 1.25621 0.993408 0.00418396C1.22425 0.00418396 1.38729 0.00418396 1.55034 0.00418396C3.46656 0.00418396 5.38193 0.0153398 7.29815 -0.00010673C8.4369 -0.00954626 9.31221 0.467579 9.97212 1.36262C10.8603 2.56573 10.9693 3.92245 10.6689 5.33151C10.523 6.01459 10.3171 6.68565 10.1111 7.35414C10.0391 7.5867 10.0605 7.71027 10.2399 7.90335C10.7813 8.48689 11.3434 9.07128 11.7665 9.73805C12.3432 10.6485 12.2325 11.692 12.0437 12.7003C11.7116 14.4733 10.6809 15.5768 8.84795 15.721C6.27611 15.9235 3.68796 15.9158 1.05605 15.999L1.05519 15.9999ZM3.61331 2.44387C3.4391 3.38182 3.65106 4.1447 4.35388 4.76342C5.30899 5.6044 6.44002 6.1004 7.63369 6.47627C7.71264 6.50115 7.8877 6.44967 7.91087 6.39131C8.32706 5.35726 8.65659 4.30689 8.20349 3.19646C8.03015 2.77168 7.70062 2.46361 7.23379 2.45331C6.03669 2.42585 4.83787 2.44387 3.61331 2.44387V2.44387Z"
      fill="#CA1F25"
    />
  </svg>
);
