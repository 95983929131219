// API
import axios from "axios";
import api, { customAxios } from "../../api/axios";

// Constants
import { globalConstants } from "../../constants/globalConstants";
const BASE_URL = `${process.env.REACT_APP_BASE_URL_END_POINTS}v1`;
const BASE_URL_2 = `${process.env.REACT_APP_BASE_URL_END_POINTS}v2`;

export const getCarouselData = (onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/carousel`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const requestUserData = (username, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/profiles/users/${username}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const requestMatchData = (seriesId, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/series/${seriesId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const requestUserBetVerificationListData = (data, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL}/bets/verifications/search`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const finishBetVerification = ({ betId, status }, onSuccess, onFail) => {
  return api
    .put(`${BASE_URL}/bets/verifications/${betId}?status=${status}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getUserAvailability = (data, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL}/users/availability`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getUpcomingGamesData = ({ esportId, type }, onSuccess, onFail) => {
  return api
    .get(
      `${BASE_URL}/upcoming/bets?type=${type}${
        esportId ? `&game=${esportId}` : ""
      }`
    )
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error);
    });
};

export const getLatestPicksData = (esportId, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/bets/latest${esportId ? `?game=${esportId}` : ""}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error);
    });
};

export const getLatestResultsData = (esportId, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/series/latest${esportId ? `?game=${esportId}` : ""}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error);
    });
};

export const getResultsData = (data, onSuccess, onFail) => {
  return api
    .get(
      `${BASE_URL}/series/results?date=${data.date ?? "null"}${
        data.esportId ? `&game=${data.esportId}` : ""
      }`
    )
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error);
    });
};

export const getSeriesOdds = (data, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL_2}/odds`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const discoverCommunityUsers = (data, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL}/communities/search`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const discoverMatches = (term, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/upcoming/search?term=${term}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getCommunityData = (onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/communities`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getTwitchStreamLink = (esport, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/twitch/bulk${esport ? `?game=${esport}` : ""}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getStreakData = (esport, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/upcoming/streaks${esport ? `?game=${esport}` : ""}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getUpsetData = (esport, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/series/upsets/${esport ? `?game=${esport}` : ""}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getFeaturedInstalocks = (onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/bets/featured`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

//  Without Headers

export const getEsportRSS = (game, onSuccess, onFail) => {
  let url = "";
  switch (game) {
    case globalConstants.LEAGUE_OF_LEGENDS:
      url = "https://rss.app/feeds/VXqd9w1AtjBLUW3v.xml";
      break;
    case globalConstants.CS_GO:
      url = "https://rss.app/feeds/xjbRDRlh4tZ5di1T.xml";
      break;
    case globalConstants.VALORANT:
      url = "https://rss.app/feeds/CgP5NXOFDUYENUAG.xml";
      break;
    case globalConstants.ROCKET_LEAGUE:
      url = "https://rss.app/feeds/duE4uj5m7A2qkAwb.xml";
      break;
    case globalConstants.CALL_OF_DUTY:
      url = "https://rss.app/feeds/6JpRx9oD71c1NUEq.xml";
      break;
    case globalConstants.TWITTER:
      url = "https://rss.app/feeds/_dm67bNZMhn224Nt9.xml";
      break;
    default:
      url = "https://rss.app/feeds/_j4AVRtGaRru1tRZ5.xml";
      break;
  }

  return axios
    .get(url)

    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const getUserRegion = (onSuccess, onFail) => {
  return axios
    .get("https://api.db-ip.com/v2/free/self")
    .then((response) => {
      const error = response?.data?.error;
      if (error) {
        onFail && onFail(error);
      } else {
        onSuccess && onSuccess(response.data);
      }
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

// User Requests

export const changeProfilePicture = (data, onSuccess, onFail) => {
  const isMultipart = true;
  return customAxios(isMultipart)
    .post(`${BASE_URL}/profiles/pictures`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const startBetVerification = (data, onSuccess, onFail) => {
  const betIds = data.betIds.join(",");
  const isMultipart = true;
  return customAxios(isMultipart)
    .post(`${BASE_URL}/bets/verifications?bets=${betIds}`, data.file)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const changeProfileData = (data, onSuccess, onFail) => {
  return api
    .put(`${BASE_URL}/profiles/`, data)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const changeProfileStatus = (status, onSuccess, onFail) => {
  return api
    .put(`${BASE_URL}/profiles/status`, status)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const createBets = (bets, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL}/bets/bulk`, bets)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error || JSON.stringify(error));
    });
};

export const deleteBet = (betId, onSuccess, onFail) => {
  return api
    .delete(`${BASE_URL}/bets/${betId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error || JSON.stringify(error));
    });
};

export const requestUserPreferences = (onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/users`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const changeProfilePreferences = (oddsType, onSuccess, onFail) => {
  return api
    .post(`${BASE_URL}/users/preferences`, oddsType)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};

export const requestUserROIData = (data, onSuccess, onFail) => {
  return api
    .get(`${BASE_URL}/bets/summary?timeframe=${data.timeframe}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onFail && onFail(error.message || JSON.stringify(error));
    });
};
