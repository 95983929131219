// Vendors
import React from "react";

const Icon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2221 0.833252C13.0159 0.833252 12.818 0.915199 12.6721 1.06103C12.5263 1.20686 12.4444 1.40478 12.4444 1.61103C12.1869 2.22872 11.6952 2.71921 11.0767 2.97494C10.4583 3.2305 9.76382 3.2305 9.14542 2.97494C8.52684 2.71921 8.0352 2.22876 7.77773 1.61103C7.77773 1.33308 7.62947 1.07632 7.38884 0.93743C7.14822 0.798541 6.85169 0.798541 6.61107 0.93743C6.37044 1.07632 6.22218 1.33309 6.22218 1.61103C5.96471 2.22872 5.47307 2.71921 4.85449 2.97494C4.23609 3.2305 3.54164 3.2305 2.92324 2.97494C2.30467 2.71921 1.81302 2.22876 1.55556 1.61103C1.55556 1.33308 1.40729 1.07632 1.16667 0.93743C0.92604 0.798541 0.629511 0.798541 0.388889 0.93743C0.148267 1.07632 0 1.33309 0 1.61103V10.1666C0 12.6675 1.33422 14.9786 3.5 16.2288C5.66578 17.4793 8.33422 17.4793 10.5 16.2288C12.6658 14.9785 14 12.6675 14 10.1666V1.61103C14 1.40478 13.9181 1.20686 13.7722 1.06103C13.6264 0.915199 13.4285 0.833252 13.2222 0.833252H13.2221ZM12.4444 10.1666C12.4444 12.1117 11.4067 13.9091 9.72213 14.8817C8.0376 15.8541 5.96222 15.8541 4.27769 14.8817C2.59316 13.9091 1.55547 12.1117 1.55547 10.1666V3.94436C2.23827 4.43063 3.0508 4.70147 3.8888 4.72214C5.07698 4.69367 6.20147 4.17925 6.99991 3.2989C7.79836 4.1793 8.92284 4.6937 10.111 4.72214C10.949 4.70148 11.7616 4.43065 12.4444 3.94436V10.1666ZM10.6633 7.28099C10.8081 7.42682 10.8895 7.62387 10.8895 7.82943C10.8895 8.03481 10.8081 8.23203 10.6633 8.3777L6.7744 12.2666C6.62892 12.415 6.42996 12.4991 6.22213 12.4999H6.16762C5.94227 12.4836 5.73498 12.3701 5.59993 12.1888L3.2666 9.0777C3.09993 8.85547 3.06434 8.56103 3.17354 8.30547C3.28274 8.04992 3.51989 7.87214 3.79576 7.83881C4.07163 7.80565 4.34438 7.92214 4.51105 8.14436L6.30776 10.5398L9.55878 7.28881C9.70375 7.14055 9.90201 7.05634 10.1095 7.05478C10.3168 7.05339 10.5163 7.13482 10.6633 7.28099L10.6633 7.28099Z"
      fill="white"
    />
  </svg>
);

export const verifiedIcon = Icon;

export const verifiedIconWithSize = (size) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 14 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2221 0.833252C13.0159 0.833252 12.818 0.915199 12.6721 1.06103C12.5263 1.20686 12.4444 1.40478 12.4444 1.61103C12.1869 2.22872 11.6952 2.71921 11.0767 2.97494C10.4583 3.2305 9.76382 3.2305 9.14542 2.97494C8.52684 2.71921 8.0352 2.22876 7.77773 1.61103C7.77773 1.33308 7.62947 1.07632 7.38884 0.93743C7.14822 0.798541 6.85169 0.798541 6.61107 0.93743C6.37044 1.07632 6.22218 1.33309 6.22218 1.61103C5.96471 2.22872 5.47307 2.71921 4.85449 2.97494C4.23609 3.2305 3.54164 3.2305 2.92324 2.97494C2.30467 2.71921 1.81302 2.22876 1.55556 1.61103C1.55556 1.33308 1.40729 1.07632 1.16667 0.93743C0.92604 0.798541 0.629511 0.798541 0.388889 0.93743C0.148267 1.07632 0 1.33309 0 1.61103V10.1666C0 12.6675 1.33422 14.9786 3.5 16.2288C5.66578 17.4793 8.33422 17.4793 10.5 16.2288C12.6658 14.9785 14 12.6675 14 10.1666V1.61103C14 1.40478 13.9181 1.20686 13.7722 1.06103C13.6264 0.915199 13.4285 0.833252 13.2222 0.833252H13.2221ZM12.4444 10.1666C12.4444 12.1117 11.4067 13.9091 9.72213 14.8817C8.0376 15.8541 5.96222 15.8541 4.27769 14.8817C2.59316 13.9091 1.55547 12.1117 1.55547 10.1666V3.94436C2.23827 4.43063 3.0508 4.70147 3.8888 4.72214C5.07698 4.69367 6.20147 4.17925 6.99991 3.2989C7.79836 4.1793 8.92284 4.6937 10.111 4.72214C10.949 4.70148 11.7616 4.43065 12.4444 3.94436V10.1666ZM10.6633 7.28099C10.8081 7.42682 10.8895 7.62387 10.8895 7.82943C10.8895 8.03481 10.8081 8.23203 10.6633 8.3777L6.7744 12.2666C6.62892 12.415 6.42996 12.4991 6.22213 12.4999H6.16762C5.94227 12.4836 5.73498 12.3701 5.59993 12.1888L3.2666 9.0777C3.09993 8.85547 3.06434 8.56103 3.17354 8.30547C3.28274 8.04992 3.51989 7.87214 3.79576 7.83881C4.07163 7.80565 4.34438 7.92214 4.51105 8.14436L6.30776 10.5398L9.55878 7.28881C9.70375 7.14055 9.90201 7.05634 10.1095 7.05478C10.3168 7.05339 10.5163 7.13482 10.6633 7.28099L10.6633 7.28099Z"
        fill="white"
      />
    </svg>
  );
};
