// Vendors
import React, { useState } from "react";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";

// Components
import ScoreCard from "../ScoreCard/ScoreCard";
import RecordCardTitleRow from "../RecordCardTitleRow/RecordCardTitleRow";
import MainModal from "../MainModal/MainModal";
// import ShareableRecordCard from "../ShareableRecordCard/ShareableRecordCard";

// Styles
import classes from "./RecordCard.module.scss";
import MainButton from "../MainButton/MainButton";

const RecordCard = ({
  recordData,
  clicked,
  showButton,
  recordToShow,
  userData,
  isProfileOwner,
}) => {
  const [openShareModal, setOpenShareModal] = useState(false);

  const handleToggleModal = () => {
    setOpenShareModal((prevOpen) => !prevOpen);
  };

  return (
    <div className={`${classes.recordCardContainer}`}>
      {clicked && showButton && (
        <div className={classes.recordButtonContainer}>
          <MainButton
            icon={recordToShow ? <CloseOutlined /> : <DownOutlined />}
            size="small"
            type="secondary"
            clicked={clicked}
          />
        </div>
      )}
      <RecordCardTitleRow
        showButton={showButton}
        recordData={recordData}
        handleToggleModal={handleToggleModal}
        isProfileOwner={isProfileOwner}
      />
      <div className={classes.recordCardSecondColumn}>
        <div className={classes.recordScoreList}>
          <div className={classes.recordScore}>
            <ScoreCard result="win" score={recordData.wins} />
          </div>
          <div className={classes.recordScore}>
            <ScoreCard result="push" score={recordData.pushes} />
          </div>
          <div className={classes.recordScore}>
            <ScoreCard result="loss" score={recordData.losses} />
          </div>
        </div>
      </div>
      <MainModal
        open={openShareModal}
        footer={null}
        onCancel={handleToggleModal}
        closable={false}
        customTitle="Share it with your friends"
      >
        {/* <ShareableRecordCard recordData={recordData} userData={userData} /> */}
      </MainModal>
    </div>
  );
};

export default RecordCard;
