// Vendors
import React from "react";

const GreenFinishedIcon = ({ size }) => {
  return (
    <svg
      width={size || "48"}
      height={size || "48"}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="10" fill="#44C300" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1101 24.0172L21.0896 27.5208L30.5605 13.586C33.1072 9.84342 38.4398 13.4267 35.9727 17.0895L24.1142 34.7669C22.8408 36.6779 20.5327 36.1207 19.0205 34.7669L12.5742 29.1928C9.2316 26.167 13.6884 21.0708 17.1106 24.0169L17.1101 24.0172Z"
        fill="#0F0F14"
      />
    </svg>
  );
};

export default GreenFinishedIcon;
