// Vendors
import React from "react";

// Styles
import classes from "./ScoreCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const ScoreCard = ({ result, score, icon }) => {
  const text = icon
    ? icon
    : isNaN(score) && globalConstants.RESULT_SYMBOLS[result]
    ? globalConstants.RESULT_SYMBOLS[result]
    : !isNaN(score)
    ? score
    : "-";
  return (
    <div
      className={`${classes.scoreCardContainer} ${
        classes[result?.toLowerCase()]
      } `}
    >
      {text}
    </div>
  );
};

export default ScoreCard;
