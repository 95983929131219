// Vendors
import React from "react";
import { Tooltip } from "antd";

// Styles
import classes from "./IconLabel.module.scss";

// Utils
import { abbreviateString } from "../../utils/globalUtils";

// Assets
import { defaultTeamIcon } from "../../assets/icons/defaultTeam/defaultTeam";

const IconLabel = ({
  bold,
  icon,
  isSvg,
  label,
  inverted,
  size,
  logoSize = "logoSmall",
  justifyEnd,
  withAbbreviation,
  withImageBackground,
  tooltipText,
}) => {
  if (withAbbreviation && label) {
    label = abbreviateString(label, 6);
  }

  const content = (
    <div
      className={`${classes.iconLabelContainer}
${inverted && classes.inverted}
${justifyEnd && classes.justifyEnd}
${bold && classes.bold}
`}
    >
      {!isSvg && icon ? (
        <img
          style={{ marginRight: !label ? "0px" : null }}
          className={`${classes.logo} ${classes[logoSize]} 
          ${withImageBackground && classes.withImageBackground}
          `}
          alt={label}
          src={icon}
        />
      ) : null}
      {isSvg || !icon ? (
        <div
          className={`${classes.logo} ${classes[logoSize]}
          ${withImageBackground && classes.withImageBackground}
          `}
          style={{ marginRight: !label ? "0px" : null }}
        >
          {icon || defaultTeamIcon}
        </div>
      ) : null}
      <div
        className={`
${classes.label}     
${size && classes[size]}
`}
      >
        {label}
      </div>
    </div>
  );

  return tooltipText ? (
    <Tooltip
      placement="top"
      title={<div className={classes.tooltipText}>{tooltipText}</div>}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default IconLabel;
