// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";
import ScoreCard from "../ScoreCard/ScoreCard";
import DividerLine from "../DividerLine/DividerLine";
import ProfilePictureVerificable from "../ProfilePictureVerificable/ProfilePictureVerificable";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./ProfileDiscoveredCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

const ProfileDiscoveredCard = ({ userData, showDivider }) => {
  return (
    <div className={classes.profileDiscoveredCard}>
      <div className={classes.profileDiscoveredContent}>
        <ProfilePictureVerificable
          image={userData.imageUrl}
          size={60}
          username={userData.username}
        />
        <Link
          to={{
            pathname: `/${globalConstants.ROUTES.PROFILE}/${userData.username}/`,
          }}
        >
          <p className={classes.usernameText}>{userData.username}</p>
        </Link>
      </div>

      <div className={classes.endRowContainer}>
        <div className={classes.gameDescriptionContainer}>
          <p className={classes.favoriteGameText}>Fave game</p>
          {userData.favoriteGame ? (
            <IconLabel
              label={globalConstants.TITLES[userData.favoriteGame]}
              icon={esportsLogos[userData.favoriteGame]}
              isSvg
              bold
            />
          ) : null}
        </div>
        <div>
          <div className={classes.scoreList}>
            <ScoreCard score={userData.wins} result="win" />
            <ScoreCard score={userData.pushes} result="push" />
            <ScoreCard score={userData.losses} result="loss" />
          </div>
          <div className={classes.allGamesRecordLabel}>All games record</div>
        </div>
      </div>

      {showDivider && (
        <DividerLine color={styledVariables.backgroundPrimaryE} margin={16} />
      )}
    </div>
  );
};

export default ProfileDiscoveredCard;
