// Vendors
import React, { useState, useEffect } from "react";
import { Outlet, NavLink, useLocation, useNavigate } from "react-router-dom";

// Pages
import EducationHomePage from "../../pages/EducationHomePage/EducationHomePage";

// Components
import MainSelect from "../../components/MainSelect/MainSelect";
import MainButton from "../../components/MainButton/MainButton";

// Styles
import classes from "./EducationPage.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import propbetGuideImageMain from "../../assets/images/education/propbetGuideImageMain.png";
import propbetGuideImageSecondary from "../../assets/images/education/propbetGuideImageSecondary.png";
import spreadGuideImageMain from "../../assets/images/education/spreadGuideImageMain.png";
import spreadGuideImageSecondary from "../../assets/images/education/spreadGuideImageSecondary.png";
import oddsGuideImageMain from "../../assets/images/education/oddsGuideImageMain.png";
import oddsGuideImageSecondary from "../../assets/images/education/oddsGuideImageSecondary.png";
import esportGuideImageMain from "../../assets/images/education/esportGuideImageMain.png";
import esportGuideImageSecondary from "../../assets/images/education/esportGuideImageSecondary.png";
import parlayGuideImageMain from "../../assets/images/education/parlayGuideImageMain.png";
import parlayGuideImageSecondary from "../../assets/images/education/parlayGuideImageSecondary.png";
import losingGuideImageMain from "../../assets/images/education/losingGuideImageMain.png";
import losingGuideImageSecondary from "../../assets/images/education/losingGuideImageSecondary.png";
import liveBettingWithESB from "../../assets/images/education/liveBettingWithESB.png";
import liveBettingWithESB2 from "../../assets/images/education/liveBettingWithESB2.png";
import liveBettingWithESB3 from "../../assets/images/education/liveBettingWithESB3.png";
import projectionsGuideImage from "../../assets/images/education/projectionsGuideImage.png";
import projectionsGuideImage2 from "../../assets/images/education/projectionsGuideImage2.png";
import cdlSportsbookGuideImage from "../../assets/images/education/cdlSportsbookGuideImage.jpg";
import rotoRadarGuideImageMain from "../../assets/images/education/rotoRadarGuideImageMain.png";
import rotoRadarGuideImageSecondary from "../../assets/images/education/rotoRadarGuideImageSecondary.png";
import esportsbetioGuideImageMain from "../../assets/images/education/esportsbetioGuideImageMain.png";
import esportsbetioGuideImageSecondary from "../../assets/images/education/esportsbetioGuideImageSecondary.png";
import cloutFantasyGuideImageMain from "../../assets/images/education/cloutFantasyGuideImageMain.png";
import cloutFantasyGuideImageSecondary from "../../assets/images/education/cloutFantasyGuideImageSecondary.png";
import cloutFantasyGuideImage3 from "../../assets/images/education/cloutFantasyGuideImage3.jpg";

const MOCK_INSTALOCK_IMAGE_URL_SIBBY =
  "https://s3.us-east-1.amazonaws.com/instalockfrontendbucket-prod/1674184489452-1e7fe48e-49cc-481f-8949-a394f0a2495d";

const mockGuidesData = [
  {
    id: 0,
    title: "What is a Prop Bet?",
    isFeatured: false,
    isTop: false,
    game: "",
    mainImage: propbetGuideImageMain,
    secondaryImage: propbetGuideImageSecondary,
    description:
      "In sports betting, and more specifically esports betting, a prop bet stands for proposition bet which is just a bet that is not tied on the actual final result of the match.",
    content: [
      {
        type: "text",
        content:
          "In sports betting, and more specifically esports betting, a prop bet stands for proposition bet which is just a bet that is not tied on the actual final result of the match.",
      },
      {
        type: "text",
        content:
          "The three normal bet types are spreads, moneylines, and totals (over/unders).",
      },
      {
        type: "text",
        content:
          "Prop bets are none of those bet types, they are generally focused on player-specific outcomes.",
      },
      {
        type: "text",
        content:
          "We can imagine a Counter-Strike match of 100 Thieves against Evil Geniuses as an example.",
      },
      {
        type: "text",
        content:
          "If we were to look at prop bets for a player on 100 Thieves for instance, prop bets would be based on the player having above or below a certain amount of kills, maybe headshot skills specifically, maybe bomb defuses, or even smoke grenades used.",
      },
      {
        type: "title",
        content: "League of Legends",
      },
      {
        type: "text",
        content:
          "C9 Blaber Over or Under 3 Assists: Sportsbooks expectation here is that Cloud 9 player Blaber will have between 2-4 assists on average by setting the line for his assists at 3.",
      },
      {
        type: "title",
        content: "VALORANT",
      },
      {
        type: "text",
        content:
          "FNC Boaster Over or Under 12 Headshot Kills: Sportsbooks expectation here is that Fnatic player Boaster will have between 11-13 headshot kills on average.",
      },
      {
        type: "title",
        content: "Rocket League",
      },
      {
        type: "text",
        content:
          "NRG jstn Over or Under 2 Goals: Sportsbooks expectation here is that NRG player jstn will have between 1-3 goals on average.",
      },
      {
        type: "text",
        content: "",
      },
      {
        type: "text",
        content:
          "Prop bets are known really as side bets but there are many bettors that focus specifically on prop betting as it is an entirely different layer to sports betting markets.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 1,
    title: "How Do Spreads in eSports Betting Differ than Sports Betting",
    isFeatured: true,
    isTop: false,
    game: "",
    mainImage: spreadGuideImageMain,
    secondaryImage: spreadGuideImageSecondary,
    description:
      "There is a ton of overlap between sports betting and esports betting as a whole, but it is important to note for those that are accustomed to sports betting that there are key differences in terms of betting on esports fundamentally with spreads.",
    content: [
      {
        type: "text",
        content:
          "There is a ton of overlap between sports betting and esports betting as a whole, but it is important to note for those that are accustomed to sports betting that there are key differences in terms of betting on esports fundamentally with spreads.",
      },
      {
        type: "text",
        content:
          "In traditional sports betting, most sports are played as single games, while in eSports, many matches are played in a best-of-series such as a best of three series where the first team to get to two points wins or a best of five series where the first team to three points wins.",
      },
      {
        type: "title",
        content: "We can take a look at a basketball spread.",
      },
      {
        type: "text",
        content:
          "In basketball, let's imagine a game between the Los Angeles Lakers and the Chicago Bulls and the odds for the match are Lakers -2 and Bulls +2. In this case, Lakers are seen as 2 point favorites and Bulls are seen as 2 point underdogs (a minus spread for anything represents a favorite to win while a plus spread would be for an underdog team). This would mean for a bettor to bet on Lakers -2, the Lakers would need to defeat the Bulls by more than 2 points, and for someone to bet on Bulls +2, the Bulls would need to lose by no more than 2 points (or win) for the bettor to win.",
      },
      {
        type: "title",
        content: "Let’s look at a Counter-Strike spread.",
      },
      {
        type: "text",
        content:
          "In Counter-Strike, let's imagine a match between Faze Clan and Team Liquid. Typically Counter-Strike matches are best of three so let’s go with that here. If the odds for Faze Clan are -1.5 and +1.5 for Team Liquid, this is regarding the series spread as a whole, which means for a bettor to win on Faze -1.5, Faze would need to win the series by more than 1.5 games. It is not possible to win or lose a series by a non-whole number, the only possible scenarios of a best of three series are a 2-0 or 2-1 for either team, so in this case Faze would need to win the series 2-0. For Team Liquid +1.5, Liquid would need to not lose by more than 1.5 games but again you cannot lose a series by a non-whole number, so in this case Liquid just needs to not lose by more than 1 game or win the series themselves.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 2,
    title: "How Are Sportsbook Odds Determined and How Can They Change?",
    isFeatured: false,
    isTop: true,
    game: "",
    mainImage: oddsGuideImageMain,
    secondaryImage: oddsGuideImageSecondary,
    description:
      "Sportsbooks supply bettors with the ability to bet on matches of their choice based on odds that the sportsbook itself provides, but a common question people have is how exactly do sportsbooks determine their odds?",
    content: [
      {
        type: "text",
        content:
          "Sportsbooks supply bettors with the ability to bet on matches of their choice based on odds that the sportsbook itself provides, but a common question people have is how exactly do sportsbooks determine their odds?",
      },
      {
        type: "text",
        content:
          "The reality is that each sportsbook has its own set of databases and analytics that are used in order to determine the implied win probability of a result for a match. This can be based off of a team’s historical record vs the other, player substitutes, even the time of day for a match.",
      },
      {
        type: "text",
        content:
          "Let’s imagine a League of Legends match between Cloud 9 and TSM is happening and a sportsbook sees Cloud 9’s implied chances to win as 70% and TSM’s as 30%.",
      },
      {
        type: "text",
        content:
          "From an odds perspective, this would place odds for Cloud 9 at -233 and TSM at +223 for their Moneyline (the bet type which is based on a team winning outright).",
      },
      {
        type: "text",
        content:
          "However, books take these odds and add their own margin which is called the vig, which is the profit books earn from the match regardless of the outcome.",
      },
      {
        type: "text",
        content:
          "In the scenario above, if the same sportsbook saw Cloud 9 was 4-0 against TSM in their last 4 games, and expected them to receive a large amount of the bets, they raise the odds for Cloud 9 to -350 (implied odds of 77.78%) and TSM to +239 (implied odds of 29.5%).",
      },
      {
        type: "text",
        content:
          "77.78% + 29.5% is an implied probability of 107.28% which a sportsbook wants an implied probability a bit over 100 so that it ensures it has profits over bettors on a longer time span.",
      },
      {
        type: "text",
        content:
          "In terms of odds line movements after they are made, it is all based on where bettors are placing their money. In the above example, if bettors were putting a lot more money on TSM +239, then the book would try to lower TSM’s odds and increase Cloud 9’s odds to try to protect its margin. Events such as player substitutes, maybe team drama, and other external factors can lead to significant changes in a matches line movements.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 3,
    title: "Esport Teams in Multiple Games",
    isFeatured: false,
    isTop: false,
    game: "",
    mainImage: esportGuideImageMain,
    secondaryImage: esportGuideImageSecondary,
    description:
      "Why do I keep seeing the same team names, even in different games? Are they connected at all?",
    content: [
      {
        type: "text",
        content:
          "Why do I keep seeing the same team names, even in different games? Are they connected at all?",
      },
      {
        type: "text",
        content:
          "Yes! Although it would be amazing if the same roster played all the games, each specific game has their own roster for each team organization. It would be similar to the Bulls and the White Sox both being formerly owned by Jerry Reinsdorf, but instead of a person, a parent company houses the rosters for each game.",
      },
      {
        type: "text",
        content:
          "For example, Cloud9 has rosters in many different games, like both Valorant and Apex Legends. The only similarities between these rosters is really the jerseys and sponsorships – the teams function independently and work with their respective staff. All rosters work with Cloud9 for things like merchandising, media shoots, and more.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 4,
    title: "What are Parlays/Combos?",
    isFeatured: false,
    isTop: false,
    game: "",
    mainImage: parlayGuideImageMain,
    secondaryImage: parlayGuideImageSecondary,
    description:
      "A parlay is simply a combination of bets bundled together as one. These types of bets typically are riskier and pay out higher, and thus are not usually recommended for beginners. Instead of having two separate bets on a team, you would have one bet on two team’s outcomes. To place a parlay or combo you can simply click on additional bets to your slip before you submit it and it will automatically convert to a combo bet. Some sites may require you to navigate to a parlay section and submit your picks there. Still unsure on what a parlay looks like? Let’s use the Call of Duty League as an example.",
    content: [
      {
        type: "text",
        content:
          "A parlay is simply a combination of bets bundled together as one. These types of bets typically are riskier and pay out higher, and thus are not usually recommended for beginners. Instead of having two separate bets on a team, you would have one bet on two team’s outcomes. To place a parlay or combo you can simply click on additional bets to your slip before you submit it and it will automatically convert to a combo bet. Some sites may require you to navigate to a parlay section and submit your picks there. Still unsure on what a parlay looks like? Let’s use the Call of Duty League as an example.",
      },
      {
        type: "text",
        content:
          "On the sportsbook, you see Seattle Surge at odds of 1.76 vs London Ravens at odds of 1.99. The next game is the Florida Mutineers at 2.76 vs Rokkr at 1.41. If you believe BOTH Surge and Rokkr will win their games, instead of taking them separately at 1.76 and 1.41, you can parlay or combo your bet slip.",
      },
      {
        type: "text",
        content: `The betslip odds depend on a few factors, such as the sportsbook, the odds of the games you’ve chosen, and when you place it. In this example, on Thunderpick the combo odds would be 2.48 for a Surge and Rokkr win.`,
      },
      {
        type: "text",
        content:
          "You may have noticed the payout of winning the combo bet would be a bit higher than winning one of the bets. It's not all rainbows though; if a single pick in your combo doesn’t win, usually your entire combo will be void (depending on sportsbook). Parlays can add an additional element of excitement and risk, but are not for the light of heart. New bettors tend to overuse parlays, so err on the side of caution.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 5,
    title: "Losing Streaks",
    isFeatured: false,
    isTop: false,
    game: "",
    mainImage: losingGuideImageMain,
    secondaryImage: losingGuideImageSecondary,
    description:
      "Losing streaks happen in every competition. From ranked games to betting, it can come at any time. Let’s go over the most important things to keep in mind if you can’t seem to find a win in your slips.",
    content: [
      {
        type: "text",
        content:
          "Losing streaks happen in every competition. From ranked games to betting, it can come at any time. Let’s go over the most important things to keep in mind if you can’t seem to find a win in your slips.",
      },
      {
        type: "title",
        content: "Don’t overbet after a loss",
      },
      {
        type: "text",
        content: `Don’t chase future games because of a loss you suffered. Remind yourself that you bet a certain amount on this game for a reason, and overbetting without doing proper research is a quick way to lose more.`,
      },
      {
        type: "title",
        content: "Take a break",
      },
      {
        type: "text",
        content:
          "Some time away from the betslip lets you come back fully refreshed and focused, rather than emotional after a loss. It also gives you more time to do research and track how well teams are doing recently.",
      },
      {
        type: "title",
        content: "Reevaluate past bets",
      },
      {
        type: "text",
        content:
          "Why did this specific bet lose? Did I choose good odds? Is there anything I could have done differently on the research side? What about the bets I won? What did I do right?",
      },
      {
        type: "title",
        content: "Understand not every bet is a win",
      },
      {
        type: "text",
        content:
          "Losses happen. Anyone who's played a ranked game knows some games are just unwinnable. If the best bettors have bad beats, you will too unfortunately. Trust your model and adapt as needed.",
      },
      {
        type: "title",
        content: "Join a betting community",
      },
      {
        type: "text",
        content:
          "Ask others if they bet on a game you watched. If they did and won, ask them politely for their rationale and see if a different perspective would be able to benefit you.",
      },
      {
        type: "title",
        content: "Focus on a smaller pool of games",
      },
      {
        type: "text",
        content:
          "Only bet in the games you have the most conviction in. Narrowing your research pool will give you more time to go in depth for these games and hopefully increase your win rate.",
      },
      {
        type: "text",
        content: " ",
      },
      {
        type: "text",
        content:
          "Using a mix of these tools will help elevate your betting and stop strong losing streaks. Understanding why these losses happen is key in breaking the pattern. Don’t focus on things you cannot control, and maximize the things you can.",
      },
    ],
    user: {
      image: MOCK_INSTALOCK_IMAGE_URL_SIBBY,
      username: "sibby",
      staff: "instalock",
    },
    date: "01/20/2023",
  },
  {
    id: 6,
    title: "What are projections in esports betting (CS:GO)",
    isFeatured: false,
    isTop: false,
    game: "",
    isPartnered: true,
    mainImage: rotoRadarGuideImageMain,
    secondaryImage: rotoRadarGuideImageSecondary,
    description:
      "Hey everyone! My name is Anthony, also known as LinkDFS. I provide CSGO projection models which can be used on various prop sites. For those new to esports, I’m here to explain the general principles of the model and how they can be used.",
    content: [
      {
        type: "text",
        content:
          "Hey everyone! My name is Anthony, also known as LinkDFS. I provide CSGO projection models which can be used on various prop sites. For those new to esports, I’m here to explain the general principles of the model and how they can be used.",
      },
      {
        type: "title",
        content: "What are CSGO projections?",
      },
      {
        type: "text",
        content:
          "CSGO projections are compiled by using sportsbook odds and player statistics to formulate an estimate of a future outcome based on using current data, also known as a projection. Often in CSGO, Kills and Headshots are the most commonly known and used projections. You can use the projections on sites like PrizePicks, Underdog, VividPicks, and more which offer props for CSGO.",
      },
      {
        type: "title",
        content: "Why are projections useful?",
      },
      {
        type: "text",
        content:
          "Projections save a TON of time. Why try and spend the time doing all the math manually inside of your head or on a notepad when you can use the projections that compile everything into one easy place to read!",
      },
      {
        type: "title",
        content: "How do you use the projections?",
      },
      {
        type: "image",
        content: projectionsGuideImage,
        size: 80,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "title",
        content: "First, let’s start with what each column header means.",
      },
      {
        type: "text",
        content: "Player - The name of the person in-game.",
      },
      {
        type: "text",
        content: "TM - The team that the player is on.",
      },
      {
        type: "text",
        content:
          "Odds - The sportsbook odds of the players team for the matchup.",
      },
      {
        type: "text",
        content:
          "K Line - The line that the players kill line is on the prop book.",
      },
      {
        type: "text",
        content:
          "HS Line - The line that the players headshot line is on the prop book.",
      },
      {
        type: "text",
        content: "K Proj - The kill projection for the player.",
      },
      {
        type: "text",
        content:
          "Diff - The difference between the line set by the prop book and the projection from the model.",
      },
      {
        type: "text",
        content: "HS Proj - The headshot projection for the player.",
      },
      {
        type: "text",
        content:
          "Diff - The difference between the line set by the prop book and the projection from the model.",
      },
      {
        type: "text",
        content: "",
      },
      {
        type: "text",
        content:
          "The way I use the model is to find the biggest differences (positive or negative). For example, Sidney has a +3.29 for his K Proj so I’d take his Over 33.5 kills because the model is projecting him to have 36.79 kills.",
      },
      {
        type: "text",
        content:
          "Another example is Cabbi. He has a -2.50 for his K Proj so I’d take him Under 38.5 because the model is projecting him to have 36 kills.",
      },
      {
        type: "title",
        content: "Where can I watch the games?",
      },
      {
        type: "text",
        content:
          "HLTV.org has everything that you’ll ever need for CSGO. Under the matches tab, you can click on the matchup you’re looking to watch. Once you click on the matchup, you have two options. Option one is you can select a stream to watch the game which is located under the “Watch” section. Option two is there will be a live kill feed on HLTV that will populate which is essentially a live box score. This will appear on the games match page once the game starts.",
      },
      {
        type: "image",
        content: projectionsGuideImage2,
        size: 80,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "title",
        content: "Where Can I Find the Projections?",
      },
      {
        type: "text",
        content:
          "The projection models can be found in two different places. The first place they can be found is on my Twitter timeline. The second place they can be found in the #models channel under the “RotoRadar” category in the RotoRadar discord.",
      },
      {
        type: "title",
        content: "What is RotoRadar?",
      },
      {
        type: "text",
        content:
          "RotoRadar has been providing premium content, lineups, and advice from full-time DFS Pros since 2015. In addition, RotoRadar provides free prop content inside of their discord that hosts over 14,000 members.",
      },
      {
        type: "text",
        content:
          "Disclaimer: Due to data limitations within the sport, map projections can not be included in the projections. If you’re looking for map projection analysis, check out my twitch channel where I often do CSGO streams where I talk about map projections!",
      },
      {
        type: "link",
        content: "LinkDFS's Twitter",
        url: "https://twitter.com/LinkDFS",
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "Li_nkdfs's Twitch",
        url: "https://www.twitch.tv/li_nkdfs",
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "HLTV",
        url: globalConstants.SPORTS_BOOKS_LINKS.HLTV,
        alignment: "center",
        size: 32,
      },
      {
        type: "text",
        content: "",
        size: 100,
      },
      {
        type: "link",
        content: "RotoRadar's Discord",
        url: "http://Discord.gg/RotoRadar",
        alignment: "center",
        size: 24,
      },
      {
        type: "link",
        content: "RotoRadar's Twitter",
        url: "https://twitter.com/rotoradar",
        alignment: "center",
        size: 24,
      },
      {
        type: "link",
        content: "RotoRadar's CEO",
        url: "https://twitter.com/justroto",
        alignment: "center",
        size: 24,
      },
      {
        type: "link",
        content: "RotoRadar's Tiktok",
        url: "https://tiktok.com/rotoradar",
        alignment: "center",
        size: 24,
      },
    ],
    user: {
      image:
        "https://s3.us-east-1.amazonaws.com/instalockfrontendbucket-prod/1674247281008-61c08734-35e1-4dd3-ad7c-b466da597028",
      username: "rotoRadar",
      staff: "rotoRadar",
    },
    date: "01/20/2023",
  },
  {
    id: 7,
    title: "What is Daily Fantasy Esports? With Clout Fantasy",
    isFeatured: false,
    isTop: false,
    game: "",
    isPartnered: true,
    mainImage: cloutFantasyGuideImageMain,
    secondaryImage: cloutFantasyGuideImageSecondary,
    description:
      'Since 2007, "Daily Fantasy" has grown into a new intuitive way aside from sports books, to place a single wager on multiple outcomes of sporting events. Due to the rise of traditional "Daily Fantasy", Daily Fantasy Esports has emerged. Daily Fantasy Esports is a new way to wager on competitive video games. Instead of answering one single question on the outcome of one match, Daily Fantasy Esports allows you to answer at least 2 questions on the outcome of an event between two teams whether it may be CS:GO, LoL, Valorant, or a different competitive event. There are many forms of Daily Fantasy Esports including progressive prediction, lineup based models, and prop based questions. For example, a typical Daily Fantasy Esports wager for a match in CS:GO can mean you predicting the amount of headshots Player X will get and how many kills Team Y will end up with. The main goal is to answer the most questions correctly.',
    content: [
      {
        type: "text",
        content:
          'Since 2007, "Daily Fantasy" has grown into a new intuitive way aside from sports books, to place a single wager on multiple outcomes of sporting events. Due to the rise of traditional "Daily Fantasy", Daily Fantasy Esports has emerged. Daily Fantasy Esports is a new way to wager on competitive video games. Instead of answering one single question on the outcome of one match, Daily Fantasy Esports allows you to answer at least 2 questions on the outcome of an event between two teams whether it may be CS:GO, LoL, Valorant, or a different competitive event. There are many forms of Daily Fantasy Esports including progressive prediction, lineup based models, and prop based questions. For example, a typical Daily Fantasy Esports wager for a match in CS:GO can mean you predicting the amount of headshots Player X will get and how many kills Team Y will end up with. The main goal is to answer the most questions correctly.',
      },
      {
        type: "text",
        content:
          "The popularity in Daily Fantasy Esports has grown rapidly as it has become a place where you can place a single wager on multiple outcomes of 1 specific match, giving the user a more immersive way to enjoy watching their favorite title or cheering on their favorite team.",
      },
      {
        type: "text",
        content:
          "Lets take a look at where you can play Daily Fantasy Esports in 2023...",
      },
      {
        type: "title",
        content: "Introducing: Clout Fantasy",
      },
      {
        type: "text",
        content:
          "Clout Fantasy is the Hub of Daily Fantasy Esports where users have the opportunity to place wagers on titles such as CS:GO, League of Legends, Valorant, & more. Currently live in North America, users compete against one another by answering 4 specific questions related to the event and earn points by answering questions correctly. The user who gets the most points/answers the most questions correctly, will ultimately win the contest. In the Clout Community, any user has the opportunity to earn cash daily, win promo weekly, and claim prizes monthly.",
      },
      {
        type: "text",
        content:
          "Additionally, all new users on Clout Fantasy begin with a $5 Bonus & a Match Deposit up to $100 to help get them started. Clout Fantasy is the one stop shop where you acquire knowledge, make picks, stream matches, and track your performance all in one.",
      },
      {
        type: "image",
        content: cloutFantasyGuideImage3,
        size: 60,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "title",
        content: "Check out Clout Fantasy’s Links Below:",
      },
      {
        type: "link",
        content: "Clout Fantasy",
        url: globalConstants.SPORTS_BOOKS_LINKS.CLOUT_FANTASY,
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "Twitter",
        url: "https://twitter.com/cloutfantasy",
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "Discord",
        url: "https://t.co/hhl1b6SLkv",
        alignment: "center",
        size: 32,
      },
    ],
    user: {
      image:
        "https://s3.us-east-1.amazonaws.com/instalockfrontendbucket-prod/1674248198463-8b5e2d8b-5703-455e-abc3-76d3c8fc2e37",
      username: "cloutfantasy",
      staff: "cloutFantasy",
    },
    date: "01/20/2023",
  },
  {
    id: 8,
    title: "Back to School: Live Betting with ESB.io",
    isFeatured: false,
    isTop: false,
    game: "",
    isPartnered: true,
    mainImage: esportsbetioGuideImageMain,
    secondaryImage: esportsbetioGuideImageSecondary,
    description:
      "Live betting is one of the most popular forms of betting in Esports. A live bet is one that is placed on an ongoing match or one that has just entered the draft phase. This occurs after the odds have adjusted to any new information available. Esports fans that are more in-tune with the meta are typically drawn to this form of betting, watching and analyzing live matches. When watching your favorite team play in major competitions, there’s no better way to increase the excitement than placing a live bet and following the results. Statistics show that more than 90% of Esports bettors placed at least one live bet in their betting careers.",
    content: [
      {
        type: "text",
        content:
          "Live betting is one of the most popular forms of betting in Esports. A live bet is one that is placed on an ongoing match or one that has just entered the draft phase. This occurs after the odds have adjusted to any new information available. Esports fans that are more in-tune with the meta are typically drawn to this form of betting, watching and analyzing live matches. When watching your favorite team play in major competitions, there’s no better way to increase the excitement than placing a live bet and following the results. Statistics show that more than 90% of Esports bettors placed at least one live bet in their betting careers.",
      },
      {
        type: "title",
        content: "How do I place a live bet on EsportsBet?",
      },
      {
        type: "text",
        content:
          "The most popular games and titles for live betting on Esportsbet.io (“ESB”) are League of Legends, Counter Strike, and Dota 2. Live betting is simple to locate on ESB as it’s one of the most popular betting types. One thing to note is that it’s always highlighted on top of the gamehall after clicking the Esports section on the home page. As long as there’s an ongoing match, the available live bets are visible on top of the match list.",
      },
      {
        type: "image",
        content: liveBettingWithESB,
        size: 80,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "text",
        content:
          "If not, users can open live bets by clicking on the “Live Odds” button under the Esports menu, as shown on the image below.",
      },
      {
        type: "image",
        content: liveBettingWithESB2,
        size: 80,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "text",
        content:
          "You can place a live bet across almost every major esports genre as well as a multitude of other match types. Do note that not all markets are open at all times during live matches. While new information and match states are being digested, the available live markets will close to allow the odds to catch up before being available again.",
      },
      {
        type: "text",
        content:
          "When you open a live match on ESB, you will see green and red arrows appearing on some of the odds markets. The reason for this is that odds in live markets are constantly fluctuating, depending on a situation within the game. In those situations, following the match live is crucial before placing a live bet, but only if you think some outcome is possible.",
      },
      {
        type: "image",
        content: liveBettingWithESB3,
        size: 80,
        alignment: "center",
        isAlone: true,
      },
      {
        type: "text",
        content:
          "Live matches and markets are always marked with a timer that denotes the current time in that match with a live score. When map 1 finishes, all map 1 markets will close entirely, preventing any new bets from being placed. Only subsequent maps will have any available markets to place bets on.",
      },
      {
        type: "title",
        content: "The most popular live Esports markets are:",
      },
      {
        type: "text",
        content:
          "1) Map Winner - after seeing draft (for example in games like League of Legends and Dota 2)",
      },
      {
        type: "text",
        content:
          "2) First to 10 kills - after seeing draft (for example in games like League of Legends and Dota 2)",
      },
      {
        type: "text",
        content:
          "3) Pistol Round Winner - after map veto (for example in CS:GO)",
      },
      {
        type: "text",
        content: "",
      },
      {
        type: "text",
        content:
          "When it comes to real-time Esports live betting, it’s essential to know exactly what’s going on in your chosen match. We all know how events in the Esports world can be quite unpredictable, so there’s no point in placing a live bet without the most up-to-date data available. Thankfully, the best Esports betting sites such as ESB.io keep the current score of your favorite Esports matches clearly visible so that placing a live bet or even increasing your stakes in accordance to the events in the match is as easy as possible. ESB provides the latest data right alongside the available odds markets to make sure you’re provided with the most insight possible before making a decision.",
      },
      {
        type: "title",
        content: "About ESB.io",
      },
      {
        type: "link",
        content: "BET HERE",
        url: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
      },
      {
        type: "text",
        content:
          "Esportsbet.io is the world's premier all-crypto Esports gaming community and entertainment platform. With live odds and a dedicated user base, ESB is the best place for all your gaming needs. Esportsbet is the only platform to provide transparency into its odds creation algorithms through its embedded statistical matrix, featuring the widest range of data on any matches, teams, players, and more all in one place. ESB.io features all of the top esports games like League of Legends, Counter Strike, Dota 2, Overwatch, Wild Rift, Call of Duty, Rainbow Siege, Kings of Glory and many others.",
      },
    ],
    user: {
      image:
        "https://s3.us-east-1.amazonaws.com/instalockfrontendbucket-prod/1674253514554-32f9c89a-6e39-49f5-b899-dd8ab559f99b",
      username: "esportsbet",
      staff: "esportsbet",
    },
    date: "01/20/2023",
  },
  {
    id: 9,
    title: "",
    isFeatured: false,
    isTop: false,
    game: "",
    isPartnered: true,
    mainImage: cdlSportsbookGuideImage,
    secondaryImage: null,
    description: "",
    content: [
      {
        type: "video",
        content: "pAjL-h3oMdQ",
      },
      {
        type: "title",
        content: "What is CDL Sportsbook?",
      },
      {
        type: "text",
        content:
          "CDL Sportsbook is a social esports community based of love and passion for the competitive Call of Duty Scene. Smittyy and Mark, off a whim idea from college, created an inclusive platform for fans and Call of Duty viewers alike to find a community of people with similar interests to interact, chat, laugh, and sweat with when watching the game we all know and love.",
      },
      {
        type: "title",
        content: "Links:",
      },

      {
        type: "link",
        content: "Twitch",
        url: "https://www.twitch.tv/cdlsportsbook",
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "YouTube",
        url: "https://www.youtube.com/@CDLSportsbook",
        alignment: "center",
        size: 32,
      },
      {
        type: "link",
        content: "Twitter",
        url: "https://twitter.com/CDLSportsbook",
        alignment: "center",
        size: 32,
      },
    ],
    user: {
      image:
        "https://s3.us-east-1.amazonaws.com/instalockfrontendbucket-prod/1674251196433-2bd8e11e-dfff-4328-ae79-fb3faed6d352",
      username: "cdlsportsbook",
      staff: "CDLSportsbook",
    },
    date: "01/20/2023",
  },
];

const EducationPage = ({ title }) => {
  const location = useLocation();
  const navigation = useNavigate();

  const pathnameRoutes = location.pathname.split("/");

  const isHomeRoute =
    pathnameRoutes[pathnameRoutes.length - 1] === "education" ||
    pathnameRoutes[pathnameRoutes.length - 1] === "";

  const isActiveRoute = (itemKey) => {
    if (!itemKey) {
      return !!isHomeRoute;
    }
    return location.pathname.includes(itemKey);
  };

  const getCurrentRoute = () => {
    const matchRoute = globalConstants.EDUCATION_MENU_ITEMS.find((route) => {
      if (!route.key) {
        return false;
      }

      return location.pathname.includes(route.key);
    });

    if (matchRoute) {
      return matchRoute;
    } else {
      return globalConstants.EDUCATION_MENU_ITEMS[0];
    }
  };

  const [currentValue, setCurrentValue] = useState(getCurrentRoute());

  const goToRoute = (item) => {
    navigation(`/education/${item.key}`);
  };

  useEffect(() => {
    if (currentValue) {
      goToRoute(currentValue);
    } else {
      goToRoute({ key: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const mainGuide = mockGuidesData.find((guide) => guide.isFeatured);
  const topGuide = mockGuidesData.find((guide) => guide.isTop);
  const partneredGuides = mockGuidesData.filter((guide) => guide.isPartnered);

  return (
    <div className={classes.educationPageContainer}>
      <nav className={classes.navBarDesktop}>
        {globalConstants.EDUCATION_MENU_ITEMS.map((item) => {
          return (
            <NavLink key={item.key} to={`${item.key}`}>
              <MainButton
                clicked={() => setCurrentValue(item)}
                buttonText={item.label}
                icon={esportsLogos[item.key]}
                type={isActiveRoute(item.key) ? "primary" : "tertiary"}
              />
            </NavLink>
          );
        })}
      </nav>
      <div className={classes.navBarMobile}>
        <MainSelect
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          options={globalConstants.EDUCATION_MENU_ITEMS}
        />
      </div>

      <div className={classes.educationSubPageContainer}>
        {isHomeRoute ? (
          <EducationHomePage
            topGuide={topGuide}
            mainGuide={mainGuide}
            partneredGuides={partneredGuides}
          />
        ) : (
          <Outlet
            context={{
              guides: mockGuidesData,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EducationPage;
