// Vendors
import React, { useState, useContext, useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";

// Components
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import RecordCard from "../../components/RecordCard/RecordCard";
import RecordBetCardList from "../../components/RecordBetCardList/RecordBetCardList";
import BiggestInstalockCard from "../../components/BiggestInstalockCard/BiggestInstalockCard";
import ProfileStatus from "../../components/ProfileStatus/ProfileStatus";
import ProfileTeamStats from "../../components/ProfileTeamStats/ProfileTeamStats";
import EditProfile from "../../components/EditProfile/EditProfile";
import Loading from "../../components/Loading/Loading";
import VerifyBets from "../../components/VerifyBets/VerifyBets";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// API
import { requestUserData } from "../../api/requests/requests";

// Styles
import classes from "./ProfilePage.module.scss";

// Assets
import leagueoflegendsImage from "../../assets/images/esports/leagueOfLegends/leagueOfLegends.png";
import valorantImage from "../../assets/images/esports/valorant/valorant.png";
import csgoImage from "../../assets/images/esports/csgo/csgo.png";
import rockerleagueImage from "../../assets/images/esports/rocketLeague/rocketLeague.png";
import callOfDutyImage from "../../assets/images/esports/callOfDuty/callOfDuty.png";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const imageSelector = {
  leagueOfLegends: leagueoflegendsImage,
  valorant: valorantImage,
  csgo: csgoImage,
  rocketLeague: rockerleagueImage,
  callOfDuty: callOfDutyImage,
};

const ProfilePage = () => {
  const params = useParams();

  const navigate = useNavigate();

  const [requestedUserData, setRequestedUserData] = useState(null);
  const [loadingrequestedUserData, setLoadingRequestedUserData] =
    useState(true);
  const [recordToShow, setRecordToShow] = useState(null);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);

  const { userSession, preferredOddsType } = useContext(UserContext);

  const isProfileOwner = useMemo(() => {
    return params.id === userSession?.accessToken.payload.username;
  }, [params, userSession]);

  const toggleOpenRecordGameList = (recordType) => {
    if (recordType) {
      setRecordToShow(recordType);
    } else {
      setRecordToShow(null);
    }
  };

  const handleToggleEditProfileModalModal = () => {
    setShowEditProfileModal(!showEditProfileModal);
  };

  const handleGetUserData = () => {
    requestUserData(
      params.id,
      (data) => {
        setRequestedUserData(data);
        setLoadingRequestedUserData(false);
      },
      () => {
        setLoadingRequestedUserData(false);
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  const handleSetUserData = (data) => {
    setRequestedUserData((prevData) => ({ ...prevData, ...data }));
  };

  const [betVerificationList, setBetVerificationList] = useState([]);
  const [showBetVerification, setShowBetVerification] = useState(false);

  useEffect(() => {
    if (requestedUserData) {
      setBetVerificationList(
        requestedUserData?.betHistorySummary?.record?.bets?.bets.filter(
          (bet) => {
            return (
              bet.verificationStatus !== globalConstants.VERIFIED &&
              bet.verificationStatus !== globalConstants.PENDING
            );
          }
        )
      );
    }
  }, [requestedUserData]);

  const handleToggleBetToVerify = (betId) => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      if (currentBet.betId === betId) {
        currentBet.isSelected = !currentBet.isSelected;
      }

      return currentBet;
    });
    setBetVerificationList(newBetList);
  };

  const handleOpenBetsToVerify = (bet) => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      if (currentBet.betId === bet.betId) {
        currentBet.isSelected = true;
      }

      return currentBet;
    });
    setBetVerificationList(newBetList);
    setShowBetVerification(true);
  };

  const handleCloseBetsToVerify = () => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      currentBet.isSelected = false;
      return currentBet;
    });

    setBetVerificationList(newBetList);
    setShowBetVerification(false);
  };

  const handleRemoveBetToVerify = (bet) => {
    const newBetList = [...betVerificationList].map((currentBet) => {
      if (bet.betId === currentBet.betId) {
        currentBet.isSelected = false;
      }
      return currentBet;
    });

    setBetVerificationList(newBetList);
  };

  const generateRecordData = (recordsData) => {
    return Object.entries(recordsData).map((recordData) => {
      const recordType = recordData[0];
      const recordStats = recordData[1];
      return {
        type: recordType,
        wins: recordStats.wins,
        pushes: recordStats.pushes,
        losses: recordStats.losses,
        roi: recordStats.roi?.toFixed(2),
        bets: [],
      };
    });
  };

  const generateTeamStats = (teamsData) => {
    return teamsData.map((teamData) => {
      return {
        logo: teamData.imageSmall,
        teamAbbreviation: teamData.teamAbbreviation,
        teamName: teamData.teamName,
        winrate: (
          (100 * teamData.wins) /
          ((teamData.wins || 0) +
            (teamData.pushes || 0) +
            (teamData.losses || 0))
        ).toFixed(2),
      };
    });
  };

  const generateEsportsBreakdown = (esportsData) => {
    const sortedEsportsData = {
      leagueOfLegends: esportsData.leagueOfLegends,
      csgo: esportsData.csgo,
      valorant: esportsData.valorant,
      rocketLeague: esportsData.rocketLeague,
      callOfDuty: esportsData.callOfDuty,
    };
    return Object.entries(sortedEsportsData).map((mappedEsport) => {
      const esport = mappedEsport[0];
      const esportStats = mappedEsport[1];
      return {
        name: esport,
        roi: esportStats.roi.toFixed(2),
        wins: esportStats.wins,
        pushes: esportStats.pushes,
        losses: esportStats.losses,
      };
    });
  };

  useEffect(() => {
    setLoadingRequestedUserData(true);
    handleGetUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const filterRecordssByDate = (betList) => {
    if (!betList) {
      return [];
    }
    const lastSevenDays = [];
    const lastThirtyDays = [];
    const allTime = [];
    betList.forEach((bet) => {
      const dateDif = differenceInDays(new Date(), new Date(bet.createdDate));
      if (dateDif <= 7) {
        lastSevenDays.push(bet);
        lastThirtyDays.push(bet);
        allTime.push(bet);
      } else if (dateDif <= 30) {
        lastThirtyDays.push(bet);
        allTime.push(bet);
      } else {
        allTime.push(bet);
      }
    });
    return {
      lastSevenDays: lastSevenDays,
      lastThirtyDays: lastThirtyDays,
      allTime: allTime,
    };
  };

  const dateFilteredRecords = useMemo(
    () =>
      filterRecordssByDate(
        requestedUserData?.betHistorySummary?.record?.bets?.bets
      ),
    [requestedUserData]
  );

  return !loadingrequestedUserData ? (
    <div className={classes.profilePageContainer}>
      <div className={classes.profilePageWrapper}>
        {requestedUserData ? (
          <ProfileCard
            userData={requestedUserData}
            isProfileOwner={isProfileOwner}
            handleToggleModal={handleToggleEditProfileModalModal}
          />
        ) : null}
        <EditProfile
          userSession={userSession}
          requestedUserData={requestedUserData}
          showEditProfileModal={showEditProfileModal}
          setShowEditProfileModal={setShowEditProfileModal}
          handleSetUserData={handleSetUserData}
        />
        <div className={classes.detailColumnsContainer}>
          <div className={classes.leftColumnContainer}>
            <div className={classes.columnTitle}>Activity</div>

            {isProfileOwner ||
            (requestedUserData?.statusMessage &&
              requestedUserData?.statusDate) ||
            requestedUserData.betHistorySummary?.betLog?.length > 0 ? (
              <ProfileStatus
                handleSetUserData={handleSetUserData}
                isProfileOwner={isProfileOwner}
                preferredOddsType={preferredOddsType}
                requestedUserData={requestedUserData}
                setRequestedUserData={setRequestedUserData}
              />
            ) : (
              <div className={classes.noActionContainer}>No Activity . . .</div>
            )}
          </div>
          <div className={classes.middleColumnContainer}>
            <div className={classes.columnTitle}>Records</div>
            <div className={classes.recordContentContainer}>
              {generateRecordData(
                requestedUserData?.betHistorySummary?.record
              ).map((record, index) => {
                return globalConstants.RECORD_TYPE[record.type] ? (
                  <div
                    className={classes.recordCardProfilePageContainer}
                    key={`Record-${record.type}-${index}`}
                  >
                    <RecordCard
                      clicked={() => toggleOpenRecordGameList(record.type)}
                      showButton={dateFilteredRecords[record.type]?.length > 0}
                      recordData={record}
                      arrowUpStatus={recordToShow}
                      preferredOddsType={preferredOddsType}
                      userData={requestedUserData}
                      isProfileOwner={isProfileOwner}
                    />
                  </div>
                ) : null;
              })}
              {recordToShow && (
                <div className={classes.recordGameList}>
                  <RecordBetCardList
                    recordData={
                      requestedUserData?.betHistorySummary?.record?.[
                        recordToShow
                      ]
                    }
                    recordBets={dateFilteredRecords[recordToShow]}
                    handleClose={() => toggleOpenRecordGameList()}
                    preferredOddsType={preferredOddsType}
                    recordToShow={recordToShow}
                    handleOpenBetsToVerify={handleOpenBetsToVerify}
                    isProfileOwner={isProfileOwner}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={classes.rightColumnContainer}>
            <div className={classes.columnTitle}>Biggest Instalock</div>
            {requestedUserData.betHistorySummary.biggestInstalock ? (
              <BiggestInstalockCard
                instalockData={
                  requestedUserData.betHistorySummary.biggestInstalock
                }
                preferredOddsType={preferredOddsType}
              />
            ) : (
              <div className={classes.noActionContainer}>
                No biggest instalock . . .
              </div>
            )}
          </div>
        </div>
        <div className={classes.detailColumnsContainer}>
          {requestedUserData?.betHistorySummary?.breakdown ? (
            <div className={classes.statsColumnContainer}>
              <div className={classes.columnTitle}>Breakdown by eSport</div>
              <div className={classes.breakDownContentContainer}>
                {generateEsportsBreakdown(
                  requestedUserData?.betHistorySummary?.breakdown
                ).map((record, index) => {
                  return (
                    <div
                      className={classes.esportBreakdownCard}
                      key={globalConstants.TITLES[record.name]}
                    >
                      <div className={classes.esportBreakdownImageContainer}>
                        <img
                          src={imageSelector[record.name]}
                          alt={record.name}
                        />
                      </div>
                      <RecordCard
                        clicked={() => toggleOpenRecordGameList(index)}
                        recordData={{
                          ...record,
                          type: globalConstants.TITLES[record.name],
                        }}
                        arrowUpStatus={recordToShow}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div className={classes.statsColumnContainer}>
            <div className={classes.columnTitle}>Best / Worst teams</div>
            {requestedUserData?.betHistorySummary?.teams?.length > 0 ? (
              <div className={classes.bestWorstContainer}>
                <ProfileTeamStats
                  teamStats={generateTeamStats(
                    requestedUserData.betHistorySummary.teams
                  )}
                />
              </div>
            ) : (
              <div className={classes.noActionContainer}>
                No team data . . .
              </div>
            )}
          </div>
        </div>
      </div>
      <VerifyBets
        betVerificationList={betVerificationList}
        showBetVerification={showBetVerification}
        handleCloseBetsToVerify={handleCloseBetsToVerify}
        handleToggleBetToVerify={handleToggleBetToVerify}
        preferredOddsType={preferredOddsType}
        handleRemoveBetToVerify={handleRemoveBetToVerify}
        username={userSession?.accessToken.payload.username}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default ProfilePage;
