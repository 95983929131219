// Vendors
import React, { useContext, useEffect } from "react";

// Context
import { CarouselGamesContext } from "../../context/contextProviders/CarouselContextProvider";

// Components
import EsportGameCard from "../../components/EsportGameCard/EsportGameCard";

// Styles
import classes from "./EsportsPage.module.scss";

// Assets
import leagueOfLegendsImage from "../../assets/images/esports/leagueOfLegends/leagueOfLegends.png";
import valorantImage from "../../assets/images/esports/valorant/valorant.png";
import csgoImage from "../../assets/images/esports/csgo/csgo.png";
import rockerLeagueImage from "../../assets/images/esports/rocketLeague/rocketLeague.png";
import callOfDutyImage from "../../assets/images/esports/callOfDuty/callOfDuty.png";

const esportImageSelector = (esport) => {
  const images = {
    leagueOfLegends: leagueOfLegendsImage,
    valorant: valorantImage,
    csgo: csgoImage,
    rocketLeague: rockerLeagueImage,
    callOfDuty: callOfDutyImage,
  };
  return images[esport];
};

const EsportsPage = ({ title }) => {
  const { carouselData } = useContext(CarouselGamesContext);

  const generateEsportsData = (data) => {
    return Object.entries(data).map((game) => {
      const esportName = game[0];
      const esportData = game[1];
      return {
        name: esportName,
        ...esportData,
        image: esportImageSelector(esportName),
      };
    });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className={classes.esportsPageContainer}>
      {generateEsportsData(carouselData).map((esport) => {
        return <EsportGameCard key={esport.name} esport={esport} />;
      })}
    </div>
  );
};

export default EsportsPage;
