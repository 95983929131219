// Vendors
import React from "react";

export const instalockBlueIcon = (
  <svg
    width="100%"
    height="auto"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59418 0C8.58782 0 9.49207 0.408816 10.1463 1.06841C10.8005 1.728 11.206 2.63837 11.206 3.64147V4.99331H9.30423V3.64147C9.30423 3.16865 9.1112 2.73763 8.80158 2.42416C8.49195 2.11069 8.06315 1.91739 7.59418 1.91739C7.12522 1.91739 6.6977 2.112 6.38679 2.42416C6.07587 2.73763 5.88414 3.16865 5.88414 3.64147V4.99331H3.98236V3.64147C3.98236 2.63967 4.38914 1.728 5.04207 1.06841C5.69629 0.408816 6.59925 0 7.59418 0Z"
      fill="url(#paint0_linear_7409_7757)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.004 5.74433L12.1219 5.74825C13.9161 5.8462 15.3425 7.34563 15.3425 9.17943C15.3425 11.0132 13.8164 12.6158 11.934 12.6158C10.2395 12.6158 8.83393 11.3698 8.57095 9.73714H6.77152C6.50724 11.3698 5.10163 12.6158 3.40843 12.6158C1.52608 12.6158 0 11.0772 0 9.17943C0 7.28163 1.48333 5.78743 3.32811 5.74433H12.004ZM10.7538 8.58384C10.4274 8.58384 10.1631 8.85029 10.1631 9.17943C10.1631 9.50857 10.4274 9.77502 10.7538 9.77502C11.0803 9.77502 11.3446 9.50857 11.3446 9.17943C11.3446 8.85029 11.0803 8.58384 10.7538 8.58384ZM13.1116 8.58384C12.7852 8.58384 12.5209 8.85029 12.5209 9.17943C12.5209 9.50857 12.7852 9.77502 13.1116 9.77502C13.4381 9.77502 13.7024 9.50857 13.7024 9.17943C13.7024 8.85029 13.4381 8.58384 13.1116 8.58384ZM11.9327 7.40441C11.6063 7.40441 11.342 7.67086 11.342 8C11.342 8.32914 11.6063 8.59559 11.9327 8.59559C12.2592 8.59559 12.5235 8.32914 12.5235 8C12.5235 7.67086 12.2592 7.40441 11.9327 7.40441ZM11.9327 9.76327C11.6063 9.76327 11.342 10.0297 11.342 10.3589C11.342 10.688 11.6063 10.9545 11.9327 10.9545C12.2592 10.9545 12.5235 10.688 12.5235 10.3589C12.5235 10.0297 12.2592 9.76327 11.9327 9.76327ZM3.08715 7.39788C2.93946 7.39788 2.81898 7.51935 2.81898 7.66825V8.58514H1.90955C1.76186 8.58514 1.64138 8.70661 1.64138 8.85682V9.50204C1.64138 9.65094 1.76186 9.77241 1.90955 9.77241H2.81898V10.6893C2.81898 10.8382 2.93946 10.961 3.08715 10.961H3.72712C3.8748 10.961 3.99658 10.8395 3.99658 10.6893V9.77241H4.90601C5.0537 9.77241 5.17418 9.65094 5.17418 9.50204V8.85682C5.17418 8.70792 5.0537 8.58514 4.90601 8.58514H3.99658V7.66825C3.99658 7.51935 3.8761 7.39788 3.72712 7.39788H3.08715Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5416 13.3211C11.5039 14.9303 9.70451 16 7.6706 16C5.63538 16 3.83725 14.9303 2.79956 13.3211C2.99777 13.3512 3.20116 13.3655 3.40844 13.3655C5.22471 13.3655 6.80391 12.1796 7.3545 10.4869H7.98799C8.53857 12.1796 10.1191 13.3655 11.934 13.3655C12.14 13.3655 12.3434 13.3499 12.5416 13.3211Z"
      fill="url(#paint1_linear_7409_7757)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5506 13.3309C12.2952 13.7293 11.9931 14.0948 11.6547 14.4195C9.71516 14.0908 8.20213 12.4816 7.9921 10.4781C8.54311 12.1819 10.1249 13.3756 11.9413 13.3756C12.1474 13.3756 12.351 13.3599 12.5493 13.3309H12.5506ZM3.69545 14.4195C3.35576 14.0948 3.05497 13.7293 2.79956 13.3309C2.99793 13.3612 3.20148 13.3756 3.40892 13.3756C5.22534 13.3756 6.80579 12.1819 7.3568 10.4781C7.14677 12.4816 5.63503 14.0895 3.69545 14.4182V14.4195Z"
      fill="url(#paint2_linear_7409_7757)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7409_7757"
        x1="10.9731"
        y1="7.96442"
        x2="1.96603"
        y2="10.2245"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#418EF8" />
        <stop offset="1" stopColor="#414CC2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7409_7757"
        x1="12.5358"
        y1="7.64779"
        x2="2.9406"
        y2="8.64357"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4070DF" />
        <stop offset="1" stopColor="#422CA8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7409_7757"
        x1="13.4555"
        y1="13.3223"
        x2="4.07814"
        y2="14.1329"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D56E2" />
        <stop offset="1" stopColor="#2F1E8E" />
      </linearGradient>
    </defs>
  </svg>

  // <svg
  //   width="32"
  //   height="32"
  //   viewBox="0 0 32 32"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <path
  //     d="M0.65625 0H31.9987V22C31.9987 27.5228 27.5216 32 21.9987 32H10.6562C5.1334 32 0.65625 27.5228 0.65625 22V0Z"
  //     fill="#3378FF"
  //     fill-opacity="0.1"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M16.2504 8C17.2441 8 18.1483 8.40882 18.8025 9.06841C19.4568 9.728 19.8623 10.6384 19.8623 11.6415V12.9933H17.9605V11.6415C17.9605 11.1687 17.7674 10.7376 17.4578 10.4242C17.1482 10.1107 16.7194 9.91739 16.2504 9.91739C15.7815 9.91739 15.354 10.112 15.043 10.4242C14.7321 10.7376 14.5404 11.1687 14.5404 11.6415V12.9933H12.6386V11.6415C12.6386 10.6397 13.0454 9.728 13.6983 9.06841C14.3525 8.40882 15.2555 8 16.2504 8Z"
  //     fill="url(#paint0_linear_7411_64891)"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M20.6602 13.7443L20.7781 13.7482C22.5724 13.8462 23.9987 15.3456 23.9987 17.1794C23.9987 19.0132 22.4726 20.6158 20.5903 20.6158C18.8958 20.6158 17.4902 19.3698 17.2272 17.7371H15.4278C15.1635 19.3698 13.7579 20.6158 12.0647 20.6158C10.1823 20.6158 8.65625 19.0772 8.65625 17.1794C8.65625 15.2816 10.1396 13.7874 11.9844 13.7443H20.6602ZM19.4101 16.5838C19.0836 16.5838 18.8194 16.8503 18.8194 17.1794C18.8194 17.5086 19.0836 17.775 19.4101 17.775C19.7366 17.775 20.0008 17.5086 20.0008 17.1794C20.0008 16.8503 19.7366 16.5838 19.4101 16.5838ZM21.7679 16.5838C21.4414 16.5838 21.1771 16.8503 21.1771 17.1794C21.1771 17.5086 21.4414 17.775 21.7679 17.775C22.0943 17.775 22.3586 17.5086 22.3586 17.1794C22.3586 16.8503 22.0943 16.5838 21.7679 16.5838ZM20.589 15.4044C20.2625 15.4044 19.9982 15.6709 19.9982 16C19.9982 16.3291 20.2625 16.5956 20.589 16.5956C20.9155 16.5956 21.1797 16.3291 21.1797 16C21.1797 15.6709 20.9155 15.4044 20.589 15.4044ZM20.589 17.7633C20.2625 17.7633 19.9982 18.0297 19.9982 18.3589C19.9982 18.688 20.2625 18.9545 20.589 18.9545C20.9155 18.9545 21.1797 18.688 21.1797 18.3589C21.1797 18.0297 20.9155 17.7633 20.589 17.7633ZM11.7434 15.3979C11.5957 15.3979 11.4752 15.5193 11.4752 15.6682V16.5851H10.5658C10.4181 16.5851 10.2976 16.7066 10.2976 16.8568V17.502C10.2976 17.6509 10.4181 17.7724 10.5658 17.7724H11.4752V18.6893C11.4752 18.8382 11.5957 18.961 11.7434 18.961H12.3834C12.5311 18.961 12.6528 18.8395 12.6528 18.6893V17.7724H13.5623C13.7099 17.7724 13.8304 17.6509 13.8304 17.502V16.8568C13.8304 16.7079 13.7099 16.5851 13.5623 16.5851H12.6528V15.6682C12.6528 15.5193 12.5324 15.3979 12.3834 15.3979H11.7434Z"
  //     fill="white"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M21.1979 21.3211C20.1602 22.9303 18.3608 24 16.3268 24C14.2916 24 12.4935 22.9303 11.4558 21.3211C11.654 21.3512 11.8574 21.3655 12.0647 21.3655C13.881 21.3655 15.4602 20.1796 16.0107 18.4869H16.6442C17.1948 20.1796 18.7753 21.3655 20.5903 21.3655C20.7963 21.3655 20.9997 21.3499 21.1979 21.3211Z"
  //     fill="url(#paint1_linear_7411_64891)"
  //   />
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M21.2069 21.3309C20.9515 21.7293 20.6494 22.0948 20.311 22.4195C18.3714 22.0908 16.8584 20.4816 16.6483 18.4781C17.1994 20.1819 18.7811 21.3756 20.5975 21.3756C20.8037 21.3756 21.0072 21.3599 21.2056 21.3309H21.2069ZM12.3517 22.4195C12.012 22.0948 11.7112 21.7293 11.4558 21.3309C11.6542 21.3612 11.8577 21.3756 12.0652 21.3756C13.8816 21.3756 15.462 20.1819 16.0131 18.4781C15.803 20.4816 14.2913 22.0895 12.3517 22.4182V22.4195Z"
  //     fill="url(#paint2_linear_7411_64891)"
  //   />
  //   <defs>
  //     <linearGradient
  //       id="paint0_linear_7411_64891"
  //       x1="19.6293"
  //       y1="15.9644"
  //       x2="10.6223"
  //       y2="18.2245"
  //       gradientUnits="userSpaceOnUse"
  //     >
  //       <stop stopColor="#418EF8" />
  //       <stop offset="1" stopColor="#414CC2" />
  //     </linearGradient>
  //     <linearGradient
  //       id="paint1_linear_7411_64891"
  //       x1="21.192"
  //       y1="15.6478"
  //       x2="11.5969"
  //       y2="16.6436"
  //       gradientUnits="userSpaceOnUse"
  //     >
  //       <stop stopColor="#4070DF" />
  //       <stop offset="1" stopColor="#422CA8" />
  //     </linearGradient>
  //     <linearGradient
  //       id="paint2_linear_7411_64891"
  //       x1="22.1118"
  //       y1="21.3223"
  //       x2="12.7344"
  //       y2="22.1329"
  //       gradientUnits="userSpaceOnUse"
  //     >
  //       <stop stopColor="#2D56E2" />
  //       <stop offset="1" stopColor="#2F1E8E" />
  //     </linearGradient>
  //   </defs>
  // </svg>
);
