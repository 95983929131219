import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

import axios from "axios";

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
const UserPool = new CognitoUserPool(poolData);

export const logInAWS = (
  values,
  onSuccessHandler,
  onFailHandler,
  newPasswordRequiredHandler
) => {
  const { username, password } = values;

  const user = new CognitoUser({
    Username: username,
    Pool: UserPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  user.authenticateUser(authDetails, {
    onSuccess: (data) => {
      onSuccessHandler && onSuccessHandler(data, user);
    },
    onFailure: (error) => {
      onFailHandler && onFailHandler(error);
    },

    newPasswordRequired: () => {
      newPasswordRequiredHandler && newPasswordRequiredHandler();
    },
  });
};

export const logOutAWS = () => {
  const user = UserPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};

export const signUpAWS = (values, onSuccessHandler, onFailHandler) => {
  const { username, email, password } = values;
  const attributeList = [];

  const dataEmail = {
    Name: "email",
    Value: email,
  };

  const attributeEmail = new CognitoUserAttribute(dataEmail);
  attributeList.push(attributeEmail);

  UserPool.signUp(username, password, attributeList, null, function (err) {
    if (err) {
      onFailHandler && onFailHandler(err.message || JSON.stringify(err));
      return;
    }
    onSuccessHandler && onSuccessHandler(values);
  });
};

export const verifyUserAWS = (
  username,
  verificationCode,
  onSuccessHandler,
  onFailHandler
) => {
  const userData = {
    Username: username,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmRegistration(verificationCode, true, function (err) {
    if (err) {
      onFailHandler && onFailHandler(err.message || JSON.stringify(err));
      return;
    }

    onSuccessHandler && onSuccessHandler();
  });
};

export const resendVerificationCodeUserAWS = (
  username,
  onSuccessHandler,
  onFailHandler
) => {
  const userData = {
    Username: username,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.resendConfirmationCode(function (err) {
    if (err) {
      onFailHandler && onFailHandler(err.message || JSON.stringify(err));
      return;
    }
    onSuccessHandler && onSuccessHandler();
  });
};

export const startForgotPasswordAWS = (
  username,
  onSuccessHandler,
  onFailHandler
) => {
  const userData = {
    Username: username,
    Pool: UserPool,
  };

  const cognitoUser = new CognitoUser(userData);

  cognitoUser.forgotPassword({
    onSuccess: function () {
      onSuccessHandler && onSuccessHandler();
    },
    onFailure: function (err) {
      onFailHandler && onFailHandler(err.message || JSON.stringify(err));
    },
  });
};

export const finishForgotPasswordAWS = (
  values,
  onSuccessHandler,
  onFailHandler
) => {
  const { username, verificationCode, newPassword } = values;

  const userData = {
    Username: username,
    Pool: UserPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess() {
      onSuccessHandler && onSuccessHandler();
    },
    onFailure(err) {
      onFailHandler && onFailHandler(err.message || JSON.stringify(err));
    },
  });
};

export const changePasswordAWS = (values, onSuccessHandler, onFailHandler) => {
  logInAWS(
    values,
    (data, user) => {
      user.changePassword(
        values.password,
        values.newPassword,
        function (err, result) {
          if (err) {
            onFailHandler && onFailHandler(err.message || JSON.stringify(err));
            return;
          }
          onSuccessHandler && onSuccessHandler(result);
        }
      );
    },
    (err) => {
      onFailHandler && onFailHandler("Incorrect password!");
    }
  );
};

export const fetchClientTokenAWS = async () => {
  const tokenPromise = new Promise(function (resolve, reject) {
    const params = {
      grant_type: "client_credentials",
      scope: "feclient/open.read",
    };

    const data = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");

    const axiosOptions = {
      method: "post",
      url: `https://${process.env.REACT_APP_COGNITO_DOMAIN_NAME}/oauth2/token`,
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${process.env.REACT_APP_AWS_CLIENT_BASIC}`,
      },
    };

    axios
      .request(axiosOptions)
      .then((response) => {
        sessionStorage.setItem("clientToken", response.data?.access_token);
        resolve(response.data.access_token);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return tokenPromise;
};

export default UserPool;
