// Vendors
import React, { useState } from "react";
import { Form, Input, message } from "antd";

// Components
import MainButton from "../../components/MainButton/MainButton";

// Api
import { changeProfileData } from "../../api/requests/requests";

// Styles
import classes from "./EditProfileSocial.module.scss";

// Assets
import { twitterIcon } from "../../assets/icons/twitter/twitter";
import { InstagramIcon } from "../../assets/icons/instagram/instagram";
import { twitchIcon } from "../../assets/icons/twitch/twitch";
import { youtubeIcon } from "../../assets/icons/youtube/youtube";

const EditProfileSocial = ({
  handleSetUserData,
  requestedUserData,
  handleModalClose,
}) => {
  const [changeSocialMediaError, setChangeSocialMediaError] = useState("");
  const [loadingChangeSocialMedia, setLoadingChangeSocialMedia] =
    useState(false);

  const handleSubmitSocialMedia = (values) => {
    setChangeSocialMediaError("");
    setLoadingChangeSocialMedia(true);

    changeProfileData(
      { ...values, description: requestedUserData.description },
      (data) => {
        setLoadingChangeSocialMedia(false);
        setChangeSocialMediaError("");
        handleSetUserData(data);
        handleModalClose();
        message.success({
          content: "Social Media links successfully updated!",
          className: "successMessage",
        });
      },
      (error) => {
        setLoadingChangeSocialMedia(false);
        setChangeSocialMediaError(error);
      }
    );
  };

  return (
    <div className={classes.profileTabContainer}>
      <h3 className={classes.profileChangeTitle}>Social Media:</h3>
      <span className={classes.profileChangeDescription}>
        Remember to add just your username, we will complete the link.
      </span>
      <Form
        name="socialMedia"
        initialValues={{
          twitter: requestedUserData.twitter,
          instagram: requestedUserData.instagram,
          youtube: requestedUserData.youtube,
          twitch: requestedUserData.twitch,
        }}
        className={classes.socialMediaform}
        onFinish={handleSubmitSocialMedia}
        autoComplete="off"
      >
        <div className={classes.inputsContainer}>
          <Form.Item className={classes.formItem} name="twitter">
            <Input
              placeholder="@Twitter"
              className={classes.inputBox}
              prefix={twitterIcon}
              bordered={false}
            />
          </Form.Item>
          <Form.Item name="instagram" className={classes.formItem}>
            <Input
              placeholder="@Instagram"
              className={classes.inputBox}
              prefix={<InstagramIcon id="InstaLogo_Edit_Profile" />}
              bordered={false}
            />
          </Form.Item>
          <Form.Item name="youtube" className={classes.formItem}>
            <Input
              placeholder="@Youtube"
              className={classes.inputBox}
              prefix={youtubeIcon}
              bordered={false}
            />
          </Form.Item>
          <Form.Item name="twitch" className={classes.formItem}>
            <Input
              placeholder="@Twitch"
              className={classes.inputBox}
              prefix={twitchIcon}
              bordered={false}
            />
          </Form.Item>

          {changeSocialMediaError && (
            <span className={classes.changeSocialMediaError}>
              {changeSocialMediaError}
            </span>
          )}

          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText="Update social media"
              fullWidth="fullWidth"
              loading={loadingChangeSocialMedia}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditProfileSocial;
