// Vendors
import React from "react";

// Styles
import classes from "./TermsOfService.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const TermsOfService = () => {
  return (
    <div className={classes.termsOfServiceContainer}>
      <div className={classes.ageRestrictionsRow}>
        <span className={classes.ageRestrictionItem}>18+</span>
        <span className={classes.ageRestrictionItem}>21+</span>
      </div>
      <p className={classes.tosText}>
        Copyright 2023 © InstaLock Network LLC., All Rights Reserved.
        <a
          href={globalConstants.PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <span>Privacy Policy</span>{" "}
        </a>
        |
        <a
          href={globalConstants.TERMS_OF_SERVICE_URL}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <span>Terms of Service</span>{" "}
        </a>
        | AdChoices DISCLAIMER: This site is 100% for entertainment purposes
        only and does not involve real money betting. Gambling can be addictive,
        please play responsibly. If you or someone you know has a gambling
        problem and wants help, call 1-800 GAMBLER in the U.S. or find your
        local gambling hotline in other jurisdictions. This service is intended
        for adult users only. Individuals must be 18+ in most jurisdictions in
        the world and 21+ to participate in sports betting and iGaming in the
        U.S.
      </p>
    </div>
  );
};

export default TermsOfService;
