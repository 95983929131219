// Vendors
import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { format } from "date-fns";

// Components
import MainButton from "../../components/MainButton/MainButton";
import MainSelect from "../../components/MainSelect/MainSelect";
import MainDatePicker from "../../components/MainDatePicker/MainDatePicker";
import ResultCard from "../../components/ResultCard/ResultCard";

// API
import { getResultsData } from "../../api/requests/requests";

// Styles
import classes from "./ResultsPage.module.scss";

// Assets
import { liveIcon } from "../../assets/icons/live/live";
import ClockIcon from "../../assets/icons/clock/clock";
import { finishedIcon } from "../../assets/icons/finished/finished";
import { instabreakIcon } from "../../assets/icons/instabreak/instabreak";

// Banner
import instalockBanner from "../../assets/images/sportsBooksBanners/instalockBanner.png";
import instalockBannerMobile from "../../assets/images/sportsBooksBanners/instalockBannerMobile.png";

// Constants
import { resultsPageConstants } from "./constants/constants";
import { globalConstants } from "../../constants/globalConstants";
import Loading from "../../components/Loading/Loading";
import styledVariables from "../../styles/utils/_variables.scss";
import { abbreviateString } from "../../utils/globalUtils";
import { useContext } from "react";
import { UserContext } from "../../context/contextProviders/UserContextProvider";

const ResultsPage = ({ title }) => {
  const [menuTab, setMenuTab] = useState(resultsPageConstants.TABS.ALL);
  const [resultsSearch, setResultsSearch] = useState("");

  const selectedDateStart = localStorage.getItem("resultsPageDate");

  const [selectedDate, setSelectedDate] = useState(
    selectedDateStart || new Date()
  );
  const [loadingResultsData, setLoadingResultsData] = useState(true);

  const { preferredOddsType } = useContext(UserContext);

  const [resultsData, setResultsData] = useState(null);

  const [resultsEsport, setResultsEsport] = useState(
    resultsPageConstants.ESPORTS_RESULTS_ITEMS[0]
  );

  const handleChangeEvent = (date) => {
    localStorage.setItem("resultsPageDate", date);
    setSelectedDate(date);
  };

  const handleGetResultData = () => {
    setLoadingResultsData(true);
    getResultsData(
      {
        esportId: globalConstants.TITLES_IDS[resultsEsport?.key] || null,
        date: selectedDate
          ? format(new Date(selectedDate), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
      },
      (data) => {
        const matches = [];
        Object.entries(data).forEach((game) => {
          const esportName = game[0];
          const esportData = game[1];

          esportData.series.forEach((serie) => {
            matches.push({
              ...serie,
              game: esportName,
            });
          });
        });

        setResultsData(matches);
        setLoadingResultsData(false);
      },
      () => {
        setResultsData(null);
        setLoadingResultsData(false);
      }
    );
  };

  useEffect(() => {
    handleGetResultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultsEsport, selectedDate]);

  const modifiedResults = resultsSearch?.toLowerCase() || "";

  const filteredBySearch =
    resultsData && resultsData.length > 0
      ? resultsData?.filter((match) => {
          return (
            match?.teamOne?.name?.toLowerCase().includes(modifiedResults) ||
            match?.teamTwo?.name?.toLowerCase().includes(modifiedResults) ||
            match?.teamTwo?.name?.toLowerCase().includes(modifiedResults) ||
            match?.teamOne?.teamAbbreviation
              ?.toLowerCase()
              .includes(modifiedResults) ||
            match?.teamTwo?.teamAbbreviation
              ?.toLowerCase()
              .includes(modifiedResults) ||
            abbreviateString(
              match?.teamOne?.teamAbbreviation?.toLowerCase() ||
                match?.teamOne?.name?.toLowerCase(),
              6
            )
              ?.toLowerCase()
              .includes(modifiedResults) ||
            abbreviateString(
              match?.teamTwo?.teamAbbreviation?.toLowerCase() ||
                match?.teamTwo?.name?.toLowerCase(),
              6
            )
              ?.toLowerCase()
              .includes(modifiedResults)
          );
        })
      : [];

  const liveGames = filteredBySearch.filter((match) => {
    return match.lifecycle === "LIVE";
  });
  const startingGames = filteredBySearch.filter((match) => {
    return match.lifecycle === "UPCOMING";
  });
  const finishedGames = filteredBySearch.filter((match) => {
    return match.lifecycle === "OVER";
  });

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className={classes.resultsPagePageContainer}>
      {/* ///// */}

      <div className={classes.tabletBanner}>
        <a
          className={classes.affiliateBannerContainer}
          href="https://ilnet.link/ykqF"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instalockBanner} alt={`instalockBanner`} />
        </a>
      </div>
      <div className={classes.mobileBanner}>
        <a
          className={classes.affiliateBannerContainer}
          href="https://ilnet.link/ykqF"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instalockBannerMobile} alt={`instalockBanner`} />
        </a>
      </div>
      {/* ///// */}
      <div className={classes.switchButtonContainer}>
        <div className={classes.switchButtonList}>
          <MainButton
            buttonText="All"
            clicked={() => setMenuTab(resultsPageConstants.TABS.ALL)}
            type={
              menuTab === resultsPageConstants.TABS.ALL ? "quinary" : "tertiary"
            }
          />
          <MainButton
            buttonText="Live"
            clicked={() => setMenuTab(resultsPageConstants.TABS.LIVE)}
            type={
              menuTab === resultsPageConstants.TABS.LIVE
                ? "quinary"
                : "tertiary"
            }
          />
          <MainButton
            buttonText="Starting"
            clicked={() => setMenuTab(resultsPageConstants.TABS.STARTING)}
            type={
              menuTab === resultsPageConstants.TABS.STARTING
                ? "quinary"
                : "tertiary"
            }
          />
          <MainButton
            buttonText="Finished"
            clicked={() => setMenuTab(resultsPageConstants.TABS.FINISHED)}
            type={
              menuTab === resultsPageConstants.TABS.FINISHED
                ? "quinary"
                : "tertiary"
            }
          />
          <div className={classes.oddsPageSearchControls}>
            <div className={classes.searchDesktop}>
              <Input
                size="small"
                placeholder="Search"
                className={classes.searchBox}
                prefix={<SearchOutlined style={{ fontSize: "150%" }} />}
                bordered={false}
                onChange={(event) => {
                  setResultsSearch(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.dateAndEsportContainer}>
          <MainDatePicker
            handleChangeEvent={handleChangeEvent}
            value={selectedDate}
          />
          <div className={classes.resultsPageEspotsControls}>
            <MainSelect
              size="small"
              currentValue={resultsPageConstants.ESPORTS_RESULTS_ITEMS.find(
                (item) => {
                  return item.key === resultsEsport.key;
                }
              )}
              setCurrentValue={(item) => setResultsEsport(item)}
              options={resultsPageConstants.ESPORTS_RESULTS_ITEMS}
            />
          </div>
        </div>
        <div className={classes.searchMobile}>
          <div className={classes.oddsPageSearchControls}>
            <Input
              size="small"
              placeholder="Search"
              className={classes.searchBox}
              prefix={<SearchOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
              onChange={(event) => {
                setResultsSearch(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
      {!loadingResultsData ? (
        <div className={classes.resultsContainer}>
          {liveGames.length > 0 &&
          [
            resultsPageConstants.TABS.ALL,
            resultsPageConstants.TABS.LIVE,
          ].includes(menuTab) ? (
            <div className={classes.resultRow}>
              <div className={classes.rowTitle}>{liveIcon}Live</div>
              <div className={classes.gameList}>
                {liveGames.map((resultData, index) => {
                  return (
                    <ResultCard
                      key={index}
                      resultData={resultData}
                      type={resultsPageConstants.TABS.LIVE}
                      preferredOddsType={preferredOddsType}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {startingGames.length > 0 &&
          [
            resultsPageConstants.TABS.ALL,
            resultsPageConstants.TABS.STARTING,
          ].includes(menuTab) ? (
            <div className={classes.resultRow}>
              <div className={classes.rowTitle}>
                <div className={classes.clockIcon}>
                  <ClockIcon color={styledVariables.backgroundEmphasisK} />
                </div>
                Starting
              </div>
              <div className={classes.gameList}>
                {startingGames.map((resultData, index) => {
                  return (
                    <ResultCard
                      key={index}
                      resultData={resultData}
                      type={resultsPageConstants.TABS.STARTING}
                      preferredOddsType={preferredOddsType}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {finishedGames.length > 0 &&
          [
            resultsPageConstants.TABS.ALL,
            resultsPageConstants.TABS.FINISHED,
          ].includes(menuTab) ? (
            <div className={classes.resultRow}>
              <div className={classes.rowTitle}>
                <div className={classes.clockIcon}>{finishedIcon}</div>
                Finished
              </div>
              <div className={classes.gameList}>
                {finishedGames.map((resultData, index) => {
                  return (
                    <ResultCard
                      key={index}
                      resultData={resultData}
                      type={resultsPageConstants.TABS.FINISHED}
                      preferredOddsType={preferredOddsType}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {filteredBySearch.length < 1 ||
          (menuTab === resultsPageConstants.TABS.LIVE &&
            liveGames.length < 1) ||
          (menuTab === resultsPageConstants.TABS.STARTING &&
            startingGames.length < 1) ||
          (menuTab === resultsPageConstants.TABS.FINISHED &&
            finishedGames.length < 1) ? (
            <div className={classes.notFoundMatchesContainer}>
              <div className={classes.notFoundMatchesIcon}>
                {instabreakIcon}
              </div>
              <p>No data for this search . . .</p>
            </div>
          ) : null}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ResultsPage;
