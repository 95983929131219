// Vendors
import React, { useState } from "react";

// Components
import DividerLine from "../../components/DividerLine/DividerLine";

// Styles
import classes from "./MainSelect.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import UpIcon from "../../assets/icons/up/up";
import DownIcon from "../../assets/icons/down/down";

const MainSelect = ({
  currentValue,
  setCurrentValue,
  options,
  size,
  iconWithBox,
  selectColor,
  truncateText,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOptionClicked = (item) => {
    setCurrentValue(item);
    item.handleChange && item.handleChange(item);
    setOpenDropdown(false);
  };

  const placeSelectedOptionFirst = (optionsToFilter) => {
    const filteredOptions = optionsToFilter.filter((option) => {
      return option.key !== currentValue.key;
    });
    return [currentValue, ...filteredOptions];
  };

  return (
    <div
      className={classes.mainSelectContainer}
      onMouseLeave={() => setOpenDropdown(false)}
    >
      <div
        className={`
        ${classes.mainSelectInput}
        ${openDropdown && classes.isOpen}
        ${classes[size]}
        `}
        style={{
          backgroundColor: selectColor ?? "",
          borderColor: selectColor ?? "",
        }}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <span
          className={`${classes.labelSpan} ${
            truncateText ? classes.truncateText : null
          } `}
        >
          {esportsLogos[currentValue.key]}
          {currentValue.label}
        </span>
        <div
          className={`${classes.inputArrowContainer} ${
            iconWithBox && openDropdown ? classes.boxOpen : ""
          }
            ${iconWithBox && !openDropdown ? classes.boxClosed : ""}
          `}
        >
          {openDropdown ? (
            <UpIcon />
          ) : (
            <DownIcon color={iconWithBox ? styledVariables.black : null} />
          )}
        </div>

        {openDropdown && (
          <div className={classes.mainSelectOptionList}>
            {placeSelectedOptionFirst(options).map((item, index) => {
              return (
                <div
                  value={item.key}
                  key={item.key}
                  className={classes.mainSelectOption}
                  onClick={() => handleOptionClicked(item)}
                >
                  {index !== 0 && <DividerLine margin={0} />}
                  <div
                    className={`${classes.mainSelectOptionLabel} ${classes[size]}`}
                  >
                    <span className={classes.labelSpan}>
                      {esportsLogos[item.key]}
                      {item.label}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainSelect;
