// Vendors
import React from "react";

export const rivalryIcon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7018 24.8435C21.7944 24.8435 20.1951 24.8928 18.6031 24.8066C18.2335 24.7869 17.7554 24.3704 17.5583 24.0106C16.814 22.6429 16.1733 21.221 15.4833 19.8262C15.089 19.0277 12.7183 18.4412 12.0653 19.0228C11.8682 19.1978 11.8164 19.6118 11.8115 19.9198C11.7844 21.2333 11.7671 22.5517 11.809 23.8652C11.8337 24.6267 11.5355 24.8805 10.7986 24.8583C9.52706 24.8189 8.253 24.8238 6.98141 24.8583C6.30126 24.8756 6.02033 24.6291 6.03758 23.9268C6.07208 22.6133 6.1189 21.29 6.01294 19.9839C5.96612 19.3949 5.6975 18.7074 5.29829 18.2811C3.73591 16.6127 2.07989 15.0306 0.458363 13.4165L0.566793 13.0592C1.8507 13.0592 3.13215 13.0666 4.41606 13.0592C6.04251 13.0468 5.88479 13.0222 6.07947 11.4179C6.4713 8.19214 5.68518 5.57996 2.91775 3.65286C1.92463 2.95793 1.12619 1.99438 0 0.932255C0.731903 0.887898 1.08184 0.850933 1.43177 0.850933C5.78376 0.846004 10.1357 0.823825 14.4877 0.855861C19.1108 0.890362 22.6027 3.4015 23.6624 7.56867C24.5274 10.9719 23.7536 14.0276 21.0798 16.4032C20.2345 17.1549 20.1803 17.7241 20.683 18.668C21.7156 20.6098 22.6249 22.6183 23.7018 24.8411V24.8435ZM11.7967 9.82845C11.7967 12.2435 12.9631 13.2892 15.296 12.9655C17.2379 12.6969 18.3 11.3958 18.13 9.4933C17.9476 7.47009 16.299 6.56322 13.0535 6.70369C12.2156 6.73983 11.7967 7.61713 11.7967 9.33558C11.7967 9.50069 11.7967 9.66334 11.7967 9.82845Z"
      fill="white"
    />
  </svg>
);
