// Vendors
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import _debounce from "lodash/debounce";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Components
import InstalockStreakCard from "../../components/InstalockStreakCard/InstalockStreakCard";
import MainButton from "../../components/MainButton/MainButton";
import MatchActionCard from "../../components/MatchActionCard/MatchActionCard";
import ProfileDiscoveredCard from "../../components/ProfileDiscoveredCard/ProfileDiscoveredCard";
import DividerLine from "../../components/DividerLine/DividerLine";
import Loading from "../../components/Loading/Loading";
import MainSkeleton from "../../components/MainSkeleton/MainSkeleton";
import LatestNews from "../../components/LatestNews/LatestNews";
import AffiliateBanner from "../../components/AffiliateBanner/AffiliateBanner";

// Assets
import { instabreakIcon } from "../../assets/icons/instabreak/instabreak";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// API
import {
  discoverCommunityUsers,
  getCommunityData,
  getUpcomingGamesData,
  getEsportRSS,
} from "../../api/requests/requests";

// Constants
import { communityPageConstants } from "./constants/constants";

// Styles
import classes from "./CommunityPage.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const TABS = {
  INSTALOCKS: "instaLocks",
  INSTAFADES: "instaFades",
};

const CommunityPage = ({ title }) => {
  const navigate = useNavigate();

  const { userRegion } = useContext(UserContext);

  const [menuTab, setMenuTab] = useState(TABS.INSTALOCKS);

  const [searchValue, setSearchValue] = useState("");
  const [loadingCommunityData, setloadingCommunityData] = useState(true);
  const [communityData, setCommunityData] = useState(null);

  const [loadingActionData, setLoadingActionData] = useState(false);
  const [actionData, setActionData] = useState(null);

  const [loadingRSSData, setLoadingRSSData] = useState(false);
  const [RSSData, setRSSData] = useState();

  const handleActionData = () => {
    setLoadingActionData(true);
    getUpcomingGamesData(
      { type: "COMMUNITY" },
      (data) => {
        setLoadingActionData(false);
        setActionData(data);
      },
      (error) => {
        setLoadingActionData(false);
        setActionData({});
      }
    );
  };

  const handleDiscoverUsers = (value) => {
    discoverCommunityUsers(
      {
        username: value,
      },
      (data) => {
        setCommunityData((prevState) => ({
          ...prevState,
          responses: data.responses,
        }));
      },
      () => {
        setCommunityData((prevState) => ({
          ...prevState,
          responses: [],
        }));
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(handleDiscoverUsers, 300), []);

  const handleRequestUserData = () => {
    setloadingCommunityData(true);
    getCommunityData(
      (data) => {
        const instafades = [...data.instafades];
        const memoryFade = instafades[instafades.length - 1];
        instafades[instafades.length - 1] = instafades[instafades.length - 2];
        instafades[instafades.length - 2] = memoryFade;

        const instalocks = [...data.instalocks];
        const memoryLock = instalocks[instalocks.length - 1];
        instalocks[instalocks.length - 1] = instalocks[instalocks.length - 2];
        instalocks[instalocks.length - 2] = memoryLock;

        const newData = {
          instafades: instafades,
          instalocks: instalocks,
          responses: data.responses,
        };
        setCommunityData(newData);
        setloadingCommunityData(false);
      },
      () => {
        setloadingCommunityData(false);
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  const handleUserSearch = (value) => {
    setSearchValue(value);
    debounceFn(value);
  };

  const generateActionData = (actionData) => {
    if (!actionData) {
      return [];
    }
    const matchesAction = [];

    Object.entries({
      leagueOfLegends: actionData.leagueOfLegends,
      csgo: actionData.csgo,
      valorant: actionData.valorant,
      rocketLeague: actionData.rocketLeague,
      callOfDuty: actionData.callOfDuty,
    }).forEach((esport) => {
      const esportName = esport[0];
      const esportData = esport[1];

      return esportData?.series.forEach((serie) => {
        matchesAction.push({
          ...serie,
          game: esportName,
        });
      });
    });
    return matchesAction;
  };

  const handleGetRSSNews = () => {
    setLoadingRSSData(true);
    getEsportRSS(
      globalConstants.TWITTER,
      (data) => {
        setRSSData(generateNewsData(data));
        setLoadingRSSData(false);
      },
      (error) => {
        setRSSData(null);
        setLoadingRSSData(false);
      }
    );
  };

  const replaceCDData = (string) => {
    if (!string) {
      return "";
    }
    return string.replace("<![CDATA[", "").replace("]]>", "");
  };

  const generateNewsData = (data, game) => {
    const feed = new window.DOMParser().parseFromString(data, "text/xml");
    const items = feed.querySelectorAll("item");
    const feedItems = [...items].slice(0, 8).map((element) => {
      const title = replaceCDData(element.querySelector("title").innerHTML);
      const date = !!element.querySelector("pubDate")
        ? replaceCDData(element.querySelector("pubDate").innerHTML)
        : "";
      const content = !!element.querySelector("description")
        ? replaceCDData(element.querySelector("description").innerHTML)
        : "";

      const link = !!element.querySelector("link")
        ? replaceCDData(element.querySelector("link").innerHTML)
        : "";

      let handle = "";
      const username = link?.split("/");
      if (username && username[3]) {
        handle = username[3];
      }
      return {
        image: communityPageConstants.TWITTER_IMAGES[handle] || null,
        title: title === "No title" ? content : title,
        date: date,
        content: title === "No title" ? "" : content.slice(title?.length),
        link: link,
      };
    });

    return { news: feedItems };
  };

  useEffect(() => {
    handleRequestUserData();
    handleActionData();
    handleGetRSSNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionMatches = useMemo(
    () => generateActionData(actionData),
    [actionData]
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  return !loadingCommunityData && communityData ? (
    <div className={classes.communityPageContainer}>
      <div className={classes.communityPageWrapper}>
        <AffiliateBanner userRegion={userRegion} />

        <div className={classes.detailColumnsContainer}>
          <div className={classes.switchButtonContainer}>
            <div className={classes.switchButtonList}>
              <MainButton
                buttonText="InstaLock"
                fullWidth="fullWidth"
                clicked={() => setMenuTab(TABS.INSTALOCKS)}
                type={menuTab === TABS.INSTALOCKS ? "primary" : "tertiary"}
              />
              <MainButton
                buttonText="InstaFades"
                fullWidth="fullWidth"
                clicked={() => setMenuTab(TABS.INSTAFADES)}
                type={menuTab === TABS.INSTAFADES ? "primary" : "tertiary"}
              />
            </div>
          </div>

          <div className={classes.sectionsContainer}>
            <div className={classes.communitySection}>
              <div
                className={`${classes.leftColumnContainer}  ${
                  menuTab !== TABS.INSTALOCKS && classes.hide
                }`}
              >
                <div className={classes.columnTitle}>Instalocks</div>
                <div className={classes.instaLocksContainer}>
                  {communityData.instalocks.map((instalockData, index) => {
                    return (
                      <InstalockStreakCard
                        key={`${instalockData.username}-${index}`}
                        userData={instalockData}
                        showDivider={
                          index < communityData.instalocks.length - 1
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div
                className={`${classes.leftColumnContainer} ${
                  menuTab !== TABS.INSTAFADES && classes.hide
                } ${classes.orderChange}`}
              >
                <div className={classes.columnTitle}>InstaFades</div>
                <div
                  className={`${classes.instaLocksContainer} ${classes.instaFadesContainer}`}
                >
                  {communityData.instafades.map((instalockData, index) => {
                    return (
                      <InstalockStreakCard
                        isInstaFade
                        key={`${instalockData.username}-${index}`}
                        userData={instalockData}
                        showDivider={
                          index < communityData.instafades.length - 1
                        }
                      />
                    );
                  })}
                </div>
              </div>
              {!loadingActionData && actionMatches?.length > 0 ? (
                <div className={classes.rightColumnContainer}>
                  <div className={classes.columnTitle}>
                    Where is the most action happening?
                  </div>
                  <div className={classes.matchActionContainer}>
                    {actionMatches.map((matchData, index) => {
                      return (
                        <MatchActionCard
                          matchData={matchData}
                          key={matchData.abiosSeriesId}
                          showDivider={index < actionMatches.length - 1}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : loadingActionData ? (
                <MainSkeleton
                  type="rectangle"
                  height={300}
                  width={220}
                  borderRad={10}
                  verticalMargin={6}
                  horizontalMargin={0}
                  fullWidth
                />
              ) : null}

              <div
                className={`${classes.rightColumnContainer} ${classes.orderChange}`}
              >
                <div className={classes.columnTitle}>Discover</div>
                <div className={classes.matchActionContainer}>
                  <Input
                    size="small"
                    placeholder="Search"
                    className={classes.searchInputBox}
                    prefix={<SearchOutlined style={{ fontSize: "150%" }} />}
                    bordered={false}
                    onChange={(event) => {
                      handleUserSearch(event.target.value);
                    }}
                    value={searchValue}
                  />
                  <DividerLine
                    color={styledVariables.backgroundPrimaryE}
                    margin={16}
                  />
                  {communityData?.responses?.length === 0 && (
                    <div>
                      <p className={classes.noUsersFound}>{instabreakIcon}</p>
                      <p className={classes.noUsersFound}>
                        No users matching your search . . .
                      </p>
                    </div>
                  )}
                  {communityData?.responses
                    ?.slice(0, 3)
                    .map((userData, index) => {
                      return (
                        <ProfileDiscoveredCard
                          userData={userData}
                          key={`${userData.id}-${index}`}
                          showDivider={
                            index <
                            communityData?.responses?.slice(0, 3).length - 1
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className={classes.newsSection}>
              <div className={classes.columnTitle}>Esports Betting Feed</div>
              {RSSData?.news.length > 0 ? (
                <LatestNews newsData={RSSData?.news} />
              ) : loadingRSSData ? null : (
                <div className={classes.noDataCard}>No news . . .</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CommunityPage;
