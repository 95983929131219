import React, { createContext, useReducer } from "react";

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_DATA": {
      return {
        ...state,
        formData: action.payload,
      };
    }
    case "CLEAR_FORM_DATA": {
      return {
        ...state,
        formData: null,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  formData: null,
};

export const FormContext = createContext(initialState);

export const FormContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const setFormData = (data) => {
    dispatch({
      type: "SET_FORM_DATA",
      payload: data,
    });
  };

  const clearFormData = () => {
    dispatch({
      type: "CLEAR_FORM_DATA",
    });
  };

  return (
    <FormContext.Provider
      value={{
        formData: state.formData,
        setFormData,
        clearFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
