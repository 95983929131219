// Vendors
import React from "react";

export const instalockTextIcon = (
  <svg
    width="100"
    height="13"
    viewBox="0 0 100 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12.0663H3.01353V0.961439H0V12.0663Z" fill="white" />
    <path
      d="M4.85578 0.961439V12.0663H7.86931V5.19544L12.6005 12.0663H16.3675V0.961439H13.3539V7.81721L8.62269 0.961439H4.85578Z"
      fill="white"
    />
    <path
      d="M23.37 12.3827C26.9712 12.3827 28.9149 10.9211 28.9149 8.57059C28.9149 4.71328 21.0496 6.23511 21.0496 4.12564C21.0496 3.26679 21.9989 2.79969 23.37 2.79969C26.0521 2.79969 25.6302 4.87902 25.6302 4.89409H28.7642C28.7642 4.89409 28.9902 0.64502 23.37 0.64502C20.1154 0.64502 17.9005 2.04631 17.9005 4.12564C17.9005 8.20897 25.7507 6.50633 25.7507 8.72127C25.7507 9.80614 24.6658 10.228 23.37 10.228C20.462 10.228 20.8989 7.80215 20.8989 7.80215H17.7498C17.7498 7.80215 17.4032 12.3827 23.37 12.3827Z"
      fill="white"
    />
    <path
      d="M33.3177 3.38733V12.0663H36.3764V3.38733H40.1434V0.961439H29.5508V3.38733H33.3177Z"
      fill="white"
    />
    <path
      d="M42.2156 12.0663L42.9087 10.1376H47.4441L48.1372 12.0663H51.3918L47.0975 0.961439H43.2553L38.961 12.0663H42.2156ZM45.1689 3.79415L46.6304 7.86242H43.7224L45.1689 3.79415Z"
      fill="white"
    />
    <path
      d="M52.4719 0.961439V12.0663H62.0247V9.6404H55.4854V0.961439H52.4719Z"
      fill="white"
    />
    <path
      d="M74.3462 6.49126C74.3462 2.40793 71.3779 0.64502 67.9726 0.64502C64.5673 0.64502 61.599 2.40793 61.599 6.49126C61.599 10.5897 64.5673 12.3827 67.9726 12.3827C71.3779 12.3827 74.3462 10.5897 74.3462 6.49126ZM64.8234 6.49126C64.8234 3.85443 66.2549 3.01064 67.9726 3.01064C69.6903 3.01064 71.1217 3.85443 71.1217 6.49126C71.1217 9.14316 69.6903 10.0171 67.9726 10.0171C66.2549 10.0171 64.8234 9.14316 64.8234 6.49126Z"
      fill="white"
    />
    <path
      d="M84.0679 7.41039C84.0679 7.42545 84.0377 10.0171 81.5365 10.0171C79.9996 10.0171 78.5079 9.14316 78.5079 6.50633C78.5079 3.85443 79.9393 3.01064 81.5365 3.01064C84.0377 3.01064 84.0679 5.61734 84.0679 5.61734H87.2923C87.2923 5.61734 87.2923 0.64502 81.5365 0.64502C77.9353 0.64502 75.2834 2.4682 75.2834 6.50633C75.2834 10.5445 77.9353 12.3827 81.5365 12.3827C87.2923 12.3827 87.2923 7.42545 87.2923 7.41039H84.0679Z"
      fill="white"
    />
    <path
      d="M88.6842 0.961439V12.0663H91.6977V8.45005L93.3401 6.94329L96.7303 12.0663H100L95.3441 5.08997L99.8493 0.961439H96.3386L91.6977 5.24065V0.961439H88.6842Z"
      fill="white"
    />
  </svg>
);
