// Vendors
import React from "react";

const Logo = ({ colorless }) => {
  return (
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5141_228)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0213 0.229996C20.3788 0.229996 22.5243 1.18918 24.0766 2.73675C25.6288 4.28432 26.5909 6.42027 26.5909 8.77379V11.9455H22.0786V8.77379C22.0786 7.66445 21.6206 6.65317 20.886 5.91769C20.1514 5.18221 19.134 4.72867 18.0213 4.72867C16.9086 4.72867 15.8942 5.18528 15.1565 5.91769C14.4188 6.65317 13.9639 7.66445 13.9639 8.77379V11.9455H9.45166V8.77379C9.45166 6.42333 10.4168 4.28432 11.966 2.73675C13.5182 1.18918 15.6606 0.229996 18.0213 0.229996Z"
          fill={colorless ? "#FFFFFF" : "url(#paint0_linear_5141_228)"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4843 13.7076L28.764 13.7168C33.0212 13.9467 36.4054 17.4647 36.4054 21.7672C36.4054 26.0698 32.7845 29.8299 28.3183 29.8299C24.2978 29.8299 20.9628 26.9064 20.3389 23.0758H16.0694C15.4424 26.9064 12.1074 29.8299 8.08997 29.8299C3.62381 29.8299 0.00292969 26.2199 0.00292969 21.7672C0.00292969 17.3145 3.52237 13.8088 7.89939 13.7076H28.4843ZM25.5181 20.3698C24.7435 20.3698 24.1165 20.995 24.1165 21.7672C24.1165 22.5395 24.7435 23.1646 25.5181 23.1646C26.2927 23.1646 26.9198 22.5395 26.9198 21.7672C26.9198 20.995 26.2927 20.3698 25.5181 20.3698ZM31.1124 20.3698C30.3378 20.3698 29.7107 20.995 29.7107 21.7672C29.7107 22.5395 30.3378 23.1646 31.1124 23.1646C31.8869 23.1646 32.514 22.5395 32.514 21.7672C32.514 20.995 31.8869 20.3698 31.1124 20.3698ZM28.3152 17.6026C27.5407 17.6026 26.9136 18.2277 26.9136 19C26.9136 19.7723 27.5407 20.3974 28.3152 20.3974C29.0898 20.3974 29.7169 19.7723 29.7169 19C29.7169 18.2277 29.0898 17.6026 28.3152 17.6026ZM28.3152 23.1371C27.5407 23.1371 26.9136 23.7622 26.9136 24.5345C26.9136 25.3067 27.5407 25.9319 28.3152 25.9319C29.0898 25.9319 29.7169 25.3067 29.7169 24.5345C29.7169 23.7622 29.0898 23.1371 28.3152 23.1371ZM7.32768 17.5873C6.97727 17.5873 6.69141 17.8723 6.69141 18.2216V20.3729H4.53364C4.18323 20.3729 3.89737 20.6579 3.89737 21.0103V22.5242C3.89737 22.8735 4.18323 23.1585 4.53364 23.1585H6.69141V25.3098C6.69141 25.6591 6.97727 25.9472 7.32768 25.9472H8.84611C9.19652 25.9472 9.48545 25.6622 9.48545 25.3098V23.1585H11.6432C11.9936 23.1585 12.2795 22.8735 12.2795 22.5242V21.0103C12.2795 20.661 11.9936 20.3729 11.6432 20.3729H9.48545V18.2216C9.48545 17.8723 9.19959 17.5873 8.84611 17.5873H7.32768Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.7601 31.4847C27.298 35.2602 23.0286 37.77 18.2028 37.77C13.3739 37.77 9.10758 35.2602 6.64551 31.4847C7.11579 31.5552 7.59837 31.5889 8.09017 31.5889C12.3996 31.5889 16.1465 28.8064 17.4528 24.8348H18.9559C20.2622 28.8064 24.0122 31.5889 28.3185 31.5889C28.8072 31.5889 29.2898 31.5521 29.7601 31.4847Z"
          fill={colorless ? "#FFFFFF" : "url(#paint1_linear_5141_228)"}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5141_228"
          x1="29.5592"
          y1="3.15715"
          x2="9.45166"
          y2="11.9455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A02BE2" />
          <stop offset="1" stopColor="#3019BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5141_228"
          x1="33.7633"
          y1="28.0667"
          x2="8.6289"
          y2="41.4851"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A02BE2" />
          <stop offset="1" stopColor="#3019BC" />
        </linearGradient>
        <clipPath id="clip0_5141_228">
          <rect
            width="36.4024"
            height="37.54"
            fill="white"
            transform="translate(0 0.229996)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
