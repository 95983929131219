// Vendors
import React from "react";

// Styles
import classes from "./BetSlipInput.module.scss";

// Utils
import {
  calculateAmountToWin,
  calculateAmountToRisk,
} from "../../utils/globalUtils";

const BetSlipInput = ({
  betData,
  changeBetProperties,
  currentValue,
  error,
  label,
  naming,
  preferredOddsType,
  type,
}) => {
  const handleInputChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    let regex = "";
    switch (type) {
      case "risk":
        regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
        if (regex.test(value.toString()) || value === "") {
          if (betData.odds) {
            const toWin = calculateAmountToWin({
              risk: value,
              odds: betData.odds,
              preferredOddsType,
            });
            changeBetProperties({ win: toWin, risk: value });
          } else {
            changeBetProperties({ risk: value });
          }
        }
        break;
      case "odds":
        if (value === "" || value === "-" || !isNaN(value)) {
          if (betData.risk) {
            const toWin = calculateAmountToWin({
              risk: betData.risk,
              odds: value,
              preferredOddsType,
            });
            changeBetProperties({ win: toWin, odds: value });
          } else {
            changeBetProperties({ odds: value });
          }
        }
        break;

      case "win":
        regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
        if (regex.test(value.toString()) || value === "") {
          if (betData.odds) {
            const toRisk = calculateAmountToRisk({
              win: value,
              odds: betData.odds,
              preferredOddsType,
            });
            changeBetProperties({ risk: toRisk, win: value });
          } else {
            changeBetProperties({ win: value });
          }
        }
        break;

      case "total":
        regex = /^-?\d*(\.\d*)?$/;
        if (regex.test(value.toString()) || value === "") {
          changeBetProperties({ total: value });
        }
        break;
      case "spread":
        regex = /^-?\d*(\.\d*)?$/;
        if (regex.test(value.toString()) || value === "") {
          changeBetProperties({ spread: value });
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.inputContainer}>
      <input
        className={`${classes.betSlipInput} ${error ? classes.error : ""}`}
        name={naming}
        onChange={handleInputChange}
        value={currentValue}
        autoComplete="off"
      />
      <label htmlFor={naming} className={classes.inputLabel}>
        {label}
      </label>
    </div>
  );
};

export default BetSlipInput;
