// Vendors
import React, { useContext, useEffect } from "react";

// Styles
import classes from "./FooterBanner.module.scss";

// Assets
import { couponIcon } from "../../assets/icons/coupon/coupon";

import bovadaFooter from "../../assets/images/footerBanners/bovadaFooter.png";
import bovadaFooterMobile from "../../assets/images/footerBanners/bovadaFooterMobile.png";
import esportsbetFooter from "../../assets/images/footerBanners/esportsbetFooter.png";
import esportsbetFooterMobile from "../../assets/images/footerBanners/esportsbetFooterMobile.png";
import thunderpickFooter from "../../assets/images/footerBanners/thunderpickFooter.png";
import thunderpickFooterMobile from "../../assets/images/footerBanners/thunderpickFooterMobile.png";
import underdogFantasyFooter from "../../assets/images/footerBanners/underdogFantasyFooter.png";
import underdogFantasyFooterMobile from "../../assets/images/footerBanners/underdogFantasyFooterMobile.png";

import bovadaLogo from "../../assets/images/sportsbooksLogos/bovadaLogo.png";
import bovadaLogoMobile from "../../assets/images/sportsbooksLogos/bovadaLogoMobile.png";
import esportsbetLogo from "../../assets/images/sportsbooksLogos/esportsbetLogo.png";
import esportsbetLogoMobile from "../../assets/images/sportsbooksLogos/esportsbetLogoMobile.png";
import thunderpickLogo from "../../assets/images/sportsbooksLogos/thunderpickLogo.png";
import thunderpickLogoMobile from "../../assets/images/sportsbooksLogos/thunderpickLogoMobile.png";
import underdogFantasyLogo from "../../assets/images/sportsbooksLogos/underdogFantasyLogo.png";
import underdogFantasyLogoMobile from "../../assets/images/sportsbooksLogos/underdogFantasyLogoMobile.png";

// Constants
import MainButton from "../../components/MainButton/MainButton";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";
import { globalConstants } from "../../constants/globalConstants";

const FOOTER_DATA = {
  thunderpick: {
    logoDesktop: thunderpickLogo,
    logoMobile: thunderpickLogoMobile,
    desktop: thunderpickFooter,
    mobile: thunderpickFooterMobile,
    url: "https://ilnet.link/tpinstalock",
    bonus: 100,
  },
  esportsBet: {
    logoDesktop: esportsbetLogo,
    logoMobile: esportsbetLogoMobile,
    desktop: esportsbetFooter,
    mobile: esportsbetFooterMobile,
    url: "https://ilnet.link/esbinstalock",
    bonus: 50,
  },
  bovada: {
    logoDesktop: bovadaLogo,
    logoMobile: bovadaLogoMobile,
    desktop: bovadaFooter,
    mobile: bovadaFooterMobile,
    url: "https://ilnet.link/bovadainstalock",
    bonus: 75,
  },
  underdogFantasy: {
    logoDesktop: underdogFantasyLogo,
    logoMobile: underdogFantasyLogoMobile,
    desktop: underdogFantasyFooter,
    mobile: underdogFantasyFooterMobile,
    url: "https://ilnet.link/underdoginstalock",
    bonus: 100,
  },
};

const FooterBanner = () => {
  const [isBottom, setIsBottom] = React.useState(false);
  const [footerData, setFooterData] = React.useState(null);

  const { userRegion } = useContext(UserContext);
  const handleScroll = () => {
    const bottom = Math.ceil(document.querySelector("#app").scrollTop) > 10;

    if (bottom) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };
  React.useEffect(() => {
    document.querySelector("#app").addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      document
        .querySelector("#app")
        .removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getFooterData = () => {
    const num = Math.floor(Math.random() * 100);
    console.log({ userRegion });
    let data = {};
    if (userRegion !== globalConstants.REGION_TYPES.NA) {
      if (num >= 30) {
        data = FOOTER_DATA.thunderpick;
      } else {
        data = FOOTER_DATA.esportsBet;
      }
    } else {
      if (num >= 80) {
        data = FOOTER_DATA.underdogFantasy;
      } else if (num >= 40) {
        data = FOOTER_DATA.bovada;
      } else {
        data = FOOTER_DATA.thunderpick;
      }
    }
    setFooterData(data);
  };

  useEffect(() => {
    getFooterData();
  }, [userRegion]);

  const CTAButtons = (
    <div className={classes.buttonsContainer}>
      <MainButton
        // clicked={footerData?.clicked}
        type="dashed"
        buttonText="INSTALOCK"
        icon={couponIcon}
      />
      <MainButton
        // clicked={footerData?.clicked}
        type="blued"
        buttonText="GET STARTED"
      />
    </div>
  );

  return footerData ? (
    <>
      <a
        href={footerData.url}
        target="_blank"
        className={`${classes.footerBannerContainer} ${
          isBottom ? classes.show : ""
        } ${classes.desktop}`}
        style={{
          backgroundImage: `url("${footerData.desktop}")`,
        }}
        rel="noreferrer"
      >
        <div className={classes.logoContainer}>
          <img src={footerData.logoDesktop} alt={footerData.logoDesktop} />
        </div>
        <div
          className={classes.textContainer}
        >{`+${footerData.bonus}% Deposit Bonus!`}</div>
        {CTAButtons}
      </a>
      <a
        href={footerData.url}
        target="_blank"
        className={`${classes.footerBannerMobileContainer} ${
          isBottom ? classes.show : ""
        } ${classes.mobile}`}
        style={{
          backgroundImage: `url("${footerData.mobile}")`,
        }}
        rel="noreferrer"
      >
        <div className={classes.mobileFirstRow}>
          <div className={classes.logoContainer}>
            <img src={footerData.logoMobile} alt={footerData.logoMobile} />
          </div>
          <div
            className={classes.textContainer}
          >{`+${footerData.bonus}% Deposit Bonus!`}</div>
        </div>
        {CTAButtons}
      </a>
    </>
  ) : null;
};

export default FooterBanner;
