// Vendors
import React from "react";

export const thunderPick = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 15.8044C2.76904 14.1082 5.53808 12.412 8.30711 10.7168L8.27345 10.5657H3.41421L3.35233 10.361C5.25781 9.30449 7.16237 8.24801 9.06785 7.19154L9.03145 7.06505C8.76574 7.06505 8.50003 7.06505 8.23523 7.06505C7.00677 7.06869 5.77922 7.07597 4.55076 7.0696C4.41881 7.0696 4.28778 6.98134 4.15674 6.93493C4.22226 6.82482 4.26412 6.68833 4.35603 6.60825C6.84206 4.45526 9.33356 2.30773 11.8269 0.162932C11.9133 0.0883149 12.0334 0.00550763 12.1381 0.00459766C13.3738 -0.00632198 14.6087 -0.0026821 15.8772 0.0874049C13.7305 1.73991 11.5848 3.39333 9.43821 5.04583L9.48643 5.16049H14.7142C14.6787 5.74924 14.5386 6.15872 13.9881 6.43626C12.8251 7.0232 11.7031 7.68929 10.5647 8.32445C10.4528 8.38724 10.3473 8.46095 10.2581 8.61837H13.9207C13.7769 9.1962 13.6932 9.7158 13.504 10.1935C13.4348 10.3682 13.1254 10.4674 12.9079 10.5612C9.27714 12.1245 5.64272 13.6824 2.00921 15.2411C1.50782 15.4559 1.00825 15.6743 0.505033 15.8836C0.375818 15.9373 0.234772 15.9618 0.0991867 16L0 15.8044H0Z"
      fill="#17BCF2"
    />
  </svg>
);
