// Vendors
import React from "react";

export const exitIcon = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0736 11.984H1.57626C0.705662 11.984 0 11.2781 0 10.4077L0.000183241 1.58964C0.000183241 0.719227 0.705846 0.0133773 1.57644 0.0133773H7.10146C7.14526 0.00714723 7.18924 0 7.23486 0C7.76297 0 8.19102 0.428234 8.19102 0.95616C8.19102 1.48427 7.76279 1.91232 7.23486 1.91232C7.18924 1.91232 7.14526 1.90517 7.10146 1.89894L5.68521 1.89913C5.67862 1.89913 5.67239 1.89729 5.66542 1.89729H2.51863C2.17047 1.89729 1.88812 2.17948 1.88812 2.52781V9.47091C1.88812 9.81925 2.17049 10.1016 2.51863 10.1016H5.66542C5.68723 10.1016 5.70775 10.0972 5.72901 10.0954H7.16121C7.18595 10.0934 7.20977 10.0877 7.23469 10.0877C7.7628 10.0877 8.19085 10.5159 8.19085 11.0438C8.19085 11.5719 7.76261 12 7.23469 12C7.17971 11.9998 7.12639 11.9925 7.07362 11.9837L7.0736 11.984ZM10.6165 5.07418L9.68277 4.12646C9.37364 3.81734 9.37364 3.31601 9.68277 3.00687L9.90577 2.78387C10.2149 2.47474 10.7164 2.47474 11.0255 2.78387L13.5339 5.32065L13.6708 5.45753C13.8255 5.61219 13.9028 5.81503 13.9028 6.01805C13.9028 6.22108 13.8259 6.42356 13.6708 6.57858L13.5684 6.68101L11.0256 9.26198C10.7164 9.5711 10.2149 9.5711 9.90579 9.26198L9.68279 9.03897C9.37366 8.72985 9.37366 8.22833 9.68279 7.9192L10.6236 6.95975H5.83502C5.40129 6.95975 5.04985 6.60829 5.04985 6.17458V5.8594C5.04985 5.42567 5.4013 5.07423 5.83502 5.07423L10.6165 5.07418Z"
      fill="#58586B"
    />
  </svg>
);
