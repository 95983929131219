export const verificationPageConstants = {
  TABS: {
    VERIFIED: { label: "Verified bets", key: "VERIFIED", API_KEY: "VERIFIED" },
    DECLINED: { label: "Denied bets", key: "DECLINED", API_KEY: "DECLINED" },
    PENDING: { label: "Your tickets", key: "PENDING", API_KEY: "PENDING" },
    ALL: { label: "All bets", key: "ALL", API_KEY: null },
  },

  VERIFY_OPTIONS: {
    VERIFIED: "VERIFIED",
    DENIED: "DECLINED",
  },
};
