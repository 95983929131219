// Vendors
import React from "react";

const GiftIcon = ({ color }) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.13893e-05 6.23997V15.8618C7.13893e-05 17.0408 0.959321 18 2.13804 18H13.3634C14.5424 18 15.5016 17.0408 15.5016 15.8618V6.23997C15.5016 5.06097 14.5424 4.10172 13.3634 4.10172L11.1981 4.10186C11.4527 3.94672 11.6601 3.78461 11.7881 3.619C12.5469 2.63839 12.3673 1.22836 11.3867 0.469571C10.9778 0.153164 10.494 0 10.0142 0C9.34318 0 8.67968 0.299386 8.23714 0.870964C8.01128 1.16282 7.85696 1.68821 7.75068 2.28139C7.64451 1.68807 7.49007 1.16254 7.26421 0.870964C6.82196 0.299393 6.15846 0 5.48743 0C5.00764 0 4.52386 0.153182 4.11507 0.469857C3.13446 1.22864 2.95493 2.63868 3.71371 3.61929C3.84178 3.78474 4.04937 3.94671 4.30371 4.10214L2.13796 4.102C0.95925 4.10186 0 5.061 0 6.23997H7.13893e-05ZM1.06914 15.8618V8.91282H7.21628V16.931H2.13807C1.54879 16.931 1.06914 16.4515 1.06914 15.8618V15.8618ZM14.4327 15.8618C14.4327 16.4512 13.9531 16.9308 13.3636 16.9308H8.28543V8.91297H14.4326L14.4327 15.8618ZM13.3638 5.17104C13.9532 5.17104 14.4329 5.65068 14.4329 6.24011V7.84375L8.28571 7.84361V5.17104L13.3638 5.17104ZM9.08271 1.52532C9.30704 1.23529 9.64675 1.069 10.0144 1.069C10.2762 1.069 10.5244 1.15396 10.7324 1.31496C10.9809 1.5072 11.1394 1.78481 11.1792 2.09621C11.219 2.40787 11.135 2.71617 10.9428 2.96439C10.7353 3.22443 9.76642 3.65913 8.62817 4.03317C8.70476 2.83856 8.88278 1.79193 9.08271 1.52532ZM4.32271 2.09632C4.36247 1.78466 4.5211 1.50732 4.76957 1.31507C4.97758 1.15408 5.22589 1.06912 5.48775 1.06912C5.85521 1.06912 6.19479 1.23556 6.41896 1.52519C6.6186 1.79124 6.79632 2.83841 6.87321 4.03383C5.73579 3.66023 4.76718 3.22537 4.55946 2.96476C4.3668 2.7163 4.28281 2.40797 4.32271 2.09632ZM7.21628 5.17097V7.84382L1.06914 7.84368V6.2399C1.06914 5.65047 1.54879 5.17083 2.13821 5.17083L7.21628 5.17097Z"
      fill={color || "#000000"}
    />
  </svg>
);

export default GiftIcon;
