// Vendors
import React from "react";

export const cupIcon = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5333 1.46666H11.2V0.999992C11.2 0.876243 11.1508 0.757491 11.0633 0.669992C10.9758 0.582493 10.8571 0.533325 10.7333 0.533325H3.26667C3.14292 0.533325 3.02417 0.582493 2.93667 0.669992C2.84917 0.757491 2.8 0.876243 2.8 0.999992V1.46666H0.466667C0.342917 1.46666 0.224165 1.51583 0.136667 1.60333C0.049168 1.69082 0 1.80958 0 1.93333V3.79999C0.00020832 4.60322 0.29624 5.37823 0.83168 5.97698C1.3672 6.57573 2.10448 6.95615 2.90272 7.04562C3.19939 8.37071 4.11677 9.47135 5.36667 10.0019V12.1999H3.73333C3.60958 12.1999 3.49083 12.2491 3.40333 12.3366C3.31583 12.4241 3.26667 12.5428 3.26667 12.6666V14.9999C3.26667 15.1237 3.31583 15.2424 3.40333 15.3299C3.49083 15.4174 3.60958 15.4666 3.73333 15.4666H10.2667C10.3904 15.4666 10.5092 15.4174 10.5967 15.3299C10.6842 15.2424 10.7333 15.1237 10.7333 14.9999V12.6666C10.7333 12.5428 10.6842 12.4241 10.5967 12.3366C10.5092 12.2491 10.3904 12.1999 10.2667 12.1999H8.63333V10.0019C9.88323 9.47127 10.8006 8.37063 11.0973 7.04562C11.8955 6.95614 12.6328 6.57573 13.1683 5.97698C13.7037 5.37823 13.9998 4.60322 14 3.79999V1.93333C14 1.80958 13.9508 1.69082 13.8633 1.60333C13.7758 1.51583 13.6571 1.46666 13.5333 1.46666V1.46666ZM2.8 6.08666C2.27291 5.97905 1.79928 5.69271 1.45917 5.27605C1.11896 4.85938 0.933227 4.33791 0.933333 3.79999V2.39999H2.8V6.08666ZM9.8 13.1333V14.5333H4.2V13.1333H9.8ZM6.3 12.2V10.2703C6.53125 10.3102 6.76531 10.3314 7 10.3333C7.23469 10.3314 7.46875 10.3102 7.7 10.2703V12.2H6.3ZM10.2667 6.13333C10.2667 7.30042 9.64405 8.37885 8.63333 8.96239C7.62261 9.54583 6.37739 9.54583 5.36667 8.96239C4.35595 8.37885 3.73333 7.30042 3.73333 6.13333V1.46666H10.2667V6.13333ZM13.0667 3.79999C13.0668 4.33791 12.881 4.85938 12.5408 5.27605C12.2007 5.69271 11.7271 5.97906 11.2 6.08666V2.39999H13.0667V3.79999Z"
      fill="black"
    />
  </svg>
);
