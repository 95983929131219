// Vendors
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";
import DividerLine from "../DividerLine/DividerLine";
import ComparedOdds from "../ComparedOdds/ComparedOdds";
import ProfileTag from "../ProfileTag/ProfileTag";

// Styles
import classes from "./InstalockAction.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Utils
import { formatDollars } from "../../utils/globalUtils";
import OddsTag from "../OddsTag/OddsTag";

const InstalockAction = ({
  gameData,
  page,
  hideInstalockerData,
  showBetOdds,
  preferredOddsType,
}) => {
  return (
    <Link
      className={`${classes.instalockActionContainer} ${
        gameData.isSeriesPresent ? classes.isSeriesPresent : ""
      }`}
      to={`/${globalConstants.ROUTES.MATCH_PAGE}/${gameData.abiosSeriesId}/`}
      style={{ pointerEvents: !gameData.isSeriesPresent ? "none" : null }}
    >
      {!page ? (
        <div className={classes.gameTitleContainer}>
          <IconLabel
            label={globalConstants.TITLES[gameData.game]}
            isSvg
            icon={esportsLogos[gameData.game]}
          />
        </div>
      ) : null}
      {gameData.tournamentName && (
        <p className={classes.tournamentName}>{gameData.tournamentName}</p>
      )}
      <div className={classes.matchPageLinkContainer}>
        {gameData.isSeriesPresent ? (
          <Link
            className={classes.matchPageLink}
            to={{
              pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${gameData.abiosSeriesId}`,
            }}
          >
            Match Page &#8594;
          </Link>
        ) : null}
      </div>
      <DividerLine color={styledVariables.backgroundPrimaryE} />
      <div className={classes.gameRow}>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={gameData.teamOne.imageSmall}
            label={
              gameData.teamOne.teamAbbreviation || gameData.teamOne.teamName
            }
            bold
            withAbbreviation
          />
          {!!gameData.teamOne.odds && gameData.teamOne.odds !== 100 ? (
            <OddsTag
              odds={gameData.teamOne.odds}
              fullWidth
              preferredOddsType={preferredOddsType}
            />
          ) : null}
        </div>
        <div className={classes.versusBoxContainer}>
          <div className={classes.versusBox}>VS </div>
          <div className={classes.dateBox}>
            <span>{format(new Date(gameData.startDate), "HH:mm")}</span>
            <span>{format(new Date(gameData.startDate), "MMM dd")}</span>
          </div>
        </div>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={gameData.teamTwo.imageSmall}
            label={
              gameData.teamTwo.teamAbbreviation || gameData.teamTwo.teamName
            }
            inverted
            bold
            withAbbreviation
          />
          {!!gameData.teamTwo.odds && gameData.teamTwo.odds !== 100 ? (
            <OddsTag
              odds={gameData.teamTwo.odds}
              fullWidth
              preferredOddsType={preferredOddsType}
            />
          ) : null}
        </div>
      </div>
      {/* {gameData.betsCount && (
        <div className={classes.betRow}>{gameData.betsCount} Bets</div>
      )} */}
      {showBetOdds &&
      gameData.teamOne.bets >= 0 &&
      gameData.teamTwo.bets >= 0 ? (
        <div className={classes.oddsContainer}>
          <ComparedOdds
            firstOdds={gameData.teamOne.bets}
            firstOddsColor={styledVariables.backgroundEmphasisA}
            secondOdds={gameData.teamTwo.bets}
            secondOddsColor={styledVariables.backgroundEmphasisQ}
          />
        </div>
      ) : null}
      {!hideInstalockerData && gameData.highestInstalocker && (
        <div className={classes.highestInstalockerContainer}>
          <DividerLine color={styledVariables.backgroundPrimaryE} />
          <div className={classes.instalockerTitle}>
            <span>Highest unit Instalocker</span>
          </div>
          <div className={classes.instalockerRow}>
            <ProfileTag
              isOnline
              profileName={gameData.highestInstalocker.username}
            />
            {gameData.highestInstalocker.betAmount && (
              <div className={classes.instalockerBet}>
                {formatDollars(gameData.highestInstalocker.betAmount)}
              </div>
            )}
          </div>
        </div>
      )}
    </Link>
  );
};

export default InstalockAction;
