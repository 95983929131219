// Vendors
import React from "react";

// Assets
import defaultProfilePicture from "../../assets/images/profile/defaultProfilePicture.jpg";

// Styles
import classes from "./HeaderProfile.module.scss";

const HeaderProfile = ({ username, image }) => {
  return (
    <div className={classes.headerProfileContainer}>
      <div className={classes.profileImageContainer}>
        {<img src={image || defaultProfilePicture} alt="profileplaceholder" />}
      </div>
      <div className={classes.profileTextContainer}>
        <p className={classes.profileGreeting}>Greetings,</p>
        <p className={classes.profileUsername}>{username}</p>
      </div>
    </div>
  );
};

export default HeaderProfile;
