// Vendors
import React from "react";
import { Link } from "react-router-dom";

import { Tooltip } from "antd";
import { format } from "date-fns";

// Components
import IconLabel from "../IconLabel/IconLabel";
import ScoreCard from "../ScoreCard/ScoreCard";

// Styles
import classes from "./ScheduleCard.module.scss";
import ScheduleCardScore from "../ScheduleCardScore/ScheduleCardScore";

// Utils
import { formatNumberWithSymbol } from "../../utils/globalUtils";

// Assets
import { defaultTeamIcon } from "../../assets/icons/defaultTeam/defaultTeam";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import OddsTag from "../OddsTag/OddsTag";

const ScheduleCard = ({
  scheduledMatch,
  preferredOddsType,
  teamData,
  isHeadToHead,
  newSeriesDetails,
  isRightTeam,
}) => {
  const { startDate, tournamentName } = scheduledMatch;

  const generateSpreadAmountToShow = () => {
    return formatNumberWithSymbol(scheduledMatch.spread);
  };

  const generateSpreadResult = () => {
    return scheduledMatch.spreadResult;
  };

  let teamToShowOnResult =
    scheduledMatch.result === "WIN"
      ? {
          teamName: teamData.teamName,
          teamImgLg: teamData.teamImgLg,
          teamImgSm: teamData.teamImgSm,
        }
      : {
          teamName: scheduledMatch.oppTeamName,
          teamImgLg: scheduledMatch.oppTeamImgLg,
          teamImgSm: scheduledMatch.oppTeamImgSm,
        };

  if (isHeadToHead) {
    teamToShowOnResult =
      scheduledMatch.result === "WIN"
        ? {
            teamName: newSeriesDetails.teamOne.teamName,
            teamImgLg: newSeriesDetails.teamOne.teamImgLg,
            teamImgSm: newSeriesDetails.teamOne.teamImgSm,
          }
        : {
            teamName: newSeriesDetails.teamTwo.teamName,
            teamImgLg: newSeriesDetails.teamTwo.teamImgLg,
            teamImgSm: newSeriesDetails.teamTwo.teamImgSm,
          };
  }

  return (
    <Link
      className={`${classes.cardContainer} ${
        scheduledMatch.isSeriesPresent ? classes.isSeriesPresent : ""
      }`}
      to={`/${globalConstants.ROUTES.MATCH_PAGE}/${scheduledMatch.abiosSeriesId}/`}
      style={{ pointerEvents: !scheduledMatch.isSeriesPresent ? "none" : null }}
    >
      <div className={classes.firstRow}>
        <p className={classes.tournamentName}>{tournamentName}</p>
        {scheduledMatch.isSeriesPresent ? (
          <span>
            <Link
              className={classes.matchPageLink}
              to={{
                pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${scheduledMatch.abiosSeriesId}`,
              }}
            >
              Match Page &#8594;
            </Link>
          </span>
        ) : null}
      </div>

      <div className={classes.scheduleCardContainer}>
        <div className={classes.dateCell}>
          <p className={classes.matchDate}>
            {format(new Date(startDate), "MMM dd / yyyy")}
          </p>
          {!isHeadToHead ? (
            <div className={classes.vsContainer}>
              <div className={classes.vsTextContainer}>VS</div>
              <IconLabel
                size="small"
                icon={
                  scheduledMatch.oppTeamImgLg || scheduledMatch.oppTeamImgSm
                }
                tooltipText={scheduledMatch.oppTeamName}
                withAbbreviation
                label={
                  scheduledMatch.oppTeamAbbreviation ||
                  scheduledMatch.oppTeamName
                }
              />
            </div>
          ) : null}
        </div>
        <div className={classes.resultCell}>
          {teamToShowOnResult.teamImgLg || teamToShowOnResult.teamImgSm ? (
            <IconLabel
              size="small"
              icon={
                teamToShowOnResult.teamImgLg || teamToShowOnResult.teamImgSm
              }
              tooltipText={teamToShowOnResult.teamName}
            />
          ) : (
            <Tooltip placement="top" title={teamToShowOnResult.teamName}>
              <div className={classes.defaultTeamIcon}>{defaultTeamIcon}</div>
            </Tooltip>
          )}
          <div className={classes.scheduledScoreContainer}>
            {scheduledMatch.teamSeriesScore}-{scheduledMatch.oppTeamSeriesScore}
          </div>
        </div>
        <div className={classes.scoreList}>
          {scheduledMatch?.matches &&
            scheduledMatch?.matches?.map((scoreData, index) => {
              return (
                <ScheduleCardScore
                  scheduledMatch={scheduledMatch}
                  teamData={teamData}
                  scoreData={scoreData}
                  key={index}
                  isHeadToHead={isHeadToHead}
                  newSeriesDetails={newSeriesDetails}
                />
              );
            })}
        </div>
        <div className={classes.oddsCell}>
          <h3 className={classes.mobileTitle}>Moneyline</h3>
          <div className={classes.oddsContainer}>
            <IconLabel
              size="small"
              icon={
                isHeadToHead
                  ? newSeriesDetails.teamOne.teamImgLg ||
                    newSeriesDetails.teamOne.teamImgSm
                  : teamData.teamImgLg || teamData.teamImgSm
              }
              tooltipText={
                isHeadToHead
                  ? newSeriesDetails.teamOne.teamName
                  : teamData.teamName
              }
              withAbbreviation
            />

            {scheduledMatch.teamOdds ? (
              <OddsTag
                odds={scheduledMatch.teamOdds}
                size="large"
                preferredOddsType={preferredOddsType}
              />
            ) : null}
            <div />
            {" - "}
            <IconLabel
              size="small"
              icon={
                isHeadToHead
                  ? newSeriesDetails.teamTwo.teamImgLg ||
                    newSeriesDetails.teamTwo.teamImgSm
                  : scheduledMatch.oppTeamImgLg || scheduledMatch.oppTeamImgSm
              }
              tooltipText={
                isHeadToHead
                  ? newSeriesDetails.teamTwo.teamName
                  : scheduledMatch.oppTeamName
              }
              withAbbreviation
            />
            {scheduledMatch.oppTeamOdds ? (
              <OddsTag
                odds={scheduledMatch.oppTeamOdds}
                size="large"
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.spreadCell}>
          <h3 className={classes.mobileTitle}>Spread</h3>
          <div className={classes.spreadContainer}>
            {scheduledMatch.spread ? (
              <>
                <ScoreCard result={generateSpreadResult()} />
                <IconLabel
                  size="small"
                  icon={
                    isRightTeam
                      ? newSeriesDetails.teamTwo.teamImgLg ||
                        newSeriesDetails.teamTwo.teamImgSm
                      : newSeriesDetails.teamOne.teamImgLg ||
                        newSeriesDetails.teamOne.teamImgSm
                  }
                  tooltipText={
                    isRightTeam
                      ? newSeriesDetails.teamTwo.teamName
                      : newSeriesDetails.teamOne.teamName
                  }
                />
                <p className={classes.spreadAmount}>
                  {generateSpreadAmountToShow()}
                </p>
              </>
            ) : (
              <div className={classes.notAvailableContainer}>N/A</div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ScheduleCard;
