// Vendors
import React from "react";
import { format } from "date-fns";
import { ClockCircleOutlined } from "@ant-design/icons";

// Components
import IconLabel from "../IconLabel/IconLabel";
import DividerLine from "../DividerLine/DividerLine";

// Utils
import {
  transformOddToPrefered,
  formatNumberWithSymbol,
} from "../../utils/globalUtils";

// Assets
import { trashIcon } from "../../assets/icons/trash/trash";

// Styles
import classes from "./BetLogItem.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";
import { globalConstants } from "../../constants/globalConstants";
import MainButton from "../MainButton/MainButton";

const BetLogItem = ({
  betData,
  handleDeleteBet,
  isProfileOwner,
  loadingDeleteBet,
  preferredOddsType,
}) => {
  return betData ? (
    <div className={classes.betLogItemContainer}>
      <div className={classes.headerRow}>
        {betData.status === "PENDING" ? (
          <p className={classes.pendingText}>
            <span>
              <ClockCircleOutlined />{" "}
            </span>
            Pending
          </p>
        ) : null}

        {betData.abiosSeriesDate ? (
          <p
            className={classes.dateText}
            style={
              betData.status !== globalConstants.PENDING
                ? { border: "none" }
                : {}
            }
          >
            {format(new Date(betData.abiosSeriesDate), "MMM. dd")}
          </p>
        ) : null}
      </div>

      <div className={classes.matchData}>
        <IconLabel
          label={
            betData.selectedAbiosTeam.teamAbbreviation ||
            betData.selectedAbiosTeam.teamName
          }
          size="medium"
          icon={betData.selectedAbiosTeam.imageSmall}
          withAbbreviation
        />
        <span className={classes.versusText}>VS</span>
        <IconLabel
          label={
            betData.alternativeAbiosTeam.teamAbbreviation ||
            betData.alternativeAbiosTeam.teamName
          }
          size="medium"
          icon={betData.alternativeAbiosTeam.imageSmall}
          withAbbreviation
          inverted
        />
      </div>

      <DividerLine color={styledVariables.backgroundPrimaryE} />

      <div className={classes.betData}>
        <div className={classes.betDataTeamRow}>
          <IconLabel
            label={
              betData.selectedAbiosTeam.teamAbbreviation ||
              betData.selectedAbiosTeam.teamName
            }
            size="medium"
            icon={betData.selectedAbiosTeam.imageSmall}
            withAbbreviation
          />

          {betData.type === globalConstants.SPREAD && betData.spread ? (
            <div className={classes.betTypeLabel}>
              <span> Spread</span>
              {formatNumberWithSymbol(parseFloat(betData.spread))}
              <span
                className={`${classes.odds} ${
                  betData.odd >= 100 ? classes.favorite : classes.underdog
                }`}
              >
                {transformOddToPrefered(betData.odd, preferredOddsType)}
              </span>
            </div>
          ) : (
            <div className={classes.betTypeLabel}>
              <span>Money Line</span>
              <span
                className={`${classes.odds} ${
                  betData.odd >= 100 ? classes.favorite : classes.underdog
                }`}
              >
                {transformOddToPrefered(betData.odd, preferredOddsType)}
              </span>
            </div>
          )}
        </div>
        {isProfileOwner ? (
          <MainButton
            clicked={() => handleDeleteBet(betData.betId)}
            icon={trashIcon}
            loading={loadingDeleteBet}
            size="small"
            type="danger"
          />
        ) : null}
      </div>
    </div>
  ) : null;
};

export default BetLogItem;
