// Vendors
import React from "react";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./MatchTeamProfitability.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";
import ScoreCard from "../ScoreCard/ScoreCard";

const MatchTeamProfitability = ({ matchInfo }) => {
  const noRecordBox = <div className={classes.noRecordBox}>N/A</div>;

  return (
    <div className={classes.matchTeamProfitabilityContainer}>
      <div className={classes.titleContainer}>
        <h3>Profitability in Last 5 Games ($100 Each Game)</h3>
      </div>
      <div className={classes.matchTeamProfitability}>
        {[matchInfo.teamOne, matchInfo.teamTwo].map((team, index) => {
          const isProfitable = team.lastFive?.amountLeft > 0;
          const isEven = team.lastFive?.amountLeft === 0;

          const noRecords = !team?.lastFive?.results?.length > 0;

          const wins = team?.lastFive?.results?.filter(
            (mat) => mat === "WIN"
          ).length;
          const losses = team?.lastFive?.results?.filter(
            (mat) => mat === "LOSS"
          ).length;
          const pushes = team?.lastFive?.results?.filter(
            (mat) => mat === "PUSH"
          ).length;

          return (
            <div
              className={classes.teamRow}
              key={team.abiosTeamId}
              style={{
                borderBottom:
                  index === 0
                    ? `1px solid ${styledVariables.backgroundPrimaryB}`
                    : "",
              }}
            >
              <div className={classes.leftSide}>
                <IconLabel
                  label={team.teamAbbreviation || team.teamName}
                  size="small"
                  icon={team.teamImgSm || team.teamImgLg}
                  withAbbreviation
                />

                {noRecords ? (
                  noRecordBox
                ) : isProfitable ? (
                  <div className={classes.profitableBox}>PROFITABLE ↑</div>
                ) : isEven ? (
                  <div className={classes.evenBox}>EVEN</div>
                ) : (
                  <div className={classes.unprofitableBox}>UNPROFITABLE ↓</div>
                )}

                <div
                  className={`${classes.recordText} 
                ${isEven && classes.isEven}
                ${isProfitable && classes.isProfitable}
                ${noRecords && classes.noRecords}
                ${!isEven && !isProfitable && !noRecords && classes.isLoss}
                `}
                >
                  {noRecords
                    ? "No records"
                    : `${
                        isProfitable ? "💸 +" : isEven ? "" : "🔻 -"
                      }$${Math.abs(team?.lastFive?.amountLeft).toFixed(
                        2
                      )} (${wins} ${
                        pushes ? `-${pushes}-` : "-"
                      } ${losses}) Record`}
                </div>
              </div>
              <div className={classes.rightSide}>
                {noRecords
                  ? noRecordBox
                  : team?.lastFive?.results?.map((result, index) => {
                      return <ScoreCard key={index} result={result} />;
                    })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MatchTeamProfitability;
