// Vendors
import React from "react";
import { useOutletContext } from "react-router-dom";

// Components
import GuideCard from "../../components/GuideCard/GuideCard";

// Styles
import classes from "./EducationGuidesPage.module.scss";

const EducationGuidesPage = () => {
  const outletProps = useOutletContext();
  const { guides } = outletProps;

  return (
    <div className={classes.educationGuidesPage}>
      {guides &&
        guides.length > 0 &&
        guides.map((guide) => {
          return <GuideCard key={guide.id} guideData={guide} />;
        })}
    </div>
  );
};

export default EducationGuidesPage;
