// Vendors
import React from "react";
import { Skeleton } from "antd";

// Styles
import styledVariables from "../../styles/utils/_variables.scss";

const MainSkeleton = ({
  borderRad,
  color,
  fullWidth,
  height,
  horizontalMargin,
  type,
  verticalMargin,
  width,
}) => {
  if (type === "rectangle") {
    return (
      <div
        style={{
          width: `${fullWidth ? "100%" : width || 100}px`,
          height: `${height || 100}px`,
          marginTop: `${verticalMargin || 0}px`,
          marginBottom: `${verticalMargin || 0}px`,
          marginRight: `${horizontalMargin || 0}px`,
          marginLeft: `${horizontalMargin || 0}px`,
          borderRadius: `${borderRad || 0}px`,
          overflow: "hidden",
        }}
      >
        <Skeleton.Input
          active={true}
          size={100}
          block={false}
          style={{
            height: `${height || 100}px`,
            backgroundColor: styledVariables[color || "backgroundPrimaryA"],
            overflow: "hidden",
          }}
        />
      </div>
    );
  }
  return null;
};

export default MainSkeleton;
