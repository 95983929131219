// Vendors
import React, { useEffect, useState, createRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { v4 as uuid } from "uuid";

// Requests
import { getLatestResultsData } from "../../api/requests/requests";

// WebSockets
import SockJS from "sockjs-client";
import Stomp from "stompjs";

// Components
import LatestResult from "../LatestResult/LatestResult";

// Styles
import classes from "./LatestResultsWrapper.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const LatestResultsWrapper = ({ preferredOddsType, game }) => {
  const [loadingLatestsResultsData, setLoadingLatestsResultsData] =
    useState(false);
  const [latestsResultsData, setLatestsResultsData] = useState(null);

  const handleGetLatestResultsData = () => {
    setLoadingLatestsResultsData(true);
    getLatestResultsData(
      globalConstants.TITLES_IDS[game] || null,
      (data) => {
        const formattedData = data?.map((pick) => {
          return {
            ...pick,
            id: uuid(),
            nodeRef: createRef(null),
          };
        });
        setLatestsResultsData(formattedData);
        setLoadingLatestsResultsData(false);
      },
      () => {
        setLatestsResultsData(null);
        setLoadingLatestsResultsData(false);
      }
    );
  };

  useEffect(() => {
    var stompClient = null;
    var socket = new SockJS(process.env.REACT_APP_BASE_URL_WEB_SOCKETS);
    stompClient = Stomp.over(socket);
    stompClient.connect(
      {
        Authorization: `bearer ${
          sessionStorage.getItem("accessToken") ||
          sessionStorage.getItem("clientToken")
        }`,
      },
      function () {
        stompClient.subscribe("/topic/series", function (messageOutput) {
          const message = JSON.parse(messageOutput.body);
          if (!game || game === message.game) {
            const messageBody = JSON.parse(messageOutput.body);
            setLatestsResultsData((prevResults) =>
              [
                {
                  id: uuid(),
                  ...messageBody,
                  nodeRef: createRef(null),
                },
                ...prevResults,
              ].slice(0, 20)
            );
          }
        });
      }
    );

    return () => {
      if (stompClient.connected) {
        stompClient.unsubscribe("/topic/bets", function (test) {});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetLatestResultsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  return !loadingLatestsResultsData && latestsResultsData?.length > 0 ? (
    <div className={classes.latestResultsContainer}>
      <div className={classes.columnTitle}>Latest Results</div>
      <TransitionGroup
        className={`${classes.pickedAnimatedList} ${
          latestsResultsData?.length > 3 ? classes.overflowYScroll : ""
        }`}
        appear={true}
      >
        {latestsResultsData?.map((latestResult) => {
          return (
            <CSSTransition
              key={latestResult.id}
              nodeRef={latestResult.nodeRef}
              timeout={1}
              classNames={{
                enterActive: classes["latestPickAnimated-enter-active"],
                exitActive: classes["latestPickAnimated-exit-active"],
                enterDone: classes["latestPickAnimated-enter-done"],
                exitDone: classes["latestPickAnimated-exit-done"],
              }}
            >
              <div
                ref={latestResult.nodeRef}
                className={classes.latestPickAnimated}
              >
                <LatestResult
                  latestResultData={latestResult}
                  preferredOddsType={preferredOddsType}
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  ) : null;
};

export default LatestResultsWrapper;
