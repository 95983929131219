// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Components
import LiveCard from "../LiveCard/LiveCard";
import MainButton from "../MainButton/MainButton";

// Styles
import classes from "./EsportGameCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const EsportGameCard = ({ esport }) => {
  return (
    <div className={classes.esportGameCardContainer}>
      <Link
        className={classes.esportGameCardImageContainer}
        to={{
          pathname: `/${globalConstants.ROUTES.ESPORTS}/${esport.name}/`,
        }}
      >
        <img
          alt={`${esport.name}`}
          className={classes.esportGameCardImage}
          src={esport.image}
        />
      </Link>

      <div className={classes.esportGameCardCTAContainer}>
        <>
          <div className={classes.esportGameCardPickCountContainer}>
            <LiveCard isOffline={!esport.live} />
            {!esport.live || !esport.liveGames || esport.liveGames === 0 ? (
              <div className={classes.offlineGameCount}>0 Live games</div>
            ) : (
              <div className={classes.liveGameCount}>{`${
                esport.liveGames
              } Live game${esport.liveGames > 1 ? "s" : ""}`}</div>
            )}
          </div>
          <span className={classes.esportGameCardNameLabel}>
            {globalConstants.TITLES[esport.name]}
          </span>
        </>
        <Link
          to={{
            pathname: `/${globalConstants.ROUTES.ESPORTS}/${esport.name}/`,
          }}
        >
          <MainButton buttonText="See now" type="secondary" />
        </Link>
      </div>
    </div>
  );
};

export default EsportGameCard;
