// Vendors
import React from "react";

export const heartIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28198 0C2.00863 0 0 2.86252 0 5.21066C0 10.2877 5.77065 15.0002 9.84375 18C13.917 15 19.6875 10.2872 19.6875 5.21066C19.6875 2.86272 17.6788 0 14.4055 0C12.5775 0 11.0157 1.45748 9.84385 2.84213C8.67204 1.45748 7.1102 0 5.28218 0H5.28198Z"
      fill="#FF4D26"
    />
  </svg>
);
