// Vendors
import React from "react";

export const summonersRiftIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="320"
    height="320"
    viewBox="0 0 254 251"
    fill="none"
  >
    <path
      opacity="0.2"
      d="M251.618 2.32593H68.5647C31.802 2.32593 2 32.128 2 68.8906V248.326M251.618 2.32593V181.761C251.618 218.524 221.816 248.326 185.053 248.326H2M251.618 2.32593H166.965V7.39063C166.965 51.346 202.598 86.9789 246.553 86.9789H251.618V2.32593ZM251.618 2.32593H156.835V10.2848C156.835 58.2361 195.708 97.1083 243.659 97.1083H251.618V2.32593ZM2 248.326H86.6529V243.261C86.6529 199.306 51.0201 163.673 7.06471 163.673H2V248.326ZM2 248.326H96.7824V240.367C96.7824 192.416 57.9101 153.544 9.95882 153.544H2V248.326ZM22.6206 155.352V79.7436C22.6206 46.9769 49.1833 20.4142 81.95 20.4142H158.282M232.444 96.3847V171.994C232.444 204.76 205.881 231.323 173.115 231.323H96.7824M62.0529 172.717L176.732 63.8259M75.4382 185.379L189.032 76.1259"
      stroke="white"
      strokeWidth="4"
    />
  </svg>
);
