// Vendors
import React, { useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

// Components
import MainButton from "../MainButton/MainButton";
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./InstalockUpset.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import { instalockBlueIcon } from "../../assets/icons/instalockBlue/instalockBlue";

// Utils
import { abbreviateString } from "../../utils/globalUtils";
import OddsTag from "../OddsTag/OddsTag";
import WinResultAmount from "../WinResultAmount/WinResultAmount";

const InstalockAction = ({ upsetData, preferredOddsType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleClickLeft = (games) => {
    const prevIndex = currentIndex;
    let newIndex = 0;

    newIndex = prevIndex - 3;

    if (newIndex < 0) {
      newIndex = 0;
    }

    setCurrentIndex(newIndex);
  };

  const handleClickRight = (games) => {
    const prevIndex = currentIndex;
    let newIndex = 0;

    newIndex = prevIndex + 3;

    if (newIndex + 1 >= games.length - 1) {
      newIndex = games.length - 1 - 2;
    }
    setCurrentIndex(newIndex);
  };

  const generateUpsetData = (data) => {
    if (!data) {
      return [];
    }
    const games = [];
    Object.entries(upsetData).forEach((entry) => {
      const [esport, entryData] = entry;
      const { series } = entryData;

      series.forEach((serie, index) => {
        if (serie.teamOne.score > serie.teamTwo.score) {
          serie.teamOne.winner = true;
        } else {
          serie.teamTwo.winner = true;
        }

        games.push({ ...serie, esport, upsetPos: index + 1 });
      });
    });

    return games;
  };

  const getItemColor = (esport) => {
    switch (esport) {
      case globalConstants.LEAGUE_OF_LEGENDS:
        return styledVariables.backgroundEmphasisB;
      case globalConstants.VALORANT:
        return styledVariables.backgroundEmphasis1A;
      case globalConstants.CS_GO:
        return styledVariables.backgroundEmphasisZ;
      case globalConstants.ROCKET_LEAGUE:
        return styledVariables.backgroundEmphasisI;
      case globalConstants.CALL_OF_DUTY:
        return styledVariables.backgroundEmphasis1C;
      default:
        return styledVariables.backgroundEmphasisI;
    }
  };

  const games = generateUpsetData(upsetData);

  const displayItems = games.slice(currentIndex, currentIndex + 3);

  return upsetData && games.length > 0 ? (
    <>
      <div className={classes.columnTitle}>
        Biggest Upsets in Last 7 Days
        {games.length > 3 ? (
          <div className={classes.controls}>
            <MainButton
              icon={<ArrowLeftOutlined />}
              type="secondary"
              size="small"
              clicked={() => handleClickLeft(games)}
              disabled={currentIndex === 0}
            />
            <MainButton
              icon={<ArrowRightOutlined />}
              type="secondary"
              size="small"
              clicked={() => handleClickRight(games)}
              disabled={currentIndex + 2 >= games.length - 1}
            />
          </div>
        ) : null}
      </div>
      <div
        className={`${classes.instalockUpsetContainer}  ${
          displayItems?.length < 3 ? classes.justifyLeft : ""
        }`}
      >
        {displayItems.map((item, index) => {
          let bestOf = null;

          if (item.teamOne?.score && item.teamTwo?.score) {
            const score1 = item.teamOne?.score;
            const score2 = item.teamTwo?.score;

            if (score1 > score2) {
              bestOf = score1 * 2 - 1;
            } else if (score2 > score1) {
              bestOf = score2 * 2 - 1;
            }
          }

          return (
            <Link
              className={`${classes.upsetCard} ${
                item.isSeriesPresent ? classes.isSeriesPresent : ""
              }`}
              to={`/${globalConstants.ROUTES.MATCH_PAGE}/${item.abiosSeriesId}/`}
              style={{
                pointerEvents: !item.isSeriesPresent ? "none" : null,
                width: displayItems.length === 2 ? "100%" : "",
              }}
              key={index}
            >
              <div className={classes.instaBlueContainer}>
                {instalockBlueIcon}
              </div>
              <div className={classes.firstRow}>
                <div
                  className={classes.esportIndex}
                  style={{ backgroundColor: getItemColor(item.esport) }}
                >
                  {item.upsetPos}
                </div>

                <IconLabel
                  label={globalConstants.TITLES[item.esport]}
                  isSvg
                  icon={esportsLogos[item.esport]}
                />
              </div>
              <div className={classes.secondRow}>
                {bestOf > 0 ? (
                  <p className={classes.bestOf}>{`Best of ${bestOf}`}</p>
                ) : null}
                {item.isSeriesPresent ? (
                  <Link
                    className={classes.matchPageLink}
                    to={{
                      pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${item.abiosSeriesId}`,
                    }}
                  >
                    Match Page &#8594;
                  </Link>
                ) : null}
              </div>
              {item.tournamentName ? (
                <div className={classes.tournamentName}>
                  {item.tournamentName}
                </div>
              ) : null}
              <div className={classes.scoreRow}>
                <div className={classes.teamContainer}>
                  <IconLabel
                    icon={item.teamOne.imageSmall}
                    bold
                    withAbbreviation
                  />
                  <div className={classes.oddsAndName}>
                    <p className={classes.teamName}>
                      {abbreviateString(
                        item.teamOne.teamAbbreviation || item.teamOne.teamName,
                        6
                      )}
                    </p>
                    {!!item.teamOne.odds && item.teamOne.odds !== 100 ? (
                      <OddsTag
                        odds={item.teamOne.odds}
                        fullWidth
                        size="large"
                        preferredOddsType={preferredOddsType}
                      />
                    ) : null}
                  </div>
                </div>
                <div
                  className={`${classes.scoreBox} ${
                    item.teamOne.winner ? classes.winner : ""
                  }`}
                >
                  {item.teamOne.score}
                </div>
                <div className={classes.dateContainer}>
                  <p>FINAL</p>
                  {item.startDate ? (
                    <p>{format(new Date(item.startDate), "MM/dd/yy")}</p>
                  ) : null}
                </div>
                <div
                  className={`${classes.scoreBox} ${
                    item.teamTwo.winner ? classes.winner : ""
                  }`}
                >
                  {item.teamTwo.score}
                </div>
                <div className={classes.teamContainer}>
                  <IconLabel
                    icon={item.teamTwo.imageSmall}
                    bold
                    withAbbreviation
                  />
                  <div className={classes.oddsAndName}>
                    <p className={classes.teamName}>
                      {abbreviateString(
                        item.teamTwo.teamAbbreviation || item.teamTwo.teamName,
                        6
                      )}
                    </p>
                    {!!item.teamTwo.odds && item.teamTwo.odds !== 100 ? (
                      <OddsTag
                        odds={item.teamTwo.odds}
                        fullWidth
                        size="large"
                        preferredOddsType={preferredOddsType}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <WinResultAmount
                team={item.teamOne.winner ? item.teamOne : item.teamTwo}
              />
            </Link>
          );
        })}
      </div>
    </>
  ) : null;
};

export default InstalockAction;
