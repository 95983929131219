// Vendors
import React from "react";

export const doubleKillIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
  >
    <path
      d="M8.59766 12.7695L10.3274 11.0878V2.67944H8.59766V12.7695Z"
      fill="#FF4D26"
    />
    <path
      d="M6.86914 12.7695L5.13941 11.0878V2.67944H6.86914V12.7695Z"
      fill="#FF4D26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7874 1.23791H1.67932V11.4336L7.73337 15.3435L13.7874 11.4336V1.23791ZM14.6523 11.9046L7.73337 16.373L0.814453 11.9046V0.373047H14.6523V11.9046Z"
      fill="#FF4D26"
    />
  </svg>
);
