// Vendors
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./ResultCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Utils
import { abbreviateString } from "../../utils/globalUtils";
import { liveIcon } from "../../assets/icons/live/live";
import OddsTag from "../OddsTag/OddsTag";
import { instalockBlueIcon } from "../../assets/icons/instalockBlue/instalockBlue";

const ResultCard = ({ resultData, preferredOddsType, type }) => {
  let bestOf = resultData.bestOf;

  if (!resultData) {
    return null;
  }

  if (resultData.lifecycle === "OVER") {
    if (resultData.teamOne.score > resultData.teamTwo.score) {
      resultData.teamOne.winner = true;
    } else {
      resultData.teamTwo.winner = true;
    }
  }
  return (
    <Link
      className={`${classes.upsetCard} ${
        resultData.isSeriesPresent ? classes.isSeriesPresent : ""
      }`}
      to={`/${globalConstants.ROUTES.MATCH_PAGE}/${resultData.abiosSeriesId}/`}
      style={{ pointerEvents: !resultData.isSeriesPresent ? "none" : null }}
    >
      <div className={classes.instaBlueContainer}>{instalockBlueIcon}</div>
      <div className={classes.firstRow}>
        <IconLabel
          label={globalConstants.TITLES[resultData.game]}
          isSvg
          icon={esportsLogos[resultData.game]}
        />
      </div>
      <div className={classes.secondRow}>
        {bestOf > 0 ? (
          <p className={classes.bestOf}>{`Best of ${bestOf}`}</p>
        ) : (
          <p></p>
        )}
        {resultData.isSeriesPresent ? (
          <Link
            className={classes.matchPageLink}
            to={{
              pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${resultData.abiosSeriesId}`,
            }}
          >
            Match Page &#8594;
          </Link>
        ) : null}
      </div>
      {resultData.tournamentName ? (
        <div className={classes.tournamentName}>
          {resultData.tournamentName}
        </div>
      ) : null}
      <div className={classes.scoreRow}>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={resultData.teamOne.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            <p className={classes.teamName}>
              {abbreviateString(
                resultData.teamOne.teamAbbreviation ||
                  resultData.teamOne.teamName,
                6
              )}
            </p>
            {!!resultData.teamOne.odds && resultData.teamOne.odds !== 100 ? (
              <OddsTag
                odds={resultData.teamOne.odds}
                fullWidth
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
        {type !== "STARTING" ? (
          <div
            className={`${classes.scoreBox} ${
              resultData.teamOne.winner ? classes.winner : ""
            }`}
          >
            {resultData.teamOne.score}
          </div>
        ) : null}
        {type === "FINISHED" ? (
          <div className={classes.dateContainer}>
            <p>FINAL</p>
            {resultData.startDate ? (
              <p>{format(new Date(resultData.startDate), "MM/dd/yy")}</p>
            ) : null}
          </div>
        ) : null}
        {type === "STARTING" ? (
          <div className={classes.dateContainer}>
            <p className={classes.VSContainer}>VS</p>
            {resultData.startDate ? (
              <p>{format(new Date(resultData.startDate), "MM/dd/yy")}</p>
            ) : null}
          </div>
        ) : null}
        {type === "LIVE" ? (
          <div className={classes.dateContainer}>
            <p className={classes.liveText}>{liveIcon}LIVE</p>
            {resultData.map ? (
              <p className={classes.mapText}>{resultData.map}</p>
            ) : null}
          </div>
        ) : null}
        {type !== "STARTING" ? (
          <div
            className={`${classes.scoreBox} ${
              resultData.teamTwo.winner ? classes.winner : ""
            }`}
          >
            {resultData.teamTwo.score}
          </div>
        ) : null}

        <div className={classes.teamContainer}>
          <IconLabel
            icon={resultData.teamTwo.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            <p className={classes.teamName}>
              {abbreviateString(
                resultData.teamTwo.teamAbbreviation ||
                  resultData.teamTwo.teamName,
                6
              )}
            </p>
            {!!resultData.teamTwo.odds && resultData.teamTwo.odds !== 100 ? (
              <OddsTag
                odds={resultData.teamTwo.odds}
                fullWidth
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ResultCard;
