// Vendors
import React from "react";

// Styles
import OddsTag from "../OddsTag/OddsTag";
import classes from "./MoneyLineLabel.module.scss";

const MoneyLineLabel = ({ text }) => {
  return (
    <div className={classes.moneyLineLabelContainer}>
      <OddsTag additionalText={text || "ML"} />
    </div>
  );
};

export default MoneyLineLabel;
