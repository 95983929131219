// Vendors
import React from "react";

export const betLogIcon = (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="2.88672"
      width="15"
      height="15"
      rx="2.5"
      fill="#1e1e26"
      stroke="white"
    />
    <rect x="4" y="6.38672" width="8" height="1" fill="#D9D9D9" />
    <rect x="4" y="9.38672" width="8" height="1" fill="#D9D9D9" />
    <rect x="4" y="12.3867" width="8" height="1" fill="#D9D9D9" />
    <rect x="9.63623" y="0.5" width="8" height="8" rx="2" fill="#44C300" />
    <path
      d="M14.7062 3.14612L13.1622 4.68112L12.5843 4.10323C12.3676 3.88651 12.0154 3.88651 11.7988 4.10323C11.582 4.31996 11.582 4.67211 11.7988 4.88879L12.7649 5.85498C12.8732 5.96331 13.0177 6.01747 13.1531 6.01747C13.2976 6.01747 13.433 5.96331 13.5414 5.85498L15.4737 3.92266C15.6904 3.70594 15.6904 3.35379 15.4737 3.13711C15.2661 2.92938 14.9139 2.92938 14.7062 3.14612L14.7062 3.14612Z"
      fill="white"
    />
  </svg>
);
