// Vendors
import React from "react";

export const calendarIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4662 5.17467C12.3662 5.27474 12.2305 5.33092 12.0891 5.33092C11.9476 5.33092 11.812 5.27462 11.712 5.17467C11.6119 5.07459 11.5558 4.93892 11.5558 4.79756V0.965494C11.5558 0.774888 11.6575 0.598796 11.8224 0.503557C11.9874 0.408319 12.1908 0.408319 12.3558 0.503557C12.5208 0.598796 12.6225 0.774894 12.6225 0.965494V4.79756C12.6225 4.93893 12.5663 5.0746 12.4662 5.17467ZM10.8447 2.34814H5.15582V3.41487H10.8447V2.34814ZM14.1442 2.34814H13.3334V3.41487H14.1443C14.3535 3.41512 14.5542 3.49833 14.7021 3.64627C14.8499 3.79422 14.9331 3.99475 14.9334 4.20393V6.47662H1.06672V4.20393C1.06697 3.99473 1.15018 3.79421 1.298 3.64627C1.44594 3.49833 1.64657 3.41513 1.85578 3.41487H2.66667V2.34814H1.85578C1.36371 2.34876 0.892 2.54445 0.544159 2.8923C0.196317 3.24027 0.000507936 3.71186 0 4.20392V14.5763C0.000496032 15.0682 0.196305 15.54 0.544159 15.8878C0.892 16.2358 1.36371 16.4314 1.85578 16.432H14.1442C14.6363 16.4315 15.108 16.2358 15.4558 15.8878C15.8037 15.54 15.9995 15.0682 16 14.5763V4.20392C15.9995 3.71186 15.8037 3.24027 15.4558 2.8923C15.108 2.54446 14.6363 2.34878 14.1442 2.34814ZM14.1443 15.3653H1.85576L1.85589 15.3654C1.64669 15.3652 1.44605 15.282 1.29811 15.134C1.1503 14.9861 1.06709 14.7855 1.06684 14.5764V7.54303H14.9335V14.5764C14.9333 14.7856 14.85 14.9861 14.7022 15.134C14.5543 15.282 14.3537 15.3652 14.1444 15.3654L14.1443 15.3653ZM4.28851 5.17467C4.18856 5.27474 4.05289 5.33092 3.9114 5.33092C3.77003 5.33092 3.63436 5.27462 3.53429 5.17467C3.43434 5.07459 3.37804 4.93892 3.37804 4.79756V0.965494C3.37804 0.774888 3.47972 0.598796 3.64478 0.503557C3.80973 0.408319 4.01309 0.408319 4.17814 0.503557C4.34307 0.598796 4.44476 0.774894 4.44476 0.965494V4.79756C4.44476 4.93893 4.38858 5.0746 4.28851 5.17467Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);
