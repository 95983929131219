// Vendors
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Assets
import fireGif from "../../assets/gifs/fire.gif";
import IconLabel from "../IconLabel/IconLabel";

// Components
import DividerLine from "../DividerLine/DividerLine";

// Styles
import classes from "./FeaturedInstalocks.module.scss";

// Utils
import { formatStringElipsisWithLength } from "../../utils/globalUtils";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import OddsTag from "../OddsTag/OddsTag";
import SpreadTag from "../SpreadTag/SpreadTag";

const FeaturedInstalocks = ({ featuredInstalocks, preferredOddsType }) => {
  const doubledInstalocks =
    featuredInstalocks?.length > 0
      ? featuredInstalocks.concat(featuredInstalocks)
      : [];
  return (
    <div className={classes.featuredInstalocks}>
      <div className={classes.desktopFeaturedInstalock}>
        <div className={classes.mainCardContainer}>
          <div className={classes.mainCard}>
            <span className={classes.featuredTitle}>Featured Instalocks </span>
            <img src={fireGif} alt="featuredInstalockFire" />
          </div>
        </div>
        <div className={classes.featuredList}>
          {doubledInstalocks.length > 0 ? (
            <Swiper
              loop={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={8000}
              centeredSlides={true}
              modules={[Autoplay]}
              freeMode={true}
              slidesPerView="auto"
              className=".swiper-container"
            >
              {doubledInstalocks.map((bet, index) => {
                return (
                  <SwiperSlide key={`${bet.betID}-${index}`}>
                    <div className={classes.featuredCard}>
                      <IconLabel
                        icon={bet.selectedAbiosTeam.imageSmall}
                        label={
                          bet.selectedAbiosTeam.teamAbbreviation ||
                          bet.selectedAbiosTeam.teamName
                        }
                        logoSize="logoSmaller"
                        size="small"
                        withAbbreviation
                      />
                      <div className={classes.oddsContainer}>
                        {bet.type === globalConstants.MONEYLINE ? (
                          <OddsTag
                            odds={bet.odd}
                            fullWidth
                            preferredOddsType={preferredOddsType}
                          />
                        ) : bet.spread ? (
                          <SpreadTag spread={bet.spread} />
                        ) : null}
                      </div>

                      <p className={classes.VSContainer}>VS</p>
                      <IconLabel
                        icon={bet.alternativeAbiosTeam.imageSmall}
                        label={
                          bet.alternativeAbiosTeam.teamAbbreviation ||
                          bet.alternativeAbiosTeam.teamName
                        }
                        logoSize="logoSmaller"
                        size="small"
                        withAbbreviation
                        inverted
                      />
                      <p className={classes.featuredTournamentName}>
                        {formatStringElipsisWithLength(bet.tournamentName, 33)}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : null}
        </div>
      </div>
      <DividerLine />
    </div>
  );
};

export default FeaturedInstalocks;
