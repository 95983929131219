// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Components
import ProfilePictureVerificable from "../ProfilePictureVerificable/ProfilePictureVerificable";
import DividerLine from "../DividerLine/DividerLine";
import ScoreCard from "../ScoreCard/ScoreCard";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./InstalockStreakCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

import { esportsLogos } from "../../assets/icons/logos/logos";

const InstalockStreakCard = ({ userData, isInstaFade, showDivider }) => {
  return (
    <div className={classes.instalockStreakCardContainer}>
      <div className={classes.logoAndUserContainer}>
        {userData.game ? (
          <div className={classes.gameLogoContainer}>
            {esportsLogos[userData.game]}
          </div>
        ) : null}
        <div className={classes.userRowContainer}>
          <ProfilePictureVerificable
            image={userData.imageUrl}
            size={36}
            iconSizePX={10}
            iconPadding={2}
            username={userData.username}
          />
          <Link
            to={{
              pathname: `/${globalConstants.ROUTES.PROFILE}/${userData.username}/`,
            }}
          >
            <p className={classes.usernameText}>{userData.username}</p>
          </Link>

          {/* <div className={classes.positionChangeContainer}>
            {Math.abs(userData.positionChange) > 1 ? (
              <p className={classes.positionChangeAmount}>
                {formatNumberWithSymbol(userData.positionChange)}
              </p>
            ) : null}
            {userData.positionChange >= 0 ? arrowUpIcon : arrowDownIcon}
          </div> */}
        </div>
      </div>
      <div className={classes.userResultsContainer}>
        <ScoreCard score={userData.wins} result="win" />
        <ScoreCard score={userData.pushes} result="push" />
        <ScoreCard score={userData.losses} result="loss" />
        {isInstaFade ? (
          <p className={classes.streakText}>{`${userData.streak} ${
            userData.streak > 1 ? "Losses" : "Loss"
          } in a row`}</p>
        ) : (
          <p className={classes.streakText}>{`${userData.streak} ${
            userData.streak > 1 ? "Wins" : "Win"
          } in a row`}</p>
        )}
      </div>
      {showDivider && (
        <DividerLine color={styledVariables.backgroundPrimaryE} margin={16} />
      )}
    </div>
  );
};

export default InstalockStreakCard;
