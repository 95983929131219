// Vendors
import React from "react";

// Context

// Components
import GuideCard from "../../components/GuideCard/GuideCard";
import TopGuideCard from "../../components/TopGuideCard/TopGuideCard";
import OddsCalculator from "../../components/OddsCalculator/OddsCalculator";
import LatestGuides from "../../components/LatestGuides/LatestGuides";

// Styles
import classes from "./EducationHomePage.module.scss";

const EducationHomePage = ({ mainGuide, topGuide, partneredGuides }) => {
  return (
    <div className={classes.subPageContainer}>
      {mainGuide && <GuideCard guideData={mainGuide} isMainGuide />}
      <div className={classes.detailColumnsContainer}>
        {topGuide && (
          <div
            className={`${classes.detailColumnsContainer} ${classes.withGap}`}
          >
            <div className={classes.leftColumnContainer}>
              <div className={classes.columnTitle}>Partnered Resources</div>
              <LatestGuides guidesData={partneredGuides} />
            </div>
            <div className={classes.rightColumnContainer}>
              <div className={classes.columnTitle}>Featured Story</div>
              <div className={classes.TopGuideContainer}>
                <TopGuideCard guideData={topGuide} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.calculatorContainer}>
        <OddsCalculator />
      </div>
    </div>
  );
};

export default EducationHomePage;
