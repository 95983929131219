// Vendors
import React from "react";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./EducationValorantPage.module.scss";

// Assets
import { tsmLogo, senLogo } from "../../assets/icons/logos/logos";

const EducationValorantPage = () => {
  return (
    <div className={classes.educationGuidesPage}>
      <div className={classes.pageBannerContainer}>
        <GameBannerRenderer game={"valorant"} />
      </div>
      <div className={classes.pageHeader}>
        <h2>What is Valorant</h2>
        <p>
          Valorant is a team-based first-person hero shooter set in the near
          future. Players play as one of a set of Agents, characters based on
          several countries and cultures around the world. In the main game
          mode, players are assigned to either the attacking or defending team
          with each team having five players on it. Agents have unique
          abilities, each requiring charges, as well as a unique ultimate
          ability that requires charging through kills, deaths, orbs, or
          objectives. Every player starts each round with a "classic" pistol and
          one or more "signature ability" charges. Other weapons and ability
          charges can be purchased using an in-game economic system that awards
          money based on the outcome of the previous round, any kills the player
          is responsible for, and any objectives completed.
        </p>
        <p>
          In competitive play, teams play rounds until the first team gets 13
          round wins. If after 25 rounds the score is tied 12-12, the game goes
          into overtime where each team plays one round of attack and one round
          of defense and the game continues until one team has won on both
          sides.
        </p>
      </div>
      <div className={classes.pageContent}>
        <h2>Valorant Betting basics</h2>
        <h3>How do MoneyLine Odds Work?</h3>
        <p>
          A popular bet in VALORANT is the moneyline, which requires you to pick
          the winner of the game. But it’s not a 50-50 proposition in most cases
          – you won’t get paid the same for taking SEN to beat TSM as you would
          for taking the TSM to beat SEN, given the ability of each team.
        </p>
        <p>
          So each team is assigned moneyline odds by the sportsbook based on
          their ability, and bettors have to pay a corresponding price.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"SEN"} icon={senLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-300</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
            <IconLabel
              label={"TSM"}
              icon={tsmLogo}
              isSvg
              size="large"
              inverted
            />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"TSM"} icon={tsmLogo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
          </div>
        </div>

        <p>
          American odds like these are centered around $100. So if you bet $300
          on SEN at -300, you’d win $100 if they win. If you bet $100 on TSM at
          +250 and they win, you’d win $250. You don’t have to bet $100, of
          course. If you bet $3 on SEN you’d win $1. Bet $1 on TSM, win $2.50.
        </p>
        <p>
          You don’t have to bet $100, of course. If you bet $3 on SEN you’d win
          $1. Bet $1 on TSM, win $2.50.
        </p>

        <h3>Are there point spreads in VALORANT?</h3>
        <p>
          In VALORANT, one of the most popular betting options is the point
          spread - it’s a bet on the margin of victory in that series. Let’s use
          that same example from above in a best-of-3 series.
        </p>
        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"SEN"} icon={senLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-110</div>
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              -110
            </div>
            <IconLabel
              label={"TSM"}
              icon={tsmLogo}
              isSvg
              size="large"
              inverted
            />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"TSM"} icon={tsmLogo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              -110
            </div>
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
          </div>
        </div>

        <p>
          Because SEN is so much better than TSM, sportsbooks are saying it’s
          about a 50-50 chance that SEN wins the series as a 2-0.
        </p>
        <h3>What is Over/Under?</h3>
        <p>
          When you are betting the Over/Under on a match, it means you are
          betting on the amount of games being played. Typically VALORANT
          matches are a best-of-3 or best-of-5, so typical over/unders are set
          at 2.5, 3.5, or 4.5.
        </p>
        <p>
          {
            "Example: If C9 and Optic are playing a best of 3 game. There should be an over/under at 2.5. Meaning you are betting on C9 OR Optic to 2-0 (2 games <2.5) if you take the under or you are betting on either team to win 2-1 if you took the over (3 games >2.5)"
          }
        </p>
      </div>
    </div>
  );
};

export default EducationValorantPage;
