// Vendors
import React from "react";
import { Tooltip } from "antd";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Constants
import styledVariables from "../../styles/utils/_variables.scss";

// Styles
import classes from "./MatchRiskScore.module.scss";

// Utils

const MatchRiskScore = ({ seriesDetails }) => {
  const teamOne = seriesDetails.teamOne;
  const teamTwo = seriesDetails.teamTwo;

  const greenStyles = {
    backgroundColor: styledVariables.backgroundEmphasisE,
    color: styledVariables.backgroundPrimaryA,
  };

  const darkGreenStyles = {
    backgroundColor: styledVariables.backgroundEmphasisX,
    color: styledVariables.backgroundEmphasisE,
  };

  const greyStyles = {
    backgroundColor: styledVariables.backgroundEmphasisT,
    color: styledVariables.white,
  };

  const darkRedStyles = {
    backgroundColor: styledVariables.backgroundEmphasisY,
    color: styledVariables.backgroundEmphasisC,
  };

  const redStyles = {
    backgroundColor: styledVariables.backgroundEmphasisC,
    color: styledVariables.black,
  };

  const generateRiskCardStyles = (risk) => {
    if (risk < 3) {
      return greenStyles;
    } else if (risk < 5) {
      return darkGreenStyles;
    } else if (risk === 5) {
      return greyStyles;
    } else if (risk < 7) {
      return darkRedStyles;
    } else if (risk > 7) {
      return redStyles;
    }

    return greyStyles;
  };

  return teamOne.risk || teamTwo.risk ? (
    <div className={classes.riskScores}>
      <div className={classes.titleContainer}>
        <h3>
          Risk Score
          <Tooltip
            placement="top"
            title={"1-10 Risk score based off of INSTALOCK modeling"}
          >
            <div className={classes.titleHelper}>?</div>
          </Tooltip>
        </h3>
      </div>
      <div className={classes.riskScoresList}>
        {teamOne.risk ? (
          <div className={classes.riskCard}>
            <div>
              <IconLabel
                label={
                  seriesDetails?.teamOne.teamAbbreviation ||
                  seriesDetails?.teamOne.teamName
                }
                withImageBackground
                logoSize="logoLarge"
                size="small"
                icon={
                  seriesDetails?.teamOne.teamImgSm ||
                  seriesDetails?.teamOne.teamImgLg
                }
              />
            </div>
            <div
              className={classes.riskValue}
              style={generateRiskCardStyles(teamOne.risk?.toFixed(3))}
            >
              {teamOne.risk?.toFixed(3)}
            </div>
          </div>
        ) : null}
        {teamTwo?.risk ? (
          <div className={classes.riskCard}>
            <div>
              <IconLabel
                label={
                  seriesDetails?.teamTwo?.teamAbbreviation ||
                  seriesDetails?.teamTwo?.teamName
                }
                withImageBackground
                logoSize="logoLarge"
                size="small"
                icon={
                  seriesDetails?.teamTwo?.teamImgSm ||
                  seriesDetails?.teamTwo?.teamImgLg
                }
              />
            </div>
            <div
              className={classes.riskValue}
              style={generateRiskCardStyles(teamTwo?.risk?.toFixed(3))}
            >
              {teamTwo?.risk?.toFixed(3)}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default MatchRiskScore;
