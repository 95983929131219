// Vendors
import React, { useState, useEffect, useCallback } from "react";
import { Button } from "antd";

// Styles
import classes from "./TimerButton.module.scss";

// Utils
import { addLeadingZeros } from "../../utils/globalUtils";

const TimerButton = ({
  buttonText,
  clicked,
  type,
  icon,
  size,
  fullWidth,
  htmlType,
  disabled,
  countDown,
}) => {
  const [timer, setTimer] = useState(countDown);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(countDown);
      clicked && clicked();
    }
  };

  return (
    <div className={`${classes.mainButtonContainer} ${classes[fullWidth]}`}>
      <Button
        className={`${classes.mainButton} ${classes[type]} ${classes[size]}`}
        onClick={resetTimer}
        icon={icon}
        block={!!fullWidth}
        htmlType={htmlType}
        disabled={disabled}
        loading={!!timer}
      >
        {buttonText && (
          <span className={classes.mainButtonText}>{`${buttonText} ${
            timer > 0 ? `00:${addLeadingZeros(timer, 2)}` : ""
          }`}</span>
        )}
      </Button>
    </div>
  );
};

export default TimerButton;
