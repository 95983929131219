// Vendors
import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  FileTextOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";

// Context
import { ModalContext } from "../../context/contextProviders/ModalContextProvider";
import { BetSlipContext } from "../../context/contextProviders/BetSlipContextProvider";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Components
import BetSlip from "../../components/BetSlip/BetSlip";
import Logo from "../../components/Logo/Logo";
import MainButton from "../../components/MainButton/MainButton";
import MobileMenuSideDrawer from "../MobileMenuSideDrawer/MobileMenuSideDrawer";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";

// Styles
import classes from "./Header.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { instalockTextIcon } from "../../assets/icons/instalockText/instalockText";

// Tracking
import ReactGA from "react-ga4";
import MainSelect from "../../components/MainSelect/MainSelect";
import Search from "../../components/Search/Search";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const Header = () => {
  const navigate = useNavigate();

  const { modalToShow, setModalToShow } = useContext(ModalContext);
  const { betSlipData, showBetSlip, toggleShowBetSlip } =
    useContext(BetSlipContext);

  const {
    userSession,
    getSessionData,
    logoutSession,
    getUserData,
    userData,
    requestUserRegion,
    preferredOddsType,
    setPreferredOddsType,
  } = useContext(UserContext);

  const [openSideDrawer, setOpenSideDrawer] = useState(false);

  useEffect(() => {
    getSessionData();
    requestUserRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOpenSideDrawer = () => {
    setOpenSideDrawer(!openSideDrawer);
  };
  const toggleOpenBetSlip = () => {
    toggleShowBetSlip();
  };

  // Scroll  to top handler
  const topRef = useRef();

  useEffect(() => {
    userSession &&
      getUserData(
        userSession.accessToken.payload.username,
        () => {},
        () => {
          navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSession]);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <div className={classes.header}>
      <div className={classes.firstRowContainer} ref={topRef}>
        <MobileMenuSideDrawer
          openDrawer={openSideDrawer}
          onClose={toggleOpenSideDrawer}
          setModalToShow={setModalToShow}
          modalToShow={modalToShow}
        />
        <BetSlip openDrawer={showBetSlip} onClose={toggleOpenBetSlip} />

        <Link className={classes.brandContainer} to="/">
          <div className={classes.logo}>
            <Logo />
          </div>
          {instalockTextIcon}
          <span className={classes.TMContainer}>TM</span>
        </Link>
        <nav className={classes.navBar}>
          {globalConstants.MENU_ITEMS.slice(0, 5).map((item) => {
            return (
              <NavLink
                className={({ isActive }) =>
                  `${classes.navBarLink} ${
                    isActive && classes.activeNavBarLink
                  }`
                }
                key={item.key}
                to={item.key}
              >
                <span className={classes.navLinkBox}>{item.label}</span>
                {item.key === "sportsbooks" ? (
                  <span className={classes.bonusesText}>BONUSES</span>
                ) : null}
              </NavLink>
            );
          })}
        </nav>
      </div>
      <div className={classes.mobileMenuContainer}>
        <Search preferredOddsType={preferredOddsType} />
        <div className={classes.loginButtonContainer}>
          <MainButton
            type="primary"
            buttonText={userSession ? "Log out" : "Login"}
            clicked={() =>
              userSession ? logoutSession() : setModalToShow("login")
            }
          />
        </div>
        <MainButton
          type="tertiary"
          icon={<MenuOutlined />}
          clicked={toggleOpenSideDrawer}
        />
      </div>

      <div className={classes.desktopMenuContainer}>
        <Search preferredOddsType={preferredOddsType} />
        <div className={classes.preferredControls}>
          <MainSelect
            size="small"
            // truncateText
            currentValue={globalConstants.PREFERRED_ODDS_ITEMS.find((item) => {
              return item.key === preferredOddsType;
            })}
            setCurrentValue={(item) => setPreferredOddsType(item.key)}
            options={globalConstants.PREFERRED_ODDS_ITEMS}
          />
        </div>
        {userSession ? (
          <ProfileMenu
            username={userSession.accessToken.payload.username}
            image={userData?.imageUrl}
            logoutHandler={logoutSession}
          />
        ) : (
          <>
            {/* <MainButton
              className={classes.loginNavLink}
              clicked={() => setModalToShow("login")}
              buttonText="Login"
              type="text"
            /> */}
            <div className={classes.signUpButtonContainer}>
              <MainButton
                type="primary"
                buttonText="Login"
                icon={<UserOutlined style={{ fontSize: "125%" }} />}
                clicked={() => setModalToShow("login")}
              />
            </div>
          </>
        )}
        <div className={classes.menuToggleContainer}>
          <MainButton
            type="tertiary"
            icon={<FileTextOutlined />}
            clicked={toggleOpenBetSlip}
          />
          {betSlipData.bets?.length > 0 ? (
            <div className={classes.betsCounter}>
              {betSlipData.bets?.length}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
