// Vendors
import React from "react";
import { Divider } from "antd";

// Styles
import styledVariables from "../../styles/utils/_variables.scss";

const DividerLine = ({ color, margin }) => {
  return (
    <Divider
      style={{
        borderColor: color || styledVariables.backgroundPrimaryB,
        margin: `${margin || 0}px  0`,
      }}
    />
  );
};

export default DividerLine;
