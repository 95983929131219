// Vendors
import React from "react";

export const hltv = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3177_3972)">
      <path
        d="M24.866 2.75269C23.2725 4.46747 21.4978 5.98008 19.6423 7.40133C17.321 9.17901 14.8199 10.6721 12.2739 12.0964C11.5356 12.5097 10.7928 12.9291 10.1144 13.4293C8.6991 14.4701 8.62272 16.0052 9.87774 17.2602C10.0994 17.4819 10.3465 17.6781 10.7089 18C8.87283 18 7.19099 18 5.50915 17.9985C5.289 17.9985 5.15422 17.8353 5.01494 17.6945C3.79137 16.456 3.87824 14.2709 5.20364 13.1447C6.09173 12.3914 7.15205 11.9481 8.22286 11.5348C11.9864 10.0806 15.7619 8.65784 19.2394 6.56565C21.1818 5.3975 23.0314 4.09905 24.869 2.75269H24.866Z"
        fill="#5978A6"
      />
      <path
        d="M3.32559 16.2629C3.3196 16.7975 2.85684 17.2513 2.3117 17.2558C1.73062 17.2603 1.25437 16.7765 1.28283 16.2089C1.30979 15.6578 1.76656 15.2415 2.32817 15.255C2.8778 15.2684 3.33009 15.7252 3.32559 16.2629Z"
        fill="#5B79A7"
      />
      <path
        d="M3.32715 4.68018C3.37208 4.18297 3.62967 3.88494 4.13437 3.87895C4.67501 3.87446 4.98353 4.19795 4.98053 4.71463C4.97754 5.19836 4.65704 5.52035 4.17331 5.52485C3.65363 5.52934 3.3601 5.21184 3.32715 4.68018Z"
        fill="#5C7AA8"
      />
      <path
        d="M0.941406 10.2303C0.968364 9.73606 1.21997 9.42755 1.72017 9.40659C2.25483 9.38262 2.58281 9.68814 2.59179 10.2123C2.60078 10.741 2.25782 11.06 1.74264 11.0495C1.26489 11.0405 0.975852 10.7365 0.941406 10.2303Z"
        fill="#5C7AA8"
      />
      <path
        d="M8.43698 0.455322C8.82187 0.504744 9.06299 0.720403 9.05401 1.13225C9.04502 1.5456 8.77844 1.78372 8.38906 1.77473C7.99818 1.76575 7.73609 1.49917 7.74808 1.0993C7.76006 0.706924 8.00716 0.474791 8.43698 0.455322Z"
        fill="#607DAA"
      />
      <path
        d="M12.8865 0C13.2893 0.0239621 13.499 0.230635 13.5035 0.558616C13.508 0.931525 13.2594 1.15916 12.888 1.1367C12.5585 1.11723 12.3413 0.895582 12.3593 0.548132C12.3773 0.206673 12.6019 0.0344455 12.8865 0V0Z"
        fill="#607DAA"
      />
      <path
        d="M16.6845 0.912109C16.972 0.970517 17.1592 1.14125 17.1248 1.46324C17.0933 1.74779 16.8927 1.89605 16.6186 1.88707C16.337 1.87658 16.1513 1.68938 16.1543 1.41531C16.1588 1.11429 16.34 0.921095 16.6845 0.913607V0.912109Z"
        fill="#6682AD"
      />
      <path
        d="M19.4896 3.06557C19.4791 3.31867 19.3683 3.47592 19.1421 3.50438C18.8815 3.53733 18.7003 3.40404 18.6779 3.14195C18.6584 2.91881 18.7962 2.76006 19.0178 2.72262C19.2934 2.67619 19.4162 2.8589 19.4911 3.06557H19.4896Z"
        fill="#6A85AF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3177_3972">
        <rect
          width="23.9246"
          height="18"
          fill="white"
          transform="translate(0.941406)"
        />
      </clipPath>
    </defs>
  </svg>
);
