// Vendors
import React, { useMemo } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Link,
  useOutletContext,
  useParams,
  useNavigate,
} from "react-router-dom";
import MainButton from "../../components/MainButton/MainButton";
import { format } from "date-fns";

// Components
import ProfilePictureVerificable from "../../components/ProfilePictureVerificable/ProfilePictureVerificable";
import GuideBuilder from "../../components/GuideBuilder/GuideBuilder";
import LikesRate from "../../components/LikesRate/LikesRate";
import StaffTag from "../../components/StaffTag/StaffTag";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./EducationGuideDetailsPage.module.scss";

const EducationGuideDetailsPage = ({ guideData }) => {
  const outletProps = useOutletContext();
  const navigate = useNavigate();

  const { guides } = outletProps;

  const { id } = useParams();

  let currentGuide = useMemo(
    () =>
      guides.find((guide) => {
        return guide.id.toString() === id;
      }),
    [guides, id]
  );

  if (guideData) {
    currentGuide = guideData;
  }

  return currentGuide ? (
    <div className={classes.educationGuideDetailsPage}>
      <div className={classes.guideHeader}>
        <MainButton
          disabled={!!guideData}
          buttonText="Back"
          type="secondary"
          icon={<ArrowLeftOutlined />}
          clicked={() => navigate(-1)}
        />
        <span className={`${classes.guideDate}  ${classes.hideOnDesktop}`}>
          {format(new Date(currentGuide.date), "MMM dd, yyyy")}
        </span>
        <div className={classes.guideHeaderEndContent}>
          {currentGuide?.user ? (
            <>
              <Link
                to={{
                  pathname: `/${globalConstants.ROUTES.PROFILE}/${currentGuide.user.username}/`,
                }}
              >
                <span className={classes.guideUsername}>
                  {currentGuide.user.username}
                </span>
              </Link>
              <div className={classes.guideProfilePicture}>
                <ProfilePictureVerificable
                  username={currentGuide.user.username}
                  image={currentGuide.user.image}
                  size={36}
                />
              </div>
              {currentGuide.user.staff ? (
                <StaffTag staff={currentGuide.user.staff} />
              ) : null}
            </>
          ) : null}
          <span className={`${classes.guideDate}  ${classes.hideOnMobile}`}>
            {format(new Date(currentGuide.date), "MMM dd, yyyy")}
          </span>
          <div className={classes.guideLikesContainer}>
            {currentGuide.likePercentage ? (
              <LikesRate likeRate={currentGuide.likePercentage} />
            ) : null}
          </div>
        </div>
      </div>
      <div className={classes.guideContent}>
        <GuideBuilder guideData={currentGuide} />
      </div>
    </div>
  ) : null;
};

export default EducationGuideDetailsPage;
