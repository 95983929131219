// Vendors
import React from "react";

export const instalock = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2925_4493)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54487 0C9.66271 0 10.68 0.459918 11.416 1.20196C12.152 1.944 12.6082 2.96816 12.6082 4.09665V5.61747H10.4687V4.09665C10.4687 3.56473 10.2515 3.07984 9.90319 2.72718C9.55486 2.37453 9.07245 2.15706 8.54487 2.15706C8.01728 2.15706 7.53633 2.376 7.18655 2.72718C6.83677 3.07984 6.62107 3.56473 6.62107 4.09665V5.61747H4.48157V4.09665C4.48157 2.96963 4.9392 1.944 5.67374 1.20196C6.40974 0.459918 7.42556 0 8.54487 0Z"
        fill="url(#paint0_linear_2925_4493)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.506 6.4624L13.6386 6.46681C15.6571 6.57701 17.2617 8.26387 17.2617 10.3269C17.2617 12.3899 15.5449 14.1929 13.4273 14.1929C11.5209 14.1929 9.93964 12.7911 9.64378 10.9543H7.61942C7.32211 12.7911 5.7408 14.1929 3.83595 14.1929C1.71831 14.1929 0.00146484 12.4619 0.00146484 10.3269C0.00146484 8.19187 1.67022 6.51089 3.74559 6.4624H13.506V6.4624ZM12.0995 9.65685C11.7323 9.65685 11.435 9.95661 11.435 10.3269C11.435 10.6972 11.7323 10.9969 12.0995 10.9969C12.4668 10.9969 12.7641 10.6972 12.7641 10.3269C12.7641 9.95661 12.4668 9.65685 12.0995 9.65685ZM14.7521 9.65685C14.3848 9.65685 14.0875 9.95661 14.0875 10.3269C14.0875 10.6972 14.3848 10.9969 14.7521 10.9969C15.1193 10.9969 15.4166 10.6972 15.4166 10.3269C15.4166 9.95661 15.1193 9.65685 14.7521 9.65685ZM13.4258 8.32999C13.0585 8.32999 12.7612 8.62975 12.7612 9.00004C12.7612 9.37032 13.0585 9.67008 13.4258 9.67008C13.7931 9.67008 14.0904 9.37032 14.0904 9.00004C14.0904 8.62975 13.7931 8.32999 13.4258 8.32999ZM13.4258 10.9837C13.0585 10.9837 12.7612 11.2835 12.7612 11.6537C12.7612 12.024 13.0585 12.3238 13.4258 12.3238C13.7931 12.3238 14.0904 12.024 14.0904 11.6537C14.0904 11.2835 13.7931 10.9837 13.4258 10.9837ZM3.47451 8.32265C3.30836 8.32265 3.17282 8.4593 3.17282 8.62681V9.65832H2.14971C1.98356 9.65832 1.84802 9.79497 1.84802 9.96395V10.6898C1.84802 10.8573 1.98356 10.994 2.14971 10.994H3.17282V12.0255C3.17282 12.193 3.30836 12.3311 3.47451 12.3311H4.19447C4.36062 12.3311 4.49762 12.1945 4.49762 12.0255V10.994H5.52073C5.68688 10.994 5.82242 10.8573 5.82242 10.6898V9.96395C5.82242 9.79644 5.68688 9.65832 5.52073 9.65832H4.49762V8.62681C4.49762 8.4593 4.36208 8.32265 4.19447 8.32265H3.47451V8.32265Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1108 14.9862C12.9434 16.7965 10.9191 17.9999 8.63092 17.9999C6.3413 17.9999 4.3184 16.7965 3.151 14.9862C3.37399 15.02 3.6028 15.0361 3.83599 15.0361C5.8793 15.0361 7.6559 13.7019 8.2753 11.7976H8.98798C9.60739 13.7019 11.3854 15.0361 13.4273 15.0361C13.659 15.0361 13.8878 15.0185 14.1108 14.9862V14.9862Z"
        fill="url(#paint1_linear_2925_4493)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.121 14.9973C13.8336 15.4455 13.4938 15.8567 13.1131 16.222C10.9311 15.8522 9.22889 14.0419 8.9926 11.7878C9.6125 13.7047 11.392 15.0476 13.4354 15.0476C13.6673 15.0476 13.8963 15.0299 14.1195 14.9973H14.121ZM4.15888 16.222C3.77673 15.8567 3.43834 15.4455 3.151 14.9973C3.37416 15.0314 3.60316 15.0476 3.83653 15.0476C5.88 15.0476 7.658 13.7047 8.2779 11.7878C8.04161 14.0419 6.34091 15.8508 4.15888 16.2205V16.222Z"
        fill="url(#paint2_linear_2925_4493)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2925_4493"
        x1="10.2938"
        y1="2.79624"
        x2="5.8582"
        y2="4.47538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#418EF8" />
        <stop offset="1" stopColor="#414CC2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2925_4493"
        x1="12.1059"
        y1="14.7622"
        x2="5.42224"
        y2="16.0404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4070DF" />
        <stop offset="1" stopColor="#422CA8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2925_4493"
        x1="15.139"
        y1="14.9876"
        x2="4.5894"
        y2="15.8996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D56E2" />
        <stop offset="1" stopColor="#2F1E8E" />
      </linearGradient>
      <clipPath id="clip0_2925_4493">
        <rect width="17.2603" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
