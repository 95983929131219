// Vendors
import React, { useState } from "react";
import Masonry from "react-masonry-css";

// Components
import MainSelect from "../../components/MainSelect/MainSelect";

// Styles
import classes from "./EducationGlossaryPage.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import { glossaryPageConstants } from "./constants/constants";

const EducationGlossaryPage = () => {
  const [selectedGlossary, setSelectedGlossary] = useState(
    globalConstants.EDUCATION_GLOSSARY_MENU_ITEMS[0]
  );

  const generateGlossaryList = () => {
    return glossaryPageConstants.GLOSSARY_DATA[selectedGlossary.key].sort(
      (a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      }
    );
  };

  const items = generateGlossaryList().map((item, index) => {
    return (
      <div key={`${item.title}-${index}`} className={classes.cardItem}>
        <h3 className={classes.cardItemTitle}>{item.title}</h3>
        <p className={classes.cardItemContent}>{item.content}</p>
      </div>
    );
  });

  return (
    <div className={classes.educationGlossaryPage}>
      <div className={classes.educationGlossaryControls}>
        <MainSelect
          currentValue={selectedGlossary}
          setCurrentValue={setSelectedGlossary}
          options={globalConstants.EDUCATION_GLOSSARY_MENU_ITEMS}
        />
      </div>
      <Masonry
        breakpointCols={glossaryPageConstants.GLOSSARY_BREAK_POINTS}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {items}
      </Masonry>
    </div>
  );
};

export default EducationGlossaryPage;
