// Vendors
import React from "react";
// Styles
import classes from "./StaffTag.module.scss";
// Constants
import { staffConstants } from "./constants/staffConstants";

const StaffTag = ({ staff }) => {
  if (!staffConstants[staff]) {
    return null;
  }

  const { color, icon, label, url } = staffConstants[staff];
  return staff === "instalock" ? (
    <div
      className={`${classes.staffTagContainer}`}
      style={{ border: `1px solid ${color}` }}
    >
      <div className={classes.logo}>{icon}</div>
      <span className={classes.label}>{label}</span>
    </div>
  ) : (
    <a
      href={`${url}`}
      target="_blank"
      rel="noreferrer"
      className={`${classes.staffTagContainer}`}
      style={{ border: `1px solid ${color}` }}
    >
      <div className={classes.logo}>{icon}</div>
      <span className={classes.label}>{label}</span>
    </a>
  );
};

export default StaffTag;
