// Vendors
import React, { useContext, useState } from "react";

// Context
import { BetSlipContext } from "../../context/contextProviders/BetSlipContextProvider";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";
import MainSelect from "../../components/MainSelect/MainSelect";

// Styles
import classes from "./MatchOdds.module.scss";
import { format } from "date-fns";

// Assets
import { rivalryIcon } from "../../assets/icons/rivalry/rivalry";
import { esportsbet } from "../../assets/icons/staff/esportsbet";
import { thunderPick } from "../../assets/icons/staff/thunderPick";
import { bovadaOddsPage } from "../../pages/OddsPage/icons/bovadaOddsPage";

// Utils
import { globalConstants } from "../../constants/globalConstants";
import {
  formatNumberWithSymbol,
  transformOddToPrefered,
  generateOpenOdds,
} from "../../utils/globalUtils";

// Constants
import { oddsPageConstants } from "../../pages/OddsPage/constants/constants";

const MatchOdds = ({ matchInfo, preferredOddsType }) => {
  const { addBetSlipSerie, toggleShowBetSlip } = useContext(BetSlipContext);

  const [oddsBetType, setOddsBetType] = useState(
    oddsPageConstants.BET_TYPE_ODDS_ITEMS[0]
  );

  if (!matchInfo) {
    return null;
  }

  const handleOddsClicked = ({ serieData, switchTeams, sportsbook }) => {
    const teamOneData = { ...serieData[switchTeams ? "teamTwo" : "teamOne"] };
    const teamTwoData = { ...serieData[switchTeams ? "teamOne" : "teamTwo"] };
    const betData = {
      game: serieData.esport,
      abiosSeriesId: serieData.abiosSeriesId,
      risk: teamOneData.risk || "",
      odds:
        teamOneData[sportsbook].find(
          (oddObj) => oddObj.betType === oddsBetType.key
        )?.odds || "",
      win: teamOneData.win || "",
      spread:
        teamOneData[sportsbook].find(
          (oddObj) => oddObj.betType === oddsBetType.key
        )?.spread || "",
      total: teamOneData.total || "",
      type: oddsBetType.key === "SPREAD" ? "spread" : "moneyLine",
      teamOne: teamOneData,
      teamTwo: teamTwoData,
      startDate: serieData.seriesDate,
    };

    addBetSlipSerie(betData);
    toggleShowBetSlip();
  };
  const generateOdds = ({
    sportsbook,
    clicked,
    serieData,
    teamPlace,
    openOdds,
  }) => {
    let spread = 0;
    let odds = 0;

    if (openOdds) {
      odds = openOdds[teamPlace][oddsBetType.key].oddsOpen || 0;
      spread = openOdds[teamPlace][oddsBetType.key].spreadOpen || 0;
    } else {
      const sportsBookOdds = serieData[teamPlace][sportsbook]?.find(
        (oddObj) => oddObj.betType === oddsBetType.key
      );

      spread = sportsBookOdds?.spread || 0;
      odds = sportsBookOdds?.odds || 0;
    }

    if (!odds) {
      return <div className={classes.nonAvailableOdds}>N/A</div>;
    }

    const isUnderdog = !spread && odds > 100;

    const isPositiveSpread = spread && spread >= 1.5;

    const content = (
      <>
        <span className={classes.buttonText}>
          {spread
            ? formatNumberWithSymbol(spread, 1)
            : transformOddToPrefered(odds, preferredOddsType)}
        </span>
        <span className={classes.buttonSubText}>
          {spread ? transformOddToPrefered(odds, preferredOddsType) : null}
        </span>
      </>
    );

    if (clicked) {
      return (
        <div className={classes.oddsButtonContainer}>
          <button
            onClick={clicked}
            className={`${classes.oddsButton} 
            ${
              isUnderdog || isPositiveSpread
                ? classes.positiveStyles
                : classes.negativeStyles
            }
            `}
            style={{
              padding: oddsBetType.key === "SPREAD" ? "7px 12px" : "14px 12px",
            }}
          >
            {content}
          </button>
        </div>
      );
    }
    return <div className={classes.oddsContainer}>{content}</div>;
  };

  const { seriesDate } = matchInfo;

  const sportsbooks = ["rivalry", "esportsBets", "thunderPick", "bovada"];
  const openOdds = generateOpenOdds(matchInfo, sportsbooks);

  return (
    <div className={classes.matchOddsContainer}>
      <div className={classes.titleContainer}>
        <h3>Popular Odds</h3>
      </div>
      <div className={classes.oddsPageBetTypeControls}>
        <MainSelect
          size="small"
          currentValue={oddsPageConstants.BET_TYPE_ODDS_ITEMS.find((item) => {
            return item.key === oddsBetType.key;
          })}
          setCurrentValue={(item) => setOddsBetType(item)}
          options={oddsPageConstants.BET_TYPE_ODDS_ITEMS}
        />
      </div>
      <div className={classes.oddsTableContainer}>
        <div className={classes.matchOddsTable}>
          <div className={classes.dateTitle}>
            {format(new Date(seriesDate), "MMMM dd    HH:mm aa")}
          </div>
          <div className={classes.openTitle}>Open </div>
          <div className={classes.rivalryTitle}>
            <div className={classes.sportsbooksLogoContainer}>
              <a
                className={`${classes.sportsbooksLogo} ${classes.rivalryLogo}`}
                href={globalConstants.SPORTS_BOOKS_LINKS.RIVALRY}
                target="_blank"
                rel="noreferrer"
              >
                {rivalryIcon}
              </a>
            </div>
          </div>
          <div className={classes.thunderpickTitle}>
            <div className={classes.sportsbooksLogoContainer}>
              <a
                className={`${classes.sportsbooksLogo} ${classes.thundepickLogo}`}
                href={globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK}
                target="_blank"
                rel="noreferrer"
              >
                {thunderPick}
              </a>
            </div>
          </div>
          <div className={classes.esportsbetTitle}>
            <div className={classes.sportsbooksLogoContainer}>
              <a
                className={`${classes.sportsbooksLogo} ${classes.esportsBetsLogo}`}
                href={globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET}
                target="_blank"
                rel="noreferrer"
              >
                {esportsbet}
              </a>
            </div>
          </div>
          <div className={classes.bovadaTitle}>
            <div className={classes.sportsbooksLogoContainer}>
              <a
                className={`${classes.sportsbooksLogo} ${classes.bovadaLogo}`}
                href={globalConstants.SPORTS_BOOKS_LINKS.BOVADA}
                target="_blank"
                rel="noreferrer"
              >
                {bovadaOddsPage}
              </a>
            </div>
          </div>

          {/* //// Data //// */}
          <div className={classes.teamOneName}>
            <IconLabel
              label={
                matchInfo.teamOne.teamAbbreviation || matchInfo.teamOne.teamName
              }
              size="small"
              icon={matchInfo.teamOne.imageSmall}
              withAbbreviation
            />
          </div>

          <div className={classes.openTeamOne}>
            {generateOdds({
              serieData: matchInfo,
              openOdds,
              teamPlace: "teamOne",
            })}
          </div>
          <div className={classes.rivalryTeamOne}>
            {generateOdds({
              teamPlace: "teamOne",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
                });
              },
            })}
          </div>
          <div className={classes.thunderpickTeamOne}>
            {generateOdds({
              teamPlace: "teamOne",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
                });
              },
            })}
          </div>
          <div className={classes.esportsBetsTeamOne}>
            {generateOdds({
              teamPlace: "teamOne",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
                });
              },
            })}
          </div>

          <div className={classes.bovadaTeamOne}>
            {generateOdds({
              teamPlace: "teamOne",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
                });
              },
            })}
          </div>

          <div className={classes.teamTwoName}>
            <IconLabel
              label={
                matchInfo.teamTwo.teamAbbreviation || matchInfo.teamTwo.teamName
              }
              size="small"
              icon={matchInfo.teamTwo.imageSmall}
              withAbbreviation
            />
          </div>
          <div className={classes.openTeamTwo}>
            {generateOdds({
              serieData: matchInfo,
              openOdds,
              teamPlace: "teamTwo",
            })}
          </div>
          <div className={classes.rivalryTeamTwo}>
            {generateOdds({
              teamPlace: "teamTwo",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  switchTeams: true,
                  sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
                });
              },
            })}
          </div>
          <div className={classes.thunderpickTeamTwo}>
            {generateOdds({
              teamPlace: "teamTwo",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  switchTeams: true,
                  sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
                });
              },
            })}
          </div>
          <div className={classes.esportsBetsTeamTwo}>
            {generateOdds({
              teamPlace: "teamTwo",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  switchTeams: true,
                  sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
                });
              },
            })}
          </div>
          <div className={classes.bovadaTeamTwo}>
            {generateOdds({
              teamPlace: "teamTwo",
              serieData: matchInfo,
              sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
              clicked: () => {
                handleOddsClicked({
                  serieData: matchInfo,
                  switchTeams: true,
                  sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
                });
              },
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchOdds;
