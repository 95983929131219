// Vendors
import React, { useState } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// Components
import MainModal from "../MainModal/MainModal";

// Styles
import classes from "./MainUpload.module.scss";

// Utils
import { getBase64Image, dummyRequest } from "../../utils/globalUtils";

const MainUpload = ({
  handleImageChange,
  fileListLength = 1,
  fileList = [],
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Image(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    handleImageChange && handleImageChange(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div className={classes.MainUploadContainer}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={dummyRequest}
      >
        {fileList.length >= fileListLength ? null : uploadButton}
      </Upload>
      <MainModal
        open={previewOpen}
        footer={null}
        onCancel={handleCancel}
        closable={false}
        customTitle={previewTitle}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </MainModal>
    </div>
  );
};

export default MainUpload;
