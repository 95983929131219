// Vendors
import React from "react";

const ClockIcon = ({ color }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.15332 0.968109C5.2968 0.968109 3.51637 1.70558 2.20355 3.01834C0.890791 4.3311 0.15332 6.11168 0.15332 7.96811C0.15332 9.82453 0.890791 11.6051 2.20355 12.9179C3.51631 14.2306 5.29689 14.9681 7.15332 14.9681C9.00975 14.9681 10.7903 14.2306 12.1031 12.9179C13.4158 11.6051 14.1533 9.82453 14.1533 7.96811C14.1513 6.11219 13.4131 4.33296 12.1008 3.02074C10.7885 1.70834 9.00921 0.970149 7.15338 0.968169L7.15332 0.968109ZM7.15332 13.5683C5.66806 13.5683 4.24364 12.9782 3.19356 11.928C2.14331 10.8779 1.55326 9.45343 1.55326 7.96823C1.55326 6.48303 2.14331 5.05855 3.19356 4.00847C4.2437 2.95822 5.66812 2.36817 7.15332 2.36817C8.63852 2.36817 10.063 2.95822 11.1131 4.00847C12.1633 5.05861 12.7534 6.48303 12.7534 7.96823C12.7516 9.45289 12.1611 10.8764 11.1112 11.9262C10.0614 12.9761 8.63795 13.5666 7.15326 13.5683L7.15332 13.5683ZM7.85329 7.6783L9.74824 9.57325L8.75846 10.563L6.65843 8.463C6.52718 8.33174 6.45335 8.15372 6.45335 7.96811V5.16811H7.85341L7.85329 7.6783Z"
      fill={color || "#61617B"}
    />
  </svg>
);

export default ClockIcon;
