// Vendors
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Components
import ProfileTag from "../ProfileTag/ProfileTag";
import StaffTag from "../StaffTag/StaffTag";
import DividerLine from "../DividerLine/DividerLine";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./LatestGuides.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const LatestGuides = ({ guidesData }) => {
  return (
    <div className={classes.latestGuidesContainer}>
      {guidesData.map((guide, index) => {
        return (
          <>
            <div
              className={classes.guideContainer}
              key={`${guide.title}-${guide.game}`}
            >
              <Link
                className={classes.itemLink}
                to={{
                  pathname: `/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GUIDES}/${guide.id}`,
                }}
              >
                <div
                  className={classes.titleRow}
                  style={{
                    justifyContent:
                      !guide.title && !guide.description ? "center" : "",
                  }}
                >
                  {guide.mainImage ? (
                    <div
                      className={classes.imageContainer}
                      style={{
                        width: !guide.title && !guide.description ? "100%" : "",
                        maxWidth:
                          !guide.title && !guide.description ? "80%" : "",
                      }}
                    >
                      <img src={guide.mainImage} alt={guide.game} />
                    </div>
                  ) : null}

                  {guide.title ? (
                    <p className={classes.titleText}>{guide.title}</p>
                  ) : null}
                </div>

                {guide.description ? (
                  <p className={classes.contentText}>{guide.description}</p>
                ) : null}

                <div className={classes.lastRow}>
                  <div className={classes.profileContainer}>
                    <Link
                      to={{
                        pathname: `/${globalConstants.ROUTES.PROFILE}/${guide.user.username}/`,
                      }}
                    >
                      <ProfileTag
                        image={guide.user.image}
                        profileName={guide.user.username}
                        size={"large"}
                      />
                    </Link>
                    <StaffTag staff={guide.user.staff} />
                  </div>

                  {guide.date ? (
                    <span className={classes.dateText}>
                      {format(new Date(guide.date), "MMMM dd")}
                    </span>
                  ) : null}
                </div>
              </Link>
            </div>
            {index !== guidesData.length - 1 ? (
              <div className={classes.dividerLineWrapper}>
                <DividerLine color={styledVariables.backgroundPrimaryE} />
              </div>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default LatestGuides;
