// Vendors
import React from "react";

export const youtubeIcon = (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1133 4.07864C21.1133 4.07864 20.9053 2.50998 20.266 1.81931C19.4547 0.913977 18.546 0.908643 18.1293 0.85731C15.1447 0.625977 10.668 0.625977 10.668 0.625977H10.658C10.658 0.625977 6.18133 0.625977 3.19733 0.85731C2.78067 0.908643 1.87267 0.913977 1.06067 1.81931C0.422 2.50998 0.213333 4.07864 0.213333 4.07864C0.213333 4.07864 0 5.91931 0 7.75998V9.48531C0 11.3273 0.213333 13.1673 0.213333 13.1673C0.213333 13.1673 0.421333 14.7353 1.06067 15.4246C1.87267 16.33 2.93733 16.3026 3.412 16.3973C5.118 16.572 10.6633 16.626 10.6633 16.626C10.6633 16.626 15.1447 16.618 18.1293 16.3893C18.546 16.336 19.4547 16.3306 20.266 15.4253C20.9047 14.736 21.1133 13.168 21.1133 13.168C21.1133 13.168 21.3267 11.3273 21.3267 9.48598V7.76064C21.3267 5.91931 21.1133 4.07864 21.1133 4.07864Z"
      fill="#E02F2F"
    />
    <path d="M8 4.62598V12.626L14.6667 8.62598L8 4.62598Z" fill="white" />
  </svg>
);
