// Vendors
import React from "react";

// Components
import IconLabel from "../IconLabel/IconLabel";
import DividerLine from "../DividerLine/DividerLine";
import { format } from "date-fns";

// Assets
import { ticketIcon } from "../../assets/icons/ticket/ticket";
import { calendarIcon } from "../../assets/icons/calendar/calendar";
import { esportsLogos } from "../../assets/icons/logos/logos";

// Utils
import {
  formatNumberWithSymbol,
  transformOddToPrefered,
} from "../../utils/globalUtils";

// Styles
import classes from "./BiggestInstalockCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const BiggestInstalockCard = ({ instalockData, preferredOddsType }) => {
  return (
    <div className={classes.biggestInstalockCardContainer}>
      <div className={classes.betList}>
        {[instalockData].map((bet, index) => {
          return (
            <div key={`${bet.seriesDate}-${index}`}>
              <div className={classes.matchContainer}>
                <div className={classes.matchData}>
                  <div className={classes.teamLabelContainer}>
                    <IconLabel
                      icon={bet?.chosenTeam?.imageSmall}
                      label={
                        bet?.chosenTeam?.teamAbbreviation ||
                        bet?.chosenTeam?.teamName
                      }
                      size="large"
                      withAbbreviation
                      bold
                    />
                  </div>
                  <div className={classes.versusContainer}>VS</div>
                  <div className={classes.teamLabelContainer}>
                    <IconLabel
                      icon={bet?.alternativeTeam?.imageSmall}
                      label={
                        bet?.alternativeTeam?.teamAbbreviation ||
                        bet?.alternativeTeam?.teamName
                      }
                      size="large"
                      withAbbreviation
                      inverted
                      bold
                    />
                  </div>
                </div>
                <div className={classes.tournamentData}>
                  <IconLabel
                    label={globalConstants.TITLES[bet.game]}
                    isSvg
                    icon={esportsLogos[bet.game]}
                    justifyEnd
                  />
                  <p className={classes.tournamentText}>{bet.tournamentName}</p>
                </div>
              </div>
              {bet.odds && bet.odds !== 100 ? (
                <p className={classes.betTypeText}>
                  {bet.betType === "SPREAD" && bet.spread ? (
                    <>
                      Spread:
                      {
                        <span>
                          {formatNumberWithSymbol(parseFloat(bet.spread))}
                        </span>
                      }
                    </>
                  ) : (
                    "Money Line"
                  )}
                  <span>
                    {transformOddToPrefered(
                      parseFloat(bet.odds),
                      preferredOddsType
                    )}
                  </span>
                </p>
              ) : null}

              <DividerLine color={styledVariables.backgroundPrimaryE} />
            </div>
          );
        })}
      </div>
      <div className={classes.instalockData}>
        <div className={classes.ticketDataRow}>
          {ticketIcon}

          {instalockData.amountType ===
          globalConstants.AMOUNT_TYPES.US_DOLLAR ? (
            <span className={classes.instalockDataText}>
              Winnings: ${instalockData.winnings?.toFixed(2)}
            </span>
          ) : (
            <span className={classes.instalockDataText}>
              Winnings: {instalockData.winnings?.toFixed(2)} u
            </span>
          )}
        </div>
        <div className={classes.ticketDateRow}>
          {calendarIcon}
          <span className={classes.instalockDateText}>
            {format(new Date(instalockData.seriesDate), "MMM dd / yyyy")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BiggestInstalockCard;
