// Vendors
import React from "react";

export const tripleKillIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
  >
    <path
      d="M18.2383 3.51962V5.41813L20.1122 3.79463L21.986 2.2932L21.6591 0.661377L18.2383 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1122 3.79463L21.986 2.2932L21.6591 0.661377L18.2383 3.51962V5.41813L20.1122 3.79463ZM18.8149 4.15572L19.743 3.3516L21.3518 2.06255L21.2844 1.72588L18.8149 3.78922V4.15572Z"
      fill="#FFB800"
    />
    <path
      d="M4.40039 3.51962V5.41813L2.52652 3.79463L0.652643 2.2932L0.979526 0.661377L4.40039 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.52652 3.79463L0.652643 2.2932L0.979526 0.661377L4.40039 3.51962V5.41813L2.52652 3.79463ZM3.82381 4.15572L2.89569 3.3516L1.28688 2.06255L1.35432 1.72588L3.82381 3.78922V4.15572Z"
      fill="#FFB800"
    />
    <path
      d="M13.9141 9.59839L15.6438 8.44524V2.67947H13.9141V9.59839Z"
      fill="#A02BE2"
    />
    <path
      d="M10.4531 11.4853L11.318 12.7695L12.1829 11.4853V2.67944H10.4531V11.4853Z"
      fill="#A02BE2"
    />
    <path
      d="M8.72461 9.59839L6.99488 8.44524V2.67947H8.72461V9.59839Z"
      fill="#A02BE2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3734 1.23791H5.26526V11.4336L11.3193 15.3435L17.3734 11.4336V1.23791ZM18.2382 11.9046L11.3193 16.373L4.40039 11.9046V0.373047H18.2382V11.9046Z"
      fill="#A02BE2"
    />
  </svg>
);
