// Vendors
import React, { useCallback, useEffect, useState } from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input } from "antd";
import _debounce from "lodash/debounce";

// Components
import SearchCard from "../SearchCard/SearchCard";
import styledVariables from "../../styles/utils/_variables.scss";
import DividerLine from "../DividerLine/DividerLine";

// Styles
import classes from "./Search.module.scss";
import { discoverMatches } from "../../api/requests/requests";
import MainButton from "../MainButton/MainButton";
import { instabreakIcon } from "../../assets/icons/instabreak/instabreak";
import { instabreakIcon2 } from "../../assets/icons/instabreak2/instabreak2";

const Search = ({ preferredOddsType }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchResults = (value) => {
    discoverMatches(
      value,
      (data) => {
        if (data?.length > 0) {
          setResults(data);
          // setResults([{ ...data[0], lifecycle: "LIVE" }]);
          // setResults([{ ...data[0], lifecycle: "LIVE" }, ...data]);
          // setResults([
          //   ...data,
          //   ...data,
          //   ...data,
          //   ...data,
          //   ...data,
          //   ...data,
          //   ...data,
          // ]);
        } else {
          setResults([]);
        }
        setShowResults(true);
        setLoading(false);
      },
      () => {
        setResults([]);
        setLoading(false);
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(fetchResults, 500), []);

  const [searchText, setSearchText] = useState("");
  const [showResults, setShowResults] = useState(false);

  const trimmedText = searchText
    ? searchText
        .replace(/\s+/g, " ")
        .split(" ")
        .filter((a) => a)
        .join(" ")
    : "";

  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleTipying = (value) => {
    setResults([]);
    setLoading(true);
    setSearchText(value);
  };

  useEffect(() => {
    const newTrim = searchText
      .replace(/\s+/g, " ")
      .split(" ")
      .filter((a) => a)
      .join(" ");

    if (newTrim && newTrim.length > 1) {
      debounceFn(newTrim || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const hanldeOpenDrawer = () => {
    setOpenDrawer(true);
  };

  // Desktop

  const handleTryToShowResults = () => {
    setShowResults(trimmedText.length > 1);
  };
  const handleIconClick = (e) => {
    e.preventDefault();
    setShowResults(trimmedText.length > 1);
  };

  const handleHideShowResults = (e) => {
    setTimeout(function () {
      setShowResults(false);
    }, 200);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchMobile}>
        <div className={classes.buttonContainer}>
          <MainButton
            type="primary"
            clicked={hanldeOpenDrawer}
            icon={<SearchOutlined size={10} />}
            fullWidth="fullWidth"
          />
        </div>
        <Drawer
          onClose={onClose}
          open={openDrawer}
          width={266}
          closable={false}
        >
          <div className={classes.closeDrawerButtonContainer}>
            <Input
              size="small"
              placeholder="Search"
              className={classes.searchBoxMobile}
              prefix={
                <SearchOutlined
                  onMouseDown={handleIconClick}
                  style={{ fontSize: "150%" }}
                  onBlur={handleHideShowResults}
                />
              }
              bordered={false}
              onChange={(event) => {
                handleTipying(event.target.value);
              }}
              value={searchText}
              onBlur={handleHideShowResults}
              onFocus={() => handleTryToShowResults()}
            />
            <MainButton
              type="text"
              icon={<CloseOutlined style={{ fontSize: "150%" }} />}
              clicked={onClose}
            />
          </div>

          <div className={classes.drawerContentContainer}>
            {!loading && trimmedText.length > 1
              ? results?.map((result, index) => {
                  return (
                    <div
                      className={classes.searchResultContainer}
                      key={`${result.abiosSeriesId}-${index}`}
                    >
                      <SearchCard
                        matchData={result}
                        preferredOddsType={preferredOddsType}
                        onClose={onClose}
                      />
                      {index !== results.lenth - 1 ? (
                        <div className={classes.dividerContainer}>
                          <DividerLine
                            color={styledVariables.backgroundPrimaryB}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
            {!loading && results?.length === 0 && trimmedText.length > 1 ? (
              <div className={classes.instabreakContainer}>
                {instabreakIcon2}
                <span>No results found . . .</span>
              </div>
            ) : null}
          </div>
        </Drawer>
      </div>

      {/* // Desktop */}
      <div className={classes.searchDesktop}>
        <Input
          size="small"
          placeholder="Search"
          className={classes.searchBox}
          prefix={
            <SearchOutlined
              onMouseDown={handleIconClick}
              style={{ fontSize: "150%" }}
              onBlur={handleHideShowResults}
            />
          }
          bordered={false}
          onChange={(event) => {
            handleTipying(event.target.value);
          }}
          value={searchText}
          onBlur={handleHideShowResults}
          onFocus={() => handleTryToShowResults()}
        />

        {!loading && showResults && trimmedText.length > 1 ? (
          <div
            className={`${classes.searchResults} ${
              results?.length > 3 ? classes.overflow : ""
            }`}
          >
            {!loading && results?.length === 0 && trimmedText.length > 1 ? (
              <div className={classes.instabreakContainer}>
                {instabreakIcon}
                <span>No results found . . .</span>
              </div>
            ) : null}

            {results?.map((result, index) => {
              return (
                <div
                  className={classes.searchResultContainer}
                  key={`${result.abiosSeriesId}-${index}`}
                >
                  <SearchCard
                    matchData={result}
                    preferredOddsType={preferredOddsType}
                  />
                  {index !== results.lenth - 1 ? (
                    <div className={classes.dividerContainer}>
                      <DividerLine color={styledVariables.backgroundPrimaryB} />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Search;
