// Vendors
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";

// Components
import MainButton from "../MainButton/MainButton";
import MainModal from "../../components/MainModal/MainModal";
import MainUpload from "../../components/MainUpload/MainUpload";
import VerifyBetCard from "../../components/VerifyBetCard/VerifyBetCard";
import { globalConstants } from "../../constants/globalConstants";

// Requests
import { startBetVerification } from "../../api/requests/requests";

// Styles
import classes from "./VerifyBets.module.scss";

import GreenFinishedIcon from "../../assets/icons/greenFinished/greenFinished";

// Utils
import { isJpgOrPng, isSmallerThan1MB } from "../../utils/globalUtils";

const VerifyBets = ({
  betVerificationList,
  handleCloseBetsToVerify,
  showBetVerification,
  handleToggleBetToVerify,
  preferredOddsType,
  handleRemoveBetToVerify,
  username,
}) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(globalConstants.VERIFY_BETS_STEPS.SELECT);

  const [fileList, setFileList] = useState([]);

  const [changePictureError, setChangePictureError] = useState("");
  const [loadingChangePicture, setLoadingChangePicture] = useState(false);

  const hasSelectedBet = betVerificationList.find((bet) => bet.isSelected);

  const handleGoSelect = () => {
    setStep(globalConstants.VERIFY_BETS_STEPS.SELECT);
  };
  const handleGoUpload = () => {
    setStep(globalConstants.VERIFY_BETS_STEPS.UPLOAD);
  };
  const handleGoDone = () => {
    setStep(globalConstants.VERIFY_BETS_STEPS.DONE);
  };

  const handleCloseProcess = () => {
    setStep(globalConstants.VERIFY_BETS_STEPS.SELECT);
    setFileList([]);
    setChangePictureError("");
    handleCloseBetsToVerify();
  };

  const handleImageChange = (imageList) => {
    if (imageList.length === 0) {
      setFileList(imageList);
      setChangePictureError("");
    } else if (!isJpgOrPng(imageList[0])) {
      setChangePictureError("You can only upload JPG/PNG files!");
    } else if (!isSmallerThan1MB(imageList[0])) {
      setChangePictureError("Image must be smaller than 4MB!");
    } else {
      setFileList(imageList);
      setChangePictureError("");
    }
  };

  const handleUpload = () => {
    setChangePictureError("");
    setLoadingChangePicture(true);

    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj);

    startBetVerification(
      {
        file: formData,
        betIds: betVerificationList
          .filter((bet) => bet.isSelected)
          .map((bet) => bet.betId),
      },
      () => {
        setLoadingChangePicture(false);
        setFileList([]);
        handleGoDone();
      },
      () => {
        message.error({
          content:
            "There was a problem starting the verification process. Please try again later.",
          className: "errorMessage",
        });
        setLoadingChangePicture(false);
      }
    );
  };

  const selectStep = (
    <div className={classes.verifyBetsList}>
      {betVerificationList.length > 0 &&
        betVerificationList.map((bet) => {
          return (
            <VerifyBetCard
              key={bet.betId}
              betData={bet}
              handleToggleBetToVerify={handleToggleBetToVerify}
              preferredOddsType={preferredOddsType}
            />
          );
        })}
    </div>
  );

  const uploadStep = (
    <>
      <div className={classes.selectMoreGamesButtonContainer}>
        <MainButton
          buttonText="Select more games"
          fullWidth="fullWidth"
          type="tertiary"
          icon={<PlusOutlined />}
          clicked={() => handleGoSelect()}
        />
      </div>
      <div className={classes.verifyBetsList}>
        {betVerificationList.length > 0 &&
          betVerificationList.map((bet) => {
            return bet.isSelected ? (
              <VerifyBetCard
                key={bet.betId}
                betData={bet}
                isSubmitStep={true}
                handleToggleBetToVerify={() => {}}
                preferredOddsType={preferredOddsType}
                handleRemoveBetToVerify={handleRemoveBetToVerify}
              />
            ) : null;
          })}
      </div>
      <div className={classes.uploadContainer}>
        <MainUpload handleImageChange={handleImageChange} fileList={fileList} />
        {changePictureError ? (
          <span className={classes.uploadLabelError}>{changePictureError}</span>
        ) : (
          <span className={classes.uploadLabel}>Upload your screenshot</span>
        )}
      </div>
    </>
  );

  const doneStep = (
    <div className={classes.doneContainer}>
      <GreenFinishedIcon />
      <span>Successfully submitted!</span>
    </div>
  );

  const generateStepData = () => {
    switch (step) {
      case globalConstants.VERIFY_BETS_STEPS.SELECT:
        return {
          title: "Select games to verify",
          clickedHandler: handleGoUpload,
          buttonText: "Next",
          disabled: !hasSelectedBet,
          modalHeight: "90vh",
        };
      case globalConstants.VERIFY_BETS_STEPS.UPLOAD:
        return {
          title: "Submit your ticket slip for verification",
          clickedHandler: handleUpload,
          loading: loadingChangePicture,
          buttonText: "Submit",
          disabled: !hasSelectedBet || fileList?.length <= 0,
          modalHeight: "90vh",
        };
      case globalConstants.VERIFY_BETS_STEPS.DONE:
        return {
          title: "Submit your ticket slip for verification",
          clickedHandler: () => {
            handleCloseProcess();
            navigate(
              `/${globalConstants.ROUTES.BET_VERIFICATION}/${username}/`
            );
          },
          buttonText: "See all bets",
          disabled: false,
          modalHeight: "350px",
        };
      default:
        return "";
    }
  };

  return (
    <MainModal
      open={showBetVerification}
      footer={null}
      onCancel={handleCloseProcess}
      closable={false}
      customTitle={generateStepData().title}
      height={generateStepData().modalHeight}
    >
      <div className={classes.verifyBetsContainer}>
        {step === globalConstants.VERIFY_BETS_STEPS.SELECT ? selectStep : null}

        {step === globalConstants.VERIFY_BETS_STEPS.UPLOAD ? uploadStep : null}

        {step === globalConstants.VERIFY_BETS_STEPS.DONE ? doneStep : null}

        <div className={classes.nextButtonContainer}>
          <MainButton
            loading={generateStepData().loading}
            buttonText={generateStepData().buttonText}
            clicked={() => generateStepData().clickedHandler()}
            disabled={generateStepData().disabled}
            fullWidth={
              step === globalConstants.VERIFY_BETS_STEPS.DONE ? "fullWidth" : ""
            }
          />
        </div>
      </div>
    </MainModal>
  );
};

export default VerifyBets;
