// Vendors
import React from "react";

const UpIcon = ({ color }) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.09972 0.004498C6.31903 0.02564 6.52642 0.114227 6.69332 0.257901L11.1756 4.09987C11.4012 4.28062 11.5437 4.5452 11.5708 4.83297C11.5977 5.12076 11.5069 5.4072 11.3188 5.62667C11.1309 5.84627 10.8619 5.98027 10.5735 5.99799C10.285 6.01571 10.0016 5.91565 9.78815 5.72077L5.99962 2.47236L2.21109 5.72077C1.99758 5.91566 1.71426 6.01571 1.42573 5.99799C1.13734 5.98027 0.868305 5.84627 0.680399 5.62667C0.492356 5.40721 0.401537 5.12077 0.428484 4.83297C0.455581 4.54517 0.598064 4.28061 0.82362 4.09987L5.30592 0.257901C5.52522 0.0694118 5.8117 -0.022007 6.09962 0.004498H6.09972Z"
      fill={color || "white"}
    />
  </svg>
);

export default UpIcon;
