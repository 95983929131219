// Vendors
import React from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

// Components
import LiveCard from "../LiveCard/LiveCard";
import IconLabel from "../IconLabel/IconLabel";
import OddsTag from "../OddsTag/OddsTag";

// Styles
import classes from "./SearchCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import {
  abbreviateString,
  navigateToMatchPageIfAvailable,
} from "../../utils/globalUtils";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { finishedIcon } from "../../assets/icons/finished/finished";
import ClockIcon from "../../assets/icons/clock/clock";
import { esportsLogos } from "../../assets/icons/logos/logos";

const SearchCard = ({ preferredOddsType, matchData, onClose }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClose && onClose();
    navigateToMatchPageIfAvailable(
      navigate,
      matchData.isSeriesPresent,
      matchData.abiosSeriesId
    );
  };

  if (matchData.lifecycle === "OVER") {
    if (matchData.teamOne.score > matchData.teamTwo.score) {
      matchData.teamOne.winner = true;
    } else {
      matchData.teamTwo.winner = true;
    }
  }

  return (
    <div
      className={`${classes.searchCard} ${
        matchData.isSeriesPresent ? classes.isSeriesPresent : ""
      }`}
      onClick={handleClick}
    >
      <div className={classes.firstRow}>
        <div className={classes.matchStatus}>
          {matchData.lifecycle === "LIVE" ? (
            <LiveCard removeLabel />
          ) : matchData.lifecycle === "OVER" ? (
            <div className={classes.doneIconContainer}>{finishedIcon}</div>
          ) : matchData.lifecycle === "UPCOMING" ? (
            <div className={classes.doneIconContainer}>
              {<ClockIcon color={styledVariables.backgroundEmphasisK} />}
            </div>
          ) : null}
          <div className={classes.matchDate}>
            {format(new Date(matchData.startDate), "MM/dd/yy")}{" "}
          </div>
        </div>
        <div className={classes.esportLabelContainer}>
          <IconLabel
            icon={esportsLogos[matchData.game]}
            label={globalConstants.TITLES[matchData.game]}
            isSvg
            justifyEnd
          />
        </div>
      </div>

      <div className={classes.secondRow}>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={matchData.teamOne.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            {!!matchData.teamOne.odds && matchData.teamOne.odds !== 100 ? (
              <OddsTag
                odds={matchData.teamOne.odds}
                fullWidth
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
        <div className={classes.teamsAndScore}>
          <p className={classes.teamName}>
            {abbreviateString(
              matchData.teamOne.teamAbbreviation || matchData.teamOne.teamName,
              6
            )}
          </p>
          <div
            className={`${classes.scores} 
          ${matchData.lifecycle === "UPCOMING" ? classes.upcoming : ""}
          `}
          >
            <div
              className={`${classes.score} ${
                matchData.teamOne.winner ? classes.winner : ""
              }
        `}
            >
              {matchData.teamOne.score}
            </div>
            -
            <div
              className={`${classes.score} ${
                matchData.teamTwo.winner ? classes.winner : ""
              }
             `}
            >
              {matchData.teamTwo.score}
            </div>
          </div>
          <p className={classes.teamName}>
            {abbreviateString(
              matchData.teamTwo.teamAbbreviation || matchData.teamTwo.teamName,
              6
            )}
          </p>
        </div>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={matchData.teamTwo.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            {!!matchData.teamTwo.odds && matchData.teamTwo.odds !== 100 ? (
              <OddsTag
                odds={matchData.teamTwo.odds}
                fullWidth
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
