// Vendors
import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { KeyOutlined } from "@ant-design/icons";

// AWS
import { changePasswordAWS } from "../../AWS/UserPool";

// Components
import MainButton from "../../components/MainButton/MainButton";

// Styles
import classes from "./EditProfilePassword.module.scss";

const EditProfilePassword = ({ userSession, handleModalClose }) => {
  const [changePasswordError, setChangePasswordError] = useState("");
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);

  const handleSubmit = (values) => {
    setLoadingChangePassword(true);
    setChangePasswordError("");
    changePasswordAWS(
      { ...values, username: userSession?.idToken.payload["cognito:username"] },
      () => {
        message.success({
          content: "Password updated successfully!",
          className: "successMessage",
        });
        setLoadingChangePassword(false);
        handleModalClose();
      },
      (error) => {
        setChangePasswordError(error);
        setLoadingChangePassword(false);
      }
    );
  };

  return (
    <div className={classes.updatePasswordContainer}>
      <h3 className={classes.profileChangeTitle}>Change Password:</h3>

      <Form
        name="login"
        initialValues={{}}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <div className={classes.inputsContainer}>
          <Form.Item
            className={classes.formItem}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your current password.",
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Current Password"
              className={classes.inputBox}
              prefix={<KeyOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: "Please enter your new password.",
              },
              {
                message:
                  "The password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special characters.",
                validator: (_, value) => {
                  if (
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                      value
                    ) ||
                    !value
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="New Password"
              className={classes.inputBox}
              prefix={<KeyOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
            />
          </Form.Item>
          <Form.Item
            name="newPasswordConfirmation"
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: "Please enter the password confirmation!",
              },
              ({ getFieldValue }) => ({
                message: "The passwords must match",
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="New Password Confirm"
              className={classes.inputBox}
              prefix={<KeyOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
            />
          </Form.Item>

          {changePasswordError && (
            <span className={classes.changePasswordError}>
              {changePasswordError}
            </span>
          )}

          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText="Change Password"
              fullWidth="fullWidth"
              loading={loadingChangePassword}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditProfilePassword;
