import React, { createContext, useReducer } from "react";
import UserPool, { logOutAWS } from "../../AWS/UserPool";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// API
import {
  requestUserData,
  requestUserPreferences,
  changeProfilePreferences,
  getUserRegion,
} from "../../api/requests/requests";

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_SESSION": {
      return {
        ...state,
        userSession: action.payload,
      };
    }
    case "CLEAR_SESSION": {
      return {
        ...state,
        userSession: null,
      };
    }
    default: {
      return state;
    }
    case "SET_USER_DATA": {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case "CLEAR_USER_DATA": {
      return {
        ...state,
        userData: null,
      };
    }
    case "SET_USER_CONFIG": {
      return {
        ...state,
        userConfig: action.payload,
      };
    }
    case "SET_PREFERRED_ODDS": {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          preferredOddsType: action.payload,
        },
      };
    }
    case "SET_USER_REGION": {
      return {
        ...state,
        userRegion: action.payload,
      };
    }
  }
};

const initialState = {
  userSession: null,
  userData: null,
  userConfig: {
    preferredOddsType: sessionStorage.getItem("preferredOddsType") || "decimal",
  },
  userRegion: null,
};

export const UserContext = createContext(initialState);

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const logoutSession = () => {
    logOutAWS();
    getSessionData();
    dispatch({
      type: "CLEAR_USER_DATA",
    });
    sessionStorage.removeItem("accessToken");
  };

  const getSessionData = (onSuccess, onFail) => {
    const getSession = async () => {
      return await new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession((error, session) => {
            if (error) {
              reject();
            } else {
              resolve(session);
            }
          });
        } else {
          reject();
        }
      });
    };

    getSession()
      .then((session) => {
        sessionStorage.setItem("accessToken", session.idToken.jwtToken);
        onSuccess && onSuccess();
        dispatch({
          type: "SET_SESSION",
          payload: session,
        });
      })
      .catch(() => {
        sessionStorage.removeItem("accessToken");
        onFail && onFail();
        logOutAWS();
        dispatch({
          type: "CLEAR_SESSION",
        });
      });
  };

  const getUserData = (id, onSuccess, onFail) => {
    requestUserData(
      id,
      (data) => {
        onSuccess && onSuccess(data);
        dispatch({
          type: "SET_USER_DATA",
          payload: data,
        });
      },
      (error) => {
        onFail && onFail(error.message || JSON.stringify(error));
        logoutSession();
        dispatch({
          type: "CLEAR_USER_DATA",
        });
      }
    );
    requestUserPreferences(
      (data) => {
        sessionStorage.setItem(
          "preferredOddsType",
          data.oddsType.toLowerCase()
        );
        dispatch({
          type: "SET_USER_CONFIG",
          payload: {
            preferredOddsType: data.oddsType.toLowerCase(),
            staff: data.staff,
          },
        });
      },
      () => {
        dispatch({
          type: "SET_USER_CONFIG",
          payload: {
            preferredOddsType: "american",
          },
        });
      }
    );
  };

  const setPreferredOddsType = (oddType) => {
    changeProfilePreferences(
      { oddsType: oddType.toUpperCase() },
      (data) => {
        sessionStorage.setItem("preferredOddsType", oddType);
        dispatch({
          type: "SET_PREFERRED_ODDS",
          payload: oddType,
        });
      },
      () => {}
    );
  };

  const updateUserData = (newData) => {
    const dataToUpdate = { ...state.requestUserData, ...newData };

    dispatch({
      type: "SET_USER_DATA",
      payload: dataToUpdate,
    });
  };

  const requestUserRegion = () => {
    getUserRegion(
      (data) => {
        dispatch({
          type: "SET_USER_REGION",
          payload: data.continentCode,
        });
      },
      () => {
        dispatch({
          type: "SET_USER_REGION",
          payload: globalConstants.REGION_TYPES.EU,
        });
      }
    );
  };

  return (
    <UserContext.Provider
      value={{
        getSessionData,
        userSession: state.userSession,
        getUserData,
        updateUserData,
        userData: state.userData,
        logoutSession,
        preferredOddsType: state.userConfig.preferredOddsType,
        setPreferredOddsType,
        userRegion: state.userRegion,
        requestUserRegion,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
