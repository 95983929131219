// Vendors
import React from "react";

// Components
import IconLabel from "../IconLabel/IconLabel";
import DividerLine from "../DividerLine/DividerLine";
import ComparedOdds from "../ComparedOdds/ComparedOdds";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Styles
import classes from "./MatchActionCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

const MatchActionCard = ({ matchData, showDivider }) => {
  return (
    <div className={classes.matchActionCardContainer}>
      <div className={classes.matchAndTournamentDataContainer}>
        <div className={classes.matchTeamsRow}>
          <IconLabel
            icon={matchData.teamOne.imageSmall}
            label={matchData.teamOne.name}
            size="medium"
          />
          <p className={classes.versusText}>VS</p>
          <IconLabel
            icon={matchData.teamTwo.imageSmall}
            label={matchData.teamTwo.name}
            inverted
            size="medium"
          />
        </div>
        <div className={classes.matchTournamentRow}>
          <IconLabel
            label={globalConstants.TITLES[matchData.game]}
            icon={esportsLogos[matchData.game]}
            isSvg
          />
          <p className={classes.tournamentNameText}>
            {matchData.tournamentName}
          </p>
        </div>
      </div>
      {/* <p className={classes.totalBetsAmount}>{matchData.totalBets} Bets</p> */}
      <div className={classes.oddsSection}>
        <ComparedOdds
          firstOdds={matchData.teamOne.bets}
          firstOddsColor={styledVariables.backgroundEmphasisB}
          secondOdds={matchData.teamTwo.bets}
          secondOddsColor={styledVariables.backgroundPrimaryC}
        />
      </div>

      {showDivider && (
        <DividerLine color={styledVariables.backgroundPrimaryE} margin={16} />
      )}
    </div>
  );
};

export default MatchActionCard;
