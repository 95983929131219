// Vendors
import React, { useState } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

// Components
import BetLogItem from "../BetLogItem/BetLogItem";
import MainButton from "../MainButton/MainButton";

// Utils
import // formatNumberWithSymbol,
"../../utils/globalUtils";

// Assets
import { betLogIcon } from "../../assets/icons/betLog/betLog";

// Styles
import classes from "./TodayBets.module.scss";

const MAXIMUM_BETS_SHOWN = 5;

const TodayBets = ({
  handleDeleteBet,
  isProfileOwner,
  loadingDeleteBet,
  preferredOddsType,
  requestedUserData,
  setShowFullBetLog,
}) => {
  const [currentBetIndex, setCurrentBetIndex] = useState(0);

  const betLogData = requestedUserData.betHistorySummary?.betLog?.slice(0, 5);

  const betLogItemData =
    requestedUserData.betHistorySummary?.betLog[currentBetIndex];

  const handleNextBet = (next) => {
    const itemAmount = betLogData?.length;

    if (currentBetIndex === 0 && !next) {
      setCurrentBetIndex(itemAmount - 1);
    } else if (
      (currentBetIndex === itemAmount - 1 ||
        currentBetIndex + 1 === MAXIMUM_BETS_SHOWN) &&
      next
    ) {
      setCurrentBetIndex(0);
    } else if (next) {
      setCurrentBetIndex(currentBetIndex + 1);
    } else {
      setCurrentBetIndex(currentBetIndex - 1);
    }
  };

  return (
    <div className={classes.todayBetItemContainer}>
      <div className={classes.headerRow}>
        <p className={classes.betLogTitle}>
          {betLogIcon}
          <span className={classes.betLogTitleText}>Today's bet log</span>
        </p>
        <MainButton
          buttonText="All bets"
          icon={<FileTextOutlined />}
          clicked={() => setShowFullBetLog(true)}
        />
      </div>

      {betLogData?.length > 0 ? (
        <div className={classes.contentRow}>
          <BetLogItem
            betData={betLogItemData}
            preferredOddsType={preferredOddsType}
            handleDeleteBet={handleDeleteBet}
            loadingDeleteBet={loadingDeleteBet}
            isProfileOwner={isProfileOwner}
          />
        </div>
      ) : (
        <div className={classes.noDataCard}>No bets for today . . .</div>
      )}
      {betLogData?.length > 1 && (
        <div className={classes.footerRow}>
          <p className={classes.betNumberText}>
            {currentBetIndex + 1}
            <span>{` / ${betLogData.length}`}</span>
          </p>
          <div className={classes.nextButtonContainer}>
            <MainButton
              icon={<ArrowLeftOutlined />}
              type="secondary"
              size="small"
              clicked={() => handleNextBet(false)}
            />
            <MainButton
              icon={<ArrowRightOutlined />}
              type="secondary"
              size="small"
              clicked={() => handleNextBet(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TodayBets;
