// Vendors
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

// Styles
import classes from "./MainDatePicker.module.scss";

const MainDatePicker = ({ handleChangeEvent, value }) => {
  const handleChange = (event) => {
    handleChangeEvent(event);
  };

  return (
    <div className={classes.mainDatePicker}>
      <DatePicker
        onChange={handleChange}
        defaultValue={moment(value || new Date())}
      />
    </div>
  );
};

export default MainDatePicker;
