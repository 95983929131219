// Vendors
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// API
import { requestMatchData, getEsportRSS } from "../../api/requests/requests";

// Components
import MatchPlayerInfo from "../../components/MatchPlayerInfo/MatchPlayerInfo";
// import MatchStatsInfo from "../../components/MatchStatsInfo/MatchStatsInfo";
import MatchBanner from "../../components/MatchBanner/MatchBanner";
import MatchRecentSchedule from "../../components/MatchRecentSchedule/MatchRecentSchedule";
import MatchOdds from "../../components/MatchOdds/MatchOdds";
import MatchBettingResults from "../../components/MatchBettingResults/MatchBettingResults";
import Loading from "../../components/Loading/Loading";
import MatchStatsLastTen from "../../components/MatchStatsLastTen/MatchStatsLastTen";
import LatestNews from "../../components/LatestNews/LatestNews";
import MatchStats from "../../components/MatchStats/MatchStats";

// Utils
import { abbreviateString } from "../../utils/globalUtils";

// Styles
import classes from "./MatchPage.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import MatchTrends from "../../components/MatchTrends/MatchTrends";
// import MatchPageLatestPicksWrapper from "../../components/MatchPageLatestPicksWrapper/MatchPageLatestPicksWrapper";

// Assets
import dexertoNews from "../../assets/images/news/dexertoNews.png";
import shiftrleGGNews from "../../assets/images/news/shiftrleGGNews.png";
import esportsNetNews from "../../assets/images/news/esportsNetNews.png";
import hltvNews from "../../assets/images/news/hltvNews.png";
import invenGlobalNews from "../../assets/images/news/invenGlobalNews.png";
import MatchRiskScore from "../../components/MatchRiskScore/MatchRiskScore";
import MatchFantasyLines from "../../components/MatchFantasyLines/MatchFantasyLines";
import MatchTeamProfitability from "../../components/MatchTeamProfitability/MatchTeamProfitability";

const MatchPage = ({ title }) => {
  const params = useParams();
  const navigate = useNavigate();

  const [loadingMatchData, setLoadingMatchData] = useState(true);
  const [requestedMatchData, setRequestedMatchData] = useState(null);
  const [loadingRSSData, setLoadingRSSData] = useState(true);
  const [RSSData, setRSSData] = useState();

  const handleGetMatchData = () => {
    setLoadingMatchData(true);
    requestMatchData(
      params.id,
      (data) => {
        setRequestedMatchData(data);
        setLoadingMatchData(false);
      },
      () => {
        setLoadingMatchData(false);
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  const { preferredOddsType } = useContext(UserContext);

  const replaceCDData = (string) => {
    if (!string) {
      return "";
    }
    return string.replace("<![CDATA[", "").replace("]]>", "");
  };

  const extractUrlFromString = (str) => {
    const regex = /url="(.*?)"/;
    const match = str.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };

  const generateNewsData = (data, game) => {
    const feed = new window.DOMParser().parseFromString(data, "text/xml");
    const items = feed.querySelectorAll("item");
    const feedItems = [...items].slice(0, 4).map((element) => {
      const title = replaceCDData(element.querySelector("title").innerHTML);
      const date = !!element.querySelector("pubDate")
        ? replaceCDData(element.querySelector("pubDate").innerHTML)
        : "";
      const content = !!element.querySelector("description")
        ? replaceCDData(element.querySelector("description").innerHTML)
        : "";

      const link = !!element.querySelector("link")
        ? replaceCDData(element.querySelector("link").innerHTML)
        : "";

      const media = element.children?.[element.children.length - 1];
      const mediaImage = extractUrlFromString(media.outerHTML);
      let newGame = { esport: game, image: game };

      if (!game && media && media.outerHTML) {
        if (media.outerHTML.includes("invenglobal.com")) {
          newGame = {
            esport: globalConstants.LEAGUE_OF_LEGENDS,
            image: globalConstants.LEAGUE_OF_LEGENDS,
          };
        } else if (media.outerHTML.includes("esports.net")) {
          newGame = {
            esport: globalConstants.CS_GO,
            image: `${globalConstants.CS_GO}-2`,
          };
        } else if (
          media.outerHTML.includes("shiftrle.gg") ||
          link?.includes("shiftrle.gg")
        ) {
          newGame = {
            esport: globalConstants.ROCKET_LEAGUE,
            image: globalConstants.ROCKET_LEAGUE,
          };
        } else if (media.outerHTML.includes("dexerto")) {
          if (link.includes(globalConstants.VALORANT.toLocaleLowerCase())) {
            newGame = {
              esport: globalConstants.VALORANT,
              image: globalConstants.VALORANT,
            };
          } else {
            newGame = {
              esport: globalConstants.CALL_OF_DUTY,
              image: globalConstants.CALL_OF_DUTY,
            };
          }
        }
      }

      return {
        image: getRssImage(newGame.image),
        game: newGame.esport,
        title: title,
        date: date,
        content: content,
        link: link,
        mediaImage: mediaImage,
      };
    });

    return { news: feedItems };
  };

  const getRssImage = (esport) => {
    switch (esport) {
      case globalConstants.CS_GO:
        return hltvNews;
      case `${globalConstants.CS_GO}-2`:
        return esportsNetNews;
      case globalConstants.VALORANT:
        return dexertoNews;
      case globalConstants.LEAGUE_OF_LEGENDS:
        return invenGlobalNews;
      case globalConstants.ROCKET_LEAGUE:
        return shiftrleGGNews;
      case globalConstants.CALL_OF_DUTY:
        return dexertoNews;
      default:
        return "";
    }
  };

  const handleGetRSSNews = (game) => {
    setLoadingRSSData(true);
    getEsportRSS(
      game,
      (data) => {
        setRSSData(generateNewsData(data, game));
        setLoadingRSSData(false);
      },
      (error) => {
        setRSSData(null);
        setLoadingRSSData(false);
      }
    );
  };

  useEffect(() => {
    handleGetMatchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (requestedMatchData) {
      handleGetRSSNews(requestedMatchData.seriesDetails.abiosGameId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestedMatchData]);

  useEffect(() => {
    document.title = title;

    if (requestedMatchData?.seriesDetails) {
      const { teamOne, teamTwo, startDate } = requestedMatchData?.seriesDetails;
      abbreviateString(teamTwo.teamAbbreviation || teamTwo.teamName, 6);

      const newTitle = `${title}${abbreviateString(
        teamOne.teamAbbreviation || teamOne.teamName,
        6
      )} v ${abbreviateString(
        teamTwo.teamAbbreviation || teamTwo.teamName,
        6
      )} ${startDate ? format(new Date(startDate), "MM/dd/yyyy") : ""}`;
      document.title = newTitle;
    } else {
      document.title = title;
    }
  }, [title, requestedMatchData]);

  const loading = loadingRSSData && loadingMatchData;

  const isFinishedMatch =
    requestedMatchData &&
    requestedMatchData.seriesDetails &&
    requestedMatchData.seriesDetails.endDate;

  return !loading ? (
    <div className={classes.matchPageContainerWrapper}>
      <div className={classes.matchPageContainer}>
        <div className={classes.bannerContainerMobile}>
          <MatchBanner />
        </div>
        <div className={classes.leftColumn}>
          <div className={classes.bannerContainer}>
            <MatchBanner />
          </div>
          <div className={classes.matchStatsLastTenContainerMobile}>
            <MatchStatsLastTen
              seriesDetails={requestedMatchData.seriesDetails}
            />
          </div>

          <div className={classes.trendsContainer}>
            <MatchTrends seriesDetails={requestedMatchData.seriesDetails} />
          </div>
          <div className={classes.riskScoreContainer}>
            <MatchRiskScore seriesDetails={requestedMatchData.seriesDetails} />
          </div>
          <div className={classes.matchFantasyLinesContainer}>
            <MatchFantasyLines
              seriesDetails={requestedMatchData.seriesDetails}
            />
          </div>
        </div>
        <div className={classes.centerColumn}>
          <div className={classes.matchPlayerInfoContainer}>
            <MatchPlayerInfo
              isFinishedMatch={isFinishedMatch}
              preferredOddsType={preferredOddsType}
              seriesDetails={requestedMatchData.seriesDetails}
            />
          </div>

          {/* <div className={classes.latestsPicksContainer}>
          {/* <MatchPageLatestPicksWrapper
          initialBets={requestedMatchData.latestBets.bets}
          preferredOddsType={preferredOddsType}
          seriesId={params.id}
          /> 
        </div> */}

          <div className={classes.recentScheduleContainer}>
            {isFinishedMatch ? (
              <div className={classes.bettingResultsContainer}>
                <MatchBettingResults
                  matchInfo={requestedMatchData.seriesDetails}
                  preferredOddsType={preferredOddsType}
                />
              </div>
            ) : null}
            <div className={classes.recentScheduleContainer}>
              <MatchTeamProfitability
                matchInfo={requestedMatchData.seriesDetails}
                preferredOddsType={preferredOddsType}
              />
            </div>
            {isFinishedMatch ? (
              <div className={classes.bettingResultsContainer}>
                <MatchStats
                  matchInfo={requestedMatchData.seriesDetails}
                  preferredOddsType={preferredOddsType}
                />
              </div>
            ) : null}

            <MatchRecentSchedule
              preferredOddsType={preferredOddsType}
              seriesDetails={requestedMatchData.seriesDetails}
            />
          </div>
          {!isFinishedMatch ? (
            <div className={classes.popularOddsContainer}>
              <MatchOdds
                matchInfo={requestedMatchData.odds}
                preferredOddsType={preferredOddsType}
              />
            </div>
          ) : null}
          {/* <div className={classes.matchStatsContainer}>
          <MatchStatsInfo
            betStats={requestedMatchData.betStats}
            seriesDetails={requestedMatchData.seriesDetails}
            />
          </div> */}
        </div>
        <div className={classes.rightColumn}>
          <div className={classes.matchStatsLastTenContainer}>
            <MatchStatsLastTen
              seriesDetails={requestedMatchData.seriesDetails}
            />
          </div>

          <div className={classes.newsContainer}>
            {RSSData?.news.length > 0 ? (
              <LatestNews newsData={RSSData?.news} />
            ) : loadingRSSData ? null : (
              <div className={classes.noDataCard}>No news . . .</div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default MatchPage;
