// Vendors
import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import HeaderProfile from "../HeaderProfile/HeaderProfile";
import MainSelect from "../MainSelect/MainSelect";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { userIcon } from "../../assets/icons/user/user";
import { exitIcon } from "../../assets/icons/exit/exit";

// Styles
import styledVariables from "../../styles/utils/_variables.scss";
import classes from "./ProfileMenu.module.scss";

const ProfileMenu = ({ username, image, logoutHandler }) => {
  const navigate = useNavigate();

  const MENU_OPTIONS = [
    {
      label: <HeaderProfile username={username} image={image} />,
      key: "american",
    },
    {
      label: (
        <span className={classes.labelWithIcon}>{userIcon}My profile</span>
      ),
      key: "profile",
      handleChange: () =>
        navigate(`/${globalConstants.ROUTES.PROFILE}/${username}/`),
    },
    {
      label: <span className={classes.labelWithIcon}>{exitIcon}Logout</span>,
      key: "logout",
      handleChange: () => logoutHandler(),
    },
  ];

  return (
    <div className={classes.profileMenuContainer}>
      <MainSelect
        selectColor={styledVariables.backgroundPrimaryG}
        size="small"
        currentValue={MENU_OPTIONS[0]}
        setCurrentValue={() => {}}
        options={MENU_OPTIONS}
        iconWithBox
      />
    </div>
  );
};

export default ProfileMenu;
