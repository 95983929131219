// Assets
import { underdogFantasy } from "../../../assets/icons/staff/underdogFantasy.js";
import { thunderPick } from "../../../assets/icons/staff/thunderPick.js";
import { esportsbet } from "../../../assets/icons/staff/esportsbet.js";
import { bovada } from "../../../assets/icons/staff/bovada.js";
import { prizePicks } from "../../../assets/icons/staff/prizePicks.js";
import { rollbit } from "../../../assets/icons/staff/rollbit.js";
import { duelbits } from "../../../assets/icons/staff/duelbits.js";
import { rivalry } from "../../../assets/icons/staff/rivalry.js";
import { stake } from "../../../assets/icons/staff/stake.js";
import { cloutFantasy } from "../../../assets/icons/staff/cloutFantasy.js";
import { thriveFantasy } from "../../../assets/icons/staff/thriveFantasy.js";
import { sportsBattle } from "../../../assets/icons/staff/sportsBattle.js";
import { betOnline } from "../../../assets/icons/staff/betOnline.js";

import { rotoRadar } from "../../../assets/icons/staff/rotoRadar.js";
import { instalock } from "../../../assets/icons/staff/instalock.js";
import { CDLSportsbook } from "../../../assets/icons/staff/CDLSportsbook.js";
import { lostFullGG } from "../../../assets/icons/staff/lostFullGG.js";
import { srirachaDFS } from "../../../assets/icons/staff/srirachaDFS.js";
import { hltv } from "../../../assets/icons/staff/hltv.js";
import { atmCrew } from "../../../assets/icons/staff/atmCrew.js";
import { bookitSports } from "../../../assets/icons/staff/bookitSports.js";

// Styles
import styledVariables from "../../../styles/utils/_variables.scss";

// Constants
import { globalConstants } from "../../../constants/globalConstants";

export const staffConstants = {
  underdogFantasy: {
    icon: underdogFantasy,
    label: "Underdog Fantasy",
    url: globalConstants.SPORTS_BOOKS_LINKS.UDNERDOG_FANTASY,
    color: styledVariables.underdogFantasy,
  },
  thunderPick: {
    icon: thunderPick,
    label: "ThunderPick",
    url: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
    color: styledVariables.thunderPick,
  },
  esportsbet: {
    icon: esportsbet,
    label: "Esportsbet",
    url: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
    color: styledVariables.esportsbet,
  },
  bovada: {
    icon: bovada,
    label: "Bovada",
    url: globalConstants.SPORTS_BOOKS_LINKS.BOVADA,
    color: styledVariables.bovada,
  },
  prizePicks: {
    icon: prizePicks,
    label: "PrizePicks",
    url: globalConstants.SPORTS_BOOKS_LINKS.PRIZE_PICKS,
    color: styledVariables.prizePicks,
  },
  rollbit: {
    icon: rollbit,
    label: "Rollbit",
    url: globalConstants.SPORTS_BOOKS_LINKS.ROLLBIT,
    color: styledVariables.rollbit,
  },
  duelbits: {
    icon: duelbits,
    label: "Duelbits",
    url: globalConstants.SPORTS_BOOKS_LINKS.DUELBITS,
    color: styledVariables.duelbits,
  },
  rivalry: {
    icon: rivalry,
    label: "Rivalry",
    url: globalConstants.SPORTS_BOOKS_LINKS.RIVALRY,
    color: styledVariables.rivalry,
  },
  stake: {
    icon: stake,
    label: "Stake",
    url: globalConstants.SPORTS_BOOKS_LINKS.STAKE,
    color: styledVariables.stake,
  },
  cloutFantasy: {
    icon: cloutFantasy,
    label: "Clout Fantasy",
    url: globalConstants.SPORTS_BOOKS_LINKS.CLOUT_FANTASY,
    color: styledVariables.cloutFantasy,
  },
  thriveFantasy: {
    icon: thriveFantasy,
    label: "ThriveFantasy",
    url: globalConstants.SPORTS_BOOKS_LINKS.THRIVE_FANTASY,
    color: styledVariables.thriveFantasy,
  },
  sportsBattle: {
    icon: sportsBattle,
    label: "SportsBattle",
    url: globalConstants.SPORTS_BOOKS_LINKS.SPORTS_BATTLE,
    color: styledVariables.sportsBattle,
  },
  betOnline: {
    icon: betOnline,
    label: "BetOnline",
    url: globalConstants.SPORTS_BOOKS_LINKS.BET_ONLINE,
    color: styledVariables.betOnline,
  },

  // Community
  rotoRadar: {
    icon: rotoRadar,
    label: "RotoRadar",
    url: globalConstants.SPORTS_BOOKS_LINKS.ROTO_RADAR,
    color: styledVariables.rotoRadar,
  },
  CDLSportsbook: {
    icon: CDLSportsbook,
    label: "CDL Sportsbook",
    url: globalConstants.SPORTS_BOOKS_LINKS.CDL_SPORTSBOOK,
    color: styledVariables.CDLSportsbook,
  },
  lostFullGG: {
    icon: lostFullGG,
    label: "LostFull.GG",
    url: globalConstants.SPORTS_BOOKS_LINKS.LOST_FULL_GG,
    color: styledVariables.lostFullGG,
  },
  srirachaDFS: {
    icon: srirachaDFS,
    label: "Sriracha DFS",
    url: globalConstants.SPORTS_BOOKS_LINKS.SRIRACHA_DFS,
    color: styledVariables.srirachaDFS,
  },
  hltv: {
    icon: hltv,
    label: "HLTV.ORG",
    url: globalConstants.SPORTS_BOOKS_LINKS.HLTV,
    color: styledVariables.hltv,
  },
  atmCrew: {
    icon: atmCrew,
    label: "ATM Crew",
    url: globalConstants.SPORTS_BOOKS_LINKS.ATM_CREW,
    color: styledVariables.atmCrew,
  },
  bookitSports: {
    icon: bookitSports,
    label: "Bookit Sports",
    url: globalConstants.SPORTS_BOOKS_LINKS.BOOKIT_SPORTS,
    color: styledVariables.bookitSports,
  },
  instalock: {
    icon: instalock,
    label: "INSTALOCK",
    url: "",
    color: styledVariables.instalock,
  },
};
