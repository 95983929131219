// Vendors
import React from "react";

export const twitterIcon = (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66065 14.6259C12.4531 14.6259 16.1683 8.99842 16.1683 4.11824C16.1683 3.95842 16.1683 3.7993 16.1575 3.64089C16.8803 3.11808 17.5042 2.47082 18 1.7293C17.326 2.02795 16.611 2.22377 15.8789 2.31033C16.6496 1.84894 17.2269 1.12268 17.5025 0.26768C16.7776 0.697816 15.9844 1.001 15.1574 1.16409C14.4592 0.421559 13.4839 0 12.4647 0C10.4368 0 8.76822 1.66866 8.76822 3.69647C8.76822 3.97779 8.80035 4.25823 8.86391 4.53224C5.89609 4.38349 3.12711 2.9797 1.25279 0.67377C0.278121 2.35164 0.782437 4.52605 2.39614 5.60359C1.80851 5.58619 1.23349 5.42763 0.72 5.14136V5.18815C0.720492 6.93942 1.96618 8.46158 3.68279 8.80833C3.13914 8.95658 2.56859 8.97824 2.0153 8.87168C2.49789 10.3723 3.88951 11.4067 5.46553 11.4363C4.15807 12.4638 2.54204 13.022 0.879117 13.0203C0.585281 13.0198 0.291727 13.002 0 12.967C1.6887 14.0507 3.65414 14.6257 5.66065 14.623"
      fill="#1DA1F2"
    />
  </svg>
);
