// Vendors
import React from "react";

export const duelbits = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.30066 8.03731C4.22706 6.119 6.17022 4.18509 8.092 2.27197C10.0138 4.19606 11.9569 6.14096 13.8752 8.06158C11.9679 9.96891 10.0248 11.9121 8.10587 13.831C6.19044 11.9196 4.25075 9.98394 2.30066 8.03789V8.03731ZM8.10587 11.3845C8.76283 11.3811 9.33255 10.8177 9.32792 10.1763C9.3233 9.48876 8.7559 8.92078 8.0816 8.92829C7.42695 8.93522 6.88034 9.49222 6.87919 10.1532C6.87803 10.8235 7.44081 11.3886 8.10587 11.3851V11.3845ZM8.10587 7.45835C8.76514 7.45835 9.32215 6.90886 9.33197 6.24958C9.34179 5.59377 8.75936 5.00557 8.10182 5.00672C7.43388 5.00846 6.88092 5.57182 6.88034 6.25189C6.87919 6.90135 7.44601 7.45893 8.10587 7.45835Z"
      fill="#33C16C"
    />
    <path
      d="M5.64205 2.75897C4.68636 3.70715 3.74512 4.64088 2.78538 5.59253C2.74147 5.55555 2.67329 5.50585 2.61435 5.44692C1.81756 4.65186 1.01614 3.86027 0.231485 3.05307C0.125747 2.94387 0.0512098 2.75666 0.047743 2.60412C0.029831 1.83506 0.047743 1.06485 0.0344534 0.295209C0.0304088 0.0629313 0.106679 -0.0023607 0.332023 0.00110613C1.12073 0.0126622 1.91001 0.00110613 2.69929 0.0109288C2.81254 0.0120844 2.95641 0.0507974 3.03326 0.125912C3.8913 0.96893 4.73952 1.82235 5.58889 2.67345C5.60854 2.6931 5.61894 2.72141 5.64205 2.75897Z"
      fill="#33C16C"
    />
    <path
      d="M13.4477 5.57747C12.4926 4.62294 11.5502 3.68111 10.5905 2.72196C10.6217 2.68613 10.6731 2.61969 10.732 2.56075C11.5207 1.77089 12.3054 0.976408 13.1051 0.198104C13.2137 0.0923661 13.3997 0.0160958 13.5523 0.012629C14.3219 -0.00586075 15.0921 0.0108956 15.8618 -8.2695e-05C16.0888 -0.00354953 16.1871 0.0472973 16.1819 0.300376C16.1669 1.07983 16.1819 1.85929 16.1703 2.63875C16.1686 2.76009 16.1218 2.91263 16.0397 2.99584C15.2112 3.84117 14.371 4.67494 13.5332 5.51102C13.5003 5.54396 13.4593 5.56822 13.4471 5.57747H13.4477Z"
      fill="#33C16C"
    />
    <path
      d="M4.73903 14.7964C4.45301 14.4642 4.15429 14.1175 3.84574 13.7598C3.05761 14.5514 2.32784 15.2852 1.61714 15.9994C1.12659 15.5111 0.673011 15.0599 0.196899 14.5855C0.243124 14.5341 0.308416 14.4555 0.380641 14.3833C0.975781 13.7818 1.56745 13.1762 2.17242 12.5851C2.34056 12.421 2.36367 12.3112 2.17819 12.1483C1.89854 11.9027 1.63679 11.637 1.3918 11.4029C1.79684 10.9967 2.16028 10.6316 2.50408 10.2866C3.61751 11.4047 4.74769 12.5395 5.8565 13.6535C5.5312 13.9869 5.1666 14.3596 4.7396 14.797L4.73903 14.7964Z"
      fill="#33C16C"
    />
    <path
      d="M12.358 13.7095C12.1835 13.9285 12.0436 14.1111 11.8957 14.2868C11.7536 14.4555 11.6028 14.6173 11.4427 14.7964C11.0469 14.3775 10.6887 13.999 10.3478 13.6379C11.4462 12.5476 12.5821 11.4191 13.6985 10.3103C14.0244 10.642 14.3901 11.0146 14.7975 11.4289C14.5028 11.693 14.1613 11.9992 13.7921 12.3297C14.586 13.1051 15.3308 13.832 16.0623 14.5462C15.5486 15.0541 15.0933 15.5042 14.597 15.9947C13.899 15.2823 13.1698 14.5381 12.358 13.7095Z"
      fill="#33C16C"
    />
  </svg>
);
