// Vendors
import React, { useState, useContext } from "react";
import { Form, Input, message } from "antd";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// API
import {
  changeProfilePicture,
  changeProfileData,
} from "../../api/requests/requests";

// Components
import MainButton from "../../components/MainButton/MainButton";
import MainUpload from "../../components/MainUpload/MainUpload";
import DividerLine from "../../components/DividerLine/DividerLine";

// Styles
import classes from "./EditProfileData.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import { isJpgOrPng, isSmallerThan1MB } from "../../utils/globalUtils";

const EditProfileData = ({
  handleSetUserData,
  requestedUserData,
  handleModalClose,
}) => {
  const [fileList, setFileList] = useState([]);
  const [changeProfilePictureError, setChangeProfilePictureError] =
    useState("");
  const [loadingChangeProfilePicture, setLoadingChangeProfilePicture] =
    useState(false);

  const [changeDescriptionError, setChangeDescriptionError] = useState("");
  const [loadingChangeDescription, setLoadingChangeDescription] =
    useState(false);

  const { updateUserData } = useContext(UserContext);

  const handleUpload = () => {
    setChangeProfilePictureError("");
    setLoadingChangeProfilePicture(true);

    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj);

    changeProfilePicture(
      formData,
      (data) => {
        setLoadingChangeProfilePicture(false);
        setFileList([]);
        handleSetUserData({ imageUrl: data });
        updateUserData({ imageUrl: data });
        handleModalClose();
        message.success({
          content: "Profile picture updated successfully!",
          className: "successMessage",
        });
      },
      (error) => {
        setChangeProfilePictureError(error);
        setLoadingChangeProfilePicture(false);
      }
    );
  };

  const handleChangeProfilePicture = () => {
    if (fileList.length > 0) {
      handleUpload();
    } else {
      setChangeProfilePictureError("You must upload an image.");
    }
  };

  const handleImageChange = (imageList) => {
    if (imageList.length === 0) {
      setFileList(imageList);
      setChangeProfilePictureError("");
    } else if (!isJpgOrPng(imageList[0])) {
      setChangeProfilePictureError("You can only upload JPG/PNG files!");
    } else if (!isSmallerThan1MB(imageList[0])) {
      setChangeProfilePictureError("Image must be smaller than 4MB!");
    } else {
      setFileList(imageList);
      setChangeProfilePictureError("");
    }
  };

  const handleChangeDescription = (values) => {
    setChangeDescriptionError("");
    setLoadingChangeDescription(true);
    changeProfileData(
      {
        ...values,
        twitter: requestedUserData.twitter,
        instagram: requestedUserData.instagram,
        youtube: requestedUserData.youtube,
        twitch: requestedUserData.twitch,
      },
      (data) => {
        setLoadingChangeDescription(false);
        setChangeDescriptionError("");
        handleSetUserData(data);
        handleModalClose();
        message.success({
          content: "User description successfully updated!",
          className: "successMessage",
        });
      },
      (error) => {
        setLoadingChangeDescription(false);
        setChangeDescriptionError(error);
      }
    );
  };

  const { TextArea } = Input;

  return (
    <div className={classes.profileTabContainer}>
      <h3 className={classes.profileChangeTitle}>Profile Picture:</h3>
      <div className={classes.updateProfileImageContainer}>
        <MainUpload handleImageChange={handleImageChange} fileList={fileList} />
        <div className={classes.updateProfilePictureControls}>
          <MainButton
            loading={loadingChangeProfilePicture}
            buttonText="Change Picture"
            clicked={handleChangeProfilePicture}
          />
          <span className={classes.changeProfilePictureError}>
            {changeProfilePictureError && changeProfilePictureError}
          </span>
        </div>
      </div>
      <DividerLine color={styledVariables.backgroundPrimaryE} />
      <h3 className={classes.profileChangeTitle}>Description:</h3>
      <Form
        name="description"
        initialValues={{
          description: requestedUserData.description,
        }}
        onFinish={handleChangeDescription}
        autoComplete="off"
        className={classes.descriptionform}
      >
        <div className={classes.inputsContainer}>
          <Form.Item className={classes.formItem} name="description">
            <TextArea
              size="large"
              placeholder="A quick description of my profile . . ."
              className={classes.inputBox}
              bordered={false}
              rows={4}
              maxLength={100}
              style={{ height: 120, resize: "none" }}
            />
          </Form.Item>

          {changeDescriptionError && (
            <span className={classes.changeDescriptionError}>
              {changeDescriptionError}
            </span>
          )}

          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText="Update description"
              fullWidth="fullWidth"
              loading={loadingChangeDescription}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditProfileData;
