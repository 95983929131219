// Vendors
import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

// Components
import MainButton from "../MainButton/MainButton";

// Styles
import classes from "./MainModal.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const MainModal = ({
  open,
  onCancel,
  customTitle,
  children,
  buttonType,
  bodyBackgroundColor,
  height,
}) => {
  const title = (
    <div
      className={classes.customTitleRow}
      style={{ marginBottom: customTitle ? "20px" : "" }}
    >
      <h4 className={classes.customTitle}>{customTitle}</h4>
      {onCancel && (
        <MainButton
          type={buttonType || "secondary"}
          icon={<CloseOutlined />}
          clicked={onCancel}
        />
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      bodyStyle={{
        backgroundColor:
          bodyBackgroundColor || styledVariables.backgroundPrimaryB,
        height: height ?? null,
      }}
      closable={false}
      destroyOnClose={true}
    >
      {title}
      {children}
    </Modal>
  );
};

export default MainModal;
