// Vendors
import React from "react";
// Styles
import classes from "./WinResultAmount.module.scss";

import IconLabel from "../IconLabel/IconLabel";
import { americanToDecimalOdds } from "../../utils/globalUtils";
import { moneyIcon } from "../../assets/icons/money/money";

const WinResultAmount = ({ team }) => {
  const calculatePayout = (odds) => {
    const decimal = americanToDecimalOdds(odds);
    return (decimal * 100).toFixed(2);
  };

  return team.odds ? (
    <div className={classes.WinResultAmountContainer}>
      <span>{moneyIcon}</span>
      <span className={classes.amount}>$100 on</span>
      <div className={classes.iconContainer}>
        <IconLabel
          icon={team.imageSmall || team.teamImgSm}
          label={team.teamAbbreviation || team.teamName}
          logoSize="logoSmall"
          size="small"
          withAbbreviation
          bold
        />
      </div>
      <span className={classes.amount}>
        to win returned{" "}
        <span className={classes.amount2}>${calculatePayout(team.odds)}</span>
      </span>
    </div>
  ) : null;
};

export default WinResultAmount;
