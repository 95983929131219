// Vendors
import React from "react";

export const chainIcon = (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.73084 7.46144H3.96151C2.05274 7.46144 0.5 5.90853 0.5 3.99993C0.5 2.09117 2.05291 0.538422 3.96151 0.538422H8.11538C10.0241 0.538422 11.5769 2.09133 11.5769 3.99993C11.5769 4.38214 11.2668 4.69226 10.8846 4.69226C10.5024 4.69226 10.1922 4.38213 10.1922 3.99993C10.1922 2.85483 9.26032 1.92293 8.11522 1.92293H3.96135C2.81624 1.92293 1.88435 2.85483 1.88435 3.99993C1.88435 5.14503 2.81624 6.07693 3.96135 6.07693H6.73055C7.11277 6.07693 7.42289 6.38706 7.42289 6.76926C7.42289 7.15135 7.11276 7.46147 6.73068 7.46147L6.73084 7.46144Z"
      fill="white"
    />
    <path
      d="M15.0382 7.46151H10.8844C8.97559 7.46151 7.42285 5.9086 7.42285 4C7.42285 3.61778 7.73298 3.30767 8.11518 3.30767C8.49739 3.30767 8.80752 3.6178 8.80752 4C8.80752 5.1451 9.73942 6.077 10.8845 6.077H15.0384C16.1835 6.077 17.1154 5.1451 17.1154 4C17.1154 2.8549 16.1835 1.923 15.0384 1.923H12.2692C11.887 1.923 11.5769 1.61287 11.5769 1.23066C11.5769 0.848461 11.887 0.53833 12.2692 0.53833H15.0384C16.9472 0.53833 18.4999 2.09124 18.4999 3.99984C18.4998 5.90873 16.9469 7.46167 15.0381 7.46167L15.0382 7.46151Z"
      fill="white"
    />
  </svg>
);
