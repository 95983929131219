// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Component
import IconLabel from "../IconLabel/IconLabel";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Utils
import { abbreviateString } from "../../utils/globalUtils";

// Styles
import classes from "./LatestResult.module.scss";
import OddsTag from "../OddsTag/OddsTag";
import { format } from "date-fns";
import WinResultAmount from "../WinResultAmount/WinResultAmount";
import { instalockBlueIcon } from "../../assets/icons/instalockBlue/instalockBlue";

const LatestResult = ({ latestResultData, preferredOddsType }) => {
  let bestOf = null;

  const score1 = latestResultData.teamOne?.score;
  const score2 = latestResultData.teamTwo?.score;

  if ((score1 || score1 === 0) && (score2 || score2 === 0)) {
    if (score1 > score2) {
      bestOf = score1 * 2 - 1;
    } else if (score2 > score1) {
      bestOf = score2 * 2 - 1;
    }
  }

  if (latestResultData.teamOne.score > latestResultData.teamTwo.score) {
    latestResultData.teamOne.winner = true;
  } else {
    latestResultData.teamTwo.winner = true;
  }

  return (
    <Link
      className={`${classes.upsetCard} ${
        latestResultData.isSeriesPresent ? classes.isSeriesPresent : ""
      }`}
      to={`/${globalConstants.ROUTES.MATCH_PAGE}/${latestResultData.abiosSeriesId}/`}
      style={{
        pointerEvents: !latestResultData.isSeriesPresent ? "none" : null,
      }}
    >
      <div className={classes.instaBlueContainer}>{instalockBlueIcon}</div>
      <div className={classes.firstRow}>
        <IconLabel
          label={globalConstants.TITLES[latestResultData.game]}
          isSvg
          icon={esportsLogos[latestResultData.game]}
        />
      </div>
      <div className={classes.secondRow}>
        {bestOf > 0 ? (
          <p className={classes.bestOf}>{`Best of ${bestOf}`}</p>
        ) : null}
        {latestResultData.isSeriesPresent ? (
          <Link
            className={classes.matchPageLink}
            to={{
              pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${latestResultData.abiosSeriesId}`,
            }}
          >
            Match Page &#8594;
          </Link>
        ) : null}
      </div>
      {latestResultData.tournamentName ? (
        <div className={classes.tournamentName}>
          {latestResultData.tournamentName}
        </div>
      ) : null}
      <div className={classes.scoreRow}>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={latestResultData.teamOne.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            <p className={classes.teamName}>
              {abbreviateString(
                latestResultData.teamOne.teamAbbreviation ||
                  latestResultData.teamOne.teamName,
                6
              )}
            </p>
            {!!latestResultData.teamOne.odds &&
            latestResultData.teamOne.odds !== 100 ? (
              <OddsTag
                odds={latestResultData.teamOne.odds}
                fullWidth
                size="large"
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
        <div
          className={`${classes.scoreBox} ${
            latestResultData.teamOne.winner ? classes.winner : ""
          }`}
        >
          {latestResultData.teamOne.score}
        </div>
        <div className={classes.dateContainer}>
          <p>FINAL</p>
          {latestResultData.startDate ? (
            <p>{format(new Date(latestResultData.startDate), "MM/dd/yy")}</p>
          ) : null}
        </div>

        <div
          className={`${classes.scoreBox} ${
            latestResultData.teamTwo.winner ? classes.winner : ""
          }`}
        >
          {latestResultData.teamTwo.score}
        </div>
        <div className={classes.teamContainer}>
          <IconLabel
            icon={latestResultData.teamTwo.imageSmall}
            bold
            withAbbreviation
          />
          <div className={classes.oddsAndName}>
            <p className={classes.teamName}>
              {abbreviateString(
                latestResultData.teamTwo.teamAbbreviation ||
                  latestResultData.teamTwo.teamName,
                6
              )}
            </p>
            {!!latestResultData.teamTwo.odds &&
            latestResultData.teamTwo.odds !== 100 ? (
              <OddsTag
                odds={latestResultData.teamTwo.odds}
                fullWidth
                size="large"
                preferredOddsType={preferredOddsType}
              />
            ) : null}
          </div>
        </div>
      </div>
      <WinResultAmount
        team={
          latestResultData.teamOne.winner
            ? latestResultData.teamOne
            : latestResultData.teamTwo
        }
      />
    </Link>
  );
};

export default LatestResult;
