// Vendors
import React, { useState, useEffect } from "react";

// Styles
import classes from "./GuideBuilder.module.scss";

const GuideBuilder = ({ guideData }) => {
  const generateJustification = (alignment) => {
    switch (alignment) {
      case "right":
        return "flex-end";
      case "center":
        return "center";
      case "left":
        return "flex-start";
      default:
        return "flex-start";
    }
  };

  const sizeToPercentage = (size) => {
    return `${size - 1}%`;
  };

  const generateContent = (item, index) => {
    switch (item.type) {
      case "text":
        return (
          <div
            key={`${item.type}-${index}`}
            className={classes.contentTextContainer}
            style={{
              width:
                !matches || item.isAlone ? "100%" : sizeToPercentage(item.size),
              justifyContent: generateJustification(item.alignment),
            }}
          >
            <p
              key={`${item.type}-${index}`}
              style={{
                textAlign: item.alignment,
                width:
                  item.isAlone && matches
                    ? sizeToPercentage(item.size)
                    : "100%",
              }}
              className={classes.contentText}
            >
              {item.content}
            </p>
          </div>
        );

      case "title":
        return (
          <div
            key={`${item.type}-${index}`}
            className={classes.contentTextContainer}
            style={{
              width:
                !matches || item.isAlone ? "100%" : sizeToPercentage(item.size),
              justifyContent: generateJustification(item.alignment),
            }}
          >
            <h3
              key={`${item.type}-${index}`}
              style={{
                textAlign: item.alignment,
                width: item.isAlone ? sizeToPercentage(item.size) : "100%",
              }}
              className={classes.contentTitle}
            >
              {item.content}
            </h3>
          </div>
        );

      case "link":
        return (
          <a
            className={classes.contentTextContainer}
            key={`${item.type}-${index}`}
            href={item.url}
            target="_blank"
            rel="noreferrer"
            style={{
              width:
                !matches || item.isAlone ? "100%" : sizeToPercentage(item.size),
              justifyContent: generateJustification(item.alignment),
            }}
          >
            <p
              key={`${item.type}-${index}`}
              style={{
                textAlign: item.alignment,
                width:
                  item.isAlone && matches
                    ? sizeToPercentage(item.size)
                    : "100%",
              }}
              className={classes.contentLink}
            >
              {item.content}
            </p>
          </a>
        );

      case "image":
        return (
          <div
            key={`${item.type}-${index}`}
            className={classes.contentImageFlexContainer}
            style={{
              width:
                !matches || item.isAlone ? "100%" : sizeToPercentage(item.size),
              justifyContent: generateJustification(item.alignment),
            }}
          >
            <div
              key={`${item.type}-${index}`}
              className={classes.contentImageContainer}
              style={{
                width:
                  item.isAlone && matches
                    ? sizeToPercentage(item.size)
                    : "100%",
              }}
            >
              <img
                className={classes.contentImage}
                src={item.content}
                alt={`Guide-${index}`}
              />
            </div>
          </div>
        );

      case "video":
        return (
          <div
            key={`${item.type}-${index}`}
            className={classes.contentImageFlexContainer}
            style={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            {item.content ? (
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube-nocookie.com/embed/${item.content}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture web-share"
                allowFullScreen
                title="YouTube video player"
              />
            ) : null}
          </div>
        );

      default:
        return null;
    }
  };

  const mediaMatch = window.matchMedia("(min-width: 576px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  return (
    <div className={classes.guideBuilderContainer}>
      {guideData.secondaryImage && (
        <div className={classes.mainImageContainer}>
          <img alt={guideData.title} src={guideData.secondaryImage} />
        </div>
      )}

      <div className={classes.guideContent}>
        <h2 className={classes.guideTitle}>{guideData.title}</h2>

        {guideData.content.map((item, index) => {
          return generateContent(item, index);
        })}
      </div>
    </div>
  );
};

export default GuideBuilder;
