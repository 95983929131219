// Vendors
import React, { useState } from "react";
import classes from "./OddsCalculator.module.scss";

// Components
import MainButton from "../MainButton/MainButton";

// Utils
import {
  americanToDecimalOdds,
  decimalToAmericanOdds,
} from "../../utils/globalUtils";

const BASE_INPUT_VALUES = {
  amount: "",
  american: "",
  decimal: "",
  errors: {},
};

const INPUT_CONSTANTS = [
  { naming: "amount", label: "Amount", disabled: false },
  { naming: "american", label: "Amercian odds", disabled: false },
  { naming: "decimal", label: "Decimal odds", disabled: false },
];

const OddsCalculator = () => {
  const [values, setValues] = useState(BASE_INPUT_VALUES);
  const [error, setError] = useState("");

  const handleAmericanChange = (value) => {
    if (
      !value ||
      isNaN(parseFloat(value)) ||
      Math.abs(parseFloat(value)) < 100
    ) {
      setValues({
        ...values,
        american: value,
        decimal: "",
        errors: { american: !value ? false : true },
      });
      setError(!value ? null : "Not valid odds");
    } else {
      const decimalOdds = americanToDecimalOdds(value);

      setValues({
        ...values,
        american: value,
        decimal: decimalOdds,
        errors: {},
      });
      setError("");
    }
  };

  const handleDecimalChange = (value) => {
    if (!value || isNaN(parseFloat(value)) || parseFloat(value) <= 1) {
      setValues({
        ...values,
        american: "",
        decimal: value,
        errors: { decimal: !value ? false : true },
      });
      setError(!value ? null : "Not valid odds");
    } else {
      const americanOdds = decimalToAmericanOdds(value);
      setValues({
        ...values,
        american: americanOdds,
        decimal: value,
        errors: {},
      });
      setError("");
    }
  };

  const handleInputChange = (value, field) => {
    const newValues = { ...values };
    newValues[field] = value;

    switch (field) {
      case "american":
        handleAmericanChange(value);
        return;
      case "decimal":
        handleDecimalChange(value);
        return;
      default:
        setValues(newValues);
        return;
    }
  };

  const handleResetFields = () => {
    setValues(BASE_INPUT_VALUES);
  };

  const generateToWin = () => {
    if (values.decimal && values.amount && values.american) {
      return values.amount * Math.abs(1 - values.decimal);
    }
  };

  const toWin = generateToWin();
  const payout = parseFloat(toWin) + parseFloat(values.amount);

  return (
    <div className={classes.oddsCalculatorContainer}>
      <p className={classes.calulatorTitle}>Odds Calculator</p>
      <p className={classes.calulatorDescription}>
        The betting odds calculator allows you to input your stake & odds in
        American or Decimal formats to quickly calculate the payout for your
        bets.
      </p>
      <div className={classes.calculatorControls}>
        {INPUT_CONSTANTS.map((inputData) => {
          return (
            <div
              key={`calculator-${inputData.naming}`}
              className={classes.calculatorInputContainer}
            >
              <input
                className={`${classes.calculatorInput} ${
                  values.errors[inputData.naming] ? classes.error : ""
                }`}
                placeholder={inputData.label}
                name={inputData.naming}
                onChange={(e) =>
                  handleInputChange(e.target.value, inputData.naming)
                }
                disabled={inputData.disabled}
                value={values[inputData.naming]}
                autoComplete="off"
              />
              <label
                htmlFor={inputData.naming}
                className={classes.calculatorInputLabel}
              >
                {inputData.label}
              </label>
            </div>
          );
        })}
      </div>
      <p className={classes.calculatorError}>{error}</p>
      <div className={classes.calculatorResults}>
        <p className={classes.amountText}>{`To win $ ${
          !isNaN(parseFloat(toWin)) ? parseFloat(toWin).toFixed(2) : "0.00"
        }`}</p>
        <p className={classes.amountText}>{`Payout $ ${
          !isNaN(parseFloat(payout)) ? parseFloat(payout).toFixed(2) : "0.00"
        }`}</p>
      </div>
      <div className={classes.resetContainer}>
        <MainButton
          buttonText="Reset"
          type="secondary"
          clicked={handleResetFields}
        />
      </div>
    </div>
  );
};

export default OddsCalculator;
