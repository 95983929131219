// Vendors
import React from "react";

export const lostFullGG = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 33 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4046 4.31259C24.1425 4.19996 23.9318 4.24074 23.7279 4.24074C21.723 4.23783 19.7182 4.24074 17.7133 4.2388C17.557 4.2388 17.3483 4.29802 17.2677 4.13686C17.1997 4.00094 17.3667 3.87666 17.4463 3.7621C18.2512 2.60967 19.0687 1.46695 19.8677 0.310644C20.025 0.0824886 20.2017 0.00967307 20.4696 0.0106439C24.3337 0.0184109 28.1978 0.0154983 32.0628 0.0184109C32.1852 0.0184109 32.3531 -0.0485794 32.4221 0.0747216C32.5094 0.231032 32.3289 0.332974 32.2531 0.440741C30.0531 3.57375 27.8454 6.70191 25.6425 9.83297C23.8201 12.4223 22.0017 15.0136 20.1852 17.6068C20.0444 17.8087 19.923 17.999 19.6269 17.998C18.124 17.9922 16.6211 18 15.1182 18C15.0405 18 14.9638 17.966 14.8308 17.9349C18.0318 13.3805 21.2104 8.85628 24.4027 4.31356L24.4046 4.31259Z"
      fill="#5692F5"
    />
    <path
      d="M8.27064 13.8097H15.4939C15.5396 14.0272 15.4027 14.1184 15.326 14.2281C14.5202 15.3786 13.7056 16.5233 12.9037 17.6767C12.7648 17.8767 12.6318 18 12.3638 17.999C8.38618 17.9912 4.40754 17.9941 0.429867 17.9922C0.387149 17.9922 0.343459 17.9699 0.252197 17.9446C1.01336 16.8573 1.75705 15.7883 2.5056 14.7242C5.87064 9.94269 9.24055 5.16405 12.5998 0.377639C12.7765 0.125212 12.9522 0.015503 13.2668 0.0193865C14.7978 0.0368623 16.3289 0.0271535 17.9638 0.0271535C14.7163 4.64366 11.5192 9.18929 8.27064 13.8087V13.8097Z"
      fill="white"
    />
    <path
      d="M17.7959 6.90386C18.5396 6.90386 19.2823 6.91162 20.025 6.90094C20.3872 6.89609 20.4143 7.00677 20.2182 7.28347C19.3687 8.4825 18.525 9.68444 17.6891 10.8922C17.5629 11.0738 17.4182 11.1359 17.2046 11.1349C15.7182 11.1281 14.2318 11.132 12.7454 11.1301C12.6376 11.1301 12.5017 11.1757 12.4289 11.0689C12.3308 10.9252 12.4784 10.833 12.5464 10.7359C13.3774 9.54366 14.2182 8.35822 15.0444 7.16211C15.1813 6.96405 15.3338 6.89415 15.5668 6.89706C16.3095 6.90871 17.0532 6.90094 17.7959 6.90094V6.90288V6.90386Z"
      fill="#5692F5"
    />
  </svg>
);
