// Vendors
import React from "react";

// Components
import BetSlipBet from "../BetSlipBet/BetSlipBet";

// Assets
import { successIcon } from "../../assets/icons/success/success";

// Styles
import classes from "./BetSlipContent.module.scss";

// Utils
import { generateBetErrors } from "../../utils/globalUtils";

const BetSlipContent = ({
  currentBetSlipData,
  setCurrentBetSlipData,
  preferredOddsType,
  setBetSlipData,
}) => {
  const handleSwitchBet = (index) => {
    const newbetSlipBets = [...currentBetSlipData.bets];
    const memoryTeam = currentBetSlipData.bets[index].teamOne;
    newbetSlipBets[index].teamOne = newbetSlipBets[index].teamTwo;
    newbetSlipBets[index].teamTwo = memoryTeam;
    const newData = {
      action: currentBetSlipData.action,
      bets: generateBetErrors(newbetSlipBets, preferredOddsType),
    };
    setCurrentBetSlipData(newData);
    setBetSlipData(newData);
  };

  const hanldeDeleteBet = (index) => {
    let newbetSlipBets = [...currentBetSlipData.bets];
    newbetSlipBets.splice(index, 1);
    const newData = {
      action: currentBetSlipData.action,
      bets: generateBetErrors(newbetSlipBets, preferredOddsType),
    };
    setCurrentBetSlipData(newData);
    setBetSlipData(newData);
  };

  const changeBetType = (index, newType) => {
    let newbetSlipBets = [...currentBetSlipData.bets];
    newbetSlipBets[index].type = newType;
    if (newType === "moneyLine") {
      newbetSlipBets[index].spread = "";
      newbetSlipBets[index].total = "";
    } else if (newType === "spread") {
      newbetSlipBets[index].total = "";
    } else {
      newbetSlipBets[index].spread = "";
    }
    const newData = {
      action: currentBetSlipData.action,
      bets: generateBetErrors(newbetSlipBets, preferredOddsType),
    };
    setCurrentBetSlipData(newData);
  };

  const changeBetProperties = (index, newProperties) => {
    let newbetSlipBets = [...currentBetSlipData.bets];
    newbetSlipBets[index] = { ...newbetSlipBets[index], ...newProperties };
    const newData = {
      action: currentBetSlipData.action,
      bets: generateBetErrors(newbetSlipBets, preferredOddsType),
    };
    setCurrentBetSlipData(newData);
  };

  return (
    <>
      <div className={classes.betSlipContent}>
        {currentBetSlipData?.bets?.length > 0 ? (
          <div className={classes.betSlipItemsContainer}>
            {currentBetSlipData.bets.map((gameToBetOn, index) => {
              return (
                <BetSlipBet
                  betData={gameToBetOn}
                  changeBetProperties={(newProperties) =>
                    changeBetProperties(index, newProperties)
                  }
                  changeBetType={(newType) => changeBetType(index, newType)}
                  handleSwitchBet={() => handleSwitchBet(index)}
                  hanldeDeleteBet={() => hanldeDeleteBet(index)}
                  index={index}
                  key={index}
                  preferredOddsType={preferredOddsType}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      {currentBetSlipData?.bets?.length > 0 ? (
        <div className={classes.trackingBetsContainer}>
          <span className={classes.successIcon}>{successIcon}</span>
          <span>Tracking bets</span>
        </div>
      ) : null}
    </>
  );
};

export default BetSlipContent;
