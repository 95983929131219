// Vendors
import React from "react";

import esportsbet from "../../images/esportsBet.png";

export const esportsbet2 = (
  <img
    alt="esportsBet"
    style={{ width: "100%", height: "auto" }}
    src={esportsbet}
  />
);
