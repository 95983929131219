const ESPORT_MENU_ITEMS = [
  {
    label: "League of Legends",
    key: "leagueOfLegends",
  },
  {
    label: "Counter-Strike",
    key: "csgo",
  },
  {
    label: "VALORANT",
    key: "valorant",
  },
  {
    label: "Rocket League",
    key: "rocketLeague",
  },
  {
    label: "Call of Duty",
    key: "callOfDuty",
  },
];

export const globalConstants = {
  VALORANT: "valorant",
  CS_GO: "csgo",
  LEAGUE_OF_LEGENDS: "leagueOfLegends",
  ROCKET_LEAGUE: "rocketLeague",
  CALL_OF_DUTY: "callOfDuty",
  TWITTER: "twitter",
  PENDING: "PENDING",
  MONEYLINE: "MONEYLINE",
  SPREAD: "SPREAD",
  PRIVACY_POLICY: "privacy-policy",
  instalock: "instalock",
  VERIFIED: "VERIFIED",
  DECLINED: "DECLINED",
  ALL_USERS: "all-users",
  WIN: "WIN",
  LOSS: "LOSS",

  SPORTSBOOKS: {
    RIVALRY: "rivalry",
    ESPORTS_BETS: "esportsBets",
    THUNDERPICK: "thunderpick",
    BOVADA: "bovada",
  },

  TITLES: {
    leagueOfLegends: "League Of Legends",
    csgo: "Counter-Strike",
    valorant: "VALORANT",
    rocketLeague: "Rocket League",
    callOfDuty: "Call of Duty",
  },
  TITLES_IDS: {
    leagueOfLegends: 2,
    csgo: 5,
    rocketLeague: 18,
    valorant: 20,
    callOfDuty: 11,
  },

  MONTHS_BY_INDEX: {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  },

  ROUTES: {
    ESPORTS: "esports",
    ODDS: "odds",
    SPORTSBOOKS: "sportsbooks",
    COMMUNITY: "community",
    EDUCATION: "education",
    PROFILE: "profile",
    NOT_FOUND: "not-found",
    BET_VERIFICATION: "bet-verification",
    MATCH_PAGE: "match",
    MATCHES: "matches",
  },
  ROUTES_TITLES: {
    INSTALOCK: "INSTALOCK",
    ESPORTS: "INSTALOCK - Esports",
    ODDS: "INSTALOCK - Odds",
    SPORTSBOOKS: "INSTALOCK - Sportsbooks",
    COMMUNITY: "INSTALOCK - Community",
    EDUCATION: "INSTALOCK - Education",
    PROFILE: "INSTALOCK - Profile",
    NOT_FOUND: "INSTALOCK - Not Found",
    BET_VERIFICATION: "INSTALOCK - Bet Verification",
    MATCHES: "INSTALOCK - Matches",
    MATCH_PAGE: "INSTALOCK - ",
  },
  EDUCATION_SUB_ROUTES: {
    GLOSSARY: "glossary",
    GUIDES: "guides",
    LEAGUE_OF_LEGENDS: "leagueOfLegends",
    CSGO: "csgo",
    VALORANT: "valorant",
    ROCKET_LEAGUE: "rocketLeague",
    CALL_OF_DUTY: "callOfDuty",
    // NEW_GUIDE: "new-guide",
  },

  MENU_ITEMS: [
    {
      label: "Esports",
      key: "esports",
    },
    {
      label: "Odds",
      key: "odds",
    },
    {
      label: "Matches",
      key: "matches",
    },
    {
      label: "Sportsbooks",
      key: "sportsbooks",
    },
    // {
    //   label: "Community",
    //   key: "community",
    // },
    {
      label: "Education",
      key: "education",
    },
    {
      label: "Privacy policy",
      key: "privacy-policy",
    },
  ],

  ESPORT_MENU_ITEMS: ESPORT_MENU_ITEMS,

  EDUCATION_MENU_ITEMS: [
    {
      label: "Home",
      key: "",
    },
    {
      label: "Glossary",
      key: "glossary",
    },
    {
      label: "Guides",
      key: "guides",
    },
    ...ESPORT_MENU_ITEMS,
  ],

  EDUCATION_GLOSSARY_MENU_ITEMS: [
    { label: "General", key: "general" },
    ...ESPORT_MENU_ITEMS,
  ],

  BET_TYPE_MENU_ITEMS: [
    {
      key: "moneyLine",
      label: "Money Line",
    },
    {
      key: "spread",
      label: "Spread",
    },
  ],

  RESULT_SYMBOLS: {
    WIN: "W",
    LOSS: "L",
    DRAW: "D",
    PUSH: "D",
    PENDING: "P",
  },

  REGION_TYPES: {
    EU: "EU",
    NA: "NA",
  },

  PREFERRED_ODDS_ITEMS: [
    {
      label: "American Odds",
      key: "american",
    },
    {
      label: "Decimal Odds",
      key: "decimal",
    },
  ],

  RECORD_TYPE: {
    allTime: { label: "All time", value: "allTime", apiParam: "ALL_TIME" },
    lastSevenDays: {
      label: "Last 7",
      value: "lastSevenDays",
      apiParam: "LAST_SEVEN_DAYS",
    },
    lastThirtyDays: {
      label: "Last 30",
      value: "lastThirtyDays",
      apiParam: "LAST_THIRTY_DAYS",
    },
  },

  SPORTS_BOOKS_LINKS: {
    HOTSTREAK:
      "https://app.hotstreak.gg/home?code=INSTALOCK&utm_source=affiliate&utm_campaign=esports",
    UDNERDOG_FANTASY: "https://play.underdogfantasy.com/p-insta-lock/",
    THUNDERPICK:
      "https://go.thunder.partners/visit/?bta=35539&nci=5509&campaign=INSTALOCK",
    ESPORT_BET: "https://esportsbet.io/referral/instalock/",
    BOVADA:
      "https://record.revenuenetwork.com/_5Tqybo29xnw2XUl_PBb-mGNd7ZgqdRLk/1/",
    PRIZE_PICKS: "https://bit.ly/PrizePicksInstalock",
    ROLLBIT: "https://rollbit.com/sports?bt-path=%2F/",
    DUELBITS: "https://duelbits.com/?a=instalock",
    RIVALRY: "https://www.rivalry.com/",
    STAKE: "https://stake.com/?c=instalock/",
    CLOUT_FANTASY: "https://cloutfantasy.gg/download?tags=Instalock,Banner",
    THRIVE_FANTASY: "https://partners.thrivefantasy.com/signup/",
    SPORTS_BATTLE: "https://t.co/eS2fmc1JS4",
    BET_ONLINE:
      "https://www.betonline.ag/?btag=Z4sk_srB4f9XMscQKvpWS2Nd7ZgqdRLk&affid=108049",
    // Community
    ROTO_RADAR: "http://Discord.gg/RotoRadar",
    CDL_SPORTSBOOK: "https://www.twitch.tv/cdlsportsbook",
    LOST_FULL_GG: "https://lostfull.gg/",
    SRIRACHA_DFS: "https://linktr.ee/sriracha_wepredicttheheat",
    HLTV: "https://www.hltv.org/",
    ATM_CREW: "https://atmcrew.gg/",
    BOOKIT_SPORTS: "https://beacons.ai/bookitsports",
  },

  AMOUNT_TYPES: {
    UNIT: "UNIT",
    US_DOLLAR: "US_DOLLAR",
  },

  VERIFY_BETS_STEPS: {
    SELECT: "SELECT",
    UPLOAD: "UPLOAD",
    DONE: "DONE",
  },

  PRIVACY_POLICY_URL:
    "https://app.termly.io/document/privacy-policy/d4362137-18a7-4525-83f2-129fdafe1877",
  TERMS_OF_SERVICE_URL:
    "https://app.termly.io/document/terms-of-use-for-website/0be2024d-3782-48a5-901c-1495f3aa15eb",
};
