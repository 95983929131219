// Vendors
import React, { useState } from "react";
import { format } from "date-fns";
import { message } from "antd";

// Components
import MainButton from "../MainButton/MainButton";
import MainModal from "../MainModal/MainModal";
import BetLogItem from "../BetLogItem/BetLogItem";
import TodayBets from "../TodayBets/TodayBets";

// API
import { changeProfileStatus, deleteBet } from "../../api/requests/requests";

// Styles
import classes from "./ProfileStatus.module.scss";

// Assets
import { wifiIcon } from "../../assets/icons/wifi/wifi";
import { trashIcon } from "../../assets/icons/trash/trash";

const ProfileStatus = ({
  handleSetUserData,
  isProfileOwner,
  preferredOddsType,
  requestedUserData,
  setRequestedUserData,
}) => {
  const userStatus =
    requestedUserData?.statusMessage && requestedUserData?.statusDate
      ? {
          content: requestedUserData.statusMessage,
          date: requestedUserData.statusDate,
        }
      : null;

  const [inputValue, setInputValue] = useState("");
  const [showFullBetLog, setShowFullBetLog] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingDeleteBet, setLoadingDeleteBet] = useState(false);

  const handlePostStatus = (justDeleting) => {
    let errorMessage = justDeleting
      ? "We could not delete your status"
      : "We could not process the new status, please try again later.";

    if (!justDeleting) {
      setLoadingStatus(true);
    }

    changeProfileStatus(
      { status: inputValue },
      (data) => {
        handleSetUserData({
          statusMessage: data.status,
          statusDate: data.updatedDate,
        });
        setLoadingStatus(false);
        setInputValue("");
      },
      () => {
        message.error({
          content: errorMessage,
          className: "errorMessage",
        });
        setLoadingStatus(false);
      }
    );
  };

  const handleDeleteBet = (betId) => {
    setLoadingDeleteBet(true);
    deleteBet(
      betId,
      () => {
        const prevRequestedData = { ...requestedUserData };
        prevRequestedData.betHistorySummary.betLog =
          prevRequestedData.betHistorySummary.betLog.filter((bet) => {
            return bet.betId !== betId;
          });
        prevRequestedData.betHistorySummary.record.bets.bets =
          prevRequestedData.betHistorySummary.betLog.filter((bet) => {
            return bet.betId !== betId;
          });
        setRequestedUserData(prevRequestedData);
        setLoadingDeleteBet(false);
        message.success({
          content: "Bet deletion successful",
          className: "successMessage",
        });
      },
      (error) => {
        const errorObj = error?.response?.data;
        let errorMsg =
          "There was an error deleting your bet, please try again later.";
        if (
          errorObj.status === 412 &&
          errorObj.message.includes("started yet")
        ) {
          errorMsg = "This match has already started.";
        }
        message.error({
          content: errorMsg,
          className: "errorMessage",
        });
        setLoadingDeleteBet(false);
      }
    );
  };

  const pendingBets =
    requestedUserData.betHistorySummary?.record?.bets?.bets.length > 0
      ? requestedUserData.betHistorySummary?.record?.bets?.bets.filter(
          (bet) => bet.status === "PENDING"
        )
      : [];

  return (
    <div className={classes.profileStatusContainer}>
      {isProfileOwner && (
        <div
          className={`${classes.postStatusCard} ${
            !userStatus && classes.fullWidth
          }`}
        >
          <div className={classes.postStatusTitle}>Write your status</div>
          <textarea
            className={classes.statusInput}
            placeholder=". . ."
            maxLength={40}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div className={classes.postButtonContainer}>
            <MainButton
              loading={loadingStatus}
              clicked={handlePostStatus}
              buttonText="Post"
            />
          </div>
        </div>
      )}

      {userStatus && userStatus.content && userStatus.date && (
        <div
          className={`${classes.currentStatusCard} ${
            !isProfileOwner && classes.fullWidth
          }`}
        >
          <div className={classes.currentStatusTitleRow}>
            <p className={classes.currentStatusTitle}>
              {wifiIcon}
              <span className={classes.statusTitleText}>Status updated</span>
              <span className={classes.statusDate}>
                {format(new Date(userStatus.date), "HH:mm")}
              </span>
            </p>
          </div>
          <p className={classes.statusContent}>{userStatus.content}</p>
          {isProfileOwner ? (
            <div className={classes.statusClearContainer}>
              <MainButton
                clicked={() => handlePostStatus(true)}
                icon={trashIcon}
                size="small"
                type="danger"
              />
            </div>
          ) : null}
        </div>
      )}

      {requestedUserData.betHistorySummary?.betLog?.length > 0 ? (
        <>
          <TodayBets
            handleDeleteBet={handleDeleteBet}
            loadingDeleteBet={loadingDeleteBet}
            preferredOddsType={preferredOddsType}
            requestedUserData={requestedUserData}
            setShowFullBetLog={setShowFullBetLog}
            isProfileOwner={isProfileOwner}
          />
          <MainModal
            open={showFullBetLog}
            onCancel={() => setShowFullBetLog(false)}
            footer={null}
            closable={false}
            customTitle="Pending Bets"
          >
            <div className={classes.modalBetList}>
              <div className={classes.cardList}>
                {pendingBets.length > 0 ? (
                  pendingBets.map((bet, index) => {
                    if (bet.status !== "PENDING") {
                      return null;
                    }

                    return (
                      <div
                        className={classes.betListItem}
                        key={`${bet.id}-${index}`}
                      >
                        <BetLogItem
                          betData={bet}
                          handleDeleteBet={handleDeleteBet}
                          loadingDeleteBet={loadingDeleteBet}
                          preferredOddsType={preferredOddsType}
                          isProfileOwner={isProfileOwner}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.noDataCard}>
                    You do not have pending bets . . .
                  </div>
                )}
              </div>
            </div>
          </MainModal>
        </>
      ) : null}
    </div>
  );
};

export default ProfileStatus;
