// Vendors
import React from "react";

export const arrowDownIcon = (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.7263 12.9182C4.89522 12.9116 5.09051 12.8421 5.21363 12.7262L9.23018 8.94597C9.4604 8.68986 9.55533 8.19858 9.28918 7.91233C9.02698 7.63035 8.51871 7.63876 8.25554 7.91958L5.4351 10.5775L5.4351 1.62704C5.4351 1.23563 5.11769 0.918213 4.72627 0.918213C4.33486 0.918213 4.01745 1.23562 4.01745 1.62704L4.01745 10.5775L1.19701 7.91958C0.954753 7.67765 0.428364 7.63299 0.163374 7.91233C-0.101795 8.1915 -0.0170835 8.70026 0.222535 8.94597L4.23908 12.7262C4.37653 12.8556 4.53776 12.9185 4.7263 12.9182Z"
      fill="#FF4D26"
    />
  </svg>
);
