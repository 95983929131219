// Vendors
import React from "react";
import { format } from "date-fns";

// Components
import RecordCard from "../RecordCard/RecordCard";
import VerifiedTag from "../VerifiedTag/VerifiedTag";
import IconLabel from "../IconLabel/IconLabel";
import MoneyLineLabel from "../MoneyLineLabel/MoneyLineLabel";
import ScoreCard from "../ScoreCard/ScoreCard";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Styles
import classes from "./RecordBetCardList.module.scss";

// Utils
import {
  calculateAmountToWin,
  formatDollars,
  formatNumberWithSymbol,
  formatUnits,
  transformOddToPrefered,
  isFavoritePick,
} from "../../utils/globalUtils";
import MainButton from "../MainButton/MainButton";

const RecordBetCardList = ({
  recordData,
  recordBets,
  handleClose,
  preferredOddsType,
  recordToShow,
  handleOpenBetsToVerify,
  isProfileOwner,
}) => {
  return (
    <div className={classes.recordBetCardListContainer}>
      <RecordCard
        clicked={handleClose}
        isDetails
        recordData={{
          ...recordData,
          type: recordToShow,
        }}
        recordToShow={recordToShow}
        showButton
      />
      <div className={classes.recordBetCardList}>
        {recordBets.map((bet, index) => {
          return (
            <div key={`bet-${index}`} className={classes.recordBetCard}>
              <div className={classes.rowsContainer}>
                <div className={classes.betCardFirstRow}>
                  {bet.createdDate ? (
                    <div className={classes.betDate}>
                      {format(new Date(bet.createdDate), "MMM dd / yyyy")}
                    </div>
                  ) : null}
                  <div className={classes.esportLabelContainer}>
                    <IconLabel
                      icon={esportsLogos[bet.game]}
                      label={globalConstants.TITLES[bet.game]}
                      isSvg
                    />
                  </div>
                </div>
                <div className={classes.betCardSecondRow}>
                  <div className={classes.teamLabels}>
                    <div className={classes.teamLabelContainer}>
                      <IconLabel
                        icon={bet.selectedAbiosTeam.imageSmall}
                        label={
                          bet.selectedAbiosTeam.teamAbbreviation ||
                          bet.selectedAbiosTeam.teamName
                        }
                        bold
                        withAbbreviation
                      />
                    </div>
                    <div className={classes.moneyLineLabelContainer}>
                      {bet.type === globalConstants.MONEYLINE ? (
                        <MoneyLineLabel />
                      ) : (
                        <p
                          className={`${classes.spreadLabel} ${
                            bet.spread < 0 ? classes.betSpreadRed : ""
                          }`}
                        >
                          {formatNumberWithSymbol(bet.spread)}
                        </p>
                      )}
                    </div>
                    <div className={classes.versusContainer}>VS</div>
                    <div className={classes.teamLabelContainer}>
                      <IconLabel
                        icon={bet.alternativeAbiosTeam.imageSmall}
                        label={
                          bet.alternativeAbiosTeam.teamAbbreviation ||
                          bet.alternativeAbiosTeam.teamName
                        }
                        bold
                        inverted
                        withAbbreviation
                      />
                    </div>
                  </div>
                  {/* ///// */}
                  <div>
                    <span
                      className={`${
                        bet.result === "WIN"
                          ? classes.moneyLineFavorite
                          : bet.result === "LOSS"
                          ? classes.moneyLineUnderdog
                          : ""
                      }`}
                    >
                      {bet.result === "WIN"
                        ? `+${
                            bet.amountType === globalConstants.AMOUNT_TYPES.UNIT
                              ? formatUnits(
                                  calculateAmountToWin({
                                    risk: bet.amount,
                                    odds: bet.odd,
                                    preferredOddsType: "american",
                                  })
                                )
                              : formatDollars(
                                  calculateAmountToWin({
                                    risk: bet.amount,
                                    odds: bet.odd,
                                    preferredOddsType: "american",
                                  })
                                )
                          }`
                        : bet.result === "LOSS"
                        ? `-${
                            bet.amountType === globalConstants.AMOUNT_TYPES.UNIT
                              ? formatUnits(bet.amount)
                              : formatDollars(bet.amount)
                          }`
                        : ""}
                    </span>
                  </div>
                  {/* ///// */}
                </div>
              </div>
              <div className={classes.betCardThirdRow}>
                <div className={classes.verifyContainer}>
                  {bet.verificationStatus === globalConstants.VERIFIED ? (
                    <VerifiedTag />
                  ) : isProfileOwner &&
                    bet.verificationStatus !== globalConstants.VERIFIED &&
                    bet.verificationStatus !== globalConstants.PENDING ? (
                    <MainButton
                      buttonText="Verify bet"
                      clicked={() => handleOpenBetsToVerify(bet)}
                    />
                  ) : null}
                </div>

                <div className={classes.rightBetData}>
                  <div className={classes.betDataContainer}>
                    <span className={classes.betDescription}>
                      {bet.amountType === globalConstants.AMOUNT_TYPES.UNIT
                        ? formatUnits(bet.amount)
                        : formatDollars(bet.amount)}
                      {bet.odd && bet.odd !== 100 ? (
                        <span
                          className={
                            isFavoritePick(bet.odd)
                              ? classes.moneyLineFavorite
                              : classes.moneyLineUnderdog
                          }
                        >
                          {" "}
                          {transformOddToPrefered(bet.odd, preferredOddsType)}
                        </span>
                      ) : null}
                    </span>
                    <span className={classes.betDescription}>
                      to win
                      <span className={classes.toWinAmount}>
                        {bet.amountType === globalConstants.AMOUNT_TYPES.UNIT
                          ? formatUnits(
                              calculateAmountToWin({
                                risk: bet.amount,
                                odds: bet.odd,
                                preferredOddsType: "american",
                              })
                            )
                          : formatDollars(
                              calculateAmountToWin({
                                risk: bet.amount,
                                odds: bet.odd,
                                preferredOddsType: "american",
                              })
                            )}
                      </span>
                    </span>
                  </div>
                  <div className={classes.scoreLabel}>
                    {bet.result ? <ScoreCard result={bet.result} /> : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecordBetCardList;
