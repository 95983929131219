// Vendors
import React from "react";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./MatchBettingResults.module.scss";
import { format } from "date-fns";

// Assets
import { successIcon } from "../../assets/icons/success/success";

// Utils
import {
  formatNumberWithSymbol,
  transformOddToPrefered,
} from "../../utils/globalUtils";
import WinResultAmount from "../WinResultAmount/WinResultAmount";
import { instalockBlueIcon } from "../../assets/icons/instalockBlue/instalockBlue";

const MatchOdds = ({ matchInfo, preferredOddsType }) => {
  if (!matchInfo || matchInfo.status === "over-forfeited") {
    return null;
  }

  if (matchInfo.teamOne.seriesScore > matchInfo.teamTwo.seriesScore) {
    matchInfo.teamOne.winner = true;
  } else {
    matchInfo.teamTwo.winner = true;
  }

  const generateOdds = ({ serieData, type, team }) => {
    let content = "";

    if (type === "ODDS") {
      content = serieData[team]?.odds ? (
        <>
          <span className={classes.buttonText}>
            {transformOddToPrefered(serieData[team]?.odds, preferredOddsType)}
          </span>
          {serieData[team]?.isMoneylineChecked ? (
            <span className={classes.successIcon}>{successIcon}</span>
          ) : null}
        </>
      ) : (
        "N/A"
      );
    } else if (type === "SPREAD") {
      const teamSpread = matchInfo[team].markets.find(
        (market) => market.betType === type
      );
      content =
        teamSpread && teamSpread.odds && teamSpread.spread ? (
          <>
            <span className={classes.buttonText}>
              {formatNumberWithSymbol(teamSpread.spread, 1)}
            </span>
            <span className={classes.buttonSubText}>
              {transformOddToPrefered(teamSpread.odds, preferredOddsType)}
            </span>
            {teamSpread.isChecked ? (
              <span className={classes.successIcon}>{successIcon}</span>
            ) : null}
          </>
        ) : (
          "N/A"
        );
    } else if (type === "OU") {
      const teamSpread = matchInfo[team].markets.find(
        (market) => market.betType === type
      );

      content =
        teamSpread &&
        teamSpread.odds &&
        teamSpread.spread &&
        teamSpread.market ? (
          <>
            <span className={classes.buttonText}>{`${
              teamSpread.market === "OVER" ? "O" : "U"
            }${teamSpread.spread}`}</span>
            <span className={classes.buttonSubText}>
              {transformOddToPrefered(teamSpread.odds, preferredOddsType)}
            </span>

            {teamSpread.isChecked ? (
              <span className={classes.successIcon}>{successIcon}</span>
            ) : null}
          </>
        ) : (
          "N/A"
        );
    }

    return <div className={classes.oddsContainer}>{content}</div>;
  };

  return (
    <div className={classes.matchOddsContainer}>
      <div className={classes.titleContainer}>
        <h3>Betting Results</h3>
      </div>
      <div className={classes.oddsTableContainer}>
        <div className={classes.instaBlueContainer}>{instalockBlueIcon}</div>
        <div className={classes.matchOddsTable}>
          <div className={classes.dateTitle}>
            {matchInfo.startDate
              ? format(new Date(matchInfo.startDate), "MMMM dd    HH:mm aa")
              : null}
          </div>
          <div className={classes.spreadTitle}>Spread</div>
          <div className={classes.moneyLineTitle}>Money Line</div>
          <div className={classes.totalTitle}>Total</div>

          {/* //// Data //// */}
          <div className={classes.teamOneName}>
            <IconLabel
              label={
                matchInfo.teamOne.teamAbbreviation || matchInfo.teamOne.teamName
              }
              size="small"
              icon={matchInfo.teamOne.teamImgSm || matchInfo.teamOne.teamImgLg}
              withAbbreviation
            />
          </div>

          <div className={classes.spreadTeamOne}>
            {generateOdds({
              serieData: matchInfo,
              type: "SPREAD",
              team: "teamOne",
            })}
          </div>
          <div className={classes.moneyLineTeamOne}>
            {generateOdds({
              serieData: matchInfo,
              type: "ODDS",
              team: "teamOne",
            })}
          </div>
          <div className={classes.totalTeamOne}>
            {generateOdds({
              serieData: matchInfo,
              type: "OU",
              team: "teamOne",
            })}
          </div>

          <div className={classes.teamTwoName}>
            <IconLabel
              label={
                matchInfo.teamTwo.teamAbbreviation || matchInfo.teamTwo.teamName
              }
              size="small"
              icon={matchInfo.teamTwo.teamImgSm || matchInfo.teamTwo.teamImgLg}
              withAbbreviation
            />
          </div>

          <div className={classes.spreadTeamTwo}>
            {generateOdds({
              serieData: matchInfo,
              type: "SPREAD",
              team: "teamTwo",
            })}
          </div>
          <div className={classes.moneyLineTeamTwo}>
            {generateOdds({
              serieData: matchInfo,
              type: "ODDS",
              team: "teamTwo",
            })}
          </div>
          <div className={classes.totalTeamTwo}>
            {generateOdds({
              serieData: matchInfo,
              type: "OU",
              team: "teamTwo",
            })}
          </div>
        </div>
        {(matchInfo.teamOne.winner && matchInfo.teamTwo.odds) ||
        (matchInfo.teamTwo.winner && matchInfo.teamTwo.winner) ? (
          <div className={classes.winAmountContainer}>
            <WinResultAmount
              team={
                matchInfo.teamOne.winner ? matchInfo.teamOne : matchInfo.teamTwo
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MatchOdds;
