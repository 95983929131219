// Vendors
import React from "react";

export const couponIcon = (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.72727C0 6.17891 0.366545 6.54545 0.818182 6.54545C2.17309 6.54545 3.27273 7.64509 3.27273 9C3.27273 10.3549 2.17309 11.4545 0.818182 11.4545C0.366545 11.4545 0 11.8211 0 12.2727V15.5455C0 16.1967 0.258545 16.821 0.719182 17.2808C1.179 17.7415 1.80327 18 2.45455 18C6.67145 18 17.874 18 22.0909 18C22.7422 18 23.3665 17.7415 23.8263 17.2808C24.2869 16.821 24.5455 16.1967 24.5455 15.5455V12.2727C24.5455 11.8211 24.1789 11.4545 23.7273 11.4545C22.3724 11.4545 21.2727 10.3549 21.2727 9C21.2727 7.64509 22.3724 6.54545 23.7273 6.54545C24.1789 6.54545 24.5455 6.17891 24.5455 5.72727V2.45455C24.5455 1.80327 24.2869 1.179 23.8263 0.719182C23.3665 0.258546 22.7422 0 22.0909 0C17.874 0 6.67145 0 2.45455 0C1.80327 0 1.179 0.258546 0.719182 0.719182C0.258545 1.179 0 1.80327 0 2.45455V5.72727ZM1.63636 4.99091V2.45455C1.63636 2.23773 1.72227 2.02909 1.87609 1.87609C2.02909 1.72227 2.23773 1.63636 2.45455 1.63636H22.0909C22.3077 1.63636 22.5164 1.72227 22.6694 1.87609C22.8232 2.02909 22.9091 2.23773 22.9091 2.45455V4.99091C21.0428 5.37055 19.6364 7.02245 19.6364 9C19.6364 10.9775 21.0428 12.6295 22.9091 13.0091V15.5455C22.9091 15.7623 22.8232 15.9709 22.6694 16.1239C22.5164 16.2777 22.3077 16.3636 22.0909 16.3636H2.45455C2.23773 16.3636 2.02909 16.2777 1.87609 16.1239C1.72227 15.9709 1.63636 15.7623 1.63636 15.5455V13.0091C3.50264 12.6295 4.90909 10.9775 4.90909 9C4.90909 7.02245 3.50264 5.37055 1.63636 4.99091Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5444 9.81836C14.1895 9.81836 13.0898 10.918 13.0898 12.2729C13.0898 13.6278 14.1895 14.7275 15.5444 14.7275C16.8993 14.7275 17.9989 13.6278 17.9989 12.2729C17.9989 10.918 16.8993 9.81836 15.5444 9.81836ZM15.5444 11.4547C15.996 11.4547 16.3626 11.8213 16.3626 12.2729C16.3626 12.7245 15.996 13.0911 15.5444 13.0911C15.0928 13.0911 14.7262 12.7245 14.7262 12.2729C14.7262 11.8213 15.0928 11.4547 15.5444 11.4547Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00142 3.27246C7.64651 3.27246 6.54688 4.3721 6.54688 5.72701C6.54688 7.08192 7.64651 8.18155 9.00142 8.18155C10.3563 8.18155 11.456 7.08192 11.456 5.72701C11.456 4.3721 10.3563 3.27246 9.00142 3.27246ZM9.00142 4.90882C9.45306 4.90882 9.8196 5.27537 9.8196 5.72701C9.8196 6.17864 9.45306 6.54519 9.00142 6.54519C8.54978 6.54519 8.18324 6.17864 8.18324 5.72701C8.18324 5.27537 8.54978 4.90882 9.00142 4.90882Z"
      fill="#FFE500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9431 14.4876L17.6819 4.74877C18.001 4.42968 18.001 3.91095 17.6819 3.59186C17.3628 3.27277 16.8441 3.27277 16.525 3.59186L6.78619 13.3307C6.4671 13.6498 6.4671 14.1685 6.78619 14.4876C7.10528 14.8067 7.62401 14.8067 7.9431 14.4876Z"
      fill="#FFE500"
    />
  </svg>
);
