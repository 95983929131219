import axios from "axios";

import { fetchClientTokenAWS } from "../AWS/UserPool";

const onRequest = (config) => {
  let token = sessionStorage.getItem("accessToken");
  if (!token) {
    token = sessionStorage.getItem("clientToken");
  }
  config.headers["Authorization"] = `Bearer ${token}`;
  if (config.headers.isMultipart) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  const originalRequest = { ...error.config };
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const token = await fetchClientTokenAWS();
    originalRequest.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    return sleepRequest(1, originalRequest);
  }
  return Promise.reject(error);
};

const sleepRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(api(originalRequest)), milliseconds);
  });
};

const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const api = setupInterceptorsTo(
  axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  })
);

export default api;

export const customAxios = (isMultipart) => {
  const axiosInstance = setupInterceptorsTo(
    axios.create({
      headers: {
        "Content-Type": "application/json",
        isMultipart,
      },
    })
  );

  return axiosInstance;
};
