import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
