// Vendors
import React from "react";

export const defaultTeamIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7328 4.54064L21.9288 5.73501L21.9286 5.73548C21.9487 5.85548 22.402 8.71987 21.4214 12.4449C20.8474 14.6261 19.8992 16.6655 18.6024 18.5063C16.9919 20.7933 14.8424 22.7715 12.2139 24.3848L11.4888 24.8305L10.7634 24.3846C8.13627 22.7721 5.98268 20.7948 4.36346 18.509C3.06085 16.6701 2.10253 14.6324 1.5153 12.4524C0.51437 8.73645 0.94275 5.8663 0.961351 5.74577L1.15015 4.53988L2.37018 4.57429C2.46691 4.57708 2.55675 4.57838 2.64455 4.57838C3.04521 4.57838 3.46448 4.55141 3.89063 4.49858C4.87557 4.37563 5.82999 3.69354 6.93469 2.90374L7.09522 2.78879C8.43934 1.82618 9.82976 0.830475 11.4464 0.830475C13.0628 0.830475 14.4532 1.82618 15.7975 2.78936L15.9578 2.90394C17.0624 3.69393 18.0166 4.37621 19.0023 4.49916C19.4279 4.55199 19.8468 4.57896 20.2473 4.57896C20.3353 4.57896 20.4255 4.57747 20.522 4.57486L21.7328 4.54064ZM12.0766 14.1425H10.1566V13.7405C10.1566 13.1045 10.3126 12.6965 10.6666 12.3605C10.896 12.1388 11.1632 11.9575 11.4144 11.7871C11.5507 11.6946 11.6823 11.6053 11.8006 11.5145C12.1306 11.2625 12.2866 11.0045 12.2866 10.4105V10.3985C12.2866 9.75648 12.0106 9.48048 11.4046 9.48048C10.7266 9.48048 10.4506 9.82248 10.4506 11.0045H8.51855C8.51855 8.85048 9.40055 7.96848 11.5366 7.96848C13.2826 7.96848 14.3866 8.75448 14.3866 10.3505V10.3625C14.3866 11.3165 14.1286 11.8565 13.6486 12.2585C13.3818 12.4818 13.0873 12.6719 12.8381 12.8327C12.6388 12.9613 12.4685 13.0712 12.3646 13.1645C12.1246 13.3685 12.0766 13.5665 12.0766 13.9325V14.1425ZM12.1426 17.6925H10.1026V15.6465H12.1426V17.6925Z"
      fill="#4C4C5F"
    />
  </svg>
);
