// Vendors
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Table, Input } from "antd";
import { differenceInMilliseconds, format } from "date-fns";

import { EditOutlined, SearchOutlined } from "@ant-design/icons";

// Context
import { UserContext } from "../../context/contextProviders/UserContextProvider";
import { BetSlipContext } from "../../context/contextProviders/BetSlipContextProvider";

// Components
import MainSelect from "../../components/MainSelect/MainSelect";
import IconLabel from "../../components/IconLabel/IconLabel";
import DividerLine from "../../components/DividerLine/DividerLine";
import MainButton from "../../components/MainButton/MainButton";
import Loading from "../../components/Loading/Loading";

// API
import { getSeriesOdds } from "../../api/requests/requests";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import { oddsPageConstants } from "./constants/constants";

//  Utils
import {
  transformOddToPrefered,
  formatNumberWithSymbol,
  generateOpenOdds,
} from "../../utils/globalUtils";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import { instabreakIcon } from "../../assets/icons/instabreak/instabreak";

// Styles
import classes from "./OddsPage.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import { abbreviateString } from "../../utils/globalUtils";

const OddsPage = ({ title }) => {
  const navigate = useNavigate();

  const isMobile = window.innerWidth < 500;

  const { preferredOddsType, setPreferredOddsType } = useContext(UserContext);
  const { addBetSlipSerie, toggleShowBetSlip } = useContext(BetSlipContext);

  const [oddsData, setOddsData] = useState(
    oddsPageConstants.INITIAL_SERIE_DATA
  );
  const [loadingOddsData, setLoadingOddsData] = useState(true);

  const selectedDateStart = localStorage.getItem("oddsPageDate");

  let startDateOption = null;

  startDateOption = oddsPageConstants.TIMEFRAME_ODDS_ITEMS.find(
    (date) => date.label === JSON.parse(selectedDateStart)?.label
  );

  console.log({ startDateOption }, startDateOption?.label);
  const [oddsTimeframe, setOddsTimeframe] = useState(
    startDateOption || oddsPageConstants.TIMEFRAME_ODDS_ITEMS[0]
  );

  const [oddsBetType, setOddsBetType] = useState(
    oddsPageConstants.BET_TYPE_ODDS_ITEMS[0]
  );

  const [oddsEsport, setOddsEsport] = useState(
    oddsPageConstants.ESPORTS_ODDS_ITEMS[0]
  );
  const [oddsSearch, setOddsSearch] = useState("");

  const sortByDate = (esportData) => {
    return {
      series:
        esportData.series && esportData.series.length > 0
          ? esportData.series.sort((a, b) => {
              return (
                differenceInMilliseconds(new Date(), new Date(b.seriesDate)) -
                differenceInMilliseconds(new Date(), new Date(a.seriesDate))
              );
            })
          : [],
    };
  };

  const generateOdds = ({
    sportsbook,
    clicked,
    serieData,
    teamPlace,
    openOdds,
  }) => {
    let spread = 0;
    let odds = 0;

    if (openOdds) {
      odds = openOdds[teamPlace][oddsBetType.key].oddsOpen || 0;
      spread = openOdds[teamPlace][oddsBetType.key].spreadOpen || 0;
    } else {
      const sportsBookOdds = serieData[teamPlace][sportsbook]?.find(
        (oddObj) => oddObj.betType === oddsBetType.key
      );

      spread = sportsBookOdds?.spread || 0;
      odds = sportsBookOdds?.odds || 0;
    }

    if (!odds) {
      return <div className={classes.nonAvailableOdds}>N/A</div>;
    }

    const isUnderdog = !spread && odds > 100;

    const isPositiveSpread = spread && spread >= 1.5;

    const content = (
      <>
        <span className={classes.buttonText}>
          {spread
            ? formatNumberWithSymbol(spread, 1)
            : transformOddToPrefered(odds, preferredOddsType)}
        </span>
        <span className={classes.buttonSubText}>
          {spread ? transformOddToPrefered(odds, preferredOddsType) : null}
        </span>
      </>
    );

    if (clicked) {
      return (
        <div className={classes.oddsButtonContainer}>
          <button
            className={`${classes.oddsButton} 
            ${
              isUnderdog || isPositiveSpread
                ? classes.positiveStyles
                : classes.negativeStyles
            }
            `}
            onClick={clicked}
            style={{
              padding: oddsBetType.key === "SPREAD" ? "7px 12px" : "14px 12px",
            }}
          >
            {content}
          </button>
        </div>
      );
    }
    return <div className={classes.oddsContainer}>{content}</div>;
  };

  const handleOddsClicked = ({
    serieData,
    manual,
    switchTeams,
    sportsbook,
  }) => {
    const teamOneData = { ...serieData[switchTeams ? "teamTwo" : "teamOne"] };
    const teamTwoData = { ...serieData[switchTeams ? "teamOne" : "teamTwo"] };

    const betData = {
      game: serieData.esport,
      abiosSeriesId: serieData.abiosSeriesId,
      risk: teamOneData.risk || "",
      odds: sportsbook
        ? teamOneData[sportsbook].find(
            (oddObj) => oddObj.betType === oddsBetType.key
          )?.odds || ""
        : "",
      win: teamOneData.win || "",
      spread: sportsbook
        ? teamOneData[sportsbook].find(
            (oddObj) => oddObj.betType === oddsBetType.key
          )?.spread || ""
        : "",
      total: teamOneData.total || "",
      type: oddsBetType.key === "SPREAD" ? "spread" : "moneyLine",
      teamOne: teamOneData,
      teamTwo: teamTwoData,
      startDate: serieData.seriesDate,
    };

    if (manual) {
      betData.risk = "";
      betData.odds = "";
      betData.win = "";
      betData.spread = "";
      betData.total = "";
    }

    addBetSlipSerie(betData);
    toggleShowBetSlip();
  };

  const generateExtraField = ({ serieData, switchTeams }) => {
    return (
      <div className={classes.extraFieldContainer}>
        <div className={classes.extraFieldDesktop}>
          <MainButton
            type="tertiary"
            icon={<EditOutlined />}
            buttonText="Manual"
            clicked={() => {
              handleOddsClicked({ serieData, manual: true, switchTeams });
            }}
          />
        </div>
        <div className={classes.extraFieldMobile}>
          <MainButton
            type="tertiary"
            icon={<EditOutlined />}
            clicked={() => {
              handleOddsClicked({ serieData, manual: true, switchTeams });
            }}
          />
        </div>
      </div>
    );
  };

  const generateRows = (serieData) => {
    const sportsbooks = ["rivalry", "esportsBets", "thunderPick", "bovada"];

    const openOdds = generateOpenOdds(serieData, sportsbooks);

    return [
      {
        key: `${serieData.abiosSeriesId}-${0}`,
        team: (
          <div className={classes.firstRowCellContainer}>
            <IconLabel
              icon={serieData.teamOne.imageSmall}
              label={
                serieData.teamOne.teamAbbreviation || serieData.teamOne.teamName
              }
              logoSize="logoMedium"
              size="small"
              withAbbreviation
            />
          </div>
        ),
        open: generateOdds({ serieData, openOdds, teamPlace: "teamOne" }),
        manual: generateExtraField({ serieData }),
        rivalry: generateOdds({
          teamPlace: "teamOne",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
          clicked: () => {
            handleOddsClicked({
              serieData,
              sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
            });
          },
        }),
        esportsBets: generateOdds({
          teamPlace: "teamOne",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
          clicked: () => {
            handleOddsClicked({
              serieData,
              sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
            });
          },
        }),
        thunderpick: generateOdds({
          teamPlace: "teamOne",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
          clicked: () => {
            handleOddsClicked({
              serieData,
              sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
            });
          },
        }),
        bovada: generateOdds({
          teamPlace: "teamOne",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
          clicked: () => {
            handleOddsClicked({
              serieData,
              sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
            });
          },
        }),
      },
      {
        key: `${serieData.abiosSeriesId}-${1}`,
        team: (
          <div className={classes.firstRowCellContainer}>
            <IconLabel
              icon={serieData.teamTwo.imageSmall}
              label={
                serieData.teamTwo.teamAbbreviation || serieData.teamTwo.teamName
              }
              logoSize="logoMedium"
              size="small"
              withAbbreviation
            />
          </div>
        ),
        open: generateOdds({ serieData, openOdds, teamPlace: "teamTwo" }),
        manual: generateExtraField({ serieData, switchTeams: true }),
        rivalry: generateOdds({
          teamPlace: "teamTwo",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
          clicked: () => {
            handleOddsClicked({
              serieData,
              switchTeams: true,
              sportsbook: globalConstants.SPORTSBOOKS.RIVALRY,
            });
          },
        }),
        esportsBets: generateOdds({
          teamPlace: "teamTwo",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
          clicked: () => {
            handleOddsClicked({
              serieData,
              switchTeams: true,
              sportsbook: globalConstants.SPORTSBOOKS.ESPORTS_BETS,
            });
          },
        }),
        thunderpick: generateOdds({
          teamPlace: "teamTwo",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
          clicked: () => {
            handleOddsClicked({
              serieData,
              switchTeams: true,
              sportsbook: globalConstants.SPORTSBOOKS.THUNDERPICK,
            });
          },
        }),
        bovada: generateOdds({
          teamPlace: "teamTwo",
          serieData,
          sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
          clicked: () => {
            handleOddsClicked({
              serieData,
              switchTeams: true,
              sportsbook: globalConstants.SPORTSBOOKS.BOVADA,
            });
          },
        }),
      },
    ];
  };

  const filteredOddsData = Object.entries({
    leagueOfLegends: sortByDate(oddsData.leagueOfLegends),
    csgo: sortByDate(oddsData.csgo),
    valorant: sortByDate(oddsData.valorant),
    rocketLeague: sortByDate(oddsData.rocketLeague),
    callOfDuty: sortByDate(oddsData.callOfDuty),
  }).map((entry) => {
    const [esport, esportData] = entry;
    if (
      [esport, ""].includes(oddsEsport.key) &&
      esportData?.series?.length > 0
    ) {
      let newSeries = [...esportData?.series];
      if (oddsSearch) {
        newSeries = esportData?.series.filter((serie) => {
          const possibleNames = [
            serie.teamOne.teamAbbreviation.toLowerCase(),
            serie.teamOne.teamName.toLowerCase(),
            serie.teamTwo.teamAbbreviation.toLowerCase(),
            serie.teamTwo.teamAbbreviation.toLowerCase(),
            abbreviateString(serie.teamOne.teamName, 6).toLowerCase(),
            abbreviateString(serie.teamTwo.teamName, 6).toLowerCase(),
            abbreviateString(serie.teamOne.teamAbbreviation, 6).toLowerCase(),
            abbreviateString(serie.teamTwo.teamAbbreviation, 6).toLowerCase(),
          ];

          const matchedName = possibleNames.filter((name) => {
            return name.includes(oddsSearch.toLowerCase());
          });

          return !!matchedName.length > 0;
        });
      }
      return { esport, series: newSeries };
    }
    return null;
  });

  const isEmptyData =
    !filteredOddsData.filter((esport) => esport?.series?.length > 0).length > 0;

  const handleGetSeriesOdds = () => {
    setLoadingOddsData(true);
    getSeriesOdds(
      {
        timeframe: oddsTimeframe.key,
        game: null,
      },
      (data) => {
        setOddsData(data);
        setLoadingOddsData(false);
      },
      () => {
        setLoadingOddsData(false);
        navigate(`/${globalConstants.ROUTES.NOT_FOUND}`);
      }
    );
  };

  useEffect(() => {
    handleGetSeriesOdds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oddsTimeframe]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleDateChange = (dateItem) => {
    localStorage.setItem("oddsPageDate", JSON.stringify(dateItem));
    setOddsTimeframe(dateItem);
  };
  return (
    <div className={classes.oddsPageContainer}>
      <div className={classes.oddsPageWrapper}>
        <div className={classes.oddsPageControls}>
          <div className={classes.oddsPageEspotsControls}>
            <MainSelect
              size="small"
              currentValue={oddsPageConstants.ESPORTS_ODDS_ITEMS.find(
                (item) => {
                  return item.key === oddsEsport.key;
                }
              )}
              setCurrentValue={(item) => setOddsEsport(item)}
              options={oddsPageConstants.ESPORTS_ODDS_ITEMS}
            />
          </div>
          <div className={classes.oddsPageTimeframeControls}>
            <MainSelect
              size="small"
              currentValue={oddsPageConstants.TIMEFRAME_ODDS_ITEMS.find(
                (item) => {
                  return item.key === oddsTimeframe.key;
                }
              )}
              setCurrentValue={(item) => handleDateChange(item)}
              options={oddsPageConstants.TIMEFRAME_ODDS_ITEMS}
            />
          </div>
          <div className={classes.oddsPageBetTypeControls}>
            <MainSelect
              size="small"
              currentValue={oddsPageConstants.BET_TYPE_ODDS_ITEMS.find(
                (item) => {
                  return item.key === oddsBetType.key;
                }
              )}
              setCurrentValue={(item) => setOddsBetType(item)}
              options={oddsPageConstants.BET_TYPE_ODDS_ITEMS}
            />
          </div>
          <div className={classes.oddsPagePreferredControls}>
            <MainSelect
              size="small"
              currentValue={globalConstants.PREFERRED_ODDS_ITEMS.find(
                (item) => {
                  return item.key === preferredOddsType;
                }
              )}
              setCurrentValue={(item) => setPreferredOddsType(item.key)}
              options={globalConstants.PREFERRED_ODDS_ITEMS}
            />
          </div>
          <div className={classes.oddsPageSearchControls}>
            <Input
              size="small"
              placeholder="Search"
              className={classes.searchBox}
              prefix={<SearchOutlined style={{ fontSize: "150%" }} />}
              bordered={false}
              onChange={(event) => {
                setOddsSearch(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.dividerLineContainer}>
        <DividerLine />
      </div>
      {!loadingOddsData && !isEmptyData ? (
        <div className={classes.tableList}>
          <div className={classes.tableHeaderContainer}>
            <Table
              columns={oddsPageConstants.ODDS_COLUMNS.filter(
                (column) => !isMobile || (isMobile && !column.hideMobile)
              )}
              pagination={false}
              dataSource={[]}
            />
          </div>

          {filteredOddsData.map((esportOdds) => {
            if (esportOdds) {
              return (
                <section
                  className={classes.esportSection}
                  key={`section-${esportOdds.esport}`}
                >
                  <div className={classes.esportRow}>
                    <IconLabel
                      icon={esportsLogos[esportOdds.esport]}
                      label={globalConstants.TITLES[esportOdds.esport]}
                      isSvg
                    />
                  </div>
                  {esportOdds?.series.map((serieData, index) => {
                    return (
                      <div key={`${serieData.abiosSeriesId}-${index}`}>
                        <div className={classes.dateContainer}>
                          <div className={classes.dateContainerLeft}>
                            <p className={classes.oddsDate}>
                              {format(
                                new Date(serieData.seriesDate),
                                "MMMM dd"
                              )}
                            </p>
                            <p className={classes.oddsDate}>
                              {format(
                                new Date(serieData.seriesDate),
                                "hh:mm aaaaa'm'"
                              )}
                            </p>
                            {serieData.tournamentName ? (
                              <p className={classes.oddsDate}>
                                {serieData.tournamentName}
                              </p>
                            ) : null}
                          </div>
                          <Link
                            className={classes.matchPageLink}
                            to={{
                              pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${serieData.abiosSeriesId}`,
                            }}
                          >
                            Match Page &#8594;
                          </Link>
                        </div>

                        <Table
                          columns={oddsPageConstants.ODDS_COLUMNS.filter(
                            (column) =>
                              !isMobile || (isMobile && !column.hideMobile)
                          )}
                          pagination={false}
                          dataSource={generateRows({
                            ...serieData,
                            esport: esportOdds.esport,
                          })}
                          showHeader={false}
                        />
                        <div className={classes.dividerLineContainer}>
                          <DividerLine
                            color={styledVariables.backgroundPrimaryE}
                          />
                        </div>
                      </div>
                    );
                  })}
                </section>
              );
            }
            return null;
          })}
        </div>
      ) : isEmptyData && !loadingOddsData ? (
        <div className={classes.notFoundMatchesContainer}>
          <div className={classes.notFoundMatchesIcon}>{instabreakIcon}</div>
          <p>No matches to instalock . . .</p>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default OddsPage;
