// Vendors
import React from "react";

// Styles
import classes from "./LikesRate.module.scss";

// Assets
import {
  happyFaceIcon,
  normalFaceIcon,
  sadFaceIcon,
} from "../../assets/icons/face/face";

const LikesRate = ({ likeRate }) => {
  const generateProps = (likeRate) => {
    if (likeRate <= 33) {
      return { icon: sadFaceIcon, color: "baseRed" };
    } else if (likeRate <= 66) {
      return { icon: normalFaceIcon, color: "baseYellow" };
    } else {
      return { icon: happyFaceIcon, color: "baseGreen" };
    }
  };

  const likeProps = generateProps(likeRate);

  return (
    <div
      className={`${classes.likesRateContainer}  ${classes[likeProps.color]}`}
    >
      {likeProps.icon}
      {`${likeRate}%`}
    </div>
  );
};

export default LikesRate;
