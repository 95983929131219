// Vendors
import React from "react";

export const esportsbet = (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    fill="rgb(131, 60, 247)"
  >
    <g>
      <path
        className="st0"
        d="M890.89,547.57c-16.22-30.66-37.71-55.84-64.48-75.58c11.27-17.61,20.09-36.47,26.42-56.55
		c6.34-20.08,9.52-41.39,9.52-63.96c0-30.29-5.82-59.01-17.44-86.15c-11.63-27.12-27.48-50.74-47.57-70.82
		c-20.08-20.08-43.7-35.94-70.82-47.57c-27.14-11.63-55.86-17.44-86.15-17.44H491.53l-41.74,146.17h3.83h311.32L735.4,379.09H420.25
		l-22.15,77.56h315.15l-29.48,103.42H368.93l-8.91,29.91l-13.61,47.66h315.15l-29.47,103.42H316.88L279.91,870.5h107.62h40.37
		h265.32c30.29,0,59.01-5.81,86.15-17.44c27.12-11.63,50.74-27.65,70.83-48.1c20.08-20.43,35.94-44.03,47.57-70.82
		c11.63-26.77,17.44-55.66,17.44-86.68C915.21,611.52,907.09,578.22,890.89,547.57z"
      />
      <path
        className="st0"
        d="M278.17,456.65h-0.03l22.19-77.56h0l29.53-103.42h-60.5c-27.28,0-51.32,17.92-59.11,44.07L84.79,741.05h100.66
		h9.65h1.84L278.17,456.65z"
      />
    </g>
  </svg>
);
