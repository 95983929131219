// Vendors
import React, { useContext } from "react";

// Context
import { ModalContext } from "../../context/contextProviders/ModalContextProvider";

// Components
import Login from "../Login/Login";
import SignUp from "../SignUp/SignUp";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import VerifyUser from "../VerifyUser/VerifyUser";

const LoginOrSignUp = () => {
  const { modalToShow, setModalToShow } = useContext(ModalContext);

  switch (modalToShow) {
    case "login":
      return (
        <Login modalToShow={modalToShow} setModalToShow={setModalToShow} />
      );
    case "signUp":
      return (
        <SignUp modalToShow={modalToShow} setModalToShow={setModalToShow} />
      );
    case "forgotPassword":
      return (
        <ForgotPassword
          modalToShow={modalToShow}
          setModalToShow={setModalToShow}
        />
      );
    case "verifyUser":
      return (
        <VerifyUser modalToShow={modalToShow} setModalToShow={setModalToShow} />
      );

    default:
      return null;
  }
};

export default LoginOrSignUp;
