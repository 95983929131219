// Assets
import { rivalryIcon } from "../../../assets/icons/rivalry/rivalry";
import { esportsbet } from "../../../assets/icons/staff/esportsbet";
import { thunderPick } from "../../../assets/icons/staff/thunderPick";
import { bovadaOddsPage } from "../icons/bovadaOddsPage";
import { addDays, format } from "date-fns";

// Constants
import { globalConstants } from "../../../constants/globalConstants";

// Styles
import classes from "./constants.module.scss";

export const oddsPageConstants = {
  ODDS_COLUMNS: [
    {
      title: <div className={classes.scheduledSpacing}>Scheduled</div>,
      dataIndex: "team",
      key: "team",
      width: "20%",
    },
    {
      title: "Open",
      dataIndex: "open",
      key: "open",
      width: "13%",
      hideMobile: true,
    },
    {
      title: (
        <div className={classes.sportsbooksLogoContainer}>
          <a
            className={`${classes.sportsbooksLogo} ${classes.rivalryLogo}`}
            href={globalConstants.SPORTS_BOOKS_LINKS.RIVALRY}
            target="_blank"
            rel="noreferrer"
          >
            {rivalryIcon}
          </a>
        </div>
      ),
      dataIndex: "rivalry",
      key: "rivalry",
      width: "13%",
    },
    {
      title: (
        <div className={classes.sportsbooksLogoContainer}>
          <a
            className={`${classes.sportsbooksLogo} ${classes.esportsBetsLogo}`}
            href={globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET}
            target="_blank"
            rel="noreferrer"
          >
            {esportsbet}
          </a>
        </div>
      ),
      dataIndex: "esportsBets",
      key: "esportsBets",
      width: "13%",
    },
    {
      title: (
        <div className={classes.sportsbooksLogoContainer}>
          <a
            className={`${classes.sportsbooksLogo} ${classes.thundepickLogo}`}
            href={globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK}
            target="_blank"
            rel="noreferrer"
          >
            {thunderPick}
          </a>
        </div>
      ),
      dataIndex: "thunderpick",
      key: "thunderpick",
      width: "13%",
    },
    {
      title: (
        <div className={classes.sportsbooksLogoContainer}>
          <a
            className={`${classes.sportsbooksLogo} ${classes.bovadaLogo}`}
            href={globalConstants.SPORTS_BOOKS_LINKS.BOVADA}
            target="_blank"
            rel="noreferrer"
          >
            {bovadaOddsPage}
          </a>
        </div>
      ),
      dataIndex: "bovada",
      key: "bovada",
      width: "13%",
    },
    {
      title: <div className={classes.manualSpacing} />,
      dataIndex: "manual",
      key: "manual",
      width: "13%",
    },
  ],

  INITIAL_SERIE_DATA: {
    csgo: {
      series: [],
    },
    valorant: {
      series: [],
    },
    leagueOfLegends: {
      series: [],
    },
    rocketLeague: {
      series: [],
    },
    callOfDuty: {
      series: [],
    },
  },

  TIMEFRAME_ODDS_ITEMS: [
    {
      label: format(addDays(new Date(), 0), "MMM dd"),
      key: "TODAY",
    },
    {
      label: format(addDays(new Date(), 1), "MMM dd"),
      key: "TOMORROW",
    },
    {
      label: format(addDays(new Date(), 2), "MMM dd"),
      key: "DAY_3",
    },
    {
      label: format(addDays(new Date(), 3), "MMM dd"),
      key: "DAY_4",
    },
    {
      label: format(addDays(new Date(), 4), "MMM dd"),
      key: "DAY_5",
    },
    {
      label: format(addDays(new Date(), 5), "MMM dd"),
      key: "DAY_6",
    },
  ],

  BET_TYPE_ODDS_ITEMS: [
    {
      label: "Money Line",
      key: "MONEYLINE",
    },
    {
      label: "Spread",
      key: "SPREAD",
    },
  ],

  ESPORTS_ODDS_ITEMS: [
    {
      label: "All games",
      key: "",
    },
    {
      label: "League of Legends",
      key: "leagueOfLegends",
    },
    {
      label: "Rocket League",
      key: "rocketLeague",
    },
    {
      label: "VALORANT",
      key: "valorant",
    },
    {
      label: "Counter-Strike",
      key: "csgo",
    },
    {
      label: "Call of Duty",
      key: "callOfDuty",
    },
  ],
};
