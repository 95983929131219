import React, { createContext, useReducer } from "react";

const ModalReducer = (state, action) => {
  switch (action.type) {
    case "SET_SHOW_MODAL": {
      return {
        ...state,
        modalToShow: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  modalToShow: null,
};

export const ModalContext = createContext(initialState);

export const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ModalReducer, initialState);

  const setModalToShow = (data) => {
    dispatch({
      type: "SET_SHOW_MODAL",
      payload: data,
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modalToShow: state.modalToShow,
        setModalToShow,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
