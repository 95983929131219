// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Components
import MainButton from "../MainButton/MainButton";
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./TopGuideCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

const TopGuideCard = ({ guideData }) => {
  return (
    <div className={classes.guideCardContainer}>
      <div className={classes.guideImageContainer}>
        <img src={guideData.mainImage} alt="Top  Guide" />
      </div>

      <div className={classes.guideContent}>
        <h3 className={classes.guideTitle}>{guideData.title}</h3>
        <p className={classes.guideDescription}>{guideData.description}</p>

        <div className={classes.ctaRow}>
          {guideData.game ? (
            <IconLabel
              label={globalConstants.TITLES[guideData.game]}
              bold
              isSvg
              icon={esportsLogos[guideData.game]}
            />
          ) : null}
          <Link
            to={{
              pathname: `/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GUIDES}/${guideData.id}`,
            }}
          >
            <MainButton buttonText="Read" type="primary" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopGuideCard;
