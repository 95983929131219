// Vendors
import React from "react";

// Assets
import defaultProfilePicture from "../../assets/images/profile/defaultProfilePicture.jpg";

// Styles
import classes from "./ProfileTag.module.scss";

const ProfileTag = ({ image, isOnline, profileName }) => {
  return (
    <div className={classes.profileTagContainer}>
      {<img src={image || defaultProfilePicture} alt="profileplaceholder" />}
      {/* <div className={classes.onlineStatusContainer}>
        <div
          className={`${classes.onlineStatus} ${isOnline && classes.isOnline}`}
        />
      </div> */}
      <span className={classes.username}>@{profileName}</span>
    </div>
  );
};

export default ProfileTag;
