// Vendors
import React from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./ProbabilityCard.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import { graphIcon } from "../../assets/icons/graph/graph";

// Constants
import { globalConstants } from "../../constants/globalConstants";
import DividerLine from "../DividerLine/DividerLine";

// Utils
import OddsTag from "../OddsTag/OddsTag";

const StreakCard = ({ loopsided, closest, preferredOddsType }) => {
  return (
    <div className={classes.probabilityList}>
      {loopsided ? (
        <Link
          className={`${classes.probabilityCard} ${
            loopsided.isSeriesPresent ? classes.isSeriesPresent : ""
          }`}
          to={`/${globalConstants.ROUTES.MATCH_PAGE}/${loopsided.abiosSeriesId}/`}
          style={{ pointerEvents: !loopsided.isSeriesPresent ? "none" : null }}
        >
          <p className={classes.cardTitle}>Most Lopsided Matchup</p>
          <IconLabel
            icon={esportsLogos[loopsided.game]}
            label={globalConstants.TITLES[loopsided.game]}
            isSvg
          />
          <p className={classes.tournamentName}> {loopsided.tournamentName}</p>
          {loopsided.isSeriesPresent ? (
            <div className={classes.matchPageLinkContainer}>
              <Link
                className={classes.matchPageLink}
                to={{
                  pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${loopsided.abiosSeriesId}`,
                }}
              >
                Match Page &#8594;
              </Link>
            </div>
          ) : null}
          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          <div className={classes.gameRow}>
            <div className={classes.teamContainer}>
              <IconLabel
                icon={loopsided.teamOne.imageSmall}
                label={
                  loopsided.teamOne.teamAbbreviation ||
                  loopsided.teamOne.teamName
                }
                bold
                withAbbreviation
              />
              {!!loopsided.teamOne.odds && loopsided.teamOne.odds !== 100 ? (
                <OddsTag
                  odds={loopsided.teamOne.odds}
                  fullWidth
                  preferredOddsType={preferredOddsType}
                />
              ) : null}
            </div>
            <div className={classes.versusBoxContainer}>
              <div className={classes.versusBox}>VS </div>
              <div className={classes.dateBox}>
                <span>{format(new Date(loopsided.startDate), "HH:mm")}</span>
              </div>
            </div>
            <div className={classes.teamContainer}>
              <IconLabel
                icon={loopsided.teamTwo.imageSmall}
                label={
                  loopsided.teamTwo.teamAbbreviation ||
                  loopsided.teamTwo.teamName
                }
                inverted
                bold
                withAbbreviation
              />
              {!!loopsided.teamTwo.odds && loopsided.teamTwo.odds !== 100 ? (
                <OddsTag
                  odds={loopsided.teamTwo.odds}
                  fullWidth
                  preferredOddsType={preferredOddsType}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          {loopsided.teamOne.impliedWinPercent &&
          loopsided.teamTwo.impliedWinPercent ? (
            <div className={classes.matchPlayerInfoFooter}>
              <div className={classes.impliedWinPercent}>
                {loopsided.teamOne.impliedWinPercent.toFixed(2)}%
              </div>
              <div className={classes.impliedWinPercentLabel}>
                <div className={classes.graphIconContainer}>{graphIcon}</div>
                <div className={classes.graphTextsContainer}>
                  <p>Implied</p> <p>win %</p>
                </div>
              </div>
              <div className={classes.impliedWinPercent}>
                {loopsided.teamTwo.impliedWinPercent.toFixed(2)}%
              </div>
            </div>
          ) : null}
        </Link>
      ) : null}
      {closest ? (
        <Link
          className={`${classes.probabilityCard} ${
            closest.isSeriesPresent ? classes.isSeriesPresent : ""
          }`}
          to={`/${globalConstants.ROUTES.MATCH_PAGE}/${closest.abiosSeriesId}/`}
          style={{ pointerEvents: !closest.isSeriesPresent ? "none" : null }}
        >
          <p className={classes.cardTitle}>Closest Matchup</p>
          <IconLabel
            icon={esportsLogos[closest.game]}
            label={globalConstants.TITLES[closest.game]}
            isSvg
          />
          <p className={classes.tournamentName}> {closest.tournamentName}</p>

          {closest.isSeriesPresent ? (
            <div className={classes.matchPageLinkContainer}>
              <Link
                className={classes.matchPageLink}
                to={{
                  pathname: `/${globalConstants.ROUTES.MATCH_PAGE}/${closest.abiosSeriesId}`,
                }}
              >
                Match Page &#8594;
              </Link>
            </div>
          ) : null}

          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          <div className={classes.gameRow}>
            <div className={classes.teamContainer}>
              <IconLabel
                icon={closest.teamOne.imageSmall}
                label={
                  closest.teamOne.teamAbbreviation || closest.teamOne.teamName
                }
                bold
                withAbbreviation
              />
              {!!closest.teamOne.odds && closest.teamOne.odds !== 100 ? (
                <OddsTag
                  odds={closest.teamOne.odds}
                  fullWidth
                  preferredOddsType={preferredOddsType}
                />
              ) : null}
            </div>
            <div className={classes.versusBoxContainer}>
              <div className={classes.versusBox}>VS </div>
              <div className={classes.dateBox}>
                <span>{format(new Date(closest.startDate), "HH:mm")}</span>
              </div>
            </div>
            <div className={classes.teamContainer}>
              <IconLabel
                icon={closest.teamTwo.imageSmall}
                label={
                  closest.teamTwo.teamAbbreviation || closest.teamTwo.teamName
                }
                inverted
                bold
                withAbbreviation
              />
              {!!closest.teamTwo.odds && closest.teamTwo.odds !== 100 ? (
                <OddsTag
                  odds={closest.teamTwo.odds}
                  fullWidth
                  preferredOddsType={preferredOddsType}
                />
              ) : null}
            </div>
          </div>
          <div className={classes.dividerLineContainer}>
            <DividerLine color={styledVariables.backgroundPrimaryE} />
          </div>
          {closest.teamOne.impliedWinPercent &&
          closest.teamTwo.impliedWinPercent ? (
            <div className={classes.matchPlayerInfoFooter}>
              <div className={classes.impliedWinPercent}>
                {closest.teamOne.impliedWinPercent.toFixed(2)}%
              </div>
              <div className={classes.impliedWinPercentLabel}>
                <div className={classes.graphIconContainer}>{graphIcon}</div>
                <div className={classes.graphTextsContainer}>
                  <p>Implied</p> <p>win %</p>
                </div>
              </div>
              <div className={classes.impliedWinPercent}>
                {closest.teamTwo.impliedWinPercent.toFixed(2)}%
              </div>
            </div>
          ) : null}
        </Link>
      ) : null}
    </div>
  );
};

export default StreakCard;
