// Vendors
import React from "react";

const DownIcon = ({ color }) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.09972 5.9955C6.31903 5.97436 6.52642 5.88577 6.69332 5.7421L11.1756 1.90013C11.4012 1.71938 11.5437 1.4548 11.5708 1.16703C11.5977 0.879236 11.5069 0.592796 11.3188 0.373331C11.1309 0.153725 10.8619 0.019729 10.5735 0.0020094C10.285 -0.0157079 10.0016 0.0843452 9.78815 0.279234L5.99962 3.52764L2.21109 0.279234C1.99758 0.0843414 1.71426 -0.0157101 1.42573 0.0020094C1.13734 0.0197267 0.868305 0.153725 0.680399 0.373331C0.492356 0.592788 0.401537 0.879228 0.428484 1.16703C0.455581 1.45483 0.598064 1.71939 0.82362 1.90013L5.30592 5.7421C5.52522 5.93059 5.8117 6.02201 6.09962 5.9955H6.09972Z"
      fill={color || "white"}
    />
  </svg>
);

export default DownIcon;
