// Vendors
import React from "react";

import { Link } from "react-router-dom";

// Components
import MainButton from "../../components/MainButton/MainButton";

// Assets
import spaceImage from "./space.png";
import { astronaut404 } from "./astronaut404/astronaut404";

// Styles
import classes from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
  return (
    <div
      className={classes.notFoundPagePageContainer}
      style={{
        backgroundSize: "cover",
        backgroundImage: `url("${spaceImage}")`,
        backgroundPosition: "center",
      }}
    >
      <div className={classes.astronautMobileWrapper}>
        {astronaut404}
        <h3 className={classes.pageTitle}>Lost in space</h3>

        <Link
          to={{
            pathname: `/`,
          }}
        >
          <MainButton buttonText="Go back to homepage" type="quaternary" />
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
