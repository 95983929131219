// Vendors
import React from "react";

// Components
import GameBannerRenderer from "../../components/GameBanner/GameBannerRenderer";
import IconLabel from "../../components/IconLabel/IconLabel";

// Styles
import classes from "./EducationCSGOPage.module.scss";

// Assets
import { fazeLogo, c9Logo } from "../../assets/icons/logos/logos";

const EducationCSGOPage = () => {
  return (
    <div className={classes.educationGuidesPage}>
      <div className={classes.pageBannerContainer}>
        <GameBannerRenderer game={"csgo"} />
      </div>
      <div className={classes.pageHeader}>
        <h2>What is Counter Strike: Global Offensive</h2>
        <p>
          Counter-Strike: Global Offensive is the fourth installment in the
          Counter-Strike series. The first Counter-Strike was released in 1999,
          and the series continues to capture hearts. This tactical shooter
          mixes strategy, gunplay, and economic factors in an intense manner for
          the ultimate experience. The two teams, Terrorists and
          Counter-Terrorists, are pitted against each other in a fight for the
          bomb-sites. The Terrorist's goal is to plant the bomb and defend it
          until it explodes, while the Counter-Terrorists goal is to defend the
          bombsites and defuse the bomb if it gets planted.
        </p>
        <p>
          There are 5 players on each team. Each player has their own money and
          must team up with others on correct ways to spend it to win the game.
          It is a meticulous, explosive, and exciting game to both watch and
          play. The first team to 16 rounds wins.
        </p>
      </div>
      <div className={classes.pageContent}>
        <h2>Counter Strike Betting basics</h2>
        <h3>How do MoneyLine Odds Work?</h3>
        <p>
          A popular bet in Counter Strike: Global Offensive is the moneyline,
          which requires you to pick the winner of the game. But it’s not a
          50-50 proposition in most cases – you won’t get paid the same for
          taking FAZE to beat C9 as you would for taking the C9 to beat FAZE,
          given the ability of each team.
        </p>
        <p>
          So each team is assigned moneyline odds by the sportsbook based on
          their ability, and bettors have to pay a corresponding price.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"FAZE"} icon={fazeLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-300</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
            <IconLabel label={"C9"} icon={c9Logo} isSvg size="large" inverted />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"C9"} icon={c9Logo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              +250
            </div>
          </div>
        </div>

        <p>
          American odds like these are centered around $100. So if you bet $300
          on FAZE at -300, you’d win $100 if they win. If you bet $100 on C9 at
          +250 and they win, you’d win $250.
        </p>
        <p>
          You don’t have to bet $100, of course. If you bet $3 on FAZE you’d win
          $1. Bet $1 on C9, win $2.50.
        </p>

        <h3>Are there point spreads in Counter-Strike?</h3>

        <p>
          In Counter-Strike, one of the most popular betting options is the
          point spread - it’s a bet on the margin of victory in that series.
          Let’s use that same example from above in a best-of-3 series.
        </p>

        <div className={classes.educationMatchContainer}>
          <div className={classes.teamAndOddsContainer}>
            <IconLabel label={"FAZE"} icon={fazeLogo} isSvg size="large" />
            <div className={classes.oddsLabel}>-110</div>
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
          </div>
          <span className={classes.vsContainer}>VS</span>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxDesktop}`}
          >
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              -110
            </div>
            <IconLabel label={"C9"} icon={c9Logo} isSvg size="large" inverted />
          </div>
          <div
            className={`${classes.teamAndOddsContainer} ${classes.boxMobile}`}
          >
            <IconLabel label={"C9"} icon={c9Logo} isSvg size="large" />
            <div className={`${classes.oddsLabel} ${classes.greenLabel}`}>
              -110
            </div>
            <div className={`${classes.overUnderOddsLabel}`}>-1.5</div>
          </div>
        </div>
        <p>
          Because FAZE is so much better than C9, sportsbooks are saying it’s
          about a 50-50 chance that FAZE wins the series as a 2-0.
        </p>
        <h3>What is Over/Under?</h3>
        <p>
          When you are betting the Over/Under on a match, it means you are
          betting on the amount of games being played. Typically Counter Strike:
          Global Offensive matches are a best-of-3 or best-of-5, so typical
          over/unders are set at 2.5, 3.5, or 4.5.
        </p>
        <p>
          {
            "Example: If C9 and Optic are playing a best of 3 game. There should be an over/under at 2.5. Meaning you are betting on C9 OR Optic to 2-0 (2 games <2.5) if you take the under or you are betting on either team to win 2-1 if you took the over (3 games >2.5)"
          }
        </p>
      </div>
    </div>
  );
};

export default EducationCSGOPage;
