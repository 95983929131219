// Vendors
import React from "react";

export const wifiIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96329 2.62674C8.53984 2.62674 13.9058 7.99268 13.9058 14.5692C13.9058 15.1581 14.3965 15.6164 14.9529 15.6164C15.5417 15.6164 16 15.1257 16 14.5692C16 6.81479 9.68524 0.5 1.93081 0.5C1.34198 0.5 0.883681 0.990693 0.883681 1.54713C0.883681 2.16862 1.37437 2.62668 1.96324 2.62668L1.96329 2.62674ZM1.96334 8.67991C5.2026 8.67991 7.85279 11.3301 7.85279 14.5694C7.85279 15.1582 8.34348 15.6165 8.89992 15.6165C9.48875 15.6165 9.94705 15.1258 9.94705 14.5694C9.97968 10.1522 6.38049 6.55317 1.96358 6.55317C1.37475 6.55317 0.916449 7.04386 0.916449 7.6003C0.88404 8.22202 1.37474 8.68003 1.96358 8.68003L1.96334 8.67991ZM3.92653 14.5692C3.92653 15.649 3.04299 16.5324 1.96326 16.5324C0.883708 16.5324 0.000228216 15.6489 0 14.5692C0 13.4894 0.883533 12.6059 1.96326 12.6059C3.04305 12.6059 3.92653 13.4894 3.92653 14.5692Z"
      fill="#3378FF"
    />
  </svg>
);
