// Vendors
import React, { useEffect, useState } from "react";
import { message } from "antd";

// Components
import MainButton from "../../components/MainButton/MainButton";

// Styles
import classes from "./SportsbooksPage.module.scss";
import IconLabel from "../../components/IconLabel/IconLabel";

// Assets
import { heartIcon } from "../../assets/icons/heart/heart";
import { chainIcon } from "../../assets/icons/chain/chain";
import GiftIcon from "../../assets/icons/gift/gift";
import { controllerIcon } from "../../assets/icons/controller/controller";

// Constants
import { sportsbooksPageConstants } from "./constants/constants";

const TABS = {
  FANTASY: "FANTASY",
  SPORTSBOOK: "SPORTSBOOK",
};

const SportsbooksPage = ({ title }) => {
  const [menuTab, setMenuTab] = useState(TABS.SPORTSBOOK);

  const handleCopyCode = (code) => {
    message.success({
      content: "Code copied to clipboard!",
      className: "successMessage",
    });
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  //

  return (
    <div className={classes.sportsbooksPageContainer}>
      <div className={classes.sportsbooksGreetingContainer}>
        <div className={classes.greetingIconsContainer}>
          <IconLabel icon={controllerIcon} isSvg />
          <IconLabel icon={heartIcon} isSvg />
        </div>
        <p className={classes.sportsbooksGreeting}>
          INSTALOCK Network is home to all things esports betting. Whether you
          are an experienced esports bettor looking to compare odds across
          different books, or a newcomer looking for where to start, below are
          the top esports-supported sportsbooks!
        </p>

        <div className={classes.switchButtonContainer}>
          <div className={classes.switchButtonList}>
            <MainButton
              buttonText="Sportsbooks"
              fullWidth="fullWidth"
              clicked={() => setMenuTab(TABS.SPORTSBOOK)}
              type={menuTab === TABS.SPORTSBOOK ? "primary" : "tertiary"}
            />
            <MainButton
              buttonText="Daily Fantasy"
              fullWidth="fullWidth"
              clicked={() => setMenuTab(TABS.FANTASY)}
              type={menuTab === TABS.FANTASY ? "primary" : "tertiary"}
            />
          </div>
        </div>
        <div className={classes.sportbookListContainer}>
          {sportsbooksPageConstants[menuTab].map((sportbookData) => {
            return (
              <div
                key={sportbookData.name}
                className={classes.sportbookCardContainer}
              >
                <div
                  className={classes.sportbookCardImageContainer}
                  style={{
                    backgroundColor: sportbookData.color,
                    padding: sportbookData.name === "Esportsbet" ? "0" : "",
                    overflow: "hidden",
                  }}
                >
                  {sportbookData.icon}
                </div>
                <div className={classes.sportbookCard}>
                  <div className={classes.sportBookInfoContainer}>
                    <p className={classes.sportbookCardTitle}>
                      {sportbookData.name}
                    </p>
                    <p className={classes.sportbookCardDescription}>
                      {sportbookData.description}
                    </p>
                    <div className={classes.sportbookCardPerkList}>
                      {sportbookData.perks.map((sportBookPerk) => {
                        return (
                          <div
                            key={`${sportbookData.name}-${sportBookPerk}`}
                            className={classes.sportBookPerkLabel}
                          >
                            {sportBookPerk}
                          </div>
                        );
                      })}
                    </div>
                    {sportbookData.promo ? (
                      <div
                        className={classes.promoContainer}
                        style={{ borderColor: sportbookData.promoColor }}
                      >
                        <div
                          className={classes.promoLabel}
                          style={{
                            backgroundColor: sportbookData.promoColor,
                          }}
                        >
                          <GiftIcon />
                          PROMO
                        </div>
                        {sportbookData.promo}
                      </div>
                    ) : null}
                  </div>
                  <div className={classes.sportbookCardCTAsContainer}>
                    {sportbookData.code ? (
                      <div className={classes.codeButton}>
                        <MainButton
                          clicked={() => handleCopyCode(sportbookData.code)}
                          type="secondary"
                          buttonText={`Code: ${sportbookData.code}`}
                        />
                      </div>
                    ) : null}
                    <a
                      href={`${sportbookData.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MainButton buttonText="Visit Site" icon={chainIcon} />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SportsbooksPage;
