// Vendors
import React from "react";
import { format } from "date-fns";

// Components
import IconLabel from "../../components/IconLabel/IconLabel";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";
import { twitchIcon } from "../../assets/icons/twitch/twitch";
import { graphIcon } from "../../assets/icons/graph/graph";
import ClockIcon from "../../assets/icons/clock/clock";
import { defaultTeamIcon } from "../../assets/icons/defaultTeam/defaultTeam";
import { defaultPlayerIcon } from "../../assets/icons/defaultPlayer/defaultPlayer";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Utils
import { abbreviateString } from "../../utils/globalUtils";

// Styles
import classes from "./MatchPlayerInfo.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";
import OddsTag from "../OddsTag/OddsTag";

const MatchPlayerInfo = ({
  preferredOddsType,
  seriesDetails,
  isFinishedMatch,
}) => {
  const {
    tournamentName,
    abiosGameId,
    bestOf,
    teamOne,
    teamTwo,
    startDate,
    streamUrl,
    endDate,
  } = seriesDetails;

  const teamPlayerCount = {
    [globalConstants.ROCKET_LEAGUE]: 3,
    [globalConstants.CALL_OF_DUTY]: 4,
    [globalConstants.LEAGUE_OF_LEGENDS]: 5,
    [globalConstants.VALORANT]: 5,
    [globalConstants.CS_GO]: 5,
  };

  const generateTwitchChannel = (channel) => {
    const channelAr = channel ? channel.split("/") : false;
    if (
      channelAr &&
      channelAr[channelAr.length - 1] &&
      !channel.includes("youtube")
    ) {
      return `/${channelAr[channelAr.length - 1] || channel}`;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.matchPlayerInfo}>
      <div className={classes.matchPlayerInfoTitle}>
        <div className={classes.esportLabel}>
          <IconLabel
            icon={esportsLogos[abiosGameId]}
            label={globalConstants.TITLES[abiosGameId]}
            isSvg
          />
        </div>
        {tournamentName ? (
          <div className={classes.tournamentName}>{tournamentName}</div>
        ) : null}
        {bestOf ? (
          <div className={classes.matchType}>Best of {bestOf}</div>
        ) : null}
      </div>
      <div className={classes.matchPlayerInfoContent}>
        <div className={classes.teamsRow}>
          <div className={classes.teams}>
            <div className={classes.teamContainer}>
              <div className={classes.teamImageContainer}>
                {teamOne.teamImgLg || teamOne.teamImgSm ? (
                  <img
                    src={teamOne.teamImgLg || teamOne.teamImgSm}
                    alt={teamOne.teamName}
                  />
                ) : (
                  defaultTeamIcon
                )}
              </div>
              <div className={classes.teamOddsAndName2}>
                <OddsTag
                  odds={teamOne.odds}
                  fullWidth
                  size="large"
                  preferredOddsType={preferredOddsType}
                />
                {abbreviateString(
                  teamOne.teamAbbreviation || teamOne.teamName,
                  6
                )}
              </div>
            </div>

            {isFinishedMatch ? (
              <div className={classes.finalBox}>
                <div
                  className={`${classes.scoreBox} ${
                    teamOne.seriesWinner ? classes.winnerScore : ""
                  }`}
                >
                  {teamOne.seriesScore}
                </div>
                <div className={classes.finalTextBox}>
                  <p className={classes.finalText}>FINAL</p>
                  {endDate ? (
                    <p className={classes.finalText}>
                      {format(new Date(endDate), "MM/dd/yy")}
                    </p>
                  ) : null}{" "}
                </div>
                <div
                  className={`${classes.scoreBox}  ${
                    teamTwo.seriesWinner ? classes.winnerScore : ""
                  }`}
                >
                  {teamTwo.seriesScore}
                </div>
              </div>
            ) : (
              <div className={classes.VSContainer}>VS</div>
            )}
            <div className={classes.teamContainer}>
              <div className={classes.teamImageContainer}>
                {teamTwo.teamImgLg || teamTwo.teamImgSm ? (
                  <img
                    src={teamTwo.teamImgLg || teamTwo.teamImgSm}
                    alt={teamTwo.teamName}
                  />
                ) : (
                  defaultTeamIcon
                )}
              </div>
              <div className={classes.teamOddsAndName}>
                {abbreviateString(
                  teamTwo.teamAbbreviation || teamTwo.teamName,
                  6
                )}
                <OddsTag
                  odds={teamTwo.odds}
                  fullWidth
                  size="large"
                  preferredOddsType={preferredOddsType}
                />
              </div>
            </div>
          </div>
          <div className={classes.matchTimeAndLinks}>
            {startDate ? (
              <div className={classes.matchTimeContainer}>
                <div className={classes.clockIcon}>
                  <ClockIcon color={styledVariables.backgroundEmphasisK} />
                </div>

                <p className={classes.matchTime}>
                  {format(new Date(startDate), "hh:mm aa")}
                </p>
              </div>
            ) : null}
            {generateTwitchChannel(streamUrl) ? (
              <div className={classes.matchLinkContainer}>
                <div className={classes.clockIcon}>{twitchIcon}</div>
                <a
                  className={classes.matchLink}
                  href={`//${streamUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {generateTwitchChannel(streamUrl)}
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.matchPlayersLeft}>
          {[
            ...(teamOne.players.length > 0
              ? teamOne.players
              : Array(teamPlayerCount[abiosGameId]).fill({})),
          ].map((player, index) => {
            return (
              <div
                key={`${player.name}-${index}`}
                className={classes.playerContainer}
              >
                <div className={classes.playerImageContainer}>
                  {player.playerImg ? (
                    <img src={player.playerImg} alt={player.playerName} />
                  ) : (
                    <div className={classes.playerImageContainer}>
                      {defaultPlayerIcon}
                    </div>
                  )}
                </div>
                <p className={classes.playerName}>{player.playerName}</p>
              </div>
            );
          })}
        </div>
        <div className={classes.matchPlayersRight}>
          {[
            ...(teamTwo.players.length > 0
              ? teamTwo.players
              : Array(teamPlayerCount[abiosGameId]).fill({})),
          ].map((player, index) => {
            return (
              <div
                key={`${player.name}-${index}`}
                className={classes.playerContainer}
              >
                <p className={classes.playerName}>{player.playerName}</p>
                <div className={classes.playerImageContainer}>
                  {player.playerImg ? (
                    <img src={player.playerImg} alt={player.playerName} />
                  ) : (
                    <div className={classes.playerImageContainer}>
                      {defaultPlayerIcon}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {teamOne.impliedWinPercent && teamTwo.impliedWinPercent ? (
        <div className={classes.matchPlayerInfoFooter}>
          <div className={classes.impliedWinPercent}>
            {teamOne.impliedWinPercent.toFixed(2)}%
          </div>
          <div className={classes.impliedWinPercentLabel}>
            <div className={classes.graphIconContainer}>{graphIcon}</div>
            <div className={classes.graphTextsContainer}>
              <p>Implied</p> <p>win percentage</p>
            </div>
          </div>
          <div className={classes.impliedWinPercent}>
            {teamTwo.impliedWinPercent.toFixed(2)}%
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MatchPlayerInfo;
