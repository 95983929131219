// Vendors
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { WarningOutlined } from "@ant-design/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { differenceInMilliseconds } from "date-fns";

// API
import { getCarouselData } from "../../api/requests/requests";

// Context
import { CarouselGamesContext } from "../../context/contextProviders/CarouselContextProvider";
import { UserContext } from "../../context/contextProviders/UserContextProvider";

// Components
import LiveGameCard from "../LiveGameCard/LiveGameCard";
import MainSkeleton from "../MainSkeleton/MainSkeleton";
import GameCategoryCard from "../GameCategoryCard/GameCategoryCard";
import MainSelect from "../MainSelect/MainSelect";

// Assets
import { liveIcon } from "../../assets/icons/live/live";
import { finishedIcon } from "../../assets/icons/finished/finished";
import ClockIcon from "../../assets/icons/clock/clock";

// Styles
import classes from "./LiveGames.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const LiveGames = () => {
  const [loading, setLoading] = useState(true);
  const [liveGamesDataError, setLiveGamesDataError] = useState("");

  const { getSessionData } = useContext(UserContext);
  const { carouselData, setCarouselData } = useContext(CarouselGamesContext);

  const FILTER_OPTIONS = useMemo(
    () => [
      {
        label: "All games",
        key: "ALL",
      },
      {
        label: (
          <span className={classes.labelWithIcon}>
            <span className={classes.iconContainer}>{liveIcon}</span>Live
          </span>
        ),
        key: "LIVE",
      },
      {
        label: (
          <span className={classes.labelWithIcon}>
            <span className={classes.iconContainer14Yellow}>
              <ClockIcon color={styledVariables.backgroundEmphasisK} />
            </span>
            Starting
          </span>
        ),
        key: "STARTING",
      },
      {
        label: (
          <span className={classes.labelWithIcon}>
            <span className={classes.iconContainer14}>{finishedIcon}</span>
            Finished
          </span>
        ),
        key: "FINISHED",
      },
    ],
    []
  );

  const [currentFilter, setCurrentFilter] = useState(FILTER_OPTIONS[0].key);

  const handleChangeFilter = (itemKey) => {
    setCurrentFilter(itemKey);
    setLoading(true);
    handleFetchCarouselData();
  };

  const sortSeriesByStartDate = (series, filter) => {
    const startingSeries = [...series]
      .filter((serie) => {
        return (
          differenceInMilliseconds(new Date(), new Date(serie.startDate)) < 0
        );
      })
      .sort((a, b) => {
        return (
          differenceInMilliseconds(new Date(), new Date(b.startDate)) -
          differenceInMilliseconds(new Date(), new Date(a.startDate))
        );
      });

    const liveSeries = [...series]
      .filter((serie) => {
        return serie.live;
      })
      .sort((a, b) => {
        return (
          differenceInMilliseconds(new Date(), new Date(b.startDate)) -
          differenceInMilliseconds(new Date(), new Date(a.startDate))
        );
      });

    const finishedSeries = [...series]
      .filter((serie) => {
        return (
          differenceInMilliseconds(new Date(), new Date(serie.startDate)) > 0 &&
          !serie.live
        );
      })
      .sort((a, b) => {
        return (
          differenceInMilliseconds(new Date(), new Date(b.startDate)) -
          differenceInMilliseconds(new Date(), new Date(a.startDate))
        );
      });

    return {
      ALL: [...liveSeries, ...startingSeries, ...finishedSeries],
      LIVE: [...liveSeries],
      STARTING: [...startingSeries],
      FINISHED: [...finishedSeries],
    }[filter];
  };

  const handleFetchCarouselData = useCallback(() => {
    setLiveGamesDataError("");
    getCarouselData(
      (data) => {
        setCarouselData({
          leagueOfLegends: {
            ...data.leagueOfLegends,
          },
          csgo: {
            ...data.csgo,
          },
          valorant: {
            ...data.valorant,
          },
          rocketLeague: {
            ...data.rocketLeague,
          },
          callOfDuty: {
            ...data.callOfDuty,
          },
        });
        setLoading(false);
      },
      (error) => {
        getSessionData(
          () => {},
          () => {
            setLiveGamesDataError(error.message);
            setCarouselData({});
            setLoading(false);
          }
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCarouselData]);

  const generateCarouselEntries = (gamesData) => {
    const carouselEntries = [];

    Object.entries(gamesData).forEach((game) => {
      const esportName = game[0];
      const esportData = {
        ...game[1],
        series: sortSeriesByStartDate(game[1].series, currentFilter),
      };

      carouselEntries.push(
        <GameCategoryCard
          key={`${esportName}`}
          game={esportName}
          esportData={esportData}
        />
      );
      return esportData.series.forEach((serie) => {
        carouselEntries.push(
          <LiveGameCard
            serie={serie}
            game={esportName}
            key={`${serie.startDate}-${serie.teamOne.name}-${serie.teamTwo.teamname}`}
          />
        );
      });
    });
    return carouselEntries;
  };

  useEffect(() => {
    handleFetchCarouselData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      setLiveGamesDataError("");
      getCarouselData(
        (data) => {
          setCarouselData({
            leagueOfLegends: {
              ...data.leagueOfLegends,
            },
            csgo: {
              ...data.csgo,
            },
            valorant: {
              ...data.valorant,
            },
            rocketLeague: {
              ...data.rocketLeague,
            },
            callOfDuty: {
              ...data.callOfDuty,
            },
          });
          setLoading(false);
        },
        (error) => {
          getSessionData(
            () => {},
            () => {
              setLiveGamesDataError(error.message);
              setCarouselData({});
              setLoading(false);
            }
          );
        }
      );
    }, process.env.REACT_APP_CAROUSEL_FRECUENCY || 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading && carouselData ? (
    <div className={classes.carouselContainer}>
      <div className={classes.controlsContainer}>
        <div className={classes.controls}>
          <MainSelect
            size="small"
            currentValue={FILTER_OPTIONS.find((option) => {
              return option.key === currentFilter;
            })}
            setCurrentValue={(item) => handleChangeFilter(item.key)}
            options={FILTER_OPTIONS}
          />
        </div>
      </div>
      <div className={classes.sliderContainer}>
        <Swiper
          loop={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={3000}
          centeredSlides={true}
          modules={[Autoplay]}
          freeMode={true}
          slidesPerView="auto"
          className=".swiper-container"
          spaceBetween={0}
        >
          {generateCarouselEntries(carouselData).map((card, index) => {
            return card ? <SwiperSlide key={index}>{card}</SwiperSlide> : null;
          })}
        </Swiper>
        {liveGamesDataError && (
          <div className={classes.liveGamesDataError}>
            <span>{<WarningOutlined />}</span> There was a problem loading the
            data, please try again.
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={classes.skeletonList}>
      {new Array(10).fill("").map((_, index) => {
        return (
          <div key={index}>
            <MainSkeleton
              key={index}
              type="rectangle"
              height={130}
              width={220}
              borderRad={10}
              verticalMargin={21}
              horizontalMargin={20}
            />
          </div>
        );
      })}
    </div>
  );
};

export default LiveGames;
