// Vendors
import React from "react";
import { Progress, Tooltip } from "antd";
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./ProfileTeamStats.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

const ProfileTeamStats = ({ teamStats }) => {
  return (
    <div className={classes.profileTeamStatsContainer}>
      {teamStats.map((teamData) => {
        if (isNaN(parseFloat(teamData.winrate))) {
          return null;
        }
        return (
          <div className={classes.rowContainer} key={`${teamData.teamName}`}>
            <div className={classes.labelContainer}>
              <IconLabel
                bold
                icon={teamData.logo}
                label={teamData.teamAbbreviation || teamData.teamName}
                withAbbreviation
              />
            </div>
            <Tooltip
              placement="top"
              title={`${teamData.winrate}% winrate`}
              color={styledVariables.backgroundEmphasisE}
            >
              <Progress
                percent={teamData.winrate}
                showInfo={false}
                strokeColor={styledVariables.backgroundEmphasisE}
                trailColor={styledVariables.backgroundEmphasisG}
              />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default ProfileTeamStats;
