// Vendors
import React from "react";

// Styles
import classes from "./MatchBanner.module.scss";

// Assets
import thunderpickBanner from "../../assets/images/sportsBooksBanners/thunderpickBanner.jpg";
import thunderPickVerticalBanner from "../../assets/images/sportsBooksBanners/thunderPickVerticalBanner.png";

// Constants
import { globalConstants } from "../../constants/globalConstants";

const MatchBanner = () => {
  return (
    <div className={classes.matchBannerContainer}>
      <a
        className={classes.mobileBanner}
        href={globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK}
        target="_blank"
        rel="noreferrer"
      >
        <img src={thunderpickBanner} alt="Banner" />
      </a>
      <a
        className={classes.desktopBanner}
        href={globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK}
        target="_blank"
        rel="noreferrer"
      >
        <img src={thunderPickVerticalBanner} alt="Banner" />
      </a>
    </div>
  );
};

export default MatchBanner;
