// Vendors
import React from "react";

// Styles
import classes from "./ProfilePicture.module.scss";

import defaultProfilePicture from "../../assets/images/profile/defaultProfilePicture.jpg";

const ProfilePicture = ({ image, isOnline }) => {
  return (
    <div>
      <div className={classes.profileTagContainer}>
        <img src={image || defaultProfilePicture} alt="profileplaceholder" />
      </div>
      {/* <div className={classes.onlineStatusContainer}>
        <div
          className={`${classes.onlineStatus} ${isOnline && classes.isOnline}`}
        />
      </div> */}
    </div>
  );
};

export default ProfilePicture;
