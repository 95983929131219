// Vendors
import React from "react";

export const finishedIcon = (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.71387 0.968108C5.85735 0.968108 4.07691 1.70558 2.7641 3.01834C1.45134 4.3311 0.713867 6.11168 0.713867 7.96811C0.713867 9.82453 1.45134 11.6051 2.7641 12.9179C4.07685 14.2306 5.85744 14.9681 7.71387 14.9681C9.57029 14.9681 11.3508 14.2306 12.6636 12.9179C13.9764 11.6051 14.7139 9.82453 14.7139 7.96811C14.7118 6.11219 13.9736 4.33296 12.6612 3.02074C11.3489 1.70834 9.56969 0.970208 7.71387 0.968108ZM7.71387 13.5683C6.22861 13.5683 4.80419 12.9782 3.75411 11.928C2.70386 10.8778 2.11381 9.45343 2.11381 7.96823C2.11381 6.48303 2.70386 5.05855 3.75411 4.00847C4.80425 2.95822 6.22867 2.36817 7.71387 2.36817C9.19907 2.36817 10.6235 2.95822 11.6736 4.00847C12.7239 5.05861 13.3139 6.48303 13.3139 7.96823C13.3122 9.45289 12.7217 10.8764 11.6718 11.9262C10.622 12.9761 9.19856 13.5665 7.71387 13.5683ZM9.52397 5.86808L10.5139 6.85797L7.50872 9.86311C7.37747 9.99437 7.19945 10.0682 7.01384 10.0682C6.82822 10.0682 6.65008 9.99437 6.51895 9.86311L4.91381 8.25809L5.9037 7.2682L7.01384 8.37833L9.52397 5.86808Z"
      fill="#61617B"
    />
  </svg>
);
