// Vendors
import React from "react";

// Components
import Logo from "../Logo/Logo";
import GreenFinishedIcon from "../../assets/icons/greenFinished/greenFinished";
import RedFinishedIcon from "../../assets/icons/redFinished/redFinished";

// Styles
import classes from "./VerifiedTag.module.scss";

const VerifiedTag = ({ denied }) => {
  return (
    <div
      className={`${classes.staffTagContainer}  ${
        denied ? classes.denied : ""
      }`}
    >
      <div className={classes.logo}>
        <Logo colorless />
      </div>

      <span className={classes.label}>{denied ? "DENIED" : "VERIFIED"}</span>
      <div className={`${classes.verifiedCheck}`}>
        {denied ? (
          <RedFinishedIcon size={6} />
        ) : (
          <GreenFinishedIcon size={14} />
        )}
      </div>
    </div>
  );
};

export default VerifiedTag;
