// Vendors
import React from "react";

// Styles
import classes from "./LiveCard.module.scss";

const LiveCard = ({ isOffline, removeLabel }) => {
  return (
    <div className={classes.liveCardContainer}>
      <div
        className={`${classes.liveSymbol} ${
          isOffline ? classes.offlineSymbol : ""
        }`}
      />
      {!removeLabel ? (
        <span className={classes.liveLabel}>
          {isOffline ? "OFFLINE" : "LIVE"}
        </span>
      ) : null}
    </div>
  );
};

export default LiveCard;
