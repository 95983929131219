// Vendors
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Context
import { AppContextProvider } from "./context/AppContextProvider";

// Slick  Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Styles
import classes from "./App.module.scss";
import "antd/dist/antd.min.css";
import "./App.scss";

// Page
import EsportsPage from "./pages/EsportsPage/EsportsPage";
import EsportsDetailPage from "./pages/EsportsDetailPage/EsportsDetailPage";
import SportsbooksPage from "./pages/SportsbooksPage/SportsbooksPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import OddsPage from "./pages/OddsPage/OddsPage";
import CommunityPage from "./pages/CommunityPage/CommunityPage";
import EducationPage from "./pages/EducationPage/EducationPage";
import BetVerificationPage from "./pages/BetVerificationPage/BetVerificationPage";
import EducationGuidesPage from "./pages/EducationGuidesPage/EducationGuidesPage";
// import EducationCreateGuidePage from "./pages/EducationCreateGuidePage/EducationCreateGuidePage";
import EducationGuideDetailsPage from "./pages/EducationGuideDetailsPage/EducationGuideDetailsPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import MatchPage from "./pages/MatchPage/MatchPage";
import ResultsPage from "./pages/ResultsPage/ResultsPage";

import EducationGlossaryPage from "./pages/EducationGlossaryPage/EducationGlossaryPage";
import EducationValorantPage from "./pages/EducationValorantPage/EducationValorantPage";
import EducationCSGOPage from "./pages/EducationCSGOPage/EducationCSGOPage";
import EducationRocketLeaguePage from "./pages/EducationRocketLeaguePage/EducationRocketLeaguePage";
import EducationLeagueOfLegendsPage from "./pages/EducationLeagueOfLegendsPage/EducationLeagueOfLegendsPage";
import EducationCallOfDutyPage from "./pages/EducationCallOfDutyPage/EducationCallOfDutyPage";

// Components
import Header from "./layout/Header/Header";
import TermsOfService from "./layout/TermsOfService/TermsOfService";
import LoginOrSignUp from "./layout/LoginOrSignUp/LoginOrSignUp";
import Footer from "./layout/Footer/Footer";
import LiveGames from "./components/LiveGames/LiveGames";
import DividerLine from "./components/DividerLine/DividerLine";
// import AuthGuard from "./components/AuthGuard/AuthGuard";

// Constants
import { globalConstants } from "./constants/globalConstants";
import FooterBanner from "./layout/FooterBanner/FooterBanner";

const App = () => {
  localStorage.removeItem("resultsPageDate");
  localStorage.removeItem("oddsPageDate");

  return (
    <AppContextProvider>
      <div className={classes.App} id="app">
        <BrowserRouter>
          <div className={classes.AppFooterContainer}>
            <LoginOrSignUp />
            <Header />
          </div>
          <div className={classes.AppContentContainer}>
            <LiveGames />
            <DividerLine />

            <Routes>
              <Route
                index
                element={
                  <EsportsDetailPage
                    title={globalConstants.ROUTES_TITLES.INSTALOCK}
                  />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.ESPORTS}`}
                element={
                  <EsportsPage title={globalConstants.ROUTES_TITLES.ESPORTS} />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.ESPORTS}/:game`}
                element={
                  <EsportsDetailPage
                    title={globalConstants.ROUTES_TITLES.INSTALOCK}
                  />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.PROFILE}/:id`}
                element={
                  <ProfilePage title={globalConstants.ROUTES_TITLES.PROFILE} />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.ODDS}`}
                element={
                  <OddsPage title={globalConstants.ROUTES_TITLES.ODDS} />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.SPORTSBOOKS}`}
                element={
                  <SportsbooksPage
                    title={globalConstants.ROUTES_TITLES.SPORTSBOOKS}
                  />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.COMMUNITY}`}
                element={
                  <CommunityPage
                    title={globalConstants.ROUTES_TITLES.COMMUNITY}
                  />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.MATCHES}`}
                element={
                  <ResultsPage title={globalConstants.ROUTES_TITLES.MATCHES} />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.BET_VERIFICATION}/:id`}
                element={
                  <BetVerificationPage
                    title={globalConstants.ROUTES_TITLES.BET_VERIFICATION}
                  />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.MATCH_PAGE}/:id`}
                element={
                  <MatchPage title={globalConstants.ROUTES_TITLES.MATCH_PAGE} />
                }
              />
              <Route
                path={`${globalConstants.ROUTES.EDUCATION}`}
                element={
                  <EducationPage
                    title={globalConstants.ROUTES_TITLES.EDUCATION}
                  />
                }
              >
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GLOSSARY}`}
                  element={<EducationGlossaryPage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GUIDES}`}
                  element={<EducationGuidesPage />}
                />
                {/* <Route
                path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.NEW_GUIDE}`}
                element={<EducationCreateGuidePage />}
              /> */}
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.GUIDES}/:id`}
                  element={<EducationGuideDetailsPage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.LEAGUE_OF_LEGENDS}`}
                  element={<EducationLeagueOfLegendsPage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.VALORANT}`}
                  element={<EducationValorantPage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.CSGO}`}
                  element={<EducationCSGOPage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.ROCKET_LEAGUE}`}
                  element={<EducationRocketLeaguePage />}
                />
                <Route
                  path={`/${globalConstants.ROUTES.EDUCATION}/${globalConstants.EDUCATION_SUB_ROUTES.CALL_OF_DUTY}`}
                  element={<EducationCallOfDutyPage />}
                />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>

          <div className={classes.AppFooterContainer}>
            <Footer />
          </div>
          <TermsOfService />
          <FooterBanner />
        </BrowserRouter>
      </div>
    </AppContextProvider>
  );
};

export default App;
