// Vendors
import React from "react";
import { NavLink } from "react-router-dom";

// Components
import DividerLine from "../../components/DividerLine/DividerLine";
// import MainButton from "../../components/MainButton/MainButton";
import Logo from "../../components/Logo/Logo";

// Assets
import { instalockTextIcon } from "../../assets/icons/instalockText/instalockText";
import { twitterIcon } from "../../assets/icons/twitter/twitter";
import { discordIcon } from "../../assets/icons/discord/discord";
import { InstagramIcon } from "../../assets/icons/instagram/instagram";

// Styles
import classes from "./Footer.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Utils
// import { validateEmail } from "../../utils/globalUtils";

const Footer = () => {
  // const [inputValue, setInputValue] = useState("");
  // const [emailError, setEmailError] = useState("");

  // const handleSubscribeToNewsletter = () => {
  //   if (inputValue && validateEmail(inputValue)) {
  //     setInputValue("");
  //     setEmailError("");
  //     message.success({
  //       content: "Successfully subscribed to our newsletter!",
  //       className: "successMessage",
  //     });
  //   } else {
  //     setEmailError("Please provide a valid email.");
  //   }
  // };

  return (
    <div className={classes.footerContainer}>
      <div className={classes.dividerLineDesktopContainer}>
        <DividerLine />
      </div>
      <div className={classes.footerContentContainer}>
        <div className={classes.instalockLogoContainer}>
          <Logo colorless />

          <div className={classes.instalockLogoTextContainer}>
            {instalockTextIcon}
            <span className={classes.TMContainer}>TM</span>
          </div>
        </div>
        <div className={classes.navigationContainer}>
          {globalConstants.MENU_ITEMS.map((item) => {
            if (item.key === globalConstants.PRIVACY_POLICY) {
              return (
                <a
                  key={item.key}
                  className={classes.navBarLink}
                  href={globalConstants.PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={classes.navLinkBox}>{item.label}</span>
                </a>
              );
            }

            return (
              <NavLink
                className={({ isActive }) =>
                  `${classes.navBarLink} ${
                    isActive && classes.activeNavBarLink
                  }`
                }
                key={item.key}
                to={item.key}
              >
                <span className={classes.navLinkBox}>{item.label}</span>
              </NavLink>
            );
          })}
        </div>
        <div className={classes.socialMediaContainer}>
          <p className={classes.footerSecondaryRowTitle}>Social media</p>
          <div className={classes.socialMediaIconRow}>
            <a
              href={"https://discord.gg/Z88EDs3DSJ"}
              target="_blank"
              rel="noreferrer"
            >
              {discordIcon}
            </a>
            <a
              href={"https://www.instagram.com/instalocknetwork/"}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon id="InstaLogo_Footer" />
            </a>
            <a
              href={"https://twitter.com/InstaLockNet"}
              target="_blank"
              rel="noreferrer"
            >
              {twitterIcon}
            </a>
          </div>
        </div>
        {/* <div className={classes.newsletterContainer}>
          <p className={classes.footerSecondaryRowTitle}>Newsletter</p>
          <input
            className={classes.newsLetterInput}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Your Email"
            onFocus={() => setEmailError("")}
          />
          <MainButton
            buttonText="Send"
            fullWidth="fullWidth"
            clicked={handleSubscribeToNewsletter}
          />
          <p className={classes.emailErrorText}>{emailError}</p>
        </div> */}
      </div>
      <div className={classes.dividerLineDesktopContainer}>
        <DividerLine />
      </div>
    </div>
  );
};

export default Footer;
