// Vendors
import React from "react";
import { Link } from "react-router-dom";

// Components
import IconLabel from "../IconLabel/IconLabel";

// Styles
import classes from "./GameCategoryCard.module.scss";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

const GameCategoryCard = ({ game, esportData }) => {
  return (
    <Link
      className={classes.gameCategoryCardContainer}
      to={{
        pathname: `/${globalConstants.ROUTES.ESPORTS}/${game}/`,
      }}
    >
      <div className={classes.gameTitle}>
        <IconLabel
          icon={esportsLogos[game]}
          label={globalConstants.TITLES[game]}
          isSvg
        />
      </div>
      <p className={classes.matchCountContainer}>
        {esportData?.liveGames || "No"} Live games
      </p>
    </Link>
  );
};

export default GameCategoryCard;
