import React, { createContext, useReducer } from "react";

const carouselGamesReducer = (state, action) => {
  switch (action.type) {
    case "SET_CAROUSEL_DATA": {
      return {
        ...state,
        carouselData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  carouselData: {},
};

export const CarouselGamesContext = createContext(initialState);

export const CarouselContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(carouselGamesReducer, initialState);

  const setCarouselData = (data) => {
    dispatch({
      type: "SET_CAROUSEL_DATA",
      payload: data,
    });
  };

  return (
    <CarouselGamesContext.Provider
      value={{
        carouselData: state.carouselData,
        setCarouselData,
      }}
    >
      {children}
    </CarouselGamesContext.Provider>
  );
};
