// Vendors
import React from "react";

export const pentaKillIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
  >
    <path
      d="M19.7861 8.70119L17.2903 9.88187L16.6097 12.3369L16.6088 12.3367L16.6006 12.3699L17.3287 11.9041L18.3597 10.3464L19.7164 9.73839L19.7861 8.70119Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3597 10.3464L19.7164 9.73839L19.7861 8.70119L17.2903 9.88187L16.6097 12.3369L16.6088 12.3367L16.6006 12.3699L17.3287 11.9041L18.3597 10.3464Z"
      fill="#FFB800"
    />
    <path
      d="M1.09088 8.70119L3.58665 9.88187L4.26726 12.3369L4.26819 12.3367L4.27636 12.3699L3.54828 11.9041L2.51727 10.3464L1.16054 9.73839L1.09088 8.70119Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.51727 10.3464L1.16054 9.73839L1.09088 8.70119L3.58665 9.88187L4.26726 12.3369L4.26819 12.3367L4.27636 12.3699L3.54828 11.9041L2.51727 10.3464Z"
      fill="#FFB800"
    />
    <path
      d="M4.06836 3.51962V12.1929L3.49822 9.63035L1.31266 8.44762L0.457446 6.18074L3.30817 7.65914V7.2649L0.541067 5.44805L0.320611 2.2932L0.647494 0.661377L4.06836 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.541067 5.44805L0.320611 2.2932L0.647494 0.661377L4.06836 3.51962V12.1929L3.49822 9.63035L1.31266 8.44762L0.457446 6.18074L3.30817 7.65914V7.2649L0.541067 5.44805ZM3.49178 6.69571L1.09633 5.12288L0.901193 2.33039L1.02229 1.72588L3.49178 3.78922V6.69571ZM3.49178 8.40387V8.97128L1.77621 8.04289L1.52822 7.38556L3.49178 8.40387Z"
      fill="#FFB800"
    />
    <path
      d="M16.7539 3.51962V12.1929L17.3241 9.63035L19.5096 8.44762L20.3648 6.18074L17.5141 7.65914V7.2649L20.2812 5.44805L20.5017 2.2932L20.1748 0.661377L16.7539 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2812 5.44805L20.5017 2.2932L20.1748 0.661377L16.7539 3.51962V12.1929L17.3241 9.63035L19.5096 8.44762L20.3648 6.18074L17.5141 7.65914V7.2649L20.2812 5.44805ZM17.3305 6.69571L19.7259 5.12288L19.9211 2.33039L19.8 1.72588L17.3305 3.78922V6.69571ZM17.3305 8.40387V8.97128L19.0461 8.04289L19.294 7.38556L17.3305 8.40387Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4652 1.23791H4.35705V11.4336L10.4111 15.3435L16.4652 11.4336V1.23791ZM17.33 11.9046L10.4111 16.373L3.49219 11.9046V0.373047H17.33V11.9046Z"
      fill="#3378FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.87891 3.04359L7.44779 2.31519L10.4111 8.69769L13.3744 2.31519L14.9433 3.04359L10.4111 12.8052L5.87891 3.04359Z"
      fill="#3378FF"
    />
  </svg>
);
