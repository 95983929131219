// Vendors
import React from "react";

import { SwapOutlined, DeleteOutlined } from "@ant-design/icons";

// Components
import IconLabel from "../IconLabel/IconLabel";
import MainButton from "../MainButton/MainButton";
import BetSlipInput from "../BetSlipInput/BetSlipInput";
import MainSelect from "../MainSelect/MainSelect";

// Constants
import { globalConstants } from "../../constants/globalConstants";

// Assets
import { esportsLogos } from "../../assets/icons/logos/logos";

// Styles
import classes from "./BetSlipBet.module.scss";

//  Utils
// import { receiveBetSlipOdd } from "../../utils/globalUtils";

const BetSlipBet = ({
  betData,
  changeBetProperties,
  changeBetType,
  handleSwitchBet,
  hanldeDeleteBet,
  index,
  preferredOddsType,
}) => {
  const teams = [betData.teamOne, betData.teamTwo];

  const hanldeSwitchBetTeam = () => {
    handleSwitchBet();
  };

  const betType = betData.type || "moneyLine";

  return (
    <div className={classes.betSlipBetContainer}>
      <div className={classes.esportRow}>
        <IconLabel
          icon={esportsLogos[betData.game]}
          label={globalConstants.TITLES[betData.game]}
          isSvg
        />
        <div className={classes.betControls}>
          {betData.type !== "total" ? (
            <MainButton
              size="small"
              icon={<SwapOutlined />}
              clicked={hanldeSwitchBetTeam}
              type="tertiary"
            />
          ) : null}
          <MainButton
            size="small"
            icon={<DeleteOutlined />}
            clicked={hanldeDeleteBet}
            type="tertiary"
          />
        </div>
      </div>
      <div className={classes.teamsRow}>
        <IconLabel
          icon={teams[0].imageSmall}
          label={teams[0].teamAbbreviation || teams[0].teamName}
          logoSize="logoSmall"
          size="small"
          withAbbreviation
        />
        <span className={classes.vsCointainer}>VS</span>
        <IconLabel
          icon={teams[1].imageSmall}
          label={teams[1].teamAbbreviation || teams[1].teamName}
          logoSize="logoSmall"
          size="small"
          withAbbreviation
          inverted
        />
      </div>

      <div className={classes.betMenusContainer}>
        <div className={classes.bettingOnRow}>
          {betType !== "total" ? (
            <>
              {`Betting on: `}
              <IconLabel
                icon={teams[0].imageSmall}
                label={teams[0].teamAbbreviation || teams[0].teamName}
                logoSize="logoSmall"
                size="small"
                withAbbreviation
                inverted
              />
            </>
          ) : null}
        </div>
        <div className={classes.betTypeSelector}>
          <MainSelect
            size="small"
            currentValue={globalConstants.BET_TYPE_MENU_ITEMS.find(
              (betTypeItem) => {
                return betTypeItem.key === betType;
              }
            )}
            setCurrentValue={(item) => changeBetType(item.key)}
            options={globalConstants.BET_TYPE_MENU_ITEMS}
          />
        </div>
      </div>
      <div className={classes.inputList}>
        {betType === "spread" && (
          <BetSlipInput
            betData={betData}
            changeBetProperties={changeBetProperties}
            currentValue={betData.spread}
            index={index}
            label={"Spread"}
            naming="spread"
            preferredOddsType={preferredOddsType}
            type="spread"
            error={betData?.errors?.spread}
          />
        )}
        {betType === "total" && (
          <BetSlipInput
            betData={betData}
            changeBetProperties={changeBetProperties}
            currentValue={betData.total}
            index={index}
            label={"Total"}
            naming="total"
            preferredOddsType={preferredOddsType}
            type="total"
            error={betData?.errors?.total}
          />
        )}

        <BetSlipInput
          betData={betData}
          changeBetProperties={changeBetProperties}
          currentValue={betData.odds}
          index={index}
          label={"Odds"}
          naming="odds"
          preferredOddsType={preferredOddsType}
          type="odds"
          error={betData?.errors?.odds}
        />
        <BetSlipInput
          betData={betData}
          changeBetProperties={changeBetProperties}
          currentValue={betData.risk}
          index={index}
          label="Risk"
          naming="risk"
          preferredOddsType={preferredOddsType}
          type="risk"
          error={betData?.errors?.risk}
        />
        <BetSlipInput
          betData={betData}
          changeBetProperties={changeBetProperties}
          currentValue={betData.win}
          index={index}
          label="To Win"
          naming="win"
          preferredOddsType={preferredOddsType}
          type="win"
          error={betData?.errors?.win}
        />
      </div>
    </div>
  );
};

export default BetSlipBet;
