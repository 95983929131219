// Vendors
import React, { useState } from "react";
import { Form, Input } from "antd";
import {
  CheckOutlined,
  KeyOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

// AWS
import {
  signUpAWS,
  verifyUserAWS,
  resendVerificationCodeUserAWS,
} from "../../AWS/UserPool";

// API
import { getUserAvailability } from "../../api/requests/requests";

// Components
import Logo from "../../components/Logo/Logo";
import MainButton from "../../components/MainButton/MainButton";
import MainModal from "../../components/MainModal/MainModal";
import TimerButton from "../../components/TimerButton/TimerButton";

// Styles
import classes from "./SignUp.module.scss";
import styledVariables from "../../styles/utils/_variables.scss";

// Utils
import { validateEmail } from "../../utils/globalUtils";

// Assets
import { successIcon } from "../../assets/icons/success/success";

const SignUp = ({ setModalToShow, modalToShow }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValues, setCurrentValues] = useState(0);
  const [isAvailableUsername, setIsAvailableUsername] = useState(false);
  const [isAvailableEmail, setIsAvailableEmail] = useState(false);

  const [signUpError, setSignUpError] = useState("");
  const [loading, setLoading] = useState(false);

  const generateAvailabilityErrorText = (isAvailable) => {
    const notAvailableArray = [];
    Object.entries(isAvailable).forEach((userParam) => {
      if (!userParam[1]) {
        notAvailableArray.push(userParam[0]);
      }
    });
    const notAvailableText = notAvailableArray.join(" and ");
    return `This ${notAvailableText} ${
      notAvailableArray.length > 1 ? "are" : "is"
    } already in use.`;
  };

  generateAvailabilityErrorText({ email: true, username: false });

  const handleCheckUsernameAndPasswordAvailability = (values) => {
    setLoading(true);
    setSignUpError("");

    let isAvailable = { username: true, email: true };

    getUserAvailability(
      values,
      (data) => {
        if (data.username && data.username.used) {
          isAvailable.username = false;
        }

        if (data.email && data.email.used) {
          isAvailable.email = false;
        }

        setIsAvailableUsername(isAvailable.username);
        setIsAvailableEmail(isAvailable.email);

        if (isAvailable.username && isAvailable.email) {
          setCurrentStep(currentStep + 1);
          setCurrentValues({ ...values });
          setLoading(false);
        } else {
          setSignUpError(generateAvailabilityErrorText(isAvailable));
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
        setSignUpError("Something went wrong, please try again.");
      }
    );
  };

  const handleSetPassword = (values) => {
    setSignUpError("");
    setLoading(true);

    const newValues = {
      username: currentValues.username,
      email: currentValues.email,
      password: values.password,
    };
    signUpAWS(newValues, handleSignUpSuccess, handleSignUpFail);
  };

  const handleSignUpSuccess = (values) => {
    setLoading(false);
    setCurrentValues(values);
    setCurrentStep(currentStep + 1);
  };
  const handleSignUpFail = (error) => {
    setLoading(false);
    setSignUpError(error);
  };

  const handleValidateOTP = (values) => {
    verifyUserAWS(
      currentValues.username,
      values.otp,
      successCodeHandler,
      wrongCodeHandler
    );
  };

  const wrongCodeHandler = (error) => {
    setSignUpError(error);
  };

  const successCodeHandler = () => {
    setSignUpError("");
    setCurrentStep(currentStep + 1);
  };

  const resendOtpCode = () => {
    resendVerificationCodeUserAWS(
      currentValues.username,
      () => {
        setSignUpError("");
      },
      (err) => {
        setSignUpError(err.message || JSON.stringify(err));
      }
    );
  };

  const handleFinishProcess = () => {
    setModalToShow("login");
  };

  const userNameInput = {
    name: "username",
    disabled: currentStep > 0,
    placeholder: "Username",
    prefix: <UserOutlined style={{ fontSize: "150%" }} />,
    suffix: isAvailableUsername ? (
      <CheckOutlined
        style={{
          fontSize: "150%",
          color: styledVariables.backgroundEmphasisE,
        }}
      />
    ) : null,
    rules: [{ required: true, message: "Please enter a desired username!" }],
  };

  const emailInput = {
    name: "email",
    disabled: currentStep > 0,
    placeholder: "Email address",
    prefix: <MailOutlined style={{ fontSize: "150%" }} />,
    suffix: isAvailableEmail ? (
      <CheckOutlined
        style={{
          fontSize: "150%",
          color: styledVariables.backgroundEmphasisE,
        }}
      />
    ) : null,
    rules: [
      {
        required: true,
        message: "Please enter a desired email address!",
      },
      {
        message: "Please enter a valid email address!",
        validator: (_, value) => {
          if (!value) {
            return Promise.resolve();
          } else if (validateEmail(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        },
      },
    ],
  };

  const OTPInput = {
    name: "otp",
    placeholder: "One time code",
    suffix: (
      <TimerButton
        buttonText="Resend"
        countDown={10}
        clicked={() => {
          resendOtpCode();
        }}
        type="primary"
      />
    ),
    rules: [
      {
        required: true,
        message: "Please enter the code we sent to your email!",
      },
    ],
  };

  const passwordInput = {
    name: "password",
    disabled: currentStep > 1,
    placeholder: "Password",
    type: "password",
    prefix: <KeyOutlined style={{ fontSize: "150%" }} />,
    rules: [
      { required: true, message: "Please enter a desired password!" },
      {
        message:
          "The password must have at least 8 characters, one uppercase letter, one lowercase letter, one number and one special characters.",
        validator: (_, value) => {
          if (
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
              value
            ) ||
            !value
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        },
      },
    ],
  };

  const passwordConfirmationInput = {
    name: "passwordConfirmation",
    placeholder: "Confirm Password",
    type: "password",
    prefix: <KeyOutlined style={{ fontSize: "150%" }} />,
    rules: [
      {
        required: true,
        message: "Please enter the password confirmation!",
      },
      ({ getFieldValue }) => ({
        message: "The passwords must match",
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error("The two passwords that you entered do not match!")
          );
        },
      }),
    ],
  };

  const signUpSteps = [
    {
      buttonText: "Continue",
      title: "Let's get started!",
      handleSubmitTry: handleCheckUsernameAndPasswordAvailability,
      inputs: [userNameInput, emailInput],
    },
    {
      buttonText: "Continue",
      title: "Set password",
      handleSubmitTry: handleSetPassword,
      inputs: [passwordInput, passwordConfirmationInput],
    },
    {
      buttonText: "Finish",
      title: "Account Created",
      subtitle: "Lets verify your email!",
      handleSubmitTry: handleValidateOTP,
      inputs: [OTPInput],
    },
    {
      buttonText: "Log in",
      title: "Success",
      handleSubmitTry: handleFinishProcess,
      inputs: [],
    },
  ];

  return (
    <MainModal
      open={modalToShow}
      onCancel={() => setModalToShow(false)}
      footer={null}
      closable={false}
    >
      <Form
        className={classes.formContainer}
        name="sign-up"
        initialValues={{}}
        onFinish={signUpSteps[currentStep].handleSubmitTry}
        autoComplete="off"
      >
        <div className={classes.SignUpContentContainer}>
          <div className={classes.loginModalTitle}>
            <Logo />
          </div>
          <p
            className={`${classes.greetingText} ${
              currentStep === signUpSteps.length - 1 && classes.successText
            }`}
          >
            {currentStep >= signUpSteps.length - 2 && (
              <span className={classes.successIcon}>{successIcon}</span>
            )}
            {signUpSteps[currentStep].title}
          </p>

          {currentStep >= signUpSteps.length - 2 && (
            <p className={classes.greetingText}>
              {signUpSteps[currentStep].subtitle}
            </p>
          )}

          <div className={classes.inputsContainer}>
            {signUpSteps[currentStep].inputs.map((input) => {
              const inputProps = {
                disabled: input.disabled,
                size: "large",
                type: input.type,
                placeholder: input.placeholder,
                className: classes.loginInputBox,
                prefix: input.prefix,
                suffix: input.suffix,
                bordered: false,
              };
              return (
                <Form.Item
                  key={input.name}
                  validateTrigger={["onBlur"]}
                  className={classes.formItem}
                  name={input.name}
                  rules={input.rules}
                >
                  {input.type === "password" ? (
                    <Input.Password {...inputProps} />
                  ) : (
                    <Input {...inputProps} />
                  )}
                </Form.Item>
              );
            })}
            {currentStep === 0 && (
              <div className={classes.forgotPasswordContainer}>
                <MainButton
                  buttonText="Forgot password?"
                  type="text"
                  clicked={() => setModalToShow("forgotPassword")}
                />
              </div>
            )}
            {currentStep === 2 && (
              <p className={classes.resendCodeText}>
                Code was sent to your email!
              </p>
            )}
            {currentStep === 3 && (
              <>
                <p className={classes.successSignUpText}>
                  You can now log in your InstaLock account.
                </p>
                <p className={classes.successSignUpText}>Good luck!</p>
              </>
            )}

            {signUpError && (
              <span className={classes.signUpError}>{signUpError}</span>
            )}
          </div>
        </div>
        <div className={classes.SignUpFooterContainer}>
          <div className={classes.submitContainer}>
            <MainButton
              htmlType="submit"
              buttonText={signUpSteps[currentStep].buttonText}
              fullWidth="fullWidth"
              loading={loading}
            />
          </div>

          {currentStep !== signUpSteps.length - 1 ? (
            <div className={classes.changeLoginText}>
              <span className={classes.changeLoginEmphasisText}>
                "Have an account?"
              </span>
              <MainButton
                buttonText="Sign in"
                type="text"
                clicked={() => setModalToShow("login")}
              />
            </div>
          ) : (
            <div className={classes.changeLoginSpacer} />
          )}
        </div>
      </Form>
    </MainModal>
  );
};

export default SignUp;
