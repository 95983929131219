// Vendors
import React from "react";
import { Button } from "antd";

// Styles
import classes from "./MainButton.module.scss";

const MainButton = ({
  buttonText,
  clicked,
  disabled,
  fullWidth,
  htmlType,
  icon,
  loading,
  size,
  type,
  shape,
  justification,
}) => {
  const handleClick = (event) => {
    event.target.blur();
    clicked && clicked();
  };
  return (
    <div className={`${classes.mainButtonContainer} ${classes[fullWidth]}`}>
      <Button
        className={`${classes.mainButton} ${classes[type]} ${classes[size]}`}
        onClick={!loading ? (event) => handleClick(event) : () => {}}
        icon={icon}
        block={!!fullWidth}
        htmlType={htmlType}
        disabled={disabled}
        loading={loading}
        shape={shape}
        style={{ justifyContent: justification ?? "" }}
      >
        {buttonText && (
          <span className={classes.mainButtonText}>{buttonText}</span>
        )}
      </Button>
    </div>
  );
};

export default MainButton;
