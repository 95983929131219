// Vendors
import React from "react";

// Styles
import classes from "./MatchFantasyLines.module.scss";
import { capitalizeFirstLetter } from "../../utils/globalUtils";

// Assets
import { hotstreak } from "../../assets/icons/staff/hotstreak";
import { globalConstants } from "../../constants/globalConstants";

const MatchFantasyLines = ({ seriesDetails }) => {
  const lines = seriesDetails?.playerLines?.hotstreak;

  return lines?.length > 0 ? (
    <div className={classes.fantasyLinesContainer}>
      <div className={classes.titleContainer}>
        <h3>Fantasy Prop Lines</h3>
      </div>

      <div className={classes.subTitleContainer}>
        <div className={classes.hotstreakImageContainer}>{hotstreak}</div>
        <a href={globalConstants.SPORTS_BOOKS_LINKS.HOTSTREAK}>
          Powered by HotStreak Fantasy
        </a>
      </div>

      <div className={classes.fantasyLinesList}>
        {lines.map((line) => {
          const {
            playerImg,
            playerName,
            hotStreakLine,
            hotStreakMarketType,
            teamImgLarge,
            teamName,
          } = line;
          return playerName &&
            hotStreakLine &&
            hotStreakMarketType &&
            teamName &&
            teamImgLarge ? (
            <div className={classes.lineCard}>
              <div className={classes.imageRow}>
                <>
                  {playerImg && (
                    <div className={classes.playerImg}>
                      <img src={playerImg} alt={playerName} />
                    </div>
                  )}
                  {teamImgLarge && (
                    <div className={classes.teamImg}>
                      <img src={teamImgLarge} alt={teamName} />
                    </div>
                  )}
                </>
              </div>
              <p className={classes.textRow}>
                {playerName} -{" "}
                <span>
                  {hotStreakLine} {capitalizeFirstLetter(hotStreakMarketType)}
                </span>
              </p>
            </div>
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};

export default MatchFantasyLines;
