// Vendors
import React from "react";

export const quadraKillIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="17"
    viewBox="0 0 23 17"
    fill="none"
  >
    <path
      d="M18.5723 3.51962V7.85626L19.3325 7.2649L20.4461 3.79463L22.32 2.2932L21.9931 0.661377L18.5723 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4461 3.79463L22.32 2.2932L21.9931 0.661377L18.5723 3.51962V7.85626L19.3325 7.2649L20.4461 3.79463ZM19.1488 5.95017L19.9502 3.4532L21.6858 2.06255L21.6183 1.72588L19.1488 3.78922V5.95017Z"
      fill="#FFB800"
    />
    <path
      d="M18.5723 6.40243V10.7391L19.3325 10.1477L20.4461 6.67744L22.32 5.17601L21.9931 3.54419L18.5723 6.40243Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4461 6.67744L22.32 5.17601L21.9931 3.54419L18.5723 6.40243V10.7391L19.3325 10.1477L20.4461 6.67744ZM19.1488 8.83299L19.9502 6.33601L21.6858 4.94536L21.6183 4.60869L19.1488 6.67203V8.83299Z"
      fill="#FFB800"
    />
    <path
      d="M4.73438 3.51962V7.85626L3.97418 7.2649L2.8605 3.79463L0.986627 2.2932L1.31351 0.661377L4.73438 3.51962Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8605 3.79463L0.986627 2.2932L1.31351 0.661377L4.73438 3.51962V7.85626L3.97418 7.2649L2.8605 3.79463ZM4.1578 5.95017L3.35647 3.4532L1.62086 2.06255L1.6883 1.72588L4.1578 3.78922V5.95017Z"
      fill="#FFB800"
    />
    <path
      d="M4.73438 6.40243V10.7391L3.97418 10.1477L2.8605 6.67744L0.986627 5.17601L1.31351 3.54419L4.73438 6.40243Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8605 6.67744L0.986627 5.17601L1.31351 3.54419L4.73438 6.40243V10.7391L3.97418 10.1477L2.8605 6.67744ZM4.1578 8.83299L3.35647 6.33601L1.62086 4.94536L1.6883 4.60869L4.1578 6.67203V8.83299Z"
      fill="#FFB800"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7073 1.23791H5.59924V11.4336L11.6533 15.3435L17.7073 11.4336V1.23791ZM18.5722 11.9046L11.6533 16.373L4.73438 11.9046V0.373047H18.5722V11.9046Z"
      fill="#44C300"
    />
    <path
      d="M7.61719 10.2758L8.24326 11.2996L8.86933 10.2758V3.25602H7.61719V10.2758Z"
      fill="#44C300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70508 3.25586H10.8408L12.9859 8.05352L15.131 3.25586H16.2667L12.9859 11.3279L9.70508 3.25586Z"
      fill="#44C300"
    />
  </svg>
);
