// Vendors
import React, { useEffect, useState } from "react";
import { SwitchTransition, Transition } from "react-transition-group";

// Styles
import classes from "./AffiliateBanner.module.scss";

// Assets
import esportsBetBanner from "../../assets/images/sportsBooksBanners/esportsBetBanner.png";
import esportsBetBannerMobile from "../../assets/images/sportsBooksBanners/esportsBetBannerMobile.png";
import prizepicksBanner from "../../assets/images/sportsBooksBanners/prizepicksBanner.jpg";
import prizepicksBannerMobile from "../../assets/images/sportsBooksBanners/prizepicksBannerMobile.png";
import underdogFantasyBanner from "../../assets/images/sportsBooksBanners/underdogFantasyBanner.jpg";
import underdogFantasyBannerMobile from "../../assets/images/sportsBooksBanners/underdogFantasyBannerMobile.jpg";
import thunderpickBanner from "../../assets/images/sportsBooksBanners/thunderpickBanner.jpg";
import thunderpickBannerMobile from "../../assets/images/sportsBooksBanners/thunderpickBannerMobile.jpg";
import hotstreakBannerMobile from "../../assets/images/sportsBooksBanners/hotstreakBannerMobile.png";
import hotstreakBanner from "../../assets/images/sportsBooksBanners/hotstreakBanner.png";
import bovadaBanner from "../../assets/images/sportsBooksBanners/bovadaBanner.gif";
// import cloutFantasyBanner from "../../assets/images/sportsBooksBanners/cloutFantasyBanner.png";
// import cloutFantasyBannerMobile from "../../assets/images/sportsBooksBanners/cloutFantasyBannerMobile.png";

import { globalConstants } from "../../constants/globalConstants";

const AffiliateBanner = ({ userRegion }) => {
  const generateBannerList = () => {
    return userRegion === globalConstants.REGION_TYPES.EU
      ? [
          {
            src: thunderpickBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
          },
          {
            src: esportsBetBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
          },
        ]
      : [
          {
            src: thunderpickBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
          },
          {
            src: esportsBetBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
          },
          {
            src: underdogFantasyBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.UDNERDOG_FANTASY,
          },
          {
            src: hotstreakBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.HOTSTREAK,
          },
          {
            src: prizepicksBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.PRIZE_PICKS,
          },
          // {
          //   src: cloutFantasyBanner,
          //   link: globalConstants.SPORTS_BOOKS_LINKS.CLOUT_FANTASY,
          // },
          {
            src: bovadaBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.BOVADA,
          },
        ];
  };

  const generateBannerMobileList = () => {
    return userRegion === globalConstants.REGION_TYPES.EU
      ? [
          {
            src: thunderpickBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
          },
          {
            src: esportsBetBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
          },
        ]
      : [
          {
            src: bovadaBanner,
            link: globalConstants.SPORTS_BOOKS_LINKS.BOVADA,
          },
          {
            src: thunderpickBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.THUNDERPICK,
          },
          {
            src: esportsBetBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.ESPORT_BET,
          },
          {
            src: underdogFantasyBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.UDNERDOG_FANTASY,
          },
          {
            src: hotstreakBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.HOTSTREAK,
          },
          {
            src: prizepicksBannerMobile,
            link: globalConstants.SPORTS_BOOKS_LINKS.PRIZE_PICKS,
          },
          // {
          //   src: cloutFantasyBannerMobile,
          //   link: globalConstants.SPORTS_BOOKS_LINKS.CLOUT_FANTASY,
          // },
        ];
  };

  const banners = generateBannerList();
  const bannersMobile = generateBannerMobileList();

  const [currentBanner, setCurrentBanner] = useState(0);
  const [currentMobileBanner, setCurrentMobileBanner] = useState(0);

  const [showBar, setShowBar] = useState(true);

  const handleChangeImage = () => {
    setCurrentBanner((prev) => {
      if (showBar) {
        let newBanner = prev + 1;
        if (newBanner === banners.length) {
          newBanner = 0;
        }
        return newBanner;
      }
    });
    setCurrentMobileBanner((prev) => {
      if (showBar) {
        let newBanner = prev + 1;
        if (newBanner === bannersMobile.length) {
          newBanner = 0;
        }
        return newBanner;
      }
    });
  };

  useEffect(() => {
    let interval = setInterval(() => {
      handleChangeImage();
      setShowBar((prevShow) => !prevShow);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FadeTransition = ({ children, ...rest }) => (
    <Transition {...rest}>
      {(state) => (
        <div
          className={classes.bannerContainer}
          style={{
            opacity: state === "entered" ? 1 : 0,
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );

  return (
    <SwitchTransition mode="out-in">
      <FadeTransition key={showBar ? "show" : "hide"} timeout={600}>
        <div className={classes.tabletBanner}>
          <a
            className={classes.affiliateBannerContainer}
            href={banners[currentBanner]?.link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={banners[currentBanner]?.src}
              alt={`${banners[currentBanner]?.src}`}
              onError={handleChangeImage}
            />
          </a>
        </div>
        <div className={classes.mobileBanner}>
          <a
            className={classes.affiliateBannerContainer}
            href={bannersMobile[currentMobileBanner]?.link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={bannersMobile[currentMobileBanner]?.src}
              alt={`${bannersMobile[currentMobileBanner]?.src}`}
              onError={handleChangeImage}
            />
          </a>
        </div>
      </FadeTransition>
    </SwitchTransition>
  );
};

export default AffiliateBanner;
