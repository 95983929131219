import React, { createContext, useReducer } from "react";

const BetSlipReducer = (state, action) => {
  switch (action.type) {
    case "SET_BET_SLIP_DATA": {
      return {
        ...state,
        betSlipData: action.payload,
      };
    }
    case "TOGGLE_SHOW_BET_SLIP": {
      return {
        ...state,
        showBetSlip: !state.showBetSlip,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  betSlipData: localStorage.getItem("betSlip")
    ? JSON.parse(localStorage.getItem("betSlip"))
    : {
        bets: [],
        action: "track",
      },
  showBetSlip: false,
};

export const BetSlipContext = createContext(initialState);

export const BetSlipContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BetSlipReducer, initialState);

  const setBetSlipData = (data) => {
    localStorage.setItem("betSlip", JSON.stringify(data));
    dispatch({
      type: "SET_BET_SLIP_DATA",
      payload: data,
    });
  };

  const addBetSlipSerie = (data) => {
    const betSlipBets = [...state.betSlipData.bets];
    betSlipBets.push(data);
    const newData = { ...state.betSlipData, bets: betSlipBets };
    localStorage.setItem("betSlip", JSON.stringify(newData));
    dispatch({
      type: "SET_BET_SLIP_DATA",
      payload: newData,
    });
  };

  const toggleShowBetSlip = () => {
    dispatch({
      type: "TOGGLE_SHOW_BET_SLIP",
    });
  };

  return (
    <BetSlipContext.Provider
      value={{
        betSlipData: state.betSlipData,
        setBetSlipData,
        addBetSlipSerie,
        showBetSlip: state.showBetSlip,
        toggleShowBetSlip,
      }}
    >
      {children}
    </BetSlipContext.Provider>
  );
};
