// Vendors
import React from "react";

export const successIcon = (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.745 1.32411L5.34094 6.69661L3.31833 4.674C2.55979 3.91547 1.32727 3.91547 0.568901 4.674C-0.189634 5.43254 -0.189634 6.66507 0.568901 7.42343L3.95034 10.8051C4.32951 11.1843 4.83523 11.3738 5.30917 11.3738C5.81487 11.3738 6.28881 11.1843 6.668 10.8051L13.4311 4.042C14.1896 3.28346 14.1896 2.05093 13.4311 1.29257C12.7045 0.565521 11.472 0.565521 10.7449 1.32409L10.745 1.32411Z"
      fill="white"
    />
  </svg>
);
